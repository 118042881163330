import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    height: 120,
    background: theme.palette.stale[100],
    borderRadius: 8,
    margin: 8,
    cursor: "pointer",
  },
  content: {
    padding: "16px 0",
  },
  avatar: {
    width: 75,
    height: 75,
    boxShadow: theme.mixins.shadows.md,
  },
}));
