import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const RestartIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "36.404px"}
      height={height ? height : "36.404px"}
      viewBox={viewBox ? viewBox : "0 0 36.404 36.404"}
      {...props}
    >
      <path
        id='icons8-restart'
        d='M20.2,2a.791.791,0,1,0,0,1.583A16.637,16.637,0,1,1,9.914,7.159v5.129a.791.791,0,1,0,1.583,0V4.374H3.583a.791.791,0,1,0,0,1.583h5.3A18.192,18.192,0,1,0,20.2,2Z'
        transform='translate(-2 -2)'
        fill={fill ?? "inherit"}
      />
    </SvgIcon>
  );
};
