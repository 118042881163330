import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const VideoConference: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20' height='16.667' viewBox='0 0 20 16.667' {...props}>
      <path
        id='icons8-video-conference_2_'
        data-name='icons8-video-conference (2)'
        d='M4.667,6A1.666,1.666,0,0,0,3,7.667v10a1.666,1.666,0,0,0,1.667,1.667H21.333A1.666,1.666,0,0,0,23,17.667v-10A1.666,1.666,0,0,0,21.333,6Zm0,2.5h2.5v1.667h-2.5ZM13,8.5a1.1,1.1,0,0,1,1.042.565c.711,0,1.042.778,1.042,1.691a2.118,2.118,0,0,1-.184.833.457.457,0,0,1,.166.451c-.057.483-.25.605-.373.615a2.138,2.138,0,0,1-.858,1.11v.754c.312,1.014,2.042.517,2.5,2.314H9.667c.458-1.8,2.188-1.3,2.5-2.314v-.754a2.138,2.138,0,0,1-.858-1.11c-.123-.01-.316-.133-.373-.615a.454.454,0,0,1,.164-.451,2.4,2.4,0,0,1-.182-.833A2,2,0,0,1,13,8.5ZM4.667,11.833h2.5V13.5h-2.5Zm0,3.333h2.5v1.667h-2.5ZM8.833,21a.833.833,0,1,0,0,1.667h8.333a.833.833,0,1,0,0-1.667Z'
        transform='translate(-3 -6)'
        fill={props.fill ? props.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
