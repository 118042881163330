import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { GetHealthPackageOrdersDocument, useVendorAcceptHealthPackageOrderMutation } from "../gql";
import { OrderStatusEnum } from "@health/queries/types";

export const AcceptHealthPackageOrderButton: FC<{ orderId: string }> = ({ orderId }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [fetchVendorAcceptHealthPackageOrder, { loading: isSubmittingReject }] = useVendorAcceptHealthPackageOrderMutation({
    onCompleted: data => {
      if (data.vendorAcceptHealthPackageOrder?.orderErrors.length === 0) {
        succeeded(t("Order accepted successfully"));
      } else {
        failed(t(formatMessageErrors(data?.vendorAcceptHealthPackageOrder?.orderErrors!)));
      }
    },
    onError: error => {
      failed(t(formatGraphQLError(error.graphQLErrors)));
    },
    refetchQueries: [
      {
        query: GetHealthPackageOrdersDocument,
        variables: {
          first: 10,
          filter: {
            checkoutType: "HEALTH_PACKAGE",
            status: [OrderStatusEnum.NewRequest],
          },
        },
      },
    ],
  });

  const handleAcceptClick = () => {
    fetchVendorAcceptHealthPackageOrder({
      variables: {
        id: orderId!,
      },
    });
  };
  return (
    <Button sx={{ margin: "5px" }} color={"success"} disabled={isSubmittingReject} onClick={handleAcceptClick}>
      {t("Accept")}
    </Button>
  );
};
