/* eslint-disable max-lines */
import { NotificationChannel } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  Checkbox,
  CircularProgress,
  EditIcon,
  Grid,
  RemoveIcon,
  TextField,
  Typography,
  WarningConfirmationDialog,
  useAddToast,
  useTheme,
} from "@toolkit/ui";
import { DeleteAppointmentNotificationSettingMutation, useDeleteAppointmentNotificationSettingMutation } from "pages/Settings/gql";
import { FC, useState } from "react";
import { GENERAL_ERROR_MESSAGE } from "shared/constants";
import { ReminderSettingProps } from "./types";

export const ReminderSetting: FC<ReminderSettingProps> = ({
  title,
  isConfirmation,
  reminderSetting,
  onOpenFormModal,
  onRefetchSettings,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const theme = useTheme();

  const [deleteAppointmentNotificationSettingMutation, { loading: isDeleteLoading }] = useDeleteAppointmentNotificationSettingMutation({
    onCompleted: (mutationData: DeleteAppointmentNotificationSettingMutation) => {
      const mutation = mutationData?.deleteAppointmentNotificationSetting;
      if (mutation) {
        succeeded(t("Appointment Notifications Deleted"));
        onRefetchSettings?.();
      } else {
        failed(t(GENERAL_ERROR_MESSAGE));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onDeleteReminderSettingClick = () => {
    setIsDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteModalOpen(prevState => !prevState);
  };

  const onDeleteModalConfirm = () => {
    setIsDeleteModalOpen(false);

    if (reminderSetting?.id) {
      deleteAppointmentNotificationSettingMutation({ variables: { deleteAppointmentNotificationSettingId: reminderSetting?.id } });
    }
  };

  return (
    <Grid item xs container>
      <Grid item xs container alignItems='center'>
        <Grid item xs>
          <Typography variant='h6'>{title}</Typography>
        </Grid>
        <Grid item xs={12} sm textAlign='end'>
          <Button
            disabled={isDeleteLoading}
            variant='outlined'
            startIcon={<EditIcon fill={theme.palette.common.white} />}
            onClick={() => onOpenFormModal(reminderSetting)}
          >
            {t("Edit")}
          </Button>
          <Button
            variant='text'
            disabled={isDeleteLoading}
            startIcon={
              isDeleteLoading ? (
                <CircularProgress color='inherit' size={20} sx={{ mx: 1 }} />
              ) : (
                <RemoveIcon opacity={isDeleteLoading ? 0.2 : 1} />
              )
            }
            onClick={onDeleteReminderSettingClick}
          >
            {t("Remove")}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={formGirdSpacing}>
        <Grid item xs={12} md={4}>
          <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.sm, marginBottom: 2 }}>{t("Remind the patient before")}</Typography>
          <TextField
            fullWidth
            size='small'
            type='number'
            label={t("Time (Hours)")}
            disabled
            value={reminderSetting.sendTimeInMinutes! / 60}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}>
            {isConfirmation ? t("Confirmation Message Text") : t("Reminder Message Text")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            size='small'
            label={isConfirmation ? t("Confirmation Message Text (English)") : t("Reminder Message Text (English)")}
            multiline
            rows={7}
            value={reminderSetting.englishText}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            size='small'
            label={isConfirmation ? t("Confirmation Message Text (Arabic)") : t("Reminder Message Text (Arabic)")}
            multiline
            rows={7}
            value={reminderSetting.arabicText}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}>{t("Messages Channels")}</Typography>
          <Checkbox readOnly checked={reminderSetting.channels?.includes(NotificationChannel.Sms)} />
          <Typography component='span' sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}>
            {t("SMS")}
          </Typography>
          <Checkbox readOnly checked={reminderSetting.channels?.includes(NotificationChannel.Email)} />
          <Typography component='span' sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}>
            {t("using-email")}
          </Typography>
          <Checkbox readOnly checked={reminderSetting.channels?.includes(NotificationChannel.App)} />
          <Typography component='span' sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}>
            {t("App Notification")}
          </Typography>
        </Grid>
      </Grid>

      <WarningConfirmationDialog
        isOpen={isDeleteModalOpen}
        titleText={t("Delete Confirmation")}
        bodyText={t("Are you sure you want to delete this item?")}
        confirmText={t("Delete")}
        cancelText={t("Cancel")}
        onClose={onDeleteModalClose}
        onConfirm={onDeleteModalConfirm}
      />
    </Grid>
  );
};

export default ReminderSetting;
