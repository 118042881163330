import { VisitStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomMuiDialog, Grid, IconButton, useIsMobileView, useTheme } from "@toolkit/ui";
import { Copilot } from "pages/e-visit/CoPilot";
import { CoPilotContextProvider } from "pages/e-visit/CoPilot/CoPilotContext";
import { isVisitDetailsEnabled } from "pages/e-visit/VitalSigns/others";
import { VisitTabsStatus } from "pages/programs-calls/utils";
import { FC, ReactElement, useState } from "react";
import { CompleteVisitButton } from "../CompleteButton/CompleteButton";
import { SideButtons } from "../SideButtons/SideButtons";
import { useVisitDetailsDialogItems } from "./useVisitDetailsDialogItems";

type VisitDetailsDialogProps = {
  visitStatus: VisitStatus;
  visitId: string;
  payerId: string;
  status?: VisitTabsStatus;
  color?: string;
  icon?: ReactElement;
};

export const VisitDetailsDialog: FC<VisitDetailsDialogProps> = props => {
  const { visitId, visitStatus, payerId, status, color, icon } = props;

  const [open, setOpen] = useState(false);
  const [notes, setVisitNotes] = useState("");

  const { t } = useTranslation("provider");
  const isMobile = useIsMobileView();

  const isHidden = status === (VisitTabsStatus.canceled || status === VisitTabsStatus.completed) || false;
  const isButtonDisabled = !isVisitDetailsEnabled(visitStatus);

  const handleVisitNoteChanged = (visitNotes: string) => {
    if (visitNotes) {
      setVisitNotes(visitNotes);
    }
  };

  const visitDetailsTabs = useVisitDetailsDialogItems({
    visitId,
    isHidden,
    handleVisitNoteChanged,
  });

  const [selected, setSelected] = useState(visitDetailsTabs[0]);

  const handleOnClick = el => {
    setSelected(el);
  };

  const handleDialogToggle = () => {
    setOpen(state => !state);
  };
  const theme = useTheme();
  return (
    <CustomMuiDialog
      open={open}
      maxWidth='lg'
      DialogTitleProps={{
        title: "Visit Details",
        onClose: handleDialogToggle,
      }}
      fullScreen={isMobile}
      DialogContentProps={{
        sx: {
          height: "90vh",
        },
      }}
      button={
        icon ? (
          <IconButton color='primary' onClick={handleDialogToggle} disabled={isButtonDisabled}>
            {icon}
          </IconButton>
        ) : (
          <Button
            sx={{
              whiteSpace: "nowrap",
              background: color ? color : theme.palette.warning.main,
              "&:hover": { backgroundColor: theme.palette.warning[1100] },
            }}
            disabled={isButtonDisabled}
            onClick={handleDialogToggle}
          >
            {t("Visit Details")}
          </Button>
        )
      }
      DialogActionsProps={{
        children: <CompleteVisitButton visitStatus={visitStatus} visitId={visitId} onToggle={handleDialogToggle} />,
      }}
    >
      <CoPilotContextProvider visitId={visitId} selectedDetailsTab={selected.id} doctorNotes={notes}>
        <Grid container spacing={1} flexWrap='nowrap'>
          <Grid item xs={1}>
            <SideButtons items={visitDetailsTabs} onClick={handleOnClick} selected={selected} isHidden={isHidden} />
          </Grid>

          <Grid item xs={6.6}>
            <Box sx={{ background: theme.palette.common.white }}>{selected?.component}</Box>
          </Grid>

          <Grid item xs='auto' md={4.5}>
            <Copilot visitId={visitId} payerId={payerId} />
          </Grid>
        </Grid>
      </CoPilotContextProvider>
    </CustomMuiDialog>
  );
};
