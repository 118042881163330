/* eslint-disable max-lines */
import { CareType, Visit, VisitCareType, VisitSortField, VisitStatus, VisitType } from "@health/queries/types";
import { BACKEND_DATE_TIME_FORMAT, addSpacesBetweenWords } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import {
  Box,
  CustomTableColumnProps,
  EyeIconVisitHistory,
  Grid,
  HighPriority,
  IconButton,
  TruncateTypography,
  format,
  useTheme,
} from "@toolkit/ui";
import { useBranchesQuery, useDepartmentsQuery } from "gql/queries";
import { produce } from "immer";
import moment from "moment";
import { VisitDetailsDialog } from "pages/e-visit/VitalSigns/components";
import { getCareType, getVisitStatus } from "pages/e-visit/VitalSigns/others";
import { useGetVisitDetailsStatusQuery } from "pages/programs-calls/gql";
import { useMemo } from "react";
import { ChatDialog } from "shared/components/Chat/ChatDialog";
import { PatientProfile } from "shared/modules/patient";
import { useGetDoctorsFilters } from "./useGetDoctorsFilters.hook";
import { useGetVisitActionColumn } from "./useGetVisitActionColumn";
import { useGetVisitsDoctorNameColumns } from "./useGetVisitsDoctorNameColumn.hook";

export type VisitsColumnsTypes = CustomTableColumnProps<Visit>[];

export const useVisitsColumns = ({ selfOnly }: { selfOnly: boolean }): VisitsColumnsTypes => {
  const { t } = useTranslation("provider");
  const doctorNameColumns = useGetVisitsDoctorNameColumns(selfOnly);
  const doctorFilters = useGetDoctorsFilters(selfOnly);
  const visitCallAction = useGetVisitActionColumn(selfOnly);
  const { data } = useGetVisitDetailsStatusQuery();
  const displayVisitDetails = data?.me?.vendor?.enableVisitDetails;
  const theme = useTheme();
  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Visit ID"),
        accessor: "id",
        filter: {
          type: "string",
          name: "id",
        },
      },
      {
        key: "patientName",
        header: t("Patient Name"),
        accessor: ({ patient }) => {
          return <PatientProfile patient={patient} />;
        },
        isHidden: false,
        type: "string",
        filter: {
          type: "string",
          name: "patientName",
        },
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        accessor: ({ patient }) => patient?.contactNumber || "--",
        isHidden: false,
        // isSortable: true,
        hideFromSettings: true,
        disableToggleVisibility: true,
        // sortColumnEnum: VisitSortField.PatientContactNumber,
      },
      {
        key: "created",
        header: t("Visit Time"),
        accessor: ({ created }) => (
          <Box sx={{ width: "max-content" }}>{created && format(created, "DD MMMM YYYY | HH:mm A", i18n.language)}</Box>
        ),
        isHidden: false,
        isSortable: true,
        sortColumnEnum: VisitSortField.Date,
        type: "string",
      },
      {
        key: "type",
        header: t("Visit Type"),
        accessor: ({ type }) => getCareType(type),
      },
      {
        key: "status",
        header: t("Visit Status"),
        accessor: ({ status }) => getVisitStatus(status),
        filter: {
          type: "autocomplete",
          name: "status",
          options: Object.keys(VisitStatus).map(key => ({
            label: addSpacesBetweenWords(key),
            value: VisitStatus[key],
          })),
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "status-reason",
        header: t("Status Reason"),
        accessor: ({ cancellationReason }) => <TruncateTypography text={cancellationReason || "-"} />,
      },
      {
        key: "branch-name",
        header: t("Branch Name"),
        accessor: ({ branch }) => (i18n.language == "ar" && branch?.nameAr ? branch?.nameAr : branch?.name) || "-",
      },
      {
        key: "startDate",
        header: t("Visit Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "date.gte",
          label: t("Visit Date From"),
        },
      },
      {
        key: "endDate",
        header: t("Visit Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "date.lte",
          getValueForBackend: value =>
            produce(value, draft => {
              draft["gte"] = draft?.gte ? moment(draft?.gte).format(BACKEND_DATE_TIME_FORMAT) : null;
              draft["lte"] = draft?.lte ? moment(draft?.lte).format(BACKEND_DATE_TIME_FORMAT) : null;
            }),
          label: t("Visit Date To"),
        },
      },
      {
        key: "careType",
        header: t("Care Type"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "careType",
          options: Object.keys(VisitCareType).map(key => ({
            label: addSpacesBetweenWords(key),
            value: VisitCareType[key],
          })),
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "careInitialType",
        header: t("Care Initial Type"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "careInitialType",
          options: Object.keys(CareType).map(key => ({
            label: addSpacesBetweenWords(key),
            value: CareType[key],
          })),
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "visitTypeFilter",
        header: t("Visit Type"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "types",
          options: Object.keys(VisitType).map(key => ({
            label: addSpacesBetweenWords(key),
            value: VisitType[key],
          })),
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
      ...doctorNameColumns,
      {
        key: "branches",
        header: t("Branches"),
        showOnlyForFilterField: true,
        filter: {
          multiple: true,
          type: "autocomplete",
          name: "branches",
          accessor: "branches",
          getOptionLabel: option => option?.name,
          query: useBranchesQuery,
          getValueForBackend: options => options?.map(item => item?.id),
          filterSearchKey: "name_Icontains",
        },
      },
      {
        key: "departments",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: {
          multiple: true,
          type: "autocomplete",
          name: "departments",
          accessor: "vendorDepartments",
          query: useDepartmentsQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.map(item => item?.id),
        },
      },
      ...doctorFilters,
      {
        key: "patient.id",
        header: t("Sehacity ID"),
        showOnlyForFilterField: true,
        accessor: ({ patient }) => patient?.id,
      },
      ...visitCallAction,
      {
        key: "chat",
        header: t("Chat with Patient"),
        accessor: ({ patient }) => (
          <Grid
            sx={{
              "& .MuiIconButton-root": {
                width: "100%",
              },
              "& svg": {
                color: theme.palette.primary.main,
                "& path": {
                  width: 26,
                  height: 25,
                },
              },
            }}
          >
            <ChatDialog userId={patient?.user?.id!} />
          </Grid>
        ),
      },
      {
        key: "gaps",
        header: t("Care Gaps"),
        accessor: () => (
          <Grid
            sx={{
              "& .MuiIconButton-root": {
                width: "100%",
              },
              "& svg": {
                color: theme.palette.warning.main,
                "& path": {
                  width: 26.21,
                  height: 26.21,
                },
              },
            }}
          >
            <IconButton>
              <HighPriority />
            </IconButton>
          </Grid>
        ),
      },
      {
        key: "details",
        header: t("Visit Details"),
        hideFromPreferencesAndTable: !displayVisitDetails,
        accessor: ({ id, status, healthProgramMember }) => (
          <VisitDetailsDialog
            visitStatus={status as VisitStatus}
            visitId={id}
            color={theme.palette.primary.main}
            payerId={healthProgramMember?.insuranceCompanyId!}
            icon={<EyeIconVisitHistory fill={theme.palette.primary.main} />}
          />
        ),
      },
    ];
  }, [doctorNameColumns, displayVisitDetails, doctorFilters, visitCallAction]);
};
