/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHealthPackageOrdersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.OrderSortingInput>;
}>;


export type GetHealthPackageOrdersQuery = { __typename?: 'Query', healthPackageOrders?: { __typename?: 'OrderCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, startCursor?: string | null, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename?: 'Order', id: string, number?: string | null, status: Types.OrderStatus, user?: { __typename?: 'User', firstName?: string | null, secondName?: string | null, fullName?: string | null, lastName?: string | null, mobile?: string | null } | null, lines: Array<{ __typename?: 'OrderLine', healthPackage?: { __typename?: 'HealthPackage', name: string, nameAr?: string | null } | null } | null> } }> } | null };


export const GetHealthPackageOrdersDocument = gql`
    query getHealthPackageOrders($filter: OrderFilterInput, $first: Int, $after: String, $last: Int, $before: String, $sortBy: OrderSortingInput) {
  healthPackageOrders: orders(
    first: $first
    filter: $filter
    after: $after
    last: $last
    before: $before
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    edges {
      node {
        id
        number
        status
        user {
          firstName
          secondName
          fullName
          lastName
          mobile
        }
        lines {
          healthPackage {
            name
            nameAr
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetHealthPackageOrdersQuery__
 *
 * To run a query within a React component, call `useGetHealthPackageOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthPackageOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthPackageOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetHealthPackageOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>(GetHealthPackageOrdersDocument, options);
      }
export function useGetHealthPackageOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>(GetHealthPackageOrdersDocument, options);
        }
export type GetHealthPackageOrdersQueryHookResult = ReturnType<typeof useGetHealthPackageOrdersQuery>;
export type GetHealthPackageOrdersLazyQueryHookResult = ReturnType<typeof useGetHealthPackageOrdersLazyQuery>;
export type GetHealthPackageOrdersQueryResult = Apollo.QueryResult<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>;