import { pickLocalizedText } from "@toolkit/i18n";
import { Box, CircularProgress, Typography, useTheme } from "@toolkit/ui";
import { GuidedCareTask } from "@health/queries/types";
import React, { FC } from "react";
import { NotificationContent } from "shared/components/Notifications/NotificationContent";
import { GuidedCareTaskListActions } from "../GuidedCareTaskListActions/GuidedCareTaskListActions";
import { useGuidedCareTaskNotificationGetQuery } from "../../gql";
import { IGuidedCareTaskNotificationData } from "../../types";
import { useGuidedCareTaskNotificationStyle } from "./useGuidedCareTaskNotificationStyle";

type GuidedCareTaskNotificationProps = {
  data: IGuidedCareTaskNotificationData;
};

export const GuidedCareTaskNotification: FC<GuidedCareTaskNotificationProps> = props => {
  const { data } = props;

  const { classes } = useGuidedCareTaskNotificationStyle();

  const theme = useTheme();
  const { data: guidedCareTaskData, loading } = useGuidedCareTaskNotificationGetQuery({
    variables: {
      guidedCareTaskId: "" /* data?.guidedCareTaskId */, // todo
    },
  });

  const guidedCareTask = guidedCareTaskData?.guidedCareTask as GuidedCareTask;

  return (
    <NotificationContent title={pickLocalizedText(data?.notificationTitleEn, data?.notificationTitleAr)} color={theme.palette.success.main}>
      {loading ? (
        <Box className={classes.loadingWrapper}>
          <CircularProgress color={"inherit"} size={20} />
        </Box>
      ) : (
        <Box>
          <Typography fontSize={14}>{pickLocalizedText(data?.notificationMessageEn, data?.notificationMessageAr)}</Typography>

          <GuidedCareTaskListActions guidedCareTask={guidedCareTask} />
        </Box>
      )}
    </NotificationContent>
  );
};
