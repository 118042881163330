import { TableGrid } from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { getApolloContextFormToken } from "shared/utils";
import { useVisitsVitalSignsQuery } from "../../gql";
import { useVitalSignsColumns } from "./useVitalSignsColumns";

type VitalSignsTableProps = {
  patientId: string;
  token: string;
  userId: string;
  visitId?: string;
};

export const VitalSignsTable: FC<VitalSignsTableProps> = props => {
  const { patientId, userId, visitId, token } = props;

  return (
    <GridProvider
      isCardView
      gridName='VitalSignsTable'
      columns={useVitalSignsColumns()}
      query={useVisitsVitalSignsQuery}
      context={getApolloContextFormToken(token!)}
      variables={{
        first: 10,
        filter: {
          patientId,
          visitId,
          patientUserId: userId,
        },
      }}
    >
      <TableGrid />
    </GridProvider>
  );
};
