import { makeStyles, Theme } from "@toolkit/ui";

export const useDetailsCardStyles = makeStyles<{
  status: string;
  border;
}>()((theme: Theme, { status, border }) => ({
  paper: {
    boxShadow: theme.mixins.shadows.md,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "5px",
  },
  actionPaper: {
    boxShadow: theme.mixins.shadows.md,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
  },
  header: {
    width: "100%",
  },
  title: { display: "flex" },
  icon: {
    width: "15px",
    height: "15px",
  },
  badge: {
    width: "20px",
    height: "20px",
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    borderRadius: "10px",
    marginBottom: "10px",
  },
  notifications: {
    margin: "auto",
  },
  titleIcon: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconHeader: {
    borderRadius: "23px",
    width: "35px",
    height: "35px",
    margin: "5px",
    boxShadow: theme.mixins.shadows.none,
    backgroundColor: status,
    border: border,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  iconShape: {
    backgroundColor: status,
    width: "45px",
    height: "45px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    margin: "0px 5px",
  },
  icons: {
    display: "flex",
  },
  itemAction: {
    width: "100px",
    height: "90px",
    borderRadius: theme?.direction == "ltr" ? "10px 0px 0px 10px" : "0px 10px 10px 0px",
    backgroundColor: theme.palette.error.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  font: {
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  defaultFont: {
    fontFamily: theme.mixins.fonts.fontFamily.regular,
  },
}));
