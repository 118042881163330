import { HealthParameter } from "@health/queries/types";
import { Grid, Typography, useMediaQuery, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useProfileContext } from "../../context/ProfileContext";
import { HealthParameterItem } from "./HealthParameterItem";

type HealthParametersProps = {
  title: string;
  fields: HealthParameter[] | null;
};
export const HealthParameters: FC<HealthParametersProps> = ({ title, fields }) => {
  const isMobile = useMediaQuery("(max-width:1340px)");
  const { selectedTab, patientId, token } = useProfileContext();
  const theme = useTheme();
  const getLayout = index => {
    if (fields?.length! <= 3) {
      switch (index) {
        case 0:
        case 1:
        case 2:
          return { lg: 4, width: 418 };
      }
    }
    if (fields?.length! % 4 === 0) {
      switch (index) {
        case 0:
        case 1:
          return { lg: 4, width: 418 };
        default:
          return { lg: 2, width: 200 };
      }
    }
    if (fields?.length! % 5 === 0) {
      return { lg: 4, width: 418 };
    }
    return { lg: 2, width: 200 };
  };

  const healthParameters = selectedTab === "3" ? fields : fields?.slice(0, 6);

  return (
    <>
      {!!healthParameters?.length && (
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={15} textTransform='capitalize' fontFamily={theme.mixins.fonts.fontFamily.medium}>
              {title}
            </Typography>
          </Grid>
          {healthParameters?.map((healthParameter, index) => {
            return (
              <Grid item xs={12} sm={4} md={4} lg={isMobile ? getLayout(index)?.lg : "auto"} key={healthParameter?.id}>
                <HealthParameterItem
                  token={token!}
                  patientId={patientId!}
                  width={getLayout(index)?.width}
                  code={healthParameter?.code!}
                  display={healthParameter?.display!}
                  type={healthParameter?.type!}
                  chartType={healthParameter?.chartType!}
                  unit={healthParameter?.unit?.display!}
                  referenceRange={healthParameter?.referenceRange!}
                  maxRangeValue={healthParameter?.maxNormalRangeValue!}
                  minRangeValue={healthParameter?.minNormalRangeValue!}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
