import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { ProductEditBreadCrumb, ProductListBreadCrumb } from "pages/product/constant";
import {
  VendorProductVariantDocument,
  VendorProductVariantStocksCreateMutation,
  VendorProductVariantStocksUpdateMutation,
  useVendorProductVariantQuery,
  useVendorProductVariantStocksCreateMutation,
  useVendorProductVariantStocksUpdateMutation,
} from "pages/product/gql";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ellipseName } from "utils";

export const useProductFormState = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const params = useParams();
  const productVariantId = params?.id as string;

  const { succeeded, failed } = useAddToast();

  const { data, loading: vendorProductVariantLoading } = useVendorProductVariantQuery({
    variables: {
      id: productVariantId,
    },
    skip: !productVariantId,
  });
  const vendorProductVariantId = data?.productVariant?.vendorProductVariant?.id;
  const productVariant = data?.productVariant;

  const [vendorProductVariantStocksCreate, { loading: isCreateLoading }] = useVendorProductVariantStocksCreateMutation({
    onCompleted: (response: VendorProductVariantStocksCreateMutation) => {
      const errors = response?.vendorProductVariantStocksCreate?.stockErrors;
      if (errors?.length === 0) {
        succeeded(t("Product Variant Stock Created Successfully"));
        navigate(-1);
      } else if (errors && errors?.length > 0) {
        const formattedMessage = formatMessageErrors(errors);
        failed(formattedMessage);
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedMessage = formatGraphQLError(graphQLErrors);
      failed(t(formattedMessage));
    },
    refetchQueries: [
      {
        query: VendorProductVariantDocument,
        variables: {
          id: productVariantId,
        },
      },
    ],
  });
  const [ProductVariantStocksUpdate, { loading: isUpdateLoading }] = useVendorProductVariantStocksUpdateMutation({
    onCompleted: (response: VendorProductVariantStocksUpdateMutation) => {
      const errors = response?.vendorProductVariantStocksUpdate?.stockErrors;
      if (errors && errors?.length > 0) {
        console.error(formatMessageErrors(errors), failed(formatMessageErrors(errors)));
      } else {
        succeeded(t("Product Variant Stock Update Successfully"));
      }
    },
    onError: () => {
      failed(t("Product Variant Stock Update Failed"));
    },
    refetchQueries: [
      {
        query: VendorProductVariantDocument,
        variables: {
          id: productVariantId,
        },
      },
    ],
  });

  const update = input => {
    ProductVariantStocksUpdate({
      variables: {
        ...input,
      },
    });
  };
  const create = input => {
    vendorProductVariantStocksCreate({
      variables: {
        input: {
          ...input,
        },
      },
    });
  };
  const onSubmit = (formData: any) => {
    const output111 = Object.entries(formData)
      .filter(([k]) => {
        return !k.includes("stocks") && formData[k];
      })
      .reduce((accum: any, [k, v]) => {
        accum[k] = v;
        return accum;
      }, {});
    const stocks = formData?.stocks?.map((stock: any) => {
      return {
        branch: stock?.branch?.id,
        quantity: Number(stock?.quantity),
      };
    });
    const request = {
      ...output111,
      stocks,
      productVariant: productVariantId,
    };

    const input = {
      id: productVariantId,
      input: {
        ...output111,
        stocks,
        productVariant: productVariantId,
      },
    };
    if (vendorProductVariantId === undefined) {
      create(request);
    } else {
      update(input);
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const isLoading = vendorProductVariantLoading;
  useEffect(() => {
    setBreadCrumb({
      title: t("Products Management"),
      values: [ProductListBreadCrumb(t), ProductEditBreadCrumb(ellipseName(data?.productVariant?.name!, 20))],
    });
  }, [data?.productVariant?.name]);

  return {
    create,
    update,
    onSubmit,
    isLoading,
    productVariant,
    vendorProductVariantId,
    handleCancel,
    isCreateLoading,
    isUpdateLoading,
  };
};
