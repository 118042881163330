import { guidedCareActivityTypeMapper } from "@health/enum-options";
import { GuidedCareJourneyItem, GuidedCareJourneyItemFilter } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Checkbox, FormControlLabel, Typography, useTheme } from "@toolkit/ui";
import moment from "moment/moment";
import { FC } from "react";
import { statusMapper } from "../utils";
import { ActivityItemsDialog } from "./ActivityItemsDialog";

export type MonthlyTimeLineViewProps = {
  year: number;
  activity?: GuidedCareJourneyItem;
  selectItem: GuidedCareJourneyItem;
  hasSimilar: boolean;
  hasMoreData?: boolean;
  onSelectItem: (item: GuidedCareJourneyItem) => void;
  filterInput?: GuidedCareJourneyItemFilter;
};
export const MonthlyTimeLineItem: FC<MonthlyTimeLineViewProps> = ({
  year,
  activity,
  selectItem,
  hasMoreData,
  filterInput,
  hasSimilar,
  onSelectItem,
}) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const isSelected =
    selectItem?.id === activity?.id || (selectItem?.guidedCareJourneyItemStatus === activity?.guidedCareJourneyItemStatus && hasSimilar);
  const getCurrentMonthFromActivity = moment(activity?.dueDate!).month();
  const firstDateOfMonth = moment({ year, month: getCurrentMonthFromActivity }).startOf("month");
  const startDateOfWeek = firstDateOfMonth.clone().add(activity?.["weekNumberInCurrentMonth"], "weeks").startOf("week");
  const endDateOfWeek = startDateOfWeek.clone().endOf("week");
  if (startDateOfWeek.month() !== getCurrentMonthFromActivity) {
    startDateOfWeek.startOf("month");
  }
  if (endDateOfWeek.month() !== getCurrentMonthFromActivity) {
    endDateOfWeek.endOf("month");
  }

  return (
    <>
      <Box
        p={1}
        m={1}
        sx={{
          boxShadow: theme.mixins.shadows.main,
          borderRadius: "4px",
          borderLeft: `3px solid ${statusMapper(activity?.guidedCareJourneyItemStatus!).color}`,
          background: isSelected ? theme.palette.background.default : theme.palette.common.white,
          color: theme.palette.primary.main,
          fontSize: theme.mixins.fonts.fontSize.xs,
          marginInline: "4px",
        }}
      >
        <FormControlLabel
          control={<Checkbox size='small' checked={isSelected} onClick={() => onSelectItem(activity!)} />}
          label={<Typography fontSize={theme.mixins.fonts.fontSize.xs}>{t("Select")}</Typography>}
        />

        <Typography fontSize={theme.mixins.fonts.fontSize.xs}>{moment(activity?.dueDate).format("DD-MM-YYYY")}</Typography>

        <Typography fontSize={11} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {guidedCareActivityTypeMapper(activity?.guidedCareJourneyItemType!, t)?.title}
        </Typography>

        <Typography fontSize={11}>{t("Added by")}:</Typography>

        <Typography
          fontSize={theme.mixins.fonts.fontSize.xs}
          fontFamily={theme.mixins.fonts.fontFamily.medium}
          sx={{
            width: 80,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "capitalize !important",
          }}
        >
          {activity?.guidedCareJourneyItemSource?.toLowerCase()}
        </Typography>
      </Box>
      {hasMoreData && (
        <ActivityItemsDialog
          filterInput={{
            ...filterInput,
            dueDate: {
              gte: startDateOfWeek.format("YYYY-MM-DD"),
              lte: endDateOfWeek.format("YYYY-MM-DD"),
            },
          }}
        />
      )}
    </>
  );
};
