var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog } from "@toolkit/ui";
import { useRef } from "react";
import { MedicalFormQuestionUpsertForm, } from "../../../MedicalForms/forms/MedicalFormQuestionUpsert/MedicalFormQuestionUpsertForm";
export const MedicalFormQuestionUpsertModal = props => {
    const { question, onChange } = props, rest = __rest(props, ["question", "onChange"]);
    const { t } = useTranslation("domains");
    const medicalFormQuestionUpsertFormRef = useRef(null);
    const onButtonClick = () => {
        var _a;
        (_a = medicalFormQuestionUpsertFormRef.current) === null || _a === void 0 ? void 0 : _a.submit();
    };
    return (_jsx(BaseDialog, Object.assign({}, rest, { title: question ? t("Update Question") : t("Add New Question"), confirmButton: { label: question ? t("Update") : t("Add"), onClick: onButtonClick }, cancelButton: question ? { label: t("Cancel") } : undefined }, { children: _jsx(MedicalFormQuestionUpsertForm, { question: question, ref: medicalFormQuestionUpsertFormRef, onChange: onChange }) })));
};
