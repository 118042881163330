import { DestinationType, IntegrationGraphqlError, PharmacyCredentialsInput } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import React from "react";
import { useForm } from "react-hook-form";
import { useGetPharmacyCredentialsQuery, useSetPharmacyCredentialsMutation } from "../gql";

export const useWasfatyCredentialsHook = (id?, handleToggle?, open?) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = React.useState<IntegrationGraphqlError[]>([]);
  const methods = useForm<PharmacyCredentialsInput>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const { data, loading: isLoading } = useGetPharmacyCredentialsQuery({
    variables: {
      branch: id,
      destinationType: DestinationType.Io,
    },
    skip: !open,
  });

  const [pharmacyCredentialsAction, { loading: isSubmitting }] = useSetPharmacyCredentialsMutation({
    onCompleted: () => {
      succeeded(t("Branch user credentials updated Successfully"));
      handleToggle();
    },
    onError: () => {
      failed(t("Branch user credentials update Failed"));
    },
  });
  const onSubmit = (inputData: PharmacyCredentialsInput) => {
    const { password, userName, token } = inputData;
    pharmacyCredentialsAction({
      variables: {
        destinationType: DestinationType.Io,
        pharmacyCredentials: {
          branch: id,
          password: password,
          userName: userName,
          token: token ? token : "",
        },
      },
    });
  };

  const dataCredential = data?.getPharmacyCredentials;
  return { onSubmit, errors, methods, handleSubmit, dataCredential, isLoading, isSubmitting };
};
