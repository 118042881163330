import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import { HealthProgram, HealthProgramMember, Maybe } from "@health/queries/types";
import { InsuranceInformationSection, SectionTitle } from "pages/appointments/components";

type InsuranceInformationProps = {
  activeHealthProgramMember?: Maybe<Maybe<HealthProgramMember>>;
  healthProgramsByActiveMember?: Maybe<Maybe<HealthProgram>>;
};

export const InsuranceInformation: FC<InsuranceInformationProps> = props => {
  const { activeHealthProgramMember, healthProgramsByActiveMember } = props;

  const { t } = useTranslation("provider");

  return (
    <Box>
      <SectionTitle title={t("Insurance Information")} />

      <InsuranceInformationSection label={"Company Name"} value={activeHealthProgramMember?.payer?.name} />
      <InsuranceInformationSection label={"Network Name"} value={healthProgramsByActiveMember?.insuranceNetworks?.edges?.[0]?.node?.name} />
      <InsuranceInformationSection label={"Card Id"} value={activeHealthProgramMember?.insuranceId} />
      <InsuranceInformationSection label={"Expiry Date Insurance"} value={activeHealthProgramMember?.membershipEnd} />
    </Box>
  );
};
