import { User } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, Button, EditIcon, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export const DoctorCard: FC<{ profile: User; isEditable: boolean }> = ({ profile, isEditable }) => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const languages = profile?.doctor?.languages;

  const handleGoToEditPage = () => {
    navigate("/doctor/profile/edit/" + profile?.id);
  };
  const theme = useTheme();

  return (
    <Box
      sx={{
        paddingBlock: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
          flex: 1,
          display: "flex",
          paddingLeft: "0px !important",
          alignItems: "flex-start",
        }}
      >
        <Avatar
          src={profile?.photo! as string}
          sx={{
            borderRadius: 1,
            border: "1px solid" + " !important",
            borderColor: theme.palette.gray[1400] + " !important",
            objectFit: "cover",
            width: 256,
            height: 190,
          }}
        />
        <Box ml={1}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Avatar src={profile?.vendor?.logo as string} />
            <Typography ml={1}>{profile?.vendor?.name}</Typography>
          </Box>
          <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontFamily={theme.mixins.fonts.fontFamily.medium}>
            {profile?.firstName! + profile?.lastName!}
          </Typography>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
            {profile?.doctor?.seniority}
          </Typography>
          {profile?.doctor?.isYearsOfExperiencePublic && (
            <Typography
              fontSize={theme.mixins.fonts.fontSize.sm}
              fontFamily={theme.mixins.fonts.fontFamily.regular}
              color={theme.palette.stale.main}
            >
              {profile?.doctor?.yearsOfExperience + " " + "Years of experience"}
            </Typography>
          )}
          {languages?.map((language, i) => (
            <Typography
              fontSize={theme.mixins.fonts.fontSize.sm}
              fontFamily={theme.mixins.fonts.fontFamily.regular}
              color={theme.palette.stale.main}
              key={i}
            >
              {language?.display}
            </Typography>
          ))}
        </Box>
      </Box>
      {isEditable && (
        <Button onClick={handleGoToEditPage} startIcon={<EditIcon width={15} height={15} />}>
          {t("Edit Profile")}
        </Button>
      )}
    </Box>
  );
};
