export const timeFrame = [
    {
        display: "Morning",
        startTime: "06:00",
        endTime: "12:00",
    },
    {
        display: "Afternoon",
        startTime: "12:00",
        endTime: "18:00",
    },
    {
        display: "Evening",
        startTime: "18:00",
        endTime: "24:00",
    },
];
