import { makeStyles } from "@toolkit/ui";

export const useAppointmentNotification = makeStyles<{ isMobile?: boolean }>()((theme, { isMobile }) => ({
  card: {
    padding: isMobile ? "24px 15px 22px" : 13.96,
    boxShadow: theme.mixins.shadows.none,
    fontSize: theme.mixins.fonts.fontSize.lg,
  },
  font: {
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  decline: {
    height: 40,
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: "10px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  btn: {
    height: 40,
  },
  seeAll: {
    // padding: "17px",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontFamily: theme.mixins.fonts.fontFamily.regular,
    // ":hover": {
    //   backgroundColor: "transparent",
    // },
  },

  margin: {
    marginLeft: 5,
    marginTop: 5,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "-14px",
    "& .MuiIconButton-root": {
      width: "26px",
      height: "26px",
    },
    "& svg": {
      width: 10,
      height: 10,
    },
  },
  padding: {
    paddingRight: 10,
  },
  span: {
    fontWeight: "bold",
  },
}));
