import { GuidedCareJourneyItem, GuidedCareJourneyItemFilter } from "@health/queries/types";
import moment from "moment";
import { FC } from "react";
import { ActivityItemsDialog } from "./ActivityItemsDialog";
import { AppointmentIconView } from "./AppointmentIconView";
import JourneyTimelineItem from "./TimelineItem";

type JourneyTimelineItemViewProps = {
  year: number;
  filterInput?: GuidedCareJourneyItemFilter;
  activities?: GuidedCareJourneyItem[];
  hasSimilar: boolean;
  selectItem: GuidedCareJourneyItem;
  onSelectItem: (appointment: GuidedCareJourneyItem) => void;
};
export const AnnualTimeLineItem: FC<JourneyTimelineItemViewProps> = ({
  year,
  filterInput,
  activities,
  hasSimilar,
  selectItem,
  onSelectItem,
}) => {
  return (
    <>
      {activities?.slice(0, 10)?.map((activity, index) => {
        const getCurrentMonthFromActivity = moment(activity?.dueDate!).month();
        const firstDateOfMonth = moment({ year, month: getCurrentMonthFromActivity }).startOf("month").format("YYYY-MM-DD");
        const lastDateOfMonth = moment({ year, month: getCurrentMonthFromActivity }).endOf("month").format("YYYY-MM-DD");

        return (
          <>
            <JourneyTimelineItem item={activity} key={activity?.id}>
              <AppointmentIconView
                item={activity}
                hasSimilar={hasSimilar}
                selectItem={selectItem!}
                onSelectItem={() => onSelectItem(activity)}
              />
            </JourneyTimelineItem>
            {index + 1 === 10 && activities?.length! > 10 && (
              <ActivityItemsDialog filterInput={{ ...filterInput, dueDate: { gte: firstDateOfMonth, lte: lastDateOfMonth } }} />
            )}
          </>
        );
      })}
    </>
  );
};
