import { VariantSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, OrderDirection, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { ROUTE_PATHS } from "shared/constants";
import { ProductColumns } from "../components/Columns";
import { useProductVariantsQuery } from "../gql";

const ProductsList: FC = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const handelEditRow = product => {
    navigate(ROUTE_PATHS.productsManagementVendorRoutes.products?.edit?.fullPath.replace(":id", product?.id));
  };
  useEffect(() => {
    setBreadCrumb({
      title: t("Products Management"),
      values: [
        {
          id: "products",
          name: t("Products List"),
        },
      ],
    });
  }, []);
  return (
    <GridProvider
      hasTableSetting
      gridName='productVariants'
      columns={ProductColumns(t)}
      variables={
        {
          sortBy: { field: VariantSortField.Newest, direction: OrderDirection.Desc },
        } as any
      }
      fetchPolicy='network-only'
      query={useProductVariantsQuery}
      tableAction={{
        isEditVisible: true,
        onEditRow: handelEditRow,
      }}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
export default ProductsList;
