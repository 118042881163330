import { useTheme } from "@toolkit/ui";
import React, { FC, PropsWithChildren } from "react";
import { NotificationContent } from "../NotificationContent";
import { notificationStatusMapper } from "./utils";

type AppointmentLayoutProps = PropsWithChildren<{
  status: string;
}>;

export const AppointmentLayout: FC<AppointmentLayoutProps> = ({ children, status }) => {
  const title = notificationStatusMapper[status!]?.display;
  const {
    palette: { success },
  } = useTheme();
  return (
    <NotificationContent title={title!} color={notificationStatusMapper[status!]?.color! || success.main}>
      {children}
    </NotificationContent>
  );
};
