import { useTranslation } from "@toolkit/i18n";
import { Card, CardHeader, Divider, ListItem, Tooltip, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useCoPilotContext } from "../CoPilotContext";

export const EnrolledPrograms: FC = () => {
  const { t } = useTranslation("provider");
  const { selectedVisitDiagnosis } = useCoPilotContext();

  const enrolledGuidedPrograms = selectedVisitDiagnosis?.riskTemplate?.enrolledGuidedPrograms;
  const theme = useTheme();
  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader
        title={t("Enrolled care programs")}
        titleTypographyProps={{ variant: "body1", fontFamily: theme.mixins.fonts.fontFamily.medium }}
      />
      <Divider />
      {enrolledGuidedPrograms?.length! < 1 ? (
        <ListItem>{t("No Program enrolled Found")}</ListItem>
      ) : (
        <>
          {enrolledGuidedPrograms?.map(enrolledGuidedProgram => {
            return (
              <ListItem key={enrolledGuidedProgram?.id}>
                <Tooltip title={t("you can't add enrolled Program")}>
                  <div>{enrolledGuidedProgram?.name!}</div>
                </Tooltip>
              </ListItem>
            );
          })}
        </>
      )}
    </Card>
  );
};
