import { HealthPackage } from "@health/queries/types";
import { formGirdSpacing, getMaxLengthValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, MDEditorField, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import ImagesForm from "../ImagesForm";
import { PackageFormProps } from "./PackageForm.types";
import { GeneralInformation } from "./components/GeneralInformation/PackageGeneralInfo.component";
import { usePackageFormHook } from "./usePackagesInfoHook";

const PackageForm: FC<PackageFormProps> = ({ isEditMode, healthPackage, onSubmit: handleOnSubmit, isSaving, isLoading, errors }) => {
  const { t } = useTranslation("provider");

  const { methods, onSubmit, handleSubmit, handleCancel } = usePackageFormHook({
    handleOnSubmit,
  });
  const doYouHavePackage = isEditMode ? healthPackage != undefined : true;
  const formButtonTitle = isEditMode ? t("Update") : t("Create");

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              isLoading={isLoading}
              hasCancel
              hasFormButton
              formButtonTitle={formButtonTitle}
              onNavigation={handleCancel}
              loadingIndicators={{
                edit: isEditMode && isSaving,
                save: !isEditMode && isSaving,
              }}
              newButtonDisabled={isSaving}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={isLoading} doYouHaveData={doYouHavePackage}>
                <GeneralInformation defaultValue={healthPackage as HealthPackage} errorsInput={errors} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Description")} loading={isLoading} doYouHaveData={doYouHavePackage}>
                <MDEditorField
                  controllerProps={{
                    name: "description",
                    control: methods.control,
                    rules: {
                      required: getRequiredValidation(t, true),
                      max: getMaxLengthValidation(t, 2000),
                    },
                  }}
                  defaultValue={healthPackage?.description!}
                />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Arabic Description")} loading={isLoading} doYouHaveData={doYouHavePackage}>
                <MDEditorField
                  controllerProps={{
                    name: "descriptionAr",
                    control: methods.control,
                    rules: {
                      required: getRequiredValidation(t, true),
                      max: getMaxLengthValidation(t, 2000),
                    },
                  }}
                  defaultValue={healthPackage?.descriptionAr!}
                />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData={doYouHavePackage} title={t("Package Images")}>
                <ImagesForm healthPackage={healthPackage!} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

export default PackageForm;
