import { HealthPackage, HealthPackageApprovalStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, WarningConfirmationDialog, useAddToast } from "@toolkit/ui";
import { HealthPackagePublishStatusUpdateMutation, useHealthPackagePublishStatusUpdateMutation } from "pages/HealthPackages/gql";
import { FC, useState } from "react";
import { useToggle } from "shared/hooks";

export const ToggleHealthPackagePublishStatus: FC<{ row: HealthPackage }> = ({ row }) => {
  const [checked, setChecked] = useState<boolean>(row?.isPublished);
  const { t } = useTranslation("provider");
  const { handleToggle, open } = useToggle();
  const { failed, succeeded } = useAddToast();
  const [healthPackagePublishStatusUpdateMutation, { loading }] = useHealthPackagePublishStatusUpdateMutation({
    onCompleted: (data: HealthPackagePublishStatusUpdateMutation) => {
      if (data?.healthPackagePublishStatusUpdate?.healthPackageErrors?.length! > 0) {
        failed(!checked ? t("Failed to publish health package") : t("Failed to unpublish health package"));
      } else {
        setChecked(data?.healthPackagePublishStatusUpdate?.healthPackage?.isPublished as boolean);
        succeeded(!checked ? t("Health package published successfully!") : t("Health package is now unpublished"));
      }
    },
  });
  const handlePublishPackage = () => {
    healthPackagePublishStatusUpdateMutation({
      variables: {
        id: row?.id,
        input: {
          isPublished: !checked,
        },
      },
    });
  };
  const isPublishDisabled = row?.approvalStatus !== HealthPackageApprovalStatus.Approved;
  return (
    <>
      <CustomToggleButton disabled={loading || isPublishDisabled} checked={checked} onChange={handleToggle} isLoading={loading} />
      {open && (
        <WarningConfirmationDialog
          isOpen={open}
          cancelText={t("Cancel")}
          confirmText={t("Confirm")}
          bodyText={
            checked
              ? t("Are you sure you want to unpublish this health package?")
              : t("Are you sure you want to publish this health publish?")
          }
          onConfirm={handlePublishPackage}
          onClose={handleToggle}
        />
      )}
    </>
  );
};
