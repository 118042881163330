import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, Typography, useMediaQuery, useTheme } from "@toolkit/ui";
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from "react";

type OverviewCardProps = PropsWithChildren<{
  title: string;
  height?: number;
  value: string | number;
  colorTitle?: string;
  description?: string;
  percentage?: number;
  range?: string;
  unit?: string;
  date?: string;
  goal?: string;
  width?: number | string;
  icon?: React.ReactNode;
}>;
export const OverviewCard: FC<OverviewCardProps> = ({
  width,
  height,
  title,
  unit,
  value,
  range,
  colorTitle,
  description,
  percentage,
  date,
  goal,
  icon,
  children,
}) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const ref = useRef<any>(null);

  const isMobile = useMediaQuery("(max-width:1340px)");

  const [widthCard, setWidth] = useState(0);

  useEffect(() => {
    setWidth(ref?.current?.clientWidth);

    function handleWindowResize() {
      setWidth(ref?.current?.clientWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [title, isMobile]);
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement<any>(child, { widthValue: widthCard || 200, width: widthCard + 50 });
    }
    return child;
  });

  return (
    <Box
      ref={ref}
      sx={{
        width: isMobile ? "100%" : width || 200,
        height: height ? height : 250,
        borderRadius: "10px",
        background: theme.palette.common.white,
        boxShadow: theme.mixins.shadows.sm,
      }}
    >
      <Box
        sx={{
          padding: "5px 10px",
        }}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography
            fontSize={theme.mixins.fonts.fontSize.lg}
            fontFamily={theme.mixins.fonts.fontFamily.medium}
            color={colorTitle || theme.palette.primary.main}
            whiteSpace='nowrap'
          >
            {value}{" "}
            <Typography component='span' fontSize={theme.mixins.fonts.fontSize.xs} fontFamily={theme.mixins.fonts.fontFamily.regular}>
              <sup>{unit || ""}</sup>
            </Typography>
          </Typography>

          {range && (
            <Typography fontSize={theme.mixins.fonts.fontSize.xs} whiteSpace='nowrap'>
              {range}
            </Typography>
          )}

          {percentage && (
            <Box display='flex' flexDirection={"column"} alignItems={"end"}>
              <Box display='flex' alignItems='center'>
                {percentage < 0 ? (
                  <CustomIcon icon={"graph"} color={theme.palette.error.main} />
                ) : (
                  <CustomIcon icon={"graphHigher"} color={theme.palette.success.main} />
                )}
                <Typography fontSize={theme.mixins.fonts.fontSize.xs} whiteSpace='nowrap'>
                  {percentage > 0
                    ? t("higher", { count: parseFloat(Number(percentage!).toFixed(2)) })
                    : t("lower", { count: parseFloat(Number(percentage!).toFixed(2)) })}
                </Typography>
              </Box>
            </Box>
          )}
          {icon}
        </Box>
        <Box display='flex' justifyContent='space-between'>
          {title && (
            <Typography
              fontSize={13}
              fontFamily={theme.mixins.fonts.fontFamily.medium}
              color={colorTitle || theme.palette.primary.main}
              whiteSpace='nowrap'
            >
              {title}
            </Typography>
          )}
          <Typography fontSize={theme.mixins.fonts.fontSize.xxs} color={theme.palette.stale.main} whiteSpace='nowrap'>
            {date}
          </Typography>
        </Box>

        <Box display='flex' justifyContent='space-between'>
          {description && (
            <Typography fontSize={theme.mixins.fonts.fontSize.xs} color={theme.palette.stale.main}>
              {description}
            </Typography>
          )}
          {goal && (
            <Typography
              fontSize={theme.mixins.fonts.fontSize.xs}
              mt={1}
              color={theme.palette.secondary.main}
              fontFamily={theme.mixins.fonts.fontFamily.medium}
            >
              {goal}
            </Typography>
          )}
        </Box>
      </Box>
      {childrenWithProps}
    </Box>
  );
};
