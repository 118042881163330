import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { useOrderPageContext } from "pages/Orders/state";
import { FC } from "react";
import { useOrdersViewStyles } from "./OrdersView.styles";

export const OrdersViewModeSelect: FC = () => {
  const { t } = useTranslation("provider");
  const { selectedView, onChangeView: handleChangeView } = useOrderPageContext();
  const { classes } = useOrdersViewStyles({ selectedView });

  return (
    <Box className={classes.flex}>
      <Button className={classes.selectedButtonTabs} onClick={handleChangeView}>
        {t("Tabs")}
      </Button>
      <Button className={classes.selectedButtonQueue} onClick={handleChangeView}>
        {t("Queue")}
      </Button>
    </Box>
  );
};
