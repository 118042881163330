import { useTranslation } from "@toolkit/i18n";
import { ConnectionDocumentNode, CustomIcon, CustomTableColumnProps, Rating, RatingIcon, useTheme } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";
import { ProductVariantReviewsQuery, useProductVariantsQuery } from "../gql";
import { ProductVariantReviewPublishToggle } from "./ProductVariantToggleButton";
import { ReviewContent } from "./ReviewContent";

export type ProductReviewsTypes = CustomTableColumnProps<ConnectionDocumentNode<ProductVariantReviewsQuery, "productVariantReviews">>[];

export const useReviewsColumn = (): ProductReviewsTypes => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return useMemo(() => {
    return [
      {
        key: "productVariant",
        header: t("Product Variant"),
        isHidden: true,
        showOnlyForFilterField: true,
        hideFromSettings: true,
        filter: {
          type: "autocomplete",
          query: useProductVariantsQuery,
          name: "productVariant",
          accessor: "productVariants",
          getOptionLabel: option => option?.product?.name,
          getValueForBackend: selectedOption => selectedOption?.id || null,
        },
      },
      {
        key: "rating",
        header: t("Rating"),
        accessor: row => (
          <Rating
            value={row?.rating}
            icon={<RatingIcon />}
            disabled
            emptyIcon={<CustomIcon icon='rating' color={theme.palette.gray.light} width={22} height={20} viewBox='0 0 22 22' />}
          />
        ),
        isSortable: true,
        sortDirection: "DESC",
      },
      {
        key: "content",
        header: t("Review Content"),
        accessor: row => <ReviewContent rating={row?.rating} content={row?.content as string} />,
        isSortable: true,
        sortDirection: "DESC",
      },
      {
        key: "createdBy",
        header: t("Created By"),
        accessor: ({ createdBy }) => createdBy?.fullName,
        isSortable: true,
        sortDirection: "DESC",
      },
      {
        key: "created",
        header: t("Created"),
        accessor: ({ created }) => moment(created).format("DD/MM/YYYY"),
        isSortable: true,
        sortDirection: "DESC",
      },
      {
        key: "modified",
        header: t("Modified"),
        accessor: ({ modified }) => moment(modified).format("DD/MM/YYYY"),
        isSortable: true,
        sortDirection: "DESC",
      },
      {
        key: "published",
        header: t("Published"),
        accessor: row => <ProductVariantReviewPublishToggle id={row?.id} published={row?.published} />,
        isSortable: true,
        sortDirection: "DESC",
      },
    ];
  }, []);
};
