import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, Dialog, Divider, IconButton, Typography } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCurrentWorkingBranchSelectDialogStyles } from "./CurrentWorkingBranchSelectDialog.styles";
import NurseWorkSpaceDetailsForm from "./NurseWorkSpaceDetailsForm";
import { NurseCurrentWorkSpaceDetailsVar } from "./NurseCurrentWorkSpaceDetailsVar";
import { setNurseWorkSpaceDetailsToLocalStorage } from "./utils";
import { useReactiveVar } from "@apollo/client";
import { SaveDoctorNurseAssignmentMutation, useSaveDoctorNurseAssignmentMutation } from "./gql/mutations";
import { isNurseAssignedToDoctor } from "utils";

export const NurseWorkingDetailsSelectDialog: FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { t } = useTranslation("provider");
  const { classes } = useCurrentWorkingBranchSelectDialogStyles();
  const [saveDoctorNurseAssignmentMutation, { loading: isSubmitting }] = useSaveDoctorNurseAssignmentMutation();
  const currentNurseWorkDetails = useReactiveVar(NurseCurrentWorkSpaceDetailsVar);
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmit = data => {
    const inputData = {
      branch: data?.branch && {
        id: data?.branch?.id,
        name: data?.branch?.name,
      },
      department: data?.department && {
        id: data?.department?.id,
        name: data?.department?.name,
      },
      doctors: data?.doctors,
    };
    const departmentId = data?.department?.id;
    const requestInput = data?.doctors?.map(doctor => {
      return { departmentId: departmentId, doctorId: doctor?.id };
    });
    saveDoctorNurseAssignmentMutation({
      variables: {
        input: requestInput,
      },
      onCompleted: (dataMutation: SaveDoctorNurseAssignmentMutation) => {
        if (dataMutation.saveDoctorNurseAssignment?.length) {
          NurseCurrentWorkSpaceDetailsVar(inputData);
          setNurseWorkSpaceDetailsToLocalStorage(JSON.stringify(inputData));
          data?.branch && onClose();
        }
      },
    });
  };

  return (
    <Dialog
      open={Boolean(isOpen)}
      classes={{
        paper: classes.paper,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.box}>
            <Box className={classes.header}>
              <Typography className={classes.title}>{t("Complete Your Current Working Data")}</Typography>
              {isNurseAssignedToDoctor(currentNurseWorkDetails) && (
                <IconButton
                  onClick={onClose}
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                >
                  <CustomIcon icon='miniClose' viewBox='0 0 22 22' />
                </IconButton>
              )}
            </Box>
            <Divider light className={classes.line} />
            <Box
              sx={{
                margin: "15px 0px",
              }}
            >
              <NurseWorkSpaceDetailsForm />
            </Box>
          </Box>
          <Box className={classes.action}>
            <Button disabled={isSubmitting} className={classes.selectBtn} type='submit'>
              {t("Assign")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  );
};
