import React, { FC, startTransition, Suspense } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { VitalSignsTable } from "./VitalSignsTable";

type VitalSignDialogProps = {
  display: string;
  children: React.ReactNode;
  token: string;
  patientId: string;
  userId: string;
  visitId?: string;
};

export const VitalSignDialog: FC<VitalSignDialogProps> = props => {
  const { display, userId, visitId, token, patientId, children } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const { t } = useTranslation("provider");

  const handleOpen = () => {
    startTransition(() => {
      setIsOpen(true);
    });
  };

  const handleClose = () => {
    startTransition(() => {
      setIsOpen(false);
    });
  };

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const width = child?.props?.width;

      return React.cloneElement<any>(child, {
        widthValue: !isOpen ? width : "100%",
        width: !isOpen ? width : "100%",
      });
    }

    return child;
  });

  return (
    <>
      <Box onClick={handleOpen}>{childrenWithProps}</Box>
      {isOpen && (
        <CustomDialog
          title={display}
          open={isOpen}
          maxWidth='md'
          onCloseModal={handleClose}
          keepMounted={true}
          footerComponent={
            <Button variant='outlined' onClick={handleClose}>
              {t("Close")}
            </Button>
          }
          DialogContentProps={{ sx: { maxHeight: "100vh" } }}
        >
          <Suspense
            fallback={
              <Box display='flex' justifyContent='center' m={1}>
                <CircularProgress size={50} />
              </Box>
            }
          >
            <Box my={2}>{childrenWithProps}</Box>
            <VitalSignsTable token={token} patientId={patientId} userId={userId} visitId={visitId} />
          </Suspense>
        </CustomDialog>
      )}
    </>
  );
};
