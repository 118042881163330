import { PageWrapper, TableGrid } from "@toolkit/ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useProfessionalsQuery } from "../gql";
import { useProfessionalsColumns } from "./useProfessionalsColumns";

export const ProfessionalsList = () => {
  const navigate = useNavigate();
  const handleEditRow = data => {
    navigate("edit/" + data?.user?.id);
  };

  return (
    <GridProvider
      gridName='doctors'
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEditRow,
      }}
      columns={useProfessionalsColumns()}
      query={useProfessionalsQuery}
    >
      <PageWrapper>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
