import { DiabetesIcon } from "@toolkit/ui";
import moment from "moment";
import { patientProfileColors } from "../../constants/colors";

export const getChronicDiseasesSectionContent = <
  T extends { code?: string | null; id?: string; display?: string | null; createdDate?: string }
>(
  chronicDiseases: T[] | null,
  title: string,
  handleDelete: (id) => void
): Array<{ title; status; xs; fontWeight; height; isEditedByDoctor?; icon? }> => {
  return chronicDiseases && chronicDiseases?.length > 0
    ? chronicDiseases?.map(el => ({
        title: el?.code + " " + el?.display || "N/A",
        date: moment(el?.createdDate).format("DD MMM YYYY"),
        isEditedByDoctor: true,
        status: patientProfileColors.red,
        xs: 4,
        icon: <DiabetesIcon viewBox='-3 -7 32 32' />,
        height: "100px",
        fontWeight: "bold",
        hasDelete: true,
        handleDelete: () => handleDelete(el?.id),
      }))
    : [
        {
          title: title,
          status: patientProfileColors.lightGrey,
          xs: 4,
          height: "100px",
          fontWeight: "bold",
        },
      ];
};
