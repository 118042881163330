/* eslint-disable react-hooks/rules-of-hooks */
import { LinearProgress, styled, Typography, useTheme } from "@toolkit/ui";
import { getAdherenceColor } from "pages/GuidedCarePatients/others/utils";
import { FC } from "react";

type AdherenceProgressProps = {
  value: number;
};

export const AdherenceProgress: FC<AdherenceProgressProps> = props => {
  const { value } = props;

  if (!value) {
    return <> -- </>;
  }

  const theme = useTheme();
  const { color, theme: colorTheme } = getAdherenceColor(value, theme.palette);
  return (
    <>
      <Typography color={color} fontSize={theme.mixins.fonts.fontSize.xs} fontFamily={theme.mixins.fonts.fontFamily.medium}>
        {value}%
      </Typography>
      {/* @ts-ignore*/}
      <BorderLinearProgress variant='determinate' value={value!} color={colorTheme} />
    </>
  );
};

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
}));
