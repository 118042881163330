import { HealthPackage } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Grid, ImageUploadController } from "@toolkit/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

export interface HealthPackageProps {
  healthPackage?: Partial<HealthPackage>;
}

const ImagesForm: FC<HealthPackageProps> = ({ healthPackage }) => {
  const { t } = useTranslation("provider");
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { accessToken } = useOidcUserProfile();

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImageUploadController
            hasMultipleImages
            control={control}
            label={t("Health Package Thumbnail")}
            defaultValue={healthPackage?.mainImageUrl ? decodeURI(healthPackage?.mainImageUrl!) : undefined}
            name='mainImageUrl'
            rules={{
              required: {
                value: true,
                message: t("Image is required"),
              },
            }}
            error={!!errors?.mainImageUrl?.message}
            helperText={errors?.mainImageUrl?.message}
            token={accessToken!}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ImagesForm;
