import { Money } from "@health/queries/types";
import {
  NumericPattern,
  formGirdBreakPoints,
  formGirdSpacing,
  getNumericValidation,
  getPositiveNumber,
  getRequiredValidation,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, InputAdornment, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

const PriceInformation: FC<{ price: Money | null | undefined }> = ({ price }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation("provider");
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          placeholder={t("Price")}
          label={t("Price")}
          fullWidth
          type='text'
          defaultValue={price?.amount}
          error={Boolean(errors?.price?.message)}
          helperText={errors?.price?.message}
          InputProps={{
            endAdornment: <InputAdornment position='end'>{price?.currency}</InputAdornment>,
            inputProps: {
              inputMode: "numeric",
              pattern: "[0-9]*",
            },
          }}
          {...register("price", {
            required: getRequiredValidation(t, true),
            min: getPositiveNumber(t, 0),
            pattern: getNumericValidation(t, NumericPattern),
          })}
        />
      </Grid>
    </Grid>
  );
};

export default PriceInformation;
