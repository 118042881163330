import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, EmptyCard, Grid, ShowButton, Typography, useTheme } from "@toolkit/ui";
import { Fragment, useState } from "react";

const ShowProductStocks = ({ stocks }: any) => {
  const { t } = useTranslation("provider");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(!open);
  };
  const theme = useTheme();
  return (
    <CustomDialog open={open} title={t("Product Stocks")} onCloseModal={handleClickOpen} button={<ShowButton onClick={handleClickOpen} />}>
      {stocks == null ? (
        <EmptyCard title={t("There are no stocks")} />
      ) : (
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <Typography fontFamily={theme.mixins.fonts.fontFamily.medium} color='primary' gutterBottom>
              {t("Branch")}
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography fontFamily={theme.mixins.fonts.fontFamily.medium} color='primary' gutterBottom>
              {t("Quantity")}
            </Typography>
          </Grid>
          {stocks?.map(stock => (
            <Fragment key={stock.branch?.id}>
              <Grid xs={6} item>
                <Typography color={theme.palette.primary.main}>{stock?.branch?.name}</Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography color={theme.palette.primary.main}>{stock?.quantity}</Typography>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      )}
    </CustomDialog>
  );
};
export default ShowProductStocks;
