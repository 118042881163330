import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomIcon, CustomTable, Typography, useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { LabTestsForm } from "./LabTestsForm";
import { useColumns } from "./useColumns";
import { useLabsHook } from "./useLabsHook";

export const LabTests: FC<{ visitId: string; token?: string }> = ({ visitId, token }) => {
  const columns = useColumns();
  const { t } = useTranslation("provider");
  const {
    labs,
    loading,
    selectedRow,
    errors,
    isSubmittingCreate,
    isSetResultCreate,
    handleCreate,
    handleDelete,
    handleEdit,
    handleSetLabResult,
  } = useLabsHook(visitId, token);
  const methods = useForm();
  const { handleSubmit } = methods;
  const theme = useTheme();
  return (
    <Box sx={{ p: 2, background: theme.palette.common.white }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(selectedRow ? handleSetLabResult : handleCreate)}>
          <LabTestsForm data={selectedRow!} />
          {errors?.map((item, index) => (
            <Typography fontSize={theme.mixins.fonts.fontSize.sm} color='error' key={index}>
              {item?.message!}
            </Typography>
          ))}
          <Button
            disabled={isSubmittingCreate || isSetResultCreate}
            sx={{
              margin: 2,
            }}
            startIcon={
              isSubmittingCreate || isSetResultCreate ? (
                <CircularProgress size={20} sx={{ color: theme.palette.common.white }} />
              ) : (
                <CustomIcon icon='add' color={theme.palette.common.white} />
              )
            }
            type='submit'
          >
            {selectedRow ? t("Add Result") : t("Add Lab")}
          </Button>
        </form>
      </FormProvider>

      <CustomTable
        isLoading={loading}
        data={labs || []}
        columns={columns || []}
        pageSize={10}
        pageIndex={0}
        pagesCount={10}
        isDeleteVisible
        isRowEditable={row => !row?.resultReceived}
        isRowDeletable
        isEditVisible
        hasFooter={false}
        // eslint-disable-next-line react/jsx-handler-names
        onSortColumn={() => {}}
        onDeleteRow={handleDelete}
        onEditRow={handleEdit}
      />
    </Box>
  );
};

LabTests.defaultProps = {
  token: undefined,
};
