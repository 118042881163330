import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const Reboot: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='109.541' height='109.541' viewBox='0 0 109.541 109.541' {...props}>
      <path
        id='icons8-reboot'
        d='M56.77,2a54.77,54.77,0,1,0,54.77,54.77h-4.763A50.021,50.021,0,1,1,90.369,19.739l-7.4,8.456L104.4,30.576,99.634,9.144l-6.139,7.014A54.575,54.575,0,0,0,56.77,2Z'
        transform='translate(-2 -2)'
        fill={palette.primary[1200]}
      />
    </SvgIcon>
  );
};
