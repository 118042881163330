/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantReviewPublishMutationVariables = Types.Exact<{
  productVariantReviewPublishId: Types.Scalars['ID'];
  input: Types.ProductVariantReviewPublishInput;
}>;


export type ProductVariantReviewPublishMutation = { __typename?: 'Mutation', productVariantReviewPublish?: { __typename?: 'ProductVariantReviewPublish', productErrors: Array<{ __typename?: 'ProductError', code: Types.ProductErrorCode, field?: string | null, message?: string | null }>, productVariantReview?: { __typename?: 'ProductVariantReview', id: string, published: boolean } | null } | null };


export const ProductVariantReviewPublishDocument = gql`
    mutation ProductVariantReviewPublish($productVariantReviewPublishId: ID!, $input: ProductVariantReviewPublishInput!) {
  productVariantReviewPublish(id: $productVariantReviewPublishId, input: $input) {
    productErrors {
      code
      field
      message
    }
    productVariantReview {
      id
      published
    }
  }
}
    `;
export type ProductVariantReviewPublishMutationFn = Apollo.MutationFunction<ProductVariantReviewPublishMutation, ProductVariantReviewPublishMutationVariables>;

/**
 * __useProductVariantReviewPublishMutation__
 *
 * To run a mutation, you first call `useProductVariantReviewPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariantReviewPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariantReviewPublishMutation, { data, loading, error }] = useProductVariantReviewPublishMutation({
 *   variables: {
 *      productVariantReviewPublishId: // value for 'productVariantReviewPublishId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductVariantReviewPublishMutation(baseOptions?: Apollo.MutationHookOptions<ProductVariantReviewPublishMutation, ProductVariantReviewPublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductVariantReviewPublishMutation, ProductVariantReviewPublishMutationVariables>(ProductVariantReviewPublishDocument, options);
      }
export type ProductVariantReviewPublishMutationHookResult = ReturnType<typeof useProductVariantReviewPublishMutation>;
export type ProductVariantReviewPublishMutationResult = Apollo.MutationResult<ProductVariantReviewPublishMutation>;
export type ProductVariantReviewPublishMutationOptions = Apollo.BaseMutationOptions<ProductVariantReviewPublishMutation, ProductVariantReviewPublishMutationVariables>;