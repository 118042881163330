import { makeStyles } from "@toolkit/ui";

export const usePatientProfileTabContainerStyle = makeStyles()(theme => ({
  content: {
    padding: 30,
    "&:nth-child(even)": {
      background: theme.palette.background.default,
    },
  },
  root: {
    background: theme.palette.background.default,
    padding: "40px 21px 40px 29px",
    ["@media (min-width:1200px)"]: {
      maxWidth: "1341px",
      marginInline: "auto",
    },
  },
  rootTab: {
    padding: "20px",
    ["@media (min-width:1200px)"]: {
      maxWidth: "1422px",
      marginInline: "auto",
    },
  },
  defaultRoot: {
    paddingLeft: 0,
  },
  container: {
    background: theme.palette.background.default,
    padding: 0,
  },
}));
