/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetPharmacyCredentialsMutationVariables = Types.Exact<{
  destinationType: Types.DestinationType;
  pharmacyCredentials: Types.PharmacyCredentialsInput;
}>;


export type SetPharmacyCredentialsMutation = { __typename?: 'Mutation', setPharmacyCredentials?: { __typename?: 'PharmacyCredentials', userName?: string | null, branch?: { __typename?: 'Branch', id: string } | null } | null };


export const SetPharmacyCredentialsDocument = gql`
    mutation setPharmacyCredentials($destinationType: DestinationType!, $pharmacyCredentials: PharmacyCredentialsInput!) {
  setPharmacyCredentials(
    destinationType: $destinationType
    pharmacyCredentials: $pharmacyCredentials
  ) {
    userName
    branch {
      id
    }
  }
}
    `;
export type SetPharmacyCredentialsMutationFn = Apollo.MutationFunction<SetPharmacyCredentialsMutation, SetPharmacyCredentialsMutationVariables>;

/**
 * __useSetPharmacyCredentialsMutation__
 *
 * To run a mutation, you first call `useSetPharmacyCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPharmacyCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPharmacyCredentialsMutation, { data, loading, error }] = useSetPharmacyCredentialsMutation({
 *   variables: {
 *      destinationType: // value for 'destinationType'
 *      pharmacyCredentials: // value for 'pharmacyCredentials'
 *   },
 * });
 */
export function useSetPharmacyCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<SetPharmacyCredentialsMutation, SetPharmacyCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPharmacyCredentialsMutation, SetPharmacyCredentialsMutationVariables>(SetPharmacyCredentialsDocument, options);
      }
export type SetPharmacyCredentialsMutationHookResult = ReturnType<typeof useSetPharmacyCredentialsMutation>;
export type SetPharmacyCredentialsMutationResult = Apollo.MutationResult<SetPharmacyCredentialsMutation>;
export type SetPharmacyCredentialsMutationOptions = Apollo.BaseMutationOptions<SetPharmacyCredentialsMutation, SetPharmacyCredentialsMutationVariables>;