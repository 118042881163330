import { Visit, VisitType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, Button, Grid, RescheduleIcon, TruncateTypography } from "@toolkit/ui";
import { AppointmentReschedule } from "pages/appointments";
import { FC } from "react";
import { ChatDialogWithUnreadMessageCount } from "shared/components/Chat/ChatDialogWithUnreadMessageCount";
import ChatButton from "./ChatButton.component";
import { JoinCall } from "./JoinCall.component";
import { RescheduleButton } from "./RescheduleButton.component";
import { VisitWaitingTime } from "./VisitWaitingTime";
import { useStyles } from "./WaitingRoomsCard.styles";

export const WaitingRoomsCard: FC<{ visit: Visit }> = ({ visit }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={3} container alignItems='center' justifyContent='center'>
        <Avatar src={visit?.doctor?.user?.photo!} className={classes.avatar} />
      </Grid>
      <Grid item xs={9} className={classes.content}>
        <TruncateTypography fontSize={theme.mixins.fonts.fontSize.md} fontWeight='bold' text={"Dr. " + visit?.doctor?.user?.fullName!} />
        <TruncateTypography
          sx={{ marginTop: "5px" }}
          fontSize={theme.mixins.fonts.fontSize.sm}
          fontFamily={theme.mixins.fonts.fontFamily.regular}
          text={t("patient-name", { name: visit.patientUser?.fullName })}
        />
        <VisitWaitingTime visit={visit} />
        <Box sx={{ marginTop: "3px" }}>
          {visit?.type === VisitType.DirectCall ? (
            <RescheduleButton disabled={true} t={t} />
          ) : (
            <AppointmentReschedule
              status={visit?.appointment?.status!}
              id={visit?.appointment?.id!}
              doctorId={visit?.doctor?.id}
              patient={visit?.appointment?.consumer}
              paymentInfo={visit?.appointment?.paymentInfo}
              paymentStatus={visit?.appointment?.paymentStatus}
              buttonEl={
                <Button className={classes?.rescheduleButton} startIcon={<RescheduleIcon width={"16px"} height={"16px"} />} variant='text'>
                  {t("Reschedule")}
                </Button>
              }
            />
          )}
          <ChatDialogWithUnreadMessageCount
            renderButton={(onClick, unread) => {
              return <ChatButton onClick={onClick} unread={unread} t={t} />;
            }}
            userId={visit?.patientUser?.id!}
            meetingPlatformLinkForChatView={visit?.meetingPlatformLinkForChatView!}
          />
          <JoinCall t={t} visit={visit} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default WaitingRoomsCard;
