import { GuidedCareTaskActionsAppointment } from "pages/GuidedCareTasks/components/GuidedCareTaskActionsAppointment/GuidedCareTaskActionsAppointment";
import { GuidedCareTaskActionsMedicalForm } from "pages/GuidedCareTasks/components/GuidedCareTaskActionsMedicalForm/GuidedCareTaskActionsMedicalForm";
import { GuidedCareTaskUpdateButton } from "../GuidedCareTaskUpdateButton/GuidedCareTaskUpdateButton";
import { GuidedCareTask, TaskWorkflowType } from "@health/queries/types";
import { Box } from "@toolkit/ui";
import { isGuidedCareTaskActionable } from "pages/GuidedCareTasks/others";
import { FC } from "react";

type GuidedCareTaskListActionsProps = {
  guidedCareTask: GuidedCareTask;
  isUpdateShown?: boolean;
};

export const GuidedCareTaskListActions: FC<GuidedCareTaskListActionsProps> = props => {
  const { guidedCareTask, isUpdateShown = true } = props;

  const taskWorkflowType = guidedCareTask?.taskDefinition?.taskWorkflowType;

  if (!isGuidedCareTaskActionable(guidedCareTask?.status!)) {
    return <></>;
  }

  return (
    <Box>
      {taskWorkflowType === TaskWorkflowType.ConfirmAppointmentDate && (
        <GuidedCareTaskActionsAppointment appointment={guidedCareTask?.guidedCareJourneyItem?.appointment!} />
      )}

      {taskWorkflowType === TaskWorkflowType.ReviewMedicalFormAnswer && (
        <GuidedCareTaskActionsMedicalForm
          guidedCareTaskId={guidedCareTask?.id!}
          taskWorkflowType={taskWorkflowType}
          assigneeUserId={guidedCareTask?.assignee?.assigneeUserId!}
          medicalFormAnswers={guidedCareTask?.guidedCareJourneyItem?.medicalFormAnswers?.edges?.[0]?.node!}
        />
      )}

      {isUpdateShown && <GuidedCareTaskUpdateButton guidedCareTask={guidedCareTask} />}
    </Box>
  );
};
