import { fetchEventSource, logSSE, logSSEError, logSSEWarning, useNetwork } from "@toolkit/core";
import React, { useCallback, useEffect, useRef } from "react";
import { NotificationsServiceContext } from "./NotificationsServiceContext";
import _ from "lodash";
class FatalError extends Error {
}
function uuidv4() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
}
// eslint-disable-next-line max-statements
export const NotificationsServiceProvider = ({ children, eventSourceUrl, token, }) => {
    const windowID = useRef(uuidv4());
    const { online: isOnline } = useNetwork();
    const connectionStatusRef = useRef("disconnected");
    function setConnectionStatus(v) {
        connectionStatusRef.current = v;
    }
    const topicsSubscribers = useRef({});
    const crossTabEventsChannel = useRef(typeof window === "undefined" ? null : new BroadcastChannel("crossTabEventsChannel")).current;
    const onMessage = useCallback(ev => {
        var _a, _b;
        if (ev.event === "FatalError") {
            logSSEError("subscription error:FatalError ", ev);
            throw new FatalError(ev.data);
        }
        if (ev.data) {
            logSSE("SSe Event Received ", ev === null || ev === void 0 ? void 0 : ev.data, { data: JSON.parse(ev === null || ev === void 0 ? void 0 : ev.data.replace(/^\s?data:\s?/g, "")) });
            try {
                const data = JSON.parse(ev === null || ev === void 0 ? void 0 : ev.data.replace(/^\s?data:\s?/g, ""));
                if (data.type === "ping-pong")
                    return;
                const topic = unifyTopic(data.type);
                (_b = (((_a = topicsSubscribers.current) === null || _a === void 0 ? void 0 : _a[topic]) || [])) === null || _b === void 0 ? void 0 : _b.map(listener => listener === null || listener === void 0 ? void 0 : listener(data));
            }
            catch (error) {
                // json parse error
            }
        }
    }, []);
    useEffect(() => {
        if (!crossTabEventsChannel)
            return;
        crossTabEventsChannel.onmessage = function (ev) {
            if (!ev.data.includes("SSE_EVENT_RECEIVED") || ev.data.includes("ping-pong")) {
                return;
            }
            const data = JSON.parse(ev.data);
            onMessage(data.data);
        };
    }, [crossTabEventsChannel]);
    useEffect(() => {
        logSSE("NotificationsServiceProvider", topicsSubscribers);
    }, [topicsSubscribers]);
    useEffect(() => {
        var _a, _b;
        if (!eventSourceUrl || !token)
            return;
        (_b = (_a = window === null || window === void 0 ? void 0 : window["sseControllerRef"]) === null || _a === void 0 ? void 0 : _a.abort) === null || _b === void 0 ? void 0 : _b.call(_a);
        startListening();
    }, [eventSourceUrl, token, isOnline]);
    function startListening() {
        if (!eventSourceUrl || !isOnline || !token)
            return;
        logSSEError("NotificationsService: EventSource starting connection ", token, eventSourceUrl);
        setConnectionStatus("loading");
        const sseConnectionRef = new AbortController();
        window["sseControllerRef"] = sseConnectionRef;
        fetchEventSource(eventSourceUrl, {
            signal: sseConnectionRef === null || sseConnectionRef === void 0 ? void 0 : sseConnectionRef.signal,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            openWhenHidden: true,
            method: "GET",
            onmessage: content => {
                onMessage(content);
                crossTabEventsChannel === null || crossTabEventsChannel === void 0 ? void 0 : crossTabEventsChannel.postMessage(JSON.stringify({
                    type: "SSE_EVENT_RECEIVED",
                    data: content,
                    src: windowID.current,
                }));
            },
            onerror: onError,
            onopen: onOpen || _.noop,
            onclose() {
                setConnectionStatus("disconnected");
                logSSEWarning("Connection closed by the server " + new Date().toLocaleString());
            },
        });
    }
    function onOpen(res) {
        logSSE("Connection made ", res);
        if (res.ok && res.status === 200) {
            logSSE("Connection made ", res);
            setConnectionStatus("connected");
        }
        else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            logSSE("Client side error ", res);
            setConnectionStatus("disconnected");
            return Promise.reject(res);
        }
        return Promise.resolve(res);
    }
    function onError(err) {
        setConnectionStatus("disconnected");
        logSSEError("NotificationsService: error occurred ", err);
    }
    const subscribe = (topic, listener) => {
        listener["ListenerID"] = Date.now();
        const unifiedTopic = unifyTopic(topic);
        const ts = topicsSubscribers.current;
        topicsSubscribers.current = Object.assign(Object.assign({}, ts), { [unifiedTopic]: [listener, ...(ts[unifiedTopic] || [])] });
        return () => unSubscribe(listener["ListenerID"]);
    };
    function unifyTopic(t) {
        return t;
    }
    function unSubscribe(listenerId) {
        const ts = topicsSubscribers.current;
        topicsSubscribers.current = Object.entries(ts).reduce((_ts, [topic, listeners = []]) => {
            const unifiedTopic = unifyTopic(topic);
            return Object.assign(Object.assign({}, _ts), { [unifiedTopic]: listeners.filter(l => l["ListenerID"] !== listenerId) });
        }, {});
    }
    return React.createElement(NotificationsServiceContext.Provider, { value: { subscribe, unSubscribe } }, children);
};
