import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useReviewsColumn } from "./components/ReviewsColumns";
import { useProductVariantReviewsQuery } from "./gql";

export const ReviewsScreen: FC = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();
  const params = useParams();
  const id = params?.id!;
  useEffect(() => {
    setBreadCrumb({ title: t("Product Variant Reviews"), values: [{ id: "", name: "", route: "" }] });
  }, []);
  return (
    <GridProvider
      hasTableSetting
      variables={{ filter: { productVariant: id } }}
      gridName='ProductVariantReviews'
      columns={useReviewsColumn()}
      query={useProductVariantReviewsQuery}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
