import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { FC, ReactNode } from "react";
import { ModalCloseIcon } from "../../../icons";
import { useBaseDialogStyle } from "./useBaseDialogStyle";

export type BaseDialogProps = Omit<DialogProps, "onChange" | "onClose"> & {
  title?: ReactNode;
  confirmButton?: ButtonProps & { label: string };
  cancelButton?: ButtonProps & { label: string };
  onClose: () => void;
};

export const BaseDialog: FC<BaseDialogProps> = props => {
  const { title, confirmButton, cancelButton, maxWidth = "md", onClose: handleClose, children, ...rest } = props;

  const { classes } = useBaseDialogStyle();

  return (
    <Dialog maxWidth={maxWidth} fullWidth className={classes.root} onClose={handleClose} {...rest}>
      {title && (
        <MuiDialogTitle className={classes.dialogTitle}>
          <Typography className={classes.dialogTitleLabel}>{title}</Typography>

          <IconButton onClick={handleClose} className={classes.dialogTitleCloseIcon}>
            <ModalCloseIcon />
          </IconButton>
        </MuiDialogTitle>
      )}

      <DialogContent className={classes.dialogContent}>{children}</DialogContent>

      {(confirmButton || cancelButton) && (
        <DialogActions>
          {confirmButton && (
            // eslint-disable-next-line react/jsx-handler-names
            <Button onClick={confirmButton.onClick || handleClose} color={confirmButton.color || "success"}>
              {confirmButton.label}
            </Button>
          )}

          {cancelButton && (
            // eslint-disable-next-line react/jsx-handler-names
            <Button onClick={cancelButton.onClick || handleClose} color={cancelButton.color || "error"}>
              {cancelButton.label}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
