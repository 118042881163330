import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode, PatientHealthHistoryInput } from "@health/queries/types";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog, CustomIcon, IconButton, useTheme } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PatientHealthHistory } from "./PatientHealthHistory";

export const AddNewDialog: FC<{
  title: string;
  onAdd: (data: PatientHealthHistoryInput) => void;
  isHealthHistorySection?: boolean;
  loading: boolean;
}> = ({ title, onAdd, loading, isHealthHistorySection = false }) => {
  const { t } = useTranslation("provider");
  const [open, setOpen] = useState(false);
  const { ...methods } = useForm<PatientHealthHistoryInput>();
  const { register, handleSubmit } = methods;
  const theme = useTheme();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleAdd = (data: PatientHealthHistoryInput) => {
    onAdd(data);
    handleToggle();
  };

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("code", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  return (
    <FormProvider {...methods}>
      <CustomDialog
        maxWidth='sm'
        scrollType='body'
        title={title}
        open={open}
        hasClose
        onCloseModal={handleToggle}
        footerComponent={
          <>
            <Button type='submit' onClick={handleSubmit(handleAdd)}>
              {t("Add")}
            </Button>
            <Button variant='outlined' onClick={handleToggle}>
              {t("Close")}
            </Button>
          </>
        }
        button={
          <Box display='flex' justifyContent='flex-end'>
            <IconButton
              onClick={handleToggle}
              sx={{
                "& svg": {
                  height: 30,
                  width: 30,
                  fill: theme.palette.success.main,
                },
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={30} /> : <CustomIcon icon='add' />}
            </IconButton>
          </Box>
        }
        DialogContentProps={{
          sx: { minHeight: "unset" },
        }}
      >
        {isHealthHistorySection ? (
          <PatientHealthHistory />
        ) : (
          <SystemCodeAutocomplete name={"code"} isCodeHidden filter={{ codeSystemCode: CodeSystemCode.Diagnosis }} />
        )}
      </CustomDialog>
    </FormProvider>
  );
};
