import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { UserInfoType } from "shared/configs/oidc";
import { fetchFreshAccessToken } from "shared/utils/tokenUtils";

export const useOidcUserProfile = () => {
  const auth = useAuth();
  const [sseToken, setSseToken] = useState<string>();

  const userInfo = auth.user?.profile! as unknown as UserInfoType;
  const tokenAsQuery = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    if (!auth?.user?.access_token || !auth?.user?.refresh_token) return;
    fetchFreshAccessToken(auth?.user?.access_token, auth?.user?.refresh_token)
      .then(nextAccessToken => {
        setSseToken(nextAccessToken);
      })
      .catch(error => {
        console.error("Failed to get refresh token.", error);
      });
  }, [auth?.user?.access_token, auth?.user?.refresh_token]);
  return {
    accessToken: tokenAsQuery || auth?.user?.access_token,
    refreshToken: auth?.user?.refresh_token,
    sseToken,
    userInfo,
  };
};
