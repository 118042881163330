import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const Chat: FC<SvgIconProps> = ({ color, ...props }) => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='26' height='25' viewBox='0 0 26 25' {...props}>
      <path
        id='icons8-topic'
        d='M15,3C7.82,3,2,7.925,2,14a10.388,10.388,0,0,0,4.611,8.4c.072,1.536-.166,3.657-2.285,4.635l0,0A.5.5,0,0,0,4.5,28l.043,0a8.789,8.789,0,0,0,5.926-2.721,1.935,1.935,0,0,1,1.7-.541A15.139,15.139,0,0,0,15,25c7.18,0,13-4.925,13-11S22.18,3,15,3Z'
        transform='translate(-2 -3)'
        fill={color ? color : palette.primary.main}
      />
    </SvgIcon>
  );
};
