import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  CustomMuiDialog,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  RejectIcon,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@toolkit/ui";
import React, { FC, ReactElement } from "react";
import { Maybe } from "@health/queries/types";
import { useAppointmentReject } from "./useAppointmentReject";
import { getReasonText } from "utils/reasons";
import { NotificationItemAction } from "shared/components";

export type AppointmentRejectProps = {
  id: string;
  requestedTimeSlots?: Maybe<Maybe<string>[]>;
  buttonEl?: ReactElement;
} & NotificationItemAction;

export const AppointmentReject: FC<AppointmentRejectProps> = props => {
  const { id, requestedTimeSlots, buttonEl, handleNotificationActionComplete } = props;

  const { t } = useTranslation("provider");

  const { state, isOpen, reasons, isExpiredDate, disabled, handleSubmit, handleChangeOtherReason, handleChangeReason, handleToggle } =
    useAppointmentReject({ id, requestedTimeSlots, handleNotificationActionComplete });
  const theme = useTheme();
  return (
    <CustomMuiDialog
      open={isOpen}
      DialogTitleProps={{
        title: t("Reject Reason"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={isExpiredDate ? t("The Date for this Appointment is Expired") : ""}>
          <span>
            {buttonEl ? (
              React.cloneElement(buttonEl, { onClick: handleToggle, disabled: false })
            ) : (
              <IconButton disabled={false} onClick={handleToggle}>
                <RejectIcon color='error' sx={{ fontSize: theme.mixins.fonts.fontSize.xl }} />
              </IconButton>
            )}
          </span>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Tooltip title={disabled ? t("Please Enter Rejected Reason") : ""}>
            <span>
              <Button color='error' disabled={disabled} onClick={handleSubmit}>
                {t("Reject")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      {isExpiredDate && (
        <Typography color='error' paragraph>
          {t("The Date for this Appointment is Expired")}
        </Typography>
      )}

      <RadioGroup onChange={handleChangeReason}>
        {reasons?.map(item => (
          <FormControlLabel
            key={item?.id}
            value={item?.text}
            control={<Radio disabled={isExpiredDate} />}
            label={getReasonText(item!) || "-"}
            sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}
          />
        ))}
      </RadioGroup>

      {state.isOther && (
        <TextField
          label={t("Reject Reason")}
          placeholder={t("Please Enter Reject Reason")}
          multiline
          minRows={3}
          fullWidth
          value={state.other}
          onChange={handleChangeOtherReason}
        />
      )}
    </CustomMuiDialog>
  );
};
