import MessageComposer from "@meetora/react/ui/MessageComposer";
import MessageList from "@meetora/react/ui/MessageList";
import NotificationBar from "@meetora/react/ui/NotificationBar";
import PeerAvatar from "@meetora/react/ui/PeerAvatar";
import PeerTitle from "@meetora/react/ui/PeerTitle";
import { Box, CustomMuiDialog, IconButton, ModalCloseIcon, SpeechIcon, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type ChatDialogProps = {
  isOpened: boolean;
  peer;
  startChat?: () => void;
  handleToggle;
  renderButton?: (onClick: () => void, unread) => JSX.Element;
  unread?: JSX.Element | null;
};

export const ChatDialogComponent: FC<ChatDialogProps> = ({ isOpened, peer, startChat, handleToggle, renderButton, unread }) => {
  const handleStartCall = () => {
    startChat?.();
  };
  const custom = renderButton ? renderButton(handleStartCall, unread) : undefined;
  const theme = useTheme();
  return (
    <CustomMuiDialog
      open={isOpened}
      type='alert'
      button={
        custom || (
          <IconButton color='primary' onClick={handleStartCall}>
            <SpeechIcon color='inherit' sx={{ "& path": { fill: theme.palette.primary.main } }} />
          </IconButton>
        )
      }
      DialogActionsProps={{
        hasClose: false,
        closeTitle: "Done",
      }}
      DialogContentProps={{
        sx: {
          height: "100vh",
          padding: "10px 0px",
        },
      }}
      onBackdropClick={handleToggle}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box display={"flex"} alignItems={"center"} mx={1} mb={1}>
          <PeerAvatar peer={peer} size={32} />
          <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight='bold' mx={1}>
            <PeerTitle peer={peer} />
          </Typography>

          <Box flexGrow={1}></Box>

          <IconButton onClick={handleToggle} sx={{ height: "30px", width: "30px" }}>
            <ModalCloseIcon />
          </IconButton>
        </Box>
        <MessageList peer={peer} />
        <MessageComposer peer={peer} />
        <NotificationBar />
      </Box>
    </CustomMuiDialog>
  );
};
