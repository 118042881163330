import { useVisitsVitalSignsChanged } from "@health/common";
import { useTranslation } from "@toolkit/i18n";
import { CircularProgress, Grid } from "@toolkit/ui";
import moment from "moment/moment";
import React, { FC } from "react";
import { useVisitsVitalSignsQuery, useVisitVitalSignsChangePercentageQuery } from "shared/modules/patient/gql";
import { getVitalSignsChangePercentage, getVitalSignsItems } from "../../utils";
import { VitalSignBloodPressureChart } from "../../VitalSignBloodPressureChart";
import { VitalSignHeartRateChart } from "../../VitalSignHeartRateChart";
import { VitalSignOxygenSaturationChart } from "../../VitalSignOxygenSaturationChart";
import { VitalSignRespirationRateChart } from "../../VitalSignRespirationRateChart";
import { VitalSignTemperatureChart } from "../../VitalSignTemperatureChart";
import { getApolloContextFormToken } from "shared/utils";
import { VitalSignDialog } from "../../VitalSignDialog";

type VitalSignsChartsProps = {
  patientId: string;
  userId: string;
  token: string;
  gridWidth?: number;
};

export const VitalSignsCharts: FC<VitalSignsChartsProps> = props => {
  const { patientId, userId, token, gridWidth = 6 } = props;

  const { t } = useTranslation("provider");

  const { data, loading, refetch } = useVisitsVitalSignsQuery({
    variables: {
      first: 10,
      filter: {
        patientId,
        patientUserId: userId,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const {
    data: visitVitalSignsChangePercentageData,
    loading: loadingVisitVitalSignsChangePercentage,
    refetch: refetchPercentage,
  } = useVisitVitalSignsChangePercentageQuery({
    variables: {
      patientId: patientId!,
    },
    context: getApolloContextFormToken(token!),
  });

  const visitVitalSigns = data?.visitsVitalSigns?.edges?.map(item => ({
    ...item?.node,
    date: moment(item?.node?.modified).format("DD MMM YYYY HH:mm"),
  }));

  const vitalSignsItems = getVitalSignsItems(visitVitalSigns!);

  const vitalSignsChangePercentage = getVitalSignsChangePercentage(visitVitalSignsChangePercentageData?.visitVitalSignsChangePercentage!);

  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.patientId) {
      refetch({
        first: 10,
        filter: {
          patientId,
          patientUserId: userId,
        },
      });

      refetchPercentage({ patientId: patientId! });
    }
  });

  return loading || loadingVisitVitalSignsChangePercentage ? (
    <CircularProgress size='20px' color='inherit' />
  ) : (
    <>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog display={t("Heart Rate")} token={token!} userId={userId} patientId={patientId!}>
          <VitalSignHeartRateChart width={"100%"} data={vitalSignsItems?.heartRate} percentage={vitalSignsChangePercentage?.heartRate} />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog display={t("Blood Pressure")} token={token!} userId={userId} patientId={patientId!}>
          <VitalSignBloodPressureChart
            width={"100%"}
            data={vitalSignsItems?.bloodPressureLow}
            data2={vitalSignsItems?.bloodPressureHigh}
            percentage={vitalSignsChangePercentage?.bloodPressureLow || vitalSignsChangePercentage?.bloodPressureHigh}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog display={t("Respiration Rate")} token={token!} userId={userId} patientId={patientId!}>
          <VitalSignRespirationRateChart
            width={"100%"}
            data={vitalSignsItems?.respiratoryRate}
            percentage={vitalSignsChangePercentage?.respiratoryRate}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={gridWidth}>
        <VitalSignDialog display={t("Temperature")} token={token!} userId={userId} patientId={patientId!}>
          <VitalSignTemperatureChart
            width={"100%"}
            data={vitalSignsItems?.temperature}
            percentage={vitalSignsChangePercentage?.temperature}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={gridWidth}>
        <VitalSignDialog display={t("Oxygen Saturation")} token={token!} userId={userId} patientId={patientId!}>
          <VitalSignOxygenSaturationChart
            width={"100%"}
            data={vitalSignsItems?.oxygenSaturation}
            percentage={vitalSignsChangePercentage?.oxygenSaturation}
          />
        </VitalSignDialog>
      </Grid>
    </>
  );
};
