import { Trans, useTranslation } from "@toolkit/i18n";
import { Box, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useVisitContentStyle } from "../VisitContent.styles";

export const CanceledView: FC<{
  cancellationReason?: string | null | undefined;
  doctorFullName?: string | null | undefined;
  patientFullName?: string | null | undefined;
}> = ({ cancellationReason, doctorFullName, patientFullName }) => {
  const { classes } = useVisitContentStyle({});
  const { t } = useTranslation("provider");
  const DoctorName = <TruncateTypography className={classes?.nameInDescription} text={doctorFullName!} />;
  const PatientName = <TruncateTypography className={classes?.nameInDescription} text={patientFullName!} />;
  const theme = useTheme();
  return (
    <Box className={classes.visitDescription}>
      <Trans t={t} i18nKey='call-notification-cancel-message' components={{ 1: DoctorName, 2: PatientName }}>
        Dear Dr. {{ DoctorName }}, it seems that {{ PatientName }} has cancelled the call from his end, leading to the termination of the
        call.
      </Trans>
      <Typography fontFamily={theme.mixins.fonts.fontFamily.medium} fontSize={theme.mixins.fonts.fontSize.sm}>
        {t("Cancellation Reason")}:{" "}
        <Typography component='span' variant='body2'>
          {t(cancellationReason || "-")}
        </Typography>
      </Typography>
    </Box>
  );
};
