import Menu from "@mui/material/Menu";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { useNotificationStyle } from "./NotificationPopup.style";
import { NotificationPopupProps } from "./types";

export const NotificationPopup: FunctionComponent<PropsWithChildren<NotificationPopupProps>> = ({
  anchorEl,
  isOpen,
  onClose: handleClose,
  children,
}) => {
  const { classes } = useNotificationStyle();
  return !anchorEl ? (
    <></>
  ) : (
    <Menu
      title={"Notifications"}
      anchorEl={anchorEl}
      keepMounted
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{
        paper: classes.paper,
      }}
      id={"notification-popup"}
    >
      {React.cloneElement(children, { handleClose })}
    </Menu>
  );
};
