import { RejectReasonButton } from "pages/Orders/actions/RejectReasonButton";
import { FC } from "react";

export const RejectHealthPackageOrderButton: FC<{ orderId: string }> = ({ orderId }) => {
  //   const { t } = useTranslation("provider");
  //   const { succeeded, failed } = useAddToast();
  //   const [fetchVendorRejectHealthPackageOrder, { loading: isSubmittingReject }] = useVendorRejectHealthPackageOrderMutation({
  //     onCompleted: data => {
  //       if (data.vendorRejectHealthPackageOrder?.orderErrors.length === 0) {
  //         succeeded(t("Order rejected successfully"));
  //       } else {
  //         failed(t(formatMessageErrors(data?.vendorRejectHealthPackageOrder?.orderErrors!)));
  //       }
  //     },
  //     onError: error => {
  //       failed(t(formatGraphQLError(error.graphQLErrors)));
  //     },
  //   });
  //   const handleRejectClick = () => {
  //     fetchVendorRejectHealthPackageOrder({
  //       variables: {
  //         id: orderId!,
  //         rejectionReason: prompt(t("Please enter the reason for rejection")) || "",
  //       },
  //     });
  //   };
  return (
    <RejectReasonButton id={orderId} />
    // <Button color={"error"} disabled={isSubmittingReject} onClick={handleRejectClick}>
    //   {t("Reject")}
    // </Button>
  );
};
