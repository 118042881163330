import { getDashboardComponent } from "../utils";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { CircularProgressPage } from "shared/components/CircularProgressPage";
import { UserInfoType } from "shared/configs/oidc";

type DashboardContainerProps = {
  userInfo: UserInfoType;
};

export const DashboardContainer: FC<DashboardContainerProps> = props => {
  const { userInfo } = props;

  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const Dashboard = getDashboardComponent(userInfo);

  useEffect(() => {
    setBreadCrumb({ title: t("Dashboard"), values: [] });
  }, [setBreadCrumb, t]);

  return userInfo ? <Dashboard /> : <CircularProgressPage />;
};
