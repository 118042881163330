/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/pageInfoFragment';
import { ProductBrandFragmentFragmentDoc } from '../../fragments/__generated__/productBrandFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductBrandsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProductBrandsQuery = { __typename?: 'Query', productBrands?: { __typename?: 'ProductBrandCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductBrandCountableEdge', node: { __typename?: 'ProductBrand', name: string, id: string } }> } | null };


export const ProductBrandsDocument = gql`
    query productBrands($first: Int, $after: String, $before: String, $last: Int) {
  productBrands(first: $first, after: $after, before: $before, last: $last) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        ...ProductBrandFragment
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${ProductBrandFragmentFragmentDoc}`;

/**
 * __useProductBrandsQuery__
 *
 * To run a query within a React component, call `useProductBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBrandsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useProductBrandsQuery(baseOptions?: Apollo.QueryHookOptions<ProductBrandsQuery, ProductBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductBrandsQuery, ProductBrandsQueryVariables>(ProductBrandsDocument, options);
      }
export function useProductBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductBrandsQuery, ProductBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductBrandsQuery, ProductBrandsQueryVariables>(ProductBrandsDocument, options);
        }
export type ProductBrandsQueryHookResult = ReturnType<typeof useProductBrandsQuery>;
export type ProductBrandsLazyQueryHookResult = ReturnType<typeof useProductBrandsLazyQuery>;
export type ProductBrandsQueryResult = Apollo.QueryResult<ProductBrandsQuery, ProductBrandsQueryVariables>;