import { HealthPackageCreateInput, HealthPackageError } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { healthPackagesManagementPaths } from "pages/HealthPackages/route";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { healthPackageListBreadCrumb, healthPackageNewBreadCrumb } from "../../constant";
import { useHealthPackageCreateMutation } from "../../gql";

export const usePackageNewPageHook = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setError] = useState<HealthPackageError[]>();
  const { succeeded, failed } = useAddToast();
  const [createHealthPackage, { loading: isSaving }] = useHealthPackageCreateMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const errors = data?.healthPackageCreate?.healthPackageErrors;
      if (errors?.length === 0) {
        succeeded(t("Health package created successfully"));
        navigate(healthPackagesManagementPaths.indexPage.fullPath);
      } else {
        const error = t(formatMessageErrors(errors));
        failed(error);
        setError(errors as HealthPackageError[]);
      }
    },
    onError: () => {
      failed(t("Failed in creating health package"));
    },
  });

  const handleCreatePackage = (data: HealthPackageCreateInput) => {
    createHealthPackage({
      variables: {
        input: {
          description: data.description,
          descriptionAr: data.descriptionAr,
          shortDescription: data.shortDescription,
          shortDescriptionAr: data.shortDescriptionAr,
          mainImageUrl: encodeURI(data.mainImageUrl!),
          name: data.name,
          nameAr: data.nameAr,
          price: +data.price,
          categoryCode: (data?.categoryCode as any)?.value?.code,
          targetGender: (data?.targetGender as any)?.value,
          targetFromAge: Number(data?.targetFromAge),
          targetToAge: Number(data?.targetToAge),
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: t("Health Packages Management"),
      values: [healthPackageListBreadCrumb(t), healthPackageNewBreadCrumb(t)],
    });
  }, []);

  return {
    errors,
    isSaving: isSaving,
    handleCreatePackage,
  };
};
