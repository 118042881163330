/* eslint-disable max-lines */
import { HealthHistoryType } from "@health/queries/types";
import { formatUserNameFull, getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, AvatarGroup, Box, CustomIcon, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@toolkit/ui";
import moment from "moment/moment";
import { FC, Fragment, useEffect } from "react";
import { useProfileContext } from "../../context/ProfileContext";
import { HealthConditions } from "./Conditions";
import { InvitePatientToHealthProgram } from "./InvitePatientToHealthProgram";
import { JourneyStatistics } from "./JourneyStatistics";
import { MaskProfilePhoto } from "./MaskProfilePhoto";
import { PatientInformationSection } from "./PatientInformationSection";
import { Programs } from "./Programs";

const msg = "N/A";

type PatientInformationProps = {
  hasTeam: boolean;
  healthPrograms: { id: string; name: string }[];
  selectedCareTeams: { id: string; photo: string }[];
  teamLeaderPhoto: string;
  hasMore: boolean;
  onFetchMore: () => void;
};

export const PatientInformation: FC<PatientInformationProps> = props => {
  const { hasTeam, healthPrograms, selectedCareTeams, teamLeaderPhoto, hasMore, onFetchMore: handleFetchMore } = props;
  const { t } = useTranslation("provider");
  const { patient, patientHealthHistory, selectedHealthProgram, handleProgramSelected, onClose: handleToggleDrawer } = useProfileContext();
  const insuranceCards = patient?.activeHealthProgramMembers?.[0];

  const allergies = patientHealthHistory
    ?.filter(item => item?.healthHistoryType! === HealthHistoryType.Allergy)
    .map(item => item?.display || item?.code);

  const getAge = () => {
    const birthdate = moment(patient?.dateOfBirth);
    const currentDate = moment();
    const years = currentDate.diff(birthdate, "years");
    birthdate.add(years, "years");
    const months = currentDate.diff(birthdate, "months");
    let age = `${years} ${t("Years")}`;
    if (months > 0) {
      age = `${age} ${months} ${t("Months")}`;
    }
    return age;
  };

  const patientInfo = [t(patient?.gender!), patient?.dateOfBirth && getAge(), t(patient?.user?.preferredLanguage?.display!)].filter(
    Boolean
  );

  const isMobile = useMediaQuery("(max-width:1340px)");

  useEffect(() => {
    handleProgramSelected?.(healthPrograms?.[0]);
  }, [healthPrograms?.[0]?.id]);
  const theme = useTheme();
  return (
    <Grid container padding='20px 0px' flexWrap={"nowrap"} spacing={2}>
      <Grid item>
        <IconButton
          onClick={handleToggleDrawer}
          sx={{
            width: 30,
            height: 30,
            color: theme.palette.primary.main,
            alignSelf: "start",
          }}
        >
          <CustomIcon icon='close' viewBox='-1 0 26 25' />
        </IconButton>
      </Grid>
      <Grid item>
        <Box sx={{ width: "90px", height: "90px" }}>
          <MaskProfilePhoto src={patient?.user?.photo!} />
        </Box>
      </Grid>
      <Grid container spacing={1} item flexWrap={isMobile ? "wrap" : "nowrap"}>
        <Grid item>
          <PatientInformationSection title={patient?.firstName || patient?.lastName ? formatUserNameFull(patient) : msg}>
            <Box display='flex' alignItems='center' flexWrap='wrap'>
              {patientInfo?.map((item, index) => (
                <Box display='flex' alignItems='center' key={item}>
                  {index > 0 && (
                    <Box sx={{ borderRadius: 25, marginInline: 1, width: 7, height: 7, background: theme.palette.primary[200] }} />
                  )}

                  <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{item}</Typography>
                </Box>
              ))}
            </Box>
            <Typography mt={1} fontSize={theme.mixins.fonts.fontSize.sm}>
              {insuranceCards?.payer?.name || ""}
            </Typography>
          </PatientInformationSection>
        </Grid>
        <Grid item xs container spacing={1} justifyContent='center' flexWrap={isMobile ? "wrap" : "nowrap"}>
          <Grid item xs='auto'>
            <HealthConditions />
          </Grid>
          <Grid item xs='auto'>
            <PatientInformationSection title={t("Allergies")} hasItems items={allergies} />
          </Grid>
        </Grid>
        {!healthPrograms?.length ? (
          <InvitePatientToHealthProgram />
        ) : (
          <Grid item>
            <Programs
              hasMore={hasMore}
              items={healthPrograms!}
              onChange={handleProgramSelected}
              selected={selectedHealthProgram}
              onFetchMore={handleFetchMore}
            />
            <PatientInformationSection title={t("CareTeam")}>
              {hasTeam ? (
                <AvatarGroup
                  sx={{
                    flexDirection: "row",
                  }}
                >
                  <Fragment>
                    {teamLeaderPhoto && (
                      <Avatar
                        sx={{
                          border: `1.5px solid ${theme.palette.secondary.main} !important`,
                          marginRight: "-11px !important",
                          marginLeft: 0,
                        }}
                        alt='Leader'
                        src={getMediaLink(teamLeaderPhoto!)}
                      />
                    )}
                    {selectedCareTeams?.map(item => {
                      return (
                        <Avatar
                          key={item?.id}
                          sx={{ marginRight: "-11px !important", marginLeft: 0 }}
                          alt='R'
                          src={getMediaLink(item?.photo!)}
                        />
                      );
                    })}
                  </Fragment>
                </AvatarGroup>
              ) : (
                <Box
                  sx={{
                    width: "fit-content",
                    border: `1px solid ${theme.palette.primary[100]}`,
                    borderRadius: "5px",
                    padding: "5px 10px",
                    background: theme.palette.background.default,
                  }}
                >
                  <Typography fontSize={theme.mixins.fonts.fontSize.xs} flexWrap={"wrap"}>
                    {msg}
                  </Typography>
                </Box>
              )}
            </PatientInformationSection>
          </Grid>
        )}
        <JourneyStatistics healthProgramId={selectedHealthProgram!} />
      </Grid>
    </Grid>
  );
};
