import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Grid, useAddToast } from "@toolkit/ui";
import {
  VendorProductVariantStocksDeleteMutation,
  useBranchesAutocompleteOptionsQuery,
  useVendorProductVariantStocksDeleteMutation,
} from "pages/product/gql";
import React, { FC, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ProductVariantsDocument } from "../../../gql";
import { WarehouseProps } from "./Warehouse.type";
import { WarehouseRaw } from "./WarehouseRaw.component";
import { Branch } from "@health/queries/types";

const Warehouse: FC<WarehouseProps> = ({ defaultData }) => {
  const { succeeded, failed } = useAddToast();
  const { t } = useTranslation("provider");

  const {
    data: Branches,
    fetchMore: fetchMoreBranches,
    loading: loadingBranches,
  } = useBranchesAutocompleteOptionsQuery({
    variables: {
      first: 10,
    },
  });
  const branches = Branches?.branches?.edges?.map(branch => branch?.node) as Array<Branch>;

  const pageInfo = Branches?.branches?.pageInfo;

  const { watch, control } = useFormContext();
  const stocks = watch("stocks");
  const { fields, remove, insert, prepend } = useFieldArray({
    control,
    name: "stocks",
  });

  const insertNewEmptyBranchQuantity = (index: number) => {
    insert(index, {
      branch: null,
      quantity: null,
    });
  };

  useEffect(() => {
    if (defaultData?.vendorProductVariant?.stocks && defaultData?.vendorProductVariant?.stocks?.length > 0) {
      prepend(defaultData?.vendorProductVariant?.stocks);
    } else {
      insertNewEmptyBranchQuantity(0);
    }
  }, []);

  const onAddRow = (index: number) => {
    insertNewEmptyBranchQuantity(index + 1);
  };

  const [deleteWareHouse] = useVendorProductVariantStocksDeleteMutation({
    onCompleted: (response: VendorProductVariantStocksDeleteMutation) => {
      const errors = response?.vendorProductVariantStocksDelete?.stockErrors;
      if (errors?.length == 0) {
        succeeded(t("Product Variant Deleted Successfully"));
      } else {
        failed(t(formatMessageErrors(errors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedErrors = formatGraphQLError(graphQLErrors);
      failed(t(formattedErrors));
    },
    refetchQueries: [
      {
        query: ProductVariantsDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });
  const onRemoveRow = (index: number) => {
    const data = watch(`stocks.${[index]}`);
    const checkExist = defaultData?.vendorProductVariant?.stocks?.find(item => item?.branch?.id === data?.branch?.id);

    checkExist &&
      defaultData?.id &&
      deleteWareHouse({
        variables: {
          variantId: defaultData?.id!,
          branchIds: [data?.branch?.id],
        },
      });
    remove(index);
  };

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMoreBranches({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            ...fetchMoreResult,
            branches: {
              ...fetchMoreResult.branches,
              edges: [...prev.branches.edges, ...fetchMoreResult.branches.edges],
            },
          });
        },
      });
    }
  };

  return (
    <Grid container spacing={2} alignItems='center'>
      {fields?.map((item, index: number) => {
        return (
          <React.Fragment key={index}>
            <Grid item xs={12} container spacing={2}>
              <WarehouseRaw
                key={index}
                branches={branches}
                defaultData={defaultData}
                stocks={stocks}
                index={index}
                onFetchMore={fetchMoreData}
                isLoading={loadingBranches}
                hasMore={pageInfo?.hasNextPage}
                handleAddRow={onAddRow}
                handleRemoveRow={onRemoveRow}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
export default Warehouse;
