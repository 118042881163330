import { SystemCodeAutocomplete } from "@health/autocompletes";
import { targetGenderOptions, targetGenderOptionsMap } from "@health/enum-options";
import { CodeSystemCode, HealthPackage } from "@health/queries/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, createAutocompleteOption, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { generalInformationFields } from "./generalInformationUtils";

interface GeneralInformationProps {
  errorsInput: any;
  defaultValue: HealthPackage;
}

export const GeneralInformation: FC<GeneralInformationProps> = ({ defaultValue, errorsInput }) => {
  const { t } = useTranslation("provider");
  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors: reactFormErrors },
  } = useFormContext();

  const formErrors = combineErrors(reactFormErrors, errorsInput);

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("categoryCode", {
      required: getRequiredValidation(t, !defaultValue),
    });
  }, [defaultValue, register, t]);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const categoryCode = defaultValue?.categoryCode
      ? createAutocompleteOption(
          { code: defaultValue?.categoryCode, display: defaultValue?.categoryCode + " - " + defaultValue?.categoryDisplay },
          "code",
          "display"
        )
      : undefined;

    setValue("categoryCode", categoryCode);
  }, [defaultValue, setValue]);

  return (
    <Grid container spacing={formGirdSpacing}>
      {generalInformationFields(t, defaultValue, watch)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item.name}>
          <CustomTextField
            name={item.name}
            label={item.label}
            defaultValue={item.defaultValue}
            pattern={item.pattern}
            errorsInput={errorsInput}
            registerProps={{ ...item?.registerProps, min: item.min, max: item.max }}
          />
        </Grid>
      ))}

      <Grid item xs={4}>
        <AutocompleteController
          ControllerProps={{
            name: "targetGender",
            control: control,
            rules: { required: getRequiredValidation(t, true) },
            defaultValue: targetGenderOptionsMap?.[defaultValue?.targetGender] || null,
          }}
          TextFieldProps={{
            error: Boolean(formErrors?.gender?.message),
            helperText: formErrors?.gender?.message,
            label: t("Gender"),
          }}
          getOptionLabel={o => t(o?.label)}
          defaultValue={targetGenderOptionsMap?.[defaultValue?.targetGender] || null}
          options={targetGenderOptions}
        />
      </Grid>

      <Grid item xs={4}>
        <SystemCodeAutocomplete
          name={"categoryCode"}
          label={t("Category Code")}
          disabled={!!defaultValue?.categoryCode}
          filter={{ codeSystemCode: CodeSystemCode.HealthPackageCategory }}
        />
      </Grid>
    </Grid>
  );
};
