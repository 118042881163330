/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRejectionReasonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRejectionReasonsQuery = { __typename?: 'Query', getRejectionReasons?: Array<{ __typename?: 'RejectionReason', id: string, code: string, display: string } | null> | null };


export const GetRejectionReasonsDocument = gql`
    query getRejectionReasons {
  getRejectionReasons {
    id
    code
    display
  }
}
    `;

/**
 * __useGetRejectionReasonsQuery__
 *
 * To run a query within a React component, call `useGetRejectionReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRejectionReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRejectionReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRejectionReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetRejectionReasonsQuery, GetRejectionReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRejectionReasonsQuery, GetRejectionReasonsQueryVariables>(GetRejectionReasonsDocument, options);
      }
export function useGetRejectionReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRejectionReasonsQuery, GetRejectionReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRejectionReasonsQuery, GetRejectionReasonsQueryVariables>(GetRejectionReasonsDocument, options);
        }
export type GetRejectionReasonsQueryHookResult = ReturnType<typeof useGetRejectionReasonsQuery>;
export type GetRejectionReasonsLazyQueryHookResult = ReturnType<typeof useGetRejectionReasonsLazyQuery>;
export type GetRejectionReasonsQueryResult = Apollo.QueryResult<GetRejectionReasonsQuery, GetRejectionReasonsQueryVariables>;