/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareJourneyListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.GuidedCareJourneyFilterInput>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.GuidedCareJourneySortingInput>;
}>;


export type GuidedCareJourneyListQuery = { __typename?: 'Query', guidedCareJourneys?: { __typename?: 'GuidedCareJourneyCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'GuidedCareJourneyCountableEdge', node: { __typename?: 'GuidedCareJourney', activities?: string | null, adherence?: number | null, allActivitiesCount?: number | null, allActivitiesCountBeforeCurrentDate?: number | null, completedActivitiesCount?: number | null, gaps?: number | null, id: string, patientMedicalProfile?: { __typename?: 'PatientMedicalProfile', patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, user?: { __typename?: 'User', id: string, photo?: string | null } | null } | null } | null, guidedCareHealthProgram?: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null, providerGuidedCareHealthProgramTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', name?: string | null } | null } }> } | null };


export const GuidedCareJourneyListDocument = gql`
    query GuidedCareJourneyList($first: Int, $after: String, $before: String, $filter: GuidedCareJourneyFilterInput, $last: Int, $sortBy: GuidedCareJourneySortingInput) {
  guidedCareJourneys(
    first: $first
    after: $after
    before: $before
    filter: $filter
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        activities
        adherence
        allActivitiesCount
        allActivitiesCountBeforeCurrentDate
        completedActivitiesCount
        gaps
        id
        patientMedicalProfile {
          patient {
            id
            firstName
            lastName
            user {
              id
              photo
            }
          }
        }
        guidedCareHealthProgram {
          id
          name
        }
        providerGuidedCareHealthProgramTeam {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGuidedCareJourneyListQuery__
 *
 * To run a query within a React component, call `useGuidedCareJourneyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareJourneyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareJourneyListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareJourneyListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>(GuidedCareJourneyListDocument, options);
      }
export function useGuidedCareJourneyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>(GuidedCareJourneyListDocument, options);
        }
export type GuidedCareJourneyListQueryHookResult = ReturnType<typeof useGuidedCareJourneyListQuery>;
export type GuidedCareJourneyListLazyQueryHookResult = ReturnType<typeof useGuidedCareJourneyListLazyQuery>;
export type GuidedCareJourneyListQueryResult = Apollo.QueryResult<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>;