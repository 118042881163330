import { IconButton, useTheme } from "@mui/material";
import React, { FC } from "react";
import { Notifications } from "../../icons";
import { NotificationPopup } from "./NotificationPopup";
import { useMenu } from "./useMenu";

export const CustomDropdown: FC<any> = ({ children }) => {
  const { anchorEl, anchorElRef, isOpen, handleClick, handleClose } = useMenu();
  const theme = useTheme();
  return (
    <React.Fragment>
      <IconButton ref={anchorElRef as any} onClick={handleClick}>
        <Notifications htmlColor={theme.palette.primary.main} />
      </IconButton>
      <NotificationPopup anchorEl={anchorEl} isOpen={isOpen} onClose={handleClose}>
        {children}
      </NotificationPopup>
    </React.Fragment>
  );
};
