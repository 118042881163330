import { VisitDiagnosis } from "@health/queries/types";
import { Box, CustomTable, CustomTableColumnProps } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DiagnosisButtonsGroup } from "./DiagnosisButtonsGroup";
import { DiagnosisForm } from "./DiagnosisForm";
import { useColumns } from "./useColumns";
import { FormValues, useVisitDiagnosesHooks } from "./useVisitDiagnosesHooks";

export const Diagnosis: FC<{ visitId: string; token?: string }> = ({ visitId, token }) => {
  const columns = useColumns();

  const methods = useForm<FormValues>({
    mode: "onChange",
  });

  const { loading, visitDiagnoses, handleCreate, handleDelete, handleSelectedVisitDiagnosis, isSubmitting } = useVisitDiagnosesHooks(
    visitId,
    token
  );

  const handleEditRow = row => {
    handleSelectedVisitDiagnosis?.(row);
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Box sx={{ marginBottom: "8px" }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleCreate)}>
            <DiagnosisForm />
            <DiagnosisButtonsGroup isSubmitting={isSubmitting} />
          </form>
        </FormProvider>
      </Box>
      <CustomTable
        isLoading={loading}
        data={visitDiagnoses || []}
        columns={(columns || []) as CustomTableColumnProps<VisitDiagnosis>[]}
        pageSize={10}
        pageIndex={0}
        pagesCount={10}
        onRowClick={handleEditRow}
        onDeleteRow={handleDelete}
        hasFooter={false}
        isDeleteVisible
        isRowEditable
      />
    </Box>
  );
};

Diagnosis.defaultProps = {
  token: undefined,
};
