import { OrderStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomMuiDialog, Typography, useTheme } from "@toolkit/ui";
import { DispenseButton } from "pages/Orders/actions/DispenseButton";
import { GetApprovalButton } from "pages/Orders/actions/GetApprovalButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useOrderQuery } from "./gql";

type OrderNotificationProps = {
  isOpen: boolean;
  orderId: string;
  message: string;
  onReset: () => void;
};

export const OrderAlert = ({ isOpen, message, orderId, onReset: handleReset }: OrderNotificationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { data, loading } = useOrderQuery({
    variables: {
      id: orderId!,
    },
    fetchPolicy: "no-cache",
    skip: !orderId,
  });

  const order = data?.order;
  const status = order?.status;

  const hasDispenseButton =
    status === OrderStatus.ErxHubPartiallyApproved ||
    status === OrderStatus.ErxHubFullyApproved ||
    status === OrderStatus.ErxHubClaimFailed ||
    status === OrderStatus.PaymentSucceeded ||
    status === OrderStatus.PaymentOnArrive;

  const handleSubmit = () => {
    navigate("/orders");
    handleReset();
  };
  return (
    <CustomMuiDialog
      DialogTitleProps={{
        title: t("Order Timeout Alert"),
        onClose: handleReset,
      }}
      open={isOpen}
      button={<></>}
      DialogActionsProps={{
        hasClose: true,
        closeTitle: t("Close"),
        children: (
          <>
            {hasDispenseButton && <DispenseButton id={order?.id!} submitLabel={t("Dispense eRx")} onDone={handleReset} />}
            {order?.status === OrderStatus.Allocated && <GetApprovalButton id={order?.id!} onDone={handleReset} />}
            {location?.pathname !== "/orders" && <Button onClick={handleSubmit}>{t("Go To Orders")}</Button>}
          </>
        ),
      }}
    >
      {loading ? (
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
          <CircularProgress size={12} sx={{ mx: 1, my: 1 }} />
          {t("Loading")}...
        </Box>
      ) : (
        <>
          <Typography fontFamily={theme.mixins.fonts.fontFamily.medium}>
            {t("Order")} #{order?.number}
          </Typography>
          <Typography>{message}</Typography>
        </>
      )}
    </CustomMuiDialog>
  );
};
