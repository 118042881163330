/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */
import { getSystemCodeAutocompleteFilter } from "@health/autocompletes";
import { targetGenderOptions } from "@health/enum-options";
import {
  CodeSystemCode,
  HealthPackage,
  HealthPackageApprovalStatusEnum,
  HealthPackageSortField,
  OrderDirection,
  SaleType,
} from "@health/queries/types";
import { TFunction, i18n } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { startCase } from "lodash";
import moment from "moment";
import { CloneDialog } from "./CloneDialog";
import { DiscountDialog } from "./DiscountDialog";
import { HealthPackageImages } from "./HealthPackageImages";
import { HealthPackageRequestReviewButton } from "./HealthPackageRequestReviewButton";
import { ToggleHealthPackagePublishStatus } from "./PublishHealthPackage";

const isPublishedOptions = [
  { label: i18n.t("Published", { ns: "provider" }), value: true },
  { label: i18n.t("Not Published", { ns: "provider" }), value: false },
];

export const getHealthPackagesColumns = (t: TFunction): CustomTableColumnProps<HealthPackage>[] => {
  const { mixins } = useTheme();
  return [
    {
      key: "search",
      header: t("Search", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "string",
        name: "name_Icontains",
      },
      isHidden: true,
      accessor: "name",
    },

    {
      key: "name",
      header: t("Name", { ns: "provider" }),
      isSortable: true,
      type: "string",
      accessor: ({ name }) => <TruncateTypography text={name} />,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: HealthPackageSortField.Name,
      filter: {
        type: "string",
        name: "name",
      },
    },
    {
      key: "nameAr",
      header: t("Arabic Name", { ns: "provider" }),
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: HealthPackageSortField.NameAr,
      type: "string",
      accessor: ({ nameAr }) => <TruncateTypography text={nameAr!} />,
    },
    {
      key: "shortDescription",
      header: t("Short Description", { ns: "provider" }),
      accessor: ({ shortDescription }) => <TruncateTypography text={shortDescription!} />,
    },
    {
      key: "shortDescriptionAr",
      header: t("Arabic Short Description", { ns: "provider" }),
      accessor: ({ shortDescriptionAr }) => <TruncateTypography text={shortDescriptionAr!} />,
    },
    {
      key: "images",
      header: t("Images", { ns: "provider" }),
      accessor: row => <HealthPackageImages row={row} />,
    },
    {
      key: "price",
      header: t("Price", { ns: "provider" }),
      accessor: row => (
        <Typography
          sx={{
            width: "100px",
          }}
        >
          {`${row.price?.amount!} ${t(row.price?.currency!)}`}
        </Typography>
      ),
    },
    {
      key: "discount",
      header: t("Discount", { ns: "provider" }),

      accessor: row => {
        if (!row?.sale?.value) return "--";
        const discount = row?.sale?.type === SaleType.Percentage ? `${Math.round(row?.sale?.value)}% ${t(" Off")}` : t(" Off");

        return (
          <Typography
            sx={{
              width: "100px",
            }}
          >
            {row?.sale?.type === SaleType.Percentage ? discount : `${row?.sale?.value} ${t(row.price?.currency!)} ${t(" Off")}`}
          </Typography>
        );
      },
    },
    {
      key: "discountExpiryDate",
      header: t("Discount Expiry Date", { ns: "provider" }),
      accessor: row => (row.sale?.endDate ? moment(row.sale?.endDate).format("DD MMM yyyy") : "--"),
    },
    {
      key: "published",
      header: t("Publish Status", { ns: "provider" }),
      filter: {
        type: "autocomplete",
        name: "isPublished",
        options: isPublishedOptions,
        getOptionLabel: option => option?.label,
        getValueForBackend: option => option?.value,
      },
      accessor: row => <ToggleHealthPackagePublishStatus row={row} />,
    },
    {
      key: "status",
      header: t("Approval Status", { ns: "provider" }),
      accessor: row => <Typography fontSize={mixins.fonts.fontSize.sm}>{t(startCase(row?.approvalStatus!.toLowerCase()))}</Typography>,
      filter: {
        type: "autocomplete",
        name: "approvalStatus",
        options: Object.keys(HealthPackageApprovalStatusEnum).map(key => ({
          value: HealthPackageApprovalStatusEnum[key],
          label: startCase(key),
        })),
        getOptionLabel: option => t(option?.label),
        getValueForBackend: option => option?.value,
      },
    },
    {
      key: "clone",
      header: t("Clone", { ns: "provider" }),
      accessor: row => <CloneDialog row={row} />,
    },
    {
      key: "actions",
      header: t("Actions", { ns: "provider" }),
      accessor: row => (
        <Box sx={{ width: "350px" }}>
          <HealthPackageRequestReviewButton row={row} />
          <DiscountDialog healthPackageId={row?.id} maxPrice={row?.price?.amount!} />
        </Box>
      ),
    },
    {
      key: "fromPrice",
      header: t("From Price", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "number",
        name: "fromPrice",
      },
      isHidden: true,
      accessor: row => row.price?.amount,
    },
    {
      key: "toPrice",
      header: t("To Price", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "number",
        name: "toPrice",
      },
      isHidden: true,
      accessor: row => row.price?.amount,
    },
    {
      key: "targetFromAge",
      header: t("From Age", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "number",
        name: "targetFromAge",
      },
      isHidden: true,
      accessor: row => row.targetFromAge,
    },
    {
      key: "targetToAge",
      header: t("To Age", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "number",
        name: "targetToAge",
      },
      isHidden: true,
      accessor: row => row.targetToAge,
    },
    {
      key: "targetGender",
      header: t("Gender", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "autocomplete",
        name: "targetGender",
        options: targetGenderOptions,
        getOptionLabel: option => option?.label!,
        getValueForBackend: option => option?.value,
      },
    },
    {
      key: "categoryCode",
      header: t("Category", { ns: "provider" }),
      type: "string",
      showOnlyForFilterField: true,
      filter: getSystemCodeAutocompleteFilter({
        name: "categoryCode",
        queryVariables: { codeSystemCode: CodeSystemCode.HealthPackageCategory },
      }),
    },
    {
      key: "description",
      header: t("Description", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: {
        type: "string",
        name: "description_Icontains",
        rows: 4,
      },
      isHidden: true,
      accessor: "description",
    },
  ];
};
