import { getMediaLink } from "@health/common";
import { HealthPackage } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, ShowButton, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useToggle } from "shared/hooks";
import { useHealthPackageStyles } from "./healthPackages.styles";

export const HealthPackageImages: FC<{ row: HealthPackage }> = ({ row }) => {
  const { t } = useTranslation("provider");
  const { handleToggle, open } = useToggle();
  const { classes } = useHealthPackageStyles();
  const image = row?.mainImageUrl;

  return (
    <>
      <ShowButton onClick={handleToggle} />
      {open && (
        <CustomDialog open={open} onCloseModal={handleToggle} title={t("Health package Images")}>
          <Grid container spacing={2}>
            <Grid item>
              {image ? (
                <img
                  className={classes.imageListItem}
                  height={150}
                  width={"300px"}
                  loading='lazy'
                  src={getMediaLink(decodeURI(image))}
                  alt={"main"}
                />
              ) : (
                <Typography>{t("There are no images")}</Typography>
              )}
            </Grid>
          </Grid>
        </CustomDialog>
      )}
    </>
  );
};
