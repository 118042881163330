import { HealthCondition, Patient, PatientCurrentMedicalStatus, PatientHealthHistory } from "@health/queries/types";
import { createContext, ReactNode, useContext } from "react";

type IProfileContext = {
  userId: string;
  selectedHealthProgram?: string;
  handleProgramSelected?: (item) => void;
  sections: (
    teams: { key: string; label: string; value: string }[]
  ) => { id?: number; title?: string; subTitle?: string; content: ReactNode }[];
  loading: boolean | undefined;
  consentStatus: string | undefined;
  checkAccess: boolean;
  patient: Patient | undefined;
  patientCurrentMedicalStatus: Partial<PatientCurrentMedicalStatus> | undefined | null;
  patientHealthHistory: PatientHealthHistory[] | undefined;
  patientId: string | undefined;
  handleResetStatus?: () => void;
  patientName: string | undefined;
  onClose?: () => void | undefined;
  isOpen?: boolean | undefined;
  token?: string | undefined;
  tokenId?: string | undefined;
  selectedTab?: string;
  handleTabChange?: (newValue: string) => void;
  conditions?: HealthCondition[];
};

export const ProfileContext = createContext<IProfileContext>({
  patient: undefined,
  userId: "",
  sections: () => [],
  selectedTab: undefined,
  consentStatus: undefined,
  loading: undefined,
  checkAccess: false,
  patientCurrentMedicalStatus: undefined,
  patientHealthHistory: undefined,
  patientId: undefined,
  isOpen: undefined,
  patientName: undefined,
  onClose: undefined,
  handleResetStatus: undefined,
  token: undefined,
  handleTabChange: undefined,
});

export const useProfileContext = () => {
  return useContext(ProfileContext);
};
