import {
  getMaxLengthValidation,
  getMaxValueValidation,
  getMinValueValidation,
  patternArabicText,
  validateValueIsNotEmpty,
} from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";

export const generalInformationFields = (t: TFunction, defaultValue, watch) => [
  {
    name: "name",
    label: t("Package Name", { ns: "provider" }),
    defaultValue: defaultValue?.name,
    registerProps: {
      validate: value => validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "nameAr",
    label: t("Arabic Package Name", { ns: "provider" }),
    defaultValue: defaultValue?.nameAr,
    pattern: patternArabicText,
    registerProps: {
      validate: value => validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "price",
    label: t("Price", { ns: "provider" }),
    min: getMinValueValidation(t, 0),
    max: getMaxValueValidation(t, 1000000),
    defaultValue: defaultValue?.price.amount,
    registerProps: {
      validate: value => validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "targetFromAge",
    label: t("Target From Age", { ns: "provider" }),
    defaultValue: defaultValue?.targetFromAge,
    min: getMinValueValidation(t, 0),
    max: getMaxValueValidation(t, watch("targetToAge") || 130),
    pattern: {
      message: t("The value must be a number", { ns: "provider" }),
      value: /^[0-9]+?\.?[0-9]*?$/,
    },
  },
  {
    name: "targetToAge",
    label: t("Target To Age", { ns: "provider" }),
    defaultValue: defaultValue?.targetToAge,

    min: getMinValueValidation(t, watch("targetFromAge") || 0),
    max: getMaxValueValidation(t, 130),
    pattern: {
      message: t("The value must be a number", { ns: "provider" }),
      value: /^[0-9]+?\.?[0-9]*?$/,
    },
  },
  {
    name: "shortDescription",
    label: t("Short Description", { ns: "provider" }),
    defaultValue: defaultValue?.shortDescription,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 200),
      validate: value => validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "shortDescriptionAr",
    label: t("Arabic Short Description", { ns: "provider" }),
    defaultValue: defaultValue?.shortDescription,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 200),
      validate: value => validateValueIsNotEmpty(value, t),
    },
  },
];
