import React, { FC } from "react";
import PackageForm from "../PackageForm.component";
import { usePackageNewPageHook } from "./usePackageNewPage.hook";

const NewPackage: FC = () => {
  const { errors, isSaving, handleCreatePackage } = usePackageNewPageHook();

  return <PackageForm errors={errors} isSaving={isSaving} onSubmit={handleCreatePackage} />;
};

export default NewPackage;
