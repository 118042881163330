import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import { TableBodyWithoutDataView } from "../Table";
import TableLayout from "../Table/components/TableLayout";
import { useScrollableTableStyle } from "./useScrollableTableStyle";
import { ScrollableTableColumnProps } from "./types";

const noop = () => undefined;

type ScrollableTableProps<T> = {
  data: T[];
  columns: ScrollableTableColumnProps<T>[];
  // eslint-disable-next-line react/require-default-props
  title?: string;
  // eslint-disable-next-line react/require-default-props
  minHeight?: number;
  // eslint-disable-next-line react/require-default-props
  maxHeight?: number;
  // eslint-disable-next-line react/require-default-props
  isEditVisible?: boolean;
  // eslint-disable-next-line react/require-default-props
  isDeleteVisible?: boolean;
  // eslint-disable-next-line react/require-default-props
  isRowEditable?: boolean | ((row: T) => boolean);
  // eslint-disable-next-line react/require-default-props
  isRowDeletable?: boolean | ((row: T) => boolean);
  // eslint-disable-next-line react/require-default-props
  onEditRow?: (row: T) => void;
  // eslint-disable-next-line react/require-default-props
  onRowClick?: (row: T) => void;
  // eslint-disable-next-line react/require-default-props
  onDeleteRow?: (row: T, index: number) => void;
};

export const ScrollableTable = <T extends object>(props: ScrollableTableProps<T>) => {
  const {
    data,
    columns,
    title,
    minHeight,
    maxHeight,
    isEditVisible,
    isDeleteVisible,
    isRowEditable,
    isRowDeletable,
    onRowClick: handleOnRowClick = noop,
    onEditRow: handleOnEditRow = noop,
    onDeleteRow: handleOnDeleteRow = noop,
  } = props;

  const { classes } = useScrollableTableStyle({ minHeight, maxHeight });

  return (
    <Card className={classes.root} elevation={0}>
      <TableContainer className={classes.tableContainer}>
        <TableLayout
          title={title}
          data={data}
          columns={columns}
          pageSize={0}
          isEditVisible={isEditVisible}
          isDeleteVisible={isDeleteVisible}
          isRowEditable={isRowEditable}
          isRowDeletable={isRowDeletable}
          extraActionsRenderer={() => <></>}
          onRowClick={handleOnRowClick}
          onEditRow={handleOnEditRow}
          onDeleteRow={handleOnDeleteRow}
        />
      </TableContainer>

      {data?.length === 0 && <TableBodyWithoutDataView isScrollableTable />}
    </Card>
  );
};
