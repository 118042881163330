/* eslint-disable max-lines */
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect, useState } from "react";
import { DiscountError } from "@health/queries/types";
import { useForm } from "react-hook-form";
import { combineErrors } from "@toolkit/core";
import { useSaleValidationHook } from "./saleValidationHook";
import {
  SaleCreateMutation,
  SaleDeleteMutation,
  SaleUpdateMutation,
  useSaleCreateMutation,
  useSaleDeleteMutation,
  useSaleUpdateMutation,
  useSalesQuery,
} from "pages/product/gql";

export const useDiscountDialogHooks = ({ productId, maxPrice }) => {
  const [isSaleModalOpen, setIsSaleModalOpen] = useState(false);

  const { saleValidationSchema } = useSaleValidationHook(maxPrice);
  type SaleInputSchema = z.infer<typeof saleValidationSchema>;
  const methods = useForm<SaleInputSchema>({
    resolver: zodResolver(saleValidationSchema),
    mode: "onChange",
  });

  const { data: saleData, loading: isLoadingSale } = useSalesQuery({
    variables: {
      first: 10,
      filter: { productId: productId },
    },
    skip: !isSaleModalOpen,
  });
  const defaultValue = saleData?.sales?.edges?.[0]?.node;

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors: reactFormErrors },
  } = methods;
  const [errors, setError] = useState<DiscountError[]>([]);

  useEffect(() => {
    setValue("startDate", defaultValue?.startDate || null);
    setValue("endDate", defaultValue?.endDate || null);
  }, [defaultValue]);

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const [productSaleCreateMutation, { loading: createLoading }] = useSaleCreateMutation({
    onCompleted: (data: SaleCreateMutation) => {
      if (data?.saleCreate?.discountErrors?.length! > 0) {
        failed(formatMessageErrors(data?.saleCreate?.discountErrors));
        setError(data?.saleCreate?.discountErrors!);
      } else {
        succeeded(t("Succeeded in Creating Product Sale!"));
        setIsSaleModalOpen(false);
      }
    },
  });

  const [productSaleUpdateMutation, { loading: updateLoading }] = useSaleUpdateMutation({
    onCompleted: (data: SaleUpdateMutation) => {
      if (data?.saleUpdate?.discountErrors?.length! > 0) {
        failed(formatMessageErrors(data?.saleUpdate?.discountErrors));
        setError(data?.saleUpdate?.discountErrors!);
      } else {
        succeeded(t("Succeeded in Updating Product Sale!"));
        setIsSaleModalOpen(false);
      }
    },
  });

  const [productSaleDeleteMutation, { loading: deleteLoading }] = useSaleDeleteMutation({
    onCompleted: (data: SaleDeleteMutation) => {
      if (data?.saleDelete?.discountErrors?.length! > 0) {
        failed(formatMessageErrors(data?.saleDelete?.discountErrors));
        setError(data?.saleDelete?.discountErrors!);
      } else {
        succeeded(t("Succeeded in Deleting Product Sale!"));
        setIsSaleModalOpen(false);
      }
    },
  });

  const handleOnSubmit = (data: SaleInputSchema) => {
    const input = {
      name: data.name,
      nameAr: data.nameAr,
      products: [productId],
      type: (data.type as any)?.value,
      startDate: data.startDate,
      endDate: data.endDate,
      value: Number(data.value),
    };

    if (defaultValue?.id) {
      productSaleUpdateMutation({
        variables: {
          id: defaultValue?.id,
          input,
        },
      });
    } else {
      productSaleCreateMutation({
        variables: {
          input,
        },
      });
    }
  };

  const onSubmit = (data: SaleInputSchema) => {
    handleOnSubmit({ ...data });
  };

  const handleDeleteDiscount = () => {
    defaultValue?.id &&
      productSaleDeleteMutation({
        variables: {
          id: defaultValue?.id,
        },
        update: cache => {
          const normalizedId = cache.identify({ id: defaultValue?.id, __typename: "Sale" });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
  };

  const handleClose = () => {
    setIsSaleModalOpen(false);
  };

  const handleOpen = () => {
    setIsSaleModalOpen(true);
  };
  const formErrors = combineErrors(reactFormErrors, errors);

  return {
    control,
    isLoadingSale,
    methods,
    defaultValue,
    t,
    formErrors,
    isSaleModalOpen,
    createLoading,
    updateLoading,
    deleteLoading,
    handleOpen,
    handleClose,
    register,
    handleSubmit,
    onSubmit,
    handleDeleteDiscount,
  };
};
