import { DiscountValueTypeEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";

type OptionSaleMapType = {
  [key in DiscountValueTypeEnum]: {
    label: string;
    value: key;
  };
};

export const discountValueTypeEnumOptionsMap: OptionSaleMapType = {
  [DiscountValueTypeEnum.Fixed]: {
    label: i18n.t("Fixed", { ns: "provider" }),
    value: DiscountValueTypeEnum.Fixed,
  },
  [DiscountValueTypeEnum.Percentage]: {
    label: i18n.t("Percentage", { ns: "provider" }),
    value: DiscountValueTypeEnum.Percentage,
  },
};
