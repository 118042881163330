import { useTranslation } from "@toolkit/i18n";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ExpandMoreIcon, Typography } from "@toolkit/ui";
import { useTimeSlotWrapperStyle } from "./useTimeSlotWrapperStyle";
import { IFormattedTimeSlot } from "pages/appointments/types/types";
import React, { FC } from "react";
import moment from "moment";

type TimeSlotWrapperProps = {
  label: string;
  isOpen?: boolean;
  timeSlots: IFormattedTimeSlot[];
  selectedTimeSlots: IFormattedTimeSlot[];
  onSelect: (timeSlot: IFormattedTimeSlot) => void;
};

export const TimeSlotWrapper: FC<TimeSlotWrapperProps> = props => {
  const { label, isOpen = true, timeSlots, selectedTimeSlots, onSelect } = props;

  const { t } = useTranslation("provider");

  const { classes } = useTimeSlotWrapperStyle();
  const currentDate = moment();

  return (
    <Accordion defaultExpanded={isOpen} disableGutters elevation={0} className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
        <Typography className={classes.label}>{t(label)}</Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        {timeSlots.length ? (
          <Box className={classes.timeSlotsWrapper}>
            {timeSlots.map((item, slotIndex) => {
              const isSelected = selectedTimeSlots.findIndex(el => el?.startTime === item?.startTime) === -1;
              const isInPast = moment(item?.startTime).isBefore(currentDate);

              return (
                <Button
                  disabled={isInPast}
                  key={item?.startTime.concat(slotIndex.toString())}
                  className={classes.timeSlotItem}
                  size='small'
                  variant={isSelected ? "outlined" : "contained"}
                  onClick={() => onSelect(item)}
                >
                  {`${item?.startTimeFormatted}-${item?.endTimeFormatted}`}
                </Button>
              );
            })}
          </Box>
        ) : (
          <Box>{t("No Time Slots Available")}</Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
