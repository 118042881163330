/* eslint-disable sonarjs/no-duplicate-string */
import { guidedCareActivityTypeMapper } from "@health/enum-options";
import { GuidedCareJourneyItem, GuidedCareJourneyItemSource } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, paths, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { statusMapper } from "../utils";
import { useTimelineStyles } from "./useTimelineStyles";

type IAppointmentIconView = {
  selectItem: GuidedCareJourneyItem;
  item: GuidedCareJourneyItem;
  hasSimilar: boolean;
  onSelectItem: () => void;
};

export const AppointmentIconView: FC<IAppointmentIconView> = ({ selectItem, item, hasSimilar, onSelectItem: handleSelectItem }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { icon, viewBox } = guidedCareActivityTypeMapper(item?.guidedCareJourneyItemType!, t);

  const { color, iconStatus, viewBoxStatus } = statusMapper(item?.guidedCareJourneyItemStatus!);

  const { classes } = useTimelineStyles();

  const isSelected =
    selectItem?.id === item?.id ||
    (selectItem?.guidedCareJourneyItemStatus === item?.guidedCareJourneyItemStatus &&
      selectItem?.guidedCareJourneyItemType === item?.guidedCareJourneyItemType &&
      hasSimilar);

  return (
    <Box
      className={classes.appointmentIcon}
      sx={{
        background: isSelected ? theme.palette.primary.main : "white",
        border: "1.5px solid",
        borderColor: theme.palette.primary.main,
        "&:hover": {
          border: "1px solid",
          borderColor: color,
          background: color,
          "& svg": {
            fill: "white",
          },
          "#id": {
            "& svg": {
              fill: theme.palette.primary.main,
            },
          },
        },
      }}
      onClick={handleSelectItem}
    >
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "17px",
            height: "17px",
            background: color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1.5px solid",
            borderColor: theme.palette.common.white,
            borderRadius: "50%",
            position: "absolute",
            right: -11,
            top: -8,
            zIndex: 1,
            "& svg": {
              fill: "white",
            },
            "&:hover": {
              "& svg": {
                fill: "white",
              },
            },
          }}
        >
          <CustomIcon icon={iconStatus as keyof typeof paths} color='white' viewBox={viewBoxStatus} />
        </Box>
        <Box
          sx={{
            "& svg": {
              fill: isSelected ? "white" : theme.palette.primary.main,
            },
          }}
        >
          <CustomIcon icon={icon as keyof typeof paths} color={theme.palette.primary.main} viewBox={viewBox} />
        </Box>
        {item?.guidedCareJourneyItemSource === GuidedCareJourneyItemSource.Personalized && (
          <Box
            id={"id"}
            sx={{
              width: "16px",
              height: "16px",
              background: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1.5px solid",
              borderColor: theme.palette.primary.main,
              borderRadius: 1,
              position: "absolute",
              left: -11,
              bottom: -8,
              zIndex: 1,
              "&:hover": {
                "& svg": {
                  fill: theme.palette.primary.main,
                },
              },
            }}
          >
            <CustomIcon icon={"user2"} color={theme.palette.primary.main} viewBox={"-4 -4 23 24"} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
