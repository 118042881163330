import { makeStyles } from "@toolkit/ui";

export const useGuidedCareAppointmentReminderNotificationStyle = makeStyles()(theme => ({
  loadingWrapper: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  boldText: {
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  button: {
    marginTop: 5,
    marginLeft: 0,
  },
}));
