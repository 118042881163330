import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { GetHealthPackageOrdersDocument, useSetHealthPackageOrderToConsumedMutation } from "../gql";
import { OrderStatusEnum } from "@health/queries/types";

export const SetHealthPackageOrderAsConsumedButton: FC<{ orderId: string }> = ({ orderId }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [fetchSetHealthPackageOrderToConsumed, { loading: isSubmittingReject }] = useSetHealthPackageOrderToConsumedMutation({
    onCompleted: data => {
      if (data.setHealthPackageOrderToConsumed?.orderErrors.length === 0) {
        succeeded(t("Health Package status set to consumed successfully"));
      } else {
        failed(t(formatMessageErrors(data?.setHealthPackageOrderToConsumed?.orderErrors!)));
      }
    },
    onError: error => {
      failed(t(formatGraphQLError(error.graphQLErrors)));
    },
  });
  const handleConsumedClick = () => {
    fetchSetHealthPackageOrderToConsumed({
      variables: {
        id: orderId!,
      },
      refetchQueries: [
        {
          query: GetHealthPackageOrdersDocument,
          variables: {
            first: 10,
            filter: {
              checkoutType: "HEALTH_PACKAGE",
              status: [OrderStatusEnum.InProgress],
            },
          },
        },
      ],
    });
  };

  return (
    <Button
      sx={{ width: "160px" }}
      color={"success"}
      disabled={isSubmittingReject}
      onClick={handleConsumedClick}
      title={t("Click here to mark package order status as consumed")}
    >
      {t("Mark as Consumed")}
    </Button>
  );
};
