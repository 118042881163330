import { Doctor } from "@health/queries/types";
import { convertToTitleCase, formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { GridColumnInfoModal } from "@toolkit/ui";

export type ProfessionalsColumnsTypes = CustomTableColumnProps<Doctor>[];

export const useProfessionalsColumns = (): ProfessionalsColumnsTypes => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "photo",
        header: t("Photo"),
        accessor: ({ user }) => <Avatar src={user?.photo || ""} />,
        type: "string",
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ user }) => formatUserNameFull(user!),
        isHidden: false,
        type: "string",
      },
      {
        key: "gender",
        header: t("Gender"),
        accessor: ({ user }) => t(convertToTitleCase(user?.gender || "") || "-"),
        isHidden: false,
        type: "string",
      },
      {
        key: "yearsOfExperience",
        header: t("Years Of Experience"),
        accessor: ({ yearsOfExperience }) => yearsOfExperience || "-",
        type: "string",
      },
      {
        key: "nationalId",
        header: t("National Id"),
        accessor: ({ user }) => user?.nationalId || "-",
        type: "string",
      },
      {
        key: "seniority",
        header: t("Seniority"),
        accessor: "seniority",
        type: "string",
      },
      {
        key: "qualifications",
        header: t("Qualifications"),
        accessor: ({ qualifications }) => (
          <GridColumnInfoModal title={t("Doctor's Qualifications")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {qualifications?.length
                ? qualifications?.map(qualification => qualification?.issuer).join("\n")
                : t("No qualifications provided")}
            </Typography>
          </GridColumnInfoModal>
        ),
        type: "string",
      },
      {
        key: "specializations",
        header: t("Specializations"),
        accessor: ({ specializations }) => (
          <GridColumnInfoModal title={t("Doctor's Specializations")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {specializations?.length
                ? specializations?.map(specialization => specialization?.display).join("\n")
                : t("No specializations provided")}
            </Typography>
          </GridColumnInfoModal>
        ),
        type: "string",
      },
      {
        key: "bio",
        header: t("Bio"),
        accessor: ({ bio }) => (
          <GridColumnInfoModal title={t("Doctor's Bio")}>
            <Typography sx={{ whiteSpace: "pre-line" }}>{bio ? bio : t("No biography provided")}</Typography>
          </GridColumnInfoModal>
        ),
        type: "string",
      },
    ];
  }, []);
};
