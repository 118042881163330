import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";
export const NoteIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "35.096px"}
      height={height ? height : "35.096px"}
      viewBox={viewBox ? viewBox : "0 0 35.096 35.096"}
      {...props}
    >
      <path
        id='icons8-note_1_'
        data-name='icons8-note (1)'
        d='M4,4V39.1H30.25L39.1,30.25V4ZM5.671,5.671H37.425v23.4H29.069v8.356H5.671Zm6.685,7.521v1.671H31.576V13.192Zm0,5.014v1.671H31.576V18.206Zm0,5.014v1.671H31.576V23.219ZM30.74,30.74h5.5l-5.5,5.5Z'
        transform='translate(-4 -4)'
        fill={props?.fill ?? "inherit"}
      />
    </SvgIcon>
  );
};
