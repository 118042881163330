import { CodeSystemConcept, ServiceDetailsInput } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, Stack } from "@toolkit/ui";
import { FC, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { ServiceDetailEditForm } from "./ServiceDetailEditForm";
import { ServiceTypeCodeField } from "./ServiceTypeCodeField";

export const ServiceTypeField: FC = () => {
  const { t } = useTranslation("provider");

  const { fields, append, remove, update } = useFieldArray({
    name: "appointmentServiceDetails",
  });

  const [editService, setEditService] = useState<number | null>(null);
  const handleAdd = (value: CodeSystemConcept) => {
    if (value.code && !fields.find(s => s["serviceCode"] === value.code)) {
      append({ serviceCode: value.code, standard: value.display! });
    }
  };

  const handleDelete = (id: number) => {
    return () => {
      remove(id);
    };
  };

  const handleEdit = (id: number) => {
    return () => {
      setEditService(id);
    };
  };

  const handleSubmitEdit = (service: ServiceDetailsInput) => {
    if (editService === null) {
      return;
    }

    update(editService, service);
    setEditService(null);
  };
  const handleCancelEdit = () => {
    setEditService(null);
  };

  return (
    <Stack spacing={2}>
      <ServiceTypeCodeField onAdd={handleAdd} />

      {fields?.length > 0 && (
        <CustomTable
          data={fields}
          columns={[
            {
              key: "code",
              header: t("Code - Description"),
              accessor: field => {
                return `${field["serviceCode"]} - ${field["standard"]}`;
              },
            },
          ]}
          isRowDeletable
          isRowEditable
          isDeleteVisible
          isEditVisible
          hasFooter={false}
          pageSize={fields?.length}
          pageIndex={10}
          onEditRow={row => handleEdit(Number(row?.id)!)()}
          onDeleteRow={row => handleDelete(Number(row?.id))()}
          pagesCount={1}
          TableContainerProps={{
            sx: {
              minHeight: "inherit",
            },
          }}
        />
      )}
      {editService !== null && (
        <ServiceDetailEditForm service={(fields[editService] as any)!} onSubmit={handleSubmitEdit} onClose={handleCancelEdit} />
      )}
    </Stack>
  );
};
