/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllHealthPackagesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAllHealthPackagesQuery = { __typename?: 'Query', healthPackages?: { __typename?: 'HealthPackageCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthPackageCountableEdge', node: { __typename?: 'HealthPackage', id: string, name: string } }> } | null };


export const GetAllHealthPackagesDocument = gql`
    query getAllHealthPackages($first: Int, $last: Int, $after: String, $before: String) {
  healthPackages(first: $first, last: $last, after: $after, before: $before) {
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAllHealthPackagesQuery__
 *
 * To run a query within a React component, call `useGetAllHealthPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHealthPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllHealthPackagesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetAllHealthPackagesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllHealthPackagesQuery, GetAllHealthPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllHealthPackagesQuery, GetAllHealthPackagesQueryVariables>(GetAllHealthPackagesDocument, options);
      }
export function useGetAllHealthPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllHealthPackagesQuery, GetAllHealthPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllHealthPackagesQuery, GetAllHealthPackagesQueryVariables>(GetAllHealthPackagesDocument, options);
        }
export type GetAllHealthPackagesQueryHookResult = ReturnType<typeof useGetAllHealthPackagesQuery>;
export type GetAllHealthPackagesLazyQueryHookResult = ReturnType<typeof useGetAllHealthPackagesLazyQuery>;
export type GetAllHealthPackagesQueryResult = Apollo.QueryResult<GetAllHealthPackagesQuery, GetAllHealthPackagesQueryVariables>;