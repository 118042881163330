import { discountValueTypeOptions, discountValueTypeOptionsMap } from "@health/enum-options";
import { BACKEND_DATE_TIME_FORMAT, formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { AutocompleteController, Button, CircularProgress, CustomDatePicker, CustomDialog, FormCard, Grid, TextField } from "@toolkit/ui";
import { FC, memo } from "react";
import { FormProvider } from "react-hook-form";
import { useDiscountDialogHooks } from "./DiscountDialog.hooks";
import { DiscountDialogProps } from "./DiscountDialog.types";

const DiscountDialog: FC<DiscountDialogProps> = ({ healthPackageId, maxPrice }) => {
  const {
    isSaleModalOpen,
    handleOpen,
    handleClose,
    handleDeleteDiscount,
    handleSubmit,
    onSubmit,
    methods,
    control,
    register,
    formErrors,
    defaultValue,
    isLoadingSale,
    t,
    isSubmitting,
    deleteLoading,
  } = useDiscountDialogHooks({ healthPackageId, maxPrice });

  return (
    <>
      <Button
        sx={{
          width: "150px",
        }}
        disabled={isSubmitting}
        onClick={handleOpen}
      >
        {t("Discount")}
      </Button>

      {isSaleModalOpen && (
        <CustomDialog
          footerComponent={
            <>
              {defaultValue?.id && (
                <Button
                  onClick={handleDeleteDiscount}
                  startIcon={deleteLoading && <CircularProgress color={"inherit"} size={15} />}
                  disabled={deleteLoading}
                >
                  {t("Delete")}
                </Button>
              )}
            </>
          }
          disableSubmit={isSubmitting}
          onConfirm={handleSubmit(onSubmit)}
          open={Boolean(isSaleModalOpen)}
          maxWidth='md'
          title={t("Health Package Discount")}
          onCloseModal={handleClose}
        >
          <FormCard loading={isLoadingSale} doYouHaveData={true}>
            <FormProvider {...methods}>
              <Grid container spacing={formGirdSpacing}>
                <Grid item {...formGirdBreakPoints}>
                  <TextField
                    label={t("Name")}
                    fullWidth
                    placeholder={t("Name")}
                    defaultValue={defaultValue?.name || ""}
                    helperText={t(formErrors?.name?.message)}
                    error={Boolean(formErrors?.name?.message)}
                    {...register("name")}
                  />
                </Grid>

                <Grid item {...formGirdBreakPoints}>
                  <TextField
                    label={t("Arabic Name")}
                    fullWidth
                    placeholder={t("Arabic Name")}
                    defaultValue={defaultValue?.nameAr}
                    helperText={t(formErrors?.nameAr?.message)}
                    error={Boolean(formErrors?.nameAr?.message)}
                    {...register("nameAr")}
                  />
                </Grid>

                <Grid item {...formGirdBreakPoints}>
                  <AutocompleteController
                    ControllerProps={{
                      name: "type",
                      control: control,
                      defaultValue: discountValueTypeOptionsMap?.[defaultValue?.type!] || null,
                    }}
                    TextFieldProps={{
                      error: Boolean(formErrors?.type?.message),
                      helperText: formErrors?.type?.message,
                      label: t("Type"),
                    }}
                    getOptionLabel={o => t(o?.label)}
                    defaultValue={discountValueTypeOptionsMap?.[defaultValue?.type!] || null}
                    options={discountValueTypeOptions}
                  />
                </Grid>

                <Grid item {...formGirdBreakPoints}>
                  <TextField
                    type='number'
                    defaultValue={defaultValue?.value}
                    placeholder={`${t("Value")}`}
                    fullWidth
                    label={`${t("Value")}`}
                    error={formErrors?.value?.message}
                    helperText={t(formErrors?.value?.message)}
                    {...register(`value`)}
                  />
                </Grid>

                <Grid item {...formGirdBreakPoints}>
                  <CustomDatePicker
                    control={control}
                    label={t("Start Date")}
                    placeholder={t("Start Date")}
                    error={Boolean(formErrors?.startDate?.message)}
                    helperText={t(formErrors?.startDate?.message)}
                    format={BACKEND_DATE_TIME_FORMAT}
                    {...register("startDate")}
                  />
                </Grid>

                <Grid item {...formGirdBreakPoints}>
                  <CustomDatePicker
                    control={control}
                    label={t("End Date")}
                    placeholder={t("End Date")}
                    error={Boolean(formErrors?.endDate?.message)}
                    helperText={t(formErrors?.endDate?.message)}
                    format={BACKEND_DATE_TIME_FORMAT}
                    {...register("endDate")}
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </FormCard>
        </CustomDialog>
      )}
    </>
  );
};
export default memo(DiscountDialog);
