import React, { FC, ReactElement } from "react";
import { NotificationItemAction } from "shared/components";
import { notificationStatusMapper } from "shared/components/Notifications/AppointmentNotification";
import { Box, Button, CircularProgress, CustomMuiDialog, Divider, Stack, Tooltip, Typography } from "@toolkit/ui";
import { AppointmentPaymentRequest, Maybe, Patient, PaymentStatus } from "@health/queries/types";
import {
  AppointmentDate,
  AppointmentReschedulePaymentInformation,
  AvailableTimeSlots,
  InsuranceInformation,
} from "pages/appointments/components";
import { useAppointmentReschedule } from "./useAppointmentReschedule";
import { useAppointmentRescheduleStyle } from "./useAppointmentRescheduleStyle";
import { getCodedDoctorId, isDoctorUser, isVendorAdmin } from "utils";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

type AppointmentRescheduleProps = {
  id: string;
  doctorId?: string;
  status: string;
  patient?: Maybe<Patient>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentInfo?: Maybe<AppointmentPaymentRequest>;
  buttonEl?: ReactElement;
} & NotificationItemAction;

export const AppointmentReschedule: FC<AppointmentRescheduleProps> = props => {
  const { id, doctorId: comingDoctorId, status, buttonEl, patient, paymentStatus, paymentInfo, handleNotificationActionComplete } = props;
  const { userInfo } = useOidcUserProfile();
  const isDoctor = isDoctorUser(userInfo);
  const isVendorAdminUser = isVendorAdmin(userInfo);
  const currentDoctorId = getCodedDoctorId(userInfo);
  const doctorId = comingDoctorId ?? (isDoctor && !isVendorAdminUser ? currentDoctorId : undefined);
  const {
    t,
    selectedDate,
    timeSlots,
    isLoadingAvailableTimeSlots,
    open,
    handleToggle,
    handleOpen,
    selectedTimeSlots,
    isRescheduleSubmitting,
    isDisable,
    isExpiredDate,
    handleSubmit,
    handleSelectTimeSlot,
    handleChangeDate,
  } = useAppointmentReschedule({ id, doctorId, paymentInfo, status, handleNotificationActionComplete });

  const { classes } = useAppointmentRescheduleStyle();

  return (
    <CustomMuiDialog
      open={open}
      DialogTitleProps={{
        title: t("Appointment Reschedule"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={isDisable ? notificationStatusMapper[status]?.display + " " + t("is not allow to reschedule") : ""}>
          <span>
            {buttonEl ? (
              React.cloneElement(buttonEl, { onClick: handleOpen, disabled: isDisable })
            ) : (
              <Button variant='outlined' sx={{ width: "120px" }} disabled={isDisable} onClick={handleOpen}>
                {t("Reschedule")}
              </Button>
            )}
          </span>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <>
            <Tooltip title={!selectedTimeSlots?.length ? t("Please Select Time Slots") : ""}>
              <span>
                <Button onClick={handleSubmit} disabled={!selectedTimeSlots?.length || isRescheduleSubmitting}>
                  {t("Reschedule")}
                </Button>
              </span>
            </Tooltip>
            <Button variant='outlined' onClick={handleToggle}>
              {t("Close")}
            </Button>
          </>
        ),
      }}
    >
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={2}
        divider={<Divider sx={{ width: "100%" }} />}
      >
        <Box className={classes.expiredDateWrapper}>
          <AppointmentDate date={selectedDate} onChangeDate={handleChangeDate} />

          {isExpiredDate && (
            <Typography color='error' className={classes.expiredDateMessage}>
              {t("The Date for this Appointment is Expired")}
            </Typography>
          )}
        </Box>

        {isLoadingAvailableTimeSlots ? (
          <CircularProgress color='inherit' size={20} />
        ) : (
          <AvailableTimeSlots
            isMulti
            availableTimeSlots={timeSlots}
            selectedTimeSlots={selectedTimeSlots}
            onSelect={handleSelectTimeSlot}
          />
        )}

        <Box>
          <InsuranceInformation
            activeHealthProgramMember={patient?.activeHealthProgramMembers?.[0]}
            healthProgramsByActiveMember={patient?.healthProgramsByActiveMembers?.[0]}
          />
        </Box>

        {paymentInfo?.totalAmount && <AppointmentReschedulePaymentInformation paymentInfo={paymentInfo} paymentStatus={paymentStatus} />}
      </Stack>
    </CustomMuiDialog>
  );
};
