import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode, HealthHistoryType } from "@health/queries/types";
import { combineErrors, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, CustomDatePicker, Grid } from "@toolkit/ui";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const PatientHealthHistory = () => {
  const { t } = useTranslation("provider");

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const formErrors = combineErrors(errors, []);

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("code", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SystemCodeAutocomplete name={"code"} isCodeHidden filter={{ codeSystemCode: CodeSystemCode.Diagnosis }} />
      </Grid>

      <Grid item xs={12}>
        <CustomDatePicker
          control={control}
          name='affectedDate'
          label={t("Affected Date")}
          placeholder={t("Affected Date")}
          error={Boolean(formErrors?.affectedDate?.message)}
          helperText={t(formErrors?.affectedDate?.message)}
          controllerProps={{
            rules: { required: getRequiredValidation(t, true) },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteController
          ControllerProps={{
            name: "healthHistoryType",
            control: control,
            rules: {
              required: getRequiredValidation(t, true),
            },
          }}
          TextFieldProps={{
            placeholder: t("Health History Type"),
            label: t("Health History Type"),
            error: Boolean(formErrors?.healthHistoryType?.message),
            helperText: t(formErrors?.healthHistoryType?.message),
          }}
          options={Object.keys(HealthHistoryType).map(item => ({ name: item, value: HealthHistoryType[item] }))}
          getOptionLabel={item => item?.name}
        />
      </Grid>
    </Grid>
  );
};
