import { Branch, PharmacyCredentials } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, ShowButton, useTheme } from "@toolkit/ui";
import { FC } from "react";
import ContentLoader from "react-content-loader";
import { FormProvider } from "react-hook-form";
import useToggle from "shared/hooks/useToggle";
import { WasfatyCredentialForm } from "./WasfatyCredentialForm";
import { useWasfatyCredentialsHook } from "./WasfatyCredentials.hooks";

export const WasfatyCredentialsDialog: FC<{ row: Branch }> = ({ row }) => {
  const id = row?.id;
  const { t } = useTranslation("provider");
  const { open, handleToggle } = useToggle();

  const { onSubmit, handleSubmit, methods, errors, dataCredential, isLoading, isSubmitting } = useWasfatyCredentialsHook(
    id,
    handleToggle,
    open
  );
  const theme = useTheme();
  return (
    <>
      <ShowButton onClick={handleToggle} />
      {open && (
        <FormProvider {...methods}>
          <CustomDialog
            open={open}
            onCloseModal={handleToggle}
            title={t("eRxHub Credential")}
            DialogContentProps={{
              sx: { minHeight: "unset" },
            }}
            footerComponent={
              <>
                <Button
                  color={"primary"}
                  type='submit'
                  form='custom-dialog-form'
                  size='large'
                  variant='contained'
                  onClick={handleSubmit(onSubmit)}
                  disabled={isSubmitting}
                >
                  {t("Save")}
                </Button>
                <Button variant='contained' size='large' color={"inherit"} onClick={handleToggle}>
                  {t("Cancel")}
                </Button>
              </>
            }
          >
            {isLoading ? (
              <ContentLoader
                speed={2}
                width={900}
                viewBox='0 0 400 160'
                backgroundColor={theme.palette.gray.light}
                foregroundColor={theme.palette.gray.light}
              >
                <rect x='20' y='0' rx='0' ry='0' width='850' height='15' />
              </ContentLoader>
            ) : (
              <WasfatyCredentialForm errors={errors} defaultValues={dataCredential as PharmacyCredentials} />
            )}
          </CustomDialog>
        </FormProvider>
      )}
    </>
  );
};
