import { Box, TabLayout, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../context/ProfileContext";
import { useProviderGuidedCareHealthProgramTeamsLazyQuery } from "../gql";
import { PatientInformation } from "./PatientInformation";
import { ProfileContentTabs } from "./ProfileContentTabs";
import { guidedCareTeamMemberPositionOptions } from "@health/enum-options";
import { TeamMemberPosition } from "@health/queries/types";

export const PatientProfileComponent: FC = () => {
  const { patientId, token, selectedTab, selectedHealthProgram, handleTabChange } = useProfileContext();
  const theme = useTheme();
  const [getTeams, { data, fetchMore }] = useProviderGuidedCareHealthProgramTeamsLazyQuery({
    context: getApolloContextFormToken(token!),
  });

  useEffect(() => {
    patientId &&
      getTeams({
        variables: {
          first: 10,
          filter: {
            patients: [patientId!],
          },
        },
      });
  }, [patientId]);

  const hasTeam = Boolean(data?.providerGuidedCareHealthProgramTeams?.edges?.length);
  const providerGuidedCareHealthProgramTeams = data?.providerGuidedCareHealthProgramTeams?.edges?.map(item => item?.node);
  const pageInfo = data?.providerGuidedCareHealthProgramTeams?.pageInfo!;
  const healthPrograms = providerGuidedCareHealthProgramTeams?.map(item => ({
    id: item?.guidedCareHealthProgram?.id!,
    name: item?.guidedCareHealthProgram?.name!,
  }));
  const selectedCareTeams = providerGuidedCareHealthProgramTeams?.find(
    item => item?.guidedCareHealthProgram?.id === selectedHealthProgram
  )?.teamMembers;

  const careTeams = selectedCareTeams
    ?.filter(member => member?.guidedCareProgramTeamMember?.position !== TeamMemberPosition.TeamLeader && !member?.isBackup)
    ?.map(item => ({
      id: item?.id!,
      photo: item?.user?.photo!,
    }));

  const teamLeader = selectedCareTeams?.find(
    member => member?.guidedCareProgramTeamMember?.position === TeamMemberPosition.TeamLeader && !member?.isBackup
  );

  const getPosition = (position: string) => {
    return guidedCareTeamMemberPositionOptions.find(item => item.value === position)?.label;
  };

  const teamMembers = [
    {
      key: teamLeader?.id!,
      label: teamLeader?.user?.firstName! + " " + teamLeader?.user?.lastName! + " - " + getPosition(TeamMemberPosition.TeamLeader),
      value: TeamMemberPosition.TeamLeader,
    },
    ...(selectedCareTeams
      ?.filter(member => member?.guidedCareProgramTeamMember?.position !== TeamMemberPosition.TeamLeader && !member?.isBackup)
      ?.map(item => ({
        key: item?.id!,
        label: item?.user?.firstName! + " " + item?.user?.lastName! + " - " + getPosition(item?.guidedCareProgramTeamMember?.position!),
        value: item?.guidedCareProgramTeamMember?.position!,
      })) || []),
  ];

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        first: 10,
        filter: {
          patients: [patientId!],
        },
        after: pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          ...fetchMoreResult,
          providerGuidedCareHealthProgramTeams: {
            ...fetchMoreResult.providerGuidedCareHealthProgramTeams,
            edges: [
              ...(prev?.providerGuidedCareHealthProgramTeams?.edges || []),
              ...(fetchMoreResult?.providerGuidedCareHealthProgramTeams?.edges || []),
            ],
          },
        });
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          background: theme.palette.common.white,
        }}
      >
        <Box
          sx={{
            margin: "auto",
            maxWidth: 1400,
          }}
        >
          <PatientInformation
            hasTeam={hasTeam}
            hasMore={pageInfo?.hasNextPage!}
            healthPrograms={healthPrograms!}
            teamLeaderPhoto={teamLeader?.user?.photo!}
            selectedCareTeams={careTeams!}
            onFetchMore={handleFetchMore}
          />
        </Box>
      </Box>
      <Box bgcolor={theme.palette.common.white}>
        <TabLayout
          items={ProfileContentTabs({ patientId: patientId!, hasTeam, teamMembers })}
          marginLeft='inherit'
          isRoot={false}
          selectedValue={selectedTab}
          onTabChange={handleTabChange}
        />
      </Box>
    </>
  );
};
