import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const JoinCallIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23' {...props}>
      <path
        id='icons8-ringer-volume'
        d='M16,3a1,1,0,1,0,0,2,8.984,8.984,0,0,1,9,9,1,1,0,1,0,2,0A11.015,11.015,0,0,0,16,3ZM7.738,5a1.186,1.186,0,0,0-.381.031,3.883,3.883,0,0,0-1.814,1.09C3.43,8.238,2.309,11.8,10.254,19.746s11.507,6.825,13.621,4.711a3.885,3.885,0,0,0,1.092-1.818,1.175,1.175,0,0,0-.549-1.3l-4.691-2.748a1.174,1.174,0,0,0-1.2.006l-1.867,1.113a1.171,1.171,0,0,1-1.324-.078,30.629,30.629,0,0,1-2.658-2.318,30.288,30.288,0,0,1-2.318-2.658,1.169,1.169,0,0,1-.078-1.324L11.4,11.467a1.179,1.179,0,0,0,0-1.2L8.66,5.586A1.184,1.184,0,0,0,7.738,5ZM16,7a1,1,0,1,0,0,2,4.984,4.984,0,0,1,5,5,1,1,0,1,0,2,0A7.015,7.015,0,0,0,16,7Zm0,4a1,1,0,1,0,0,2,.984.984,0,0,1,1,1,1,1,0,1,0,2,0A3.015,3.015,0,0,0,16,11Z'
        transform='translate(-4 -3)'
        fill={props?.fill ?? palette.common.white}
      />
    </SvgIcon>
  );
};
