/* eslint-disable max-lines */
import { AppointmentFrequencyType, CreatePersonalizedAppointmentsInput, GuidedCareActivityType } from "@health/queries/types";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  CustomDatePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ModalCloseIcon,
  Typography,
} from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { AppointmentTypeField } from "./AppointmentTypeField";
import { FrequencyField } from "./FrequencyField";
import { MedicalFormsField } from "./MedicalFormsField";
import { NumberField } from "./NumberField";
import { PatientActivityType } from "./PatientAddActivity.types";
import { useStyles } from "./PatientAppointmentForm.styles";
import { ServiceDescriptionInput } from "./ServiceDescription";
import { ServiceTypeField } from "./ServiceTypeField";
import { useAddPatientAppointmentHook } from "./hooks";

type PatientAppointmentFormProps = {
  type: PatientActivityType.Service | PatientActivityType.Visit | PatientActivityType.MedicalForms;
  onClose: () => void;
};

export const PatientAppointmentForm: FC<PatientAppointmentFormProps> = ({ type, onClose }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();

  const getType = () => {
    switch (type) {
      case PatientActivityType.Service:
        return GuidedCareActivityType.Lab;
      case PatientActivityType.Visit:
        return GuidedCareActivityType.OnlineVisit;
      case PatientActivityType.MedicalForms:
        return GuidedCareActivityType.MedicalForm;
      default:
        return GuidedCareActivityType.OnlineVisit;
    }
  };

  const methods = useForm<CreatePersonalizedAppointmentsInput>({
    mode: "onChange",
    defaultValues: {
      type: getType(),
      numberOfAppointments: 1,
      frequencyType: AppointmentFrequencyType.Week,
      DurationBetweenAppointments: 1,
      appointmentServiceDetails: [],
    },
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors: formErrors },
  } = methods;

  const { handleCreateAppointment } = useAddPatientAppointmentHook();
  const onSubmit = async (data: CreatePersonalizedAppointmentsInput) => {
    const isCreated = await handleCreateAppointment(data);
    if (isCreated) {
      onClose();
    }
  };
  const numberOfAppointments = watch("numberOfAppointments");

  return (
    <Dialog
      open={true}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classes.title}>
        <Typography fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {type === PatientActivityType.Visit && t("Add Visit")}
          {type === PatientActivityType.Service && t("Add Service")}
          {type === PatientActivityType.MedicalForms && t("Add Medical Forms")}
        </Typography>
        <IconButton onClick={onClose} sx={{ width: 40 }}>
          <ModalCloseIcon sx={{ width: 10, height: 10 }} />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <FormProvider {...methods}>
          <DialogContent
            sx={{
              height: "calc(100vh - 185px)",
              padding: 2,
              marginBottom: "70px",
              overflowY: "auto",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {type === PatientActivityType.Visit && <AppointmentTypeField />}
                {type === PatientActivityType.Service && <ServiceTypeField />}
                {type === PatientActivityType.MedicalForms && <MedicalFormsField />}
              </Grid>

              <Grid container spacing={1} item xs={12}>
                <Grid item xs={12}>
                  <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
                    {t("Frequency")}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <NumberField isRequired name={"numberOfAppointments"} min={1} label={t("Visits")} />
                </Grid>
                <Grid item xs={8}>
                  <CustomDatePicker
                    control={control}
                    name='startTime'
                    label={t("Start from")}
                    placeholder={t("Start from")}
                    error={Boolean(formErrors?.startTime?.message)}
                    helperText={t(formErrors?.startTime?.message)}
                    controllerProps={{
                      rules: { required: getRequiredValidation(t, true) },
                    }}
                    datePickerProps={{
                      disablePast: true,
                    }}
                  />
                </Grid>
              </Grid>

              {type === PatientActivityType.Service && (
                <Grid item xs={12}>
                  <ServiceDescriptionInput />
                </Grid>
              )}
              {numberOfAppointments > 1 && (
                <Grid item xs={12}>
                  <FrequencyField />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
                  {t("Justification")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField multiline isRequired={false} rows={4} name='justification' label={t("Doctor Justification")} />
              </Grid>

              <Grid item xs={12}>
                <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
                  {t("Doctor Note")}
                </Typography>
              </Grid>
              <Grid item xs={12} columnSpacing={2}>
                <CustomTextField multiline isRequired={false} rows={4} name='doctorNote' label={t("Doctor Note")} />
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
                  {t("Message to Patient")}
                </Typography>
              </Grid>
              <Grid item xs={12} columnSpacing={2}>
                <CustomTextField multiline isRequired={false} rows={4} name='messageToPatient' label={t("Message to Patient")} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.action}>
            <Button type='submit'>
              {type === PatientActivityType.Visit && t("Add Visit")}
              {type === PatientActivityType.Service && t("Add Service")}
              {type === PatientActivityType.MedicalForms && t("Add Medical Form")}
            </Button>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  );
};
