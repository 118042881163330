import { GridProvider } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { Box, TableGrid, Typography, useTheme } from "@toolkit/ui";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../context/ProfileContext";
import { useMedicalFormAnswersQuery } from "../../gql";
import { useCareEngagementColumns } from "./useCareEngagementColumns";

export const CareEngagement = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { patientId, selectedHealthProgram, token } = useProfileContext();

  return (
    <GridProvider
      isCardView
      gridName='medicalFormAnswers'
      columns={useCareEngagementColumns()}
      query={useMedicalFormAnswersQuery}
      context={getApolloContextFormToken(token!)}
      variables={{
        first: 10,
        filter: {
          patients: [patientId!],
          guidedCareHealthPrograms: [selectedHealthProgram!],
        },
      }}
    >
      <Box
        py={3}
        px={10}
        sx={{
          height: "100%",
          backgroundColor: theme.palette.gray.light,
        }}
      >
        <Typography fontSize={26} fontFamily={theme.mixins.fonts.fontFamily.medium} gutterBottom>
          {t("Care Engagement")}
        </Typography>
        <Box>
          <TableGrid />
        </Box>
      </Box>
    </GridProvider>
  );
};
