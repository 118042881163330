import { makeStyles } from "@toolkit/ui";

export const useAppointmentAcceptPaymentInformationStyle = makeStyles()(theme => ({
  boldTitle: {
    fontFamily: theme.mixins.fonts.fontFamily.bold,
  },
  updateButton: {
    paddingBlock: 24,
  },
}));
