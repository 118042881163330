import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, Grid, PlusIcon, ScrollableTable, Typography, useModal } from "@toolkit/ui";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { MedicalFormQuestionUpsertModal } from "../../modals";
import { useMedicalFormQuestionsTableColumns } from "./useMedicalFormQuestionsTableColumns";
import { useMedicalFormQuestionsFormStyle } from "./useMedicalFormQuestionsFormStyle";
export const MedicalFormQuestionsForm = () => {
    const [selectedQuestion, setSelectedQuestion] = useState();
    const { t } = useTranslation("domains");
    const { open, handleOpen, handleClose } = useModal();
    const { classes, theme } = useMedicalFormQuestionsFormStyle();
    const form = useCustomFormContext();
    const { fields, append, remove, update } = useFieldArray({
        control: form.control,
        name: "questions",
    });
    const onAddClick = () => {
        setSelectedQuestion(undefined);
        handleOpen();
    };
    const onMedicalFormQuestionUpsertModalChange = (event) => {
        if (event.type === "CREATE") {
            append(event.payload.values);
            handleClose();
        }
        else if (event.type === "UPDATE" && selectedQuestion) {
            const updatedFieldIndex = fields.findIndex(item => item.id === selectedQuestion.id);
            if (updatedFieldIndex > -1) {
                const updatedTeaMember = Object.assign(Object.assign({}, fields[updatedFieldIndex]), event.payload.values);
                update(updatedFieldIndex, updatedTeaMember);
            }
            handleClose();
        }
    };
    const onEditRowClick = (item) => {
        setSelectedQuestion(item);
        handleOpen();
    };
    const onDeleteRowClick = (item) => {
        const itemIndex = fields.findIndex(field => field.id === item.id);
        if (itemIndex > -1) {
            remove(itemIndex);
        }
    };
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(MedicalFormQuestionUpsertModal, { open: open, onClose: handleClose, question: selectedQuestion, onChange: onMedicalFormQuestionUpsertModalChange }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Grid, Object.assign({ container: true, justifyContent: "space-between" }, { children: [_jsx(Grid, { children: _jsxs(Typography, Object.assign({ className: classes.title }, { children: [" ", t("Questions"), " "] })) }), _jsx(Grid, { children: _jsx(Button, Object.assign({ fullWidth: true, onClick: onAddClick, startIcon: _jsx(PlusIcon, { fill: theme.palette.common.white }) }, { children: t("Add Question") })) })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(ScrollableTable, { data: fields, columns: useMedicalFormQuestionsTableColumns(), isEditVisible: true, isDeleteVisible: true, isRowEditable: true, isRowDeletable: true, onEditRow: onEditRowClick, onDeleteRow: onDeleteRowClick }) }))] })));
};
