import { GridProvider } from "@health/domains";
import { GuidedCareJourneyItemFilter } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, IconButton, MoreVertIcon, TableGrid } from "@toolkit/ui";
import { FC, useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../context/ProfileContext";
import { useTimelineItemsQuery } from "../../../gql";
import { useUpcomingPatientCareActivitiesColumns } from "../UpcomingActivities/useUpcomingPatientCareActivitiesColumns";
import { useActivityItemsDialogStyles } from "./ActivityItemsDialog.style";

type ActivityItemsDialogProps = {
  filterInput?: GuidedCareJourneyItemFilter;
};
export const ActivityItemsDialog: FC<ActivityItemsDialogProps> = ({ filterInput }) => {
  const { t } = useTranslation("provider");
  const { classes } = useActivityItemsDialogStyles();
  const { token } = useProfileContext();

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <CustomDialog
      open={open}
      maxWidth='md'
      title={t("Journey Items")}
      onCloseModal={handleToggle}
      DialogContentProps={{
        sx: { maxHeight: "100vh" },
      }}
      footerComponent={<Button onClick={handleToggle}>{t("Close")}</Button>}
      button={
        <IconButton className={classes.showMoreButtonIcon} onClick={handleToggle}>
          <Box mt={1} className={classes.moreIcon}>
            <MoreVertIcon />
          </Box>
        </IconButton>
      }
    >
      <GridProvider
        gridName='guidedCareJourneyItems'
        columns={useUpcomingPatientCareActivitiesColumns({ upcoming: false })}
        query={useTimelineItemsQuery}
        filterInput={{ ...filterInput }}
        context={getApolloContextFormToken(token!)}
      >
        <TableGrid />
      </GridProvider>
    </CustomDialog>
  );
};
