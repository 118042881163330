/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type CategoryClientFragmentFragment = { __typename?: 'Category', id: string, name: string, level: number, description: string, backgroundImage?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null, icon?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null };

export const CategoryClientFragmentFragmentDoc = gql`
    fragment CategoryClientFragment on Category {
  id
  name
  level
  description
  backgroundImage {
    alt
    url
  }
  icon {
    alt
    url
  }
}
    `;