import { zodSchema } from "@health/domains";
import { z } from "zod";

export const GuidedCareTaskUpdateFormSchema = z.object({
  status: zodSchema.guidedCareTaskStatusAutocompleteEnum,
  assignee: z.object({
    id: z.string(),
    assigneeUserId: z.string(),
    assigneeType: zodSchema.guidedCareTaskAssigneeTypeAutocompleteEnum,
    teamMember: zodSchema.guidedCareTeamMemberAutocompleteOptional,
  }),
});

export type IGuidedCareTaskUpdateFormSchemaValues = z.infer<typeof GuidedCareTaskUpdateFormSchema>;

export const guidedCareTaskUpdateFormSchemaDefaultValues: Partial<IGuidedCareTaskUpdateFormSchemaValues> = {
  status: undefined,
  assignee: undefined,
};
