import type { SvgIconProps } from "@mui/material/SvgIcon";
import SvgIcon from "@mui/material/SvgIcon";
import type { FC } from "react";
import React from "react";

export const VideoConference2Icon: FC<SvgIconProps> = props => (
  <SvgIcon xmlns='http://www.w3.org/2000/svg' width='27' height='22.5' viewBox='0 0 27 22.5' {...props}>
    <path
      id='icons8-video-conference_2_'
      data-name='icons8-video-conference (2)'
      d='M5.25,6A2.249,2.249,0,0,0,3,8.25v13.5A2.249,2.249,0,0,0,5.25,24h22.5A2.249,2.249,0,0,0,30,21.75V8.25A2.249,2.249,0,0,0,27.75,6Zm0,3.375H8.625v2.25H5.25Zm11.25,0a1.48,1.48,0,0,1,1.406.762c.96,0,1.406,1.05,1.406,2.283a2.859,2.859,0,0,1-.248,1.125.617.617,0,0,1,.224.609c-.076.651-.338.817-.5.831a2.886,2.886,0,0,1-1.158,1.5V17.5c.421,1.369,2.756.7,3.375,3.125H12c.619-2.427,2.954-1.755,3.375-3.125V16.483a2.886,2.886,0,0,1-1.158-1.5c-.165-.014-.427-.179-.5-.831a.613.613,0,0,1,.222-.609,3.244,3.244,0,0,1-.246-1.125C13.689,10.755,14.512,9.375,16.5,9.375Zm-11.25,4.5H8.625v2.25H5.25Zm0,4.5H8.625v2.25H5.25Zm5.625,7.875a1.125,1.125,0,1,0,0,2.25h11.25a1.125,1.125,0,1,0,0-2.25Z'
      transform='translate(-3 -6)'
      fill={props.fill ? props.fill : "#1b2346"}
    />
  </SvgIcon>
);
