import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { UpdatePromotionMutation, useUpdatePromotionMutation } from "pages/Promotions/gql";
import React, { FC } from "react";

export const PublishedToggleButton: FC<{ id: string; isPublished: boolean }> = ({ id, isPublished }) => {
  const { t } = useTranslation("provider");
  const [checked, setChecked] = React.useState(isPublished);
  const { succeeded, failed } = useAddToast();
  const [updatePromotionMutation, { loading }] = useUpdatePromotionMutation({
    onCompleted: (data: UpdatePromotionMutation) => {
      const errors = data?.promotionUpdate?.promotionErrors;
      if (errors && errors.length > 0) {
        const error = formatMessageErrors(errors);
        failed(t(error));
      } else {
        setChecked(data?.promotionUpdate?.promotion?.published as boolean);
        succeeded(t("Promotion updated successfully"));
      }
    },
    onError: () => {
      failed(t("Failed in updating promotion"));
    },
  });

  const handleChangeActivity = event => {
    const isChecked: boolean = event.target.checked;
    updatePromotionMutation({
      variables: {
        promotionUpdateId: id,
        input: {
          published: isChecked,
        },
      },
    });
  };

  return <CustomToggleButton checked={checked} onChange={handleChangeActivity} disabled={loading} />;
};
