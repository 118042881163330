import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import React, { FC } from "react";
import { ProductVariantReviewPublishMutation, useProductVariantReviewPublishMutation } from "../gql";

export const ProductVariantReviewPublishToggle: FC<{ id: string; published: boolean }> = ({ id, published }) => {
  const [isPublished, setIsPublished] = React.useState(published);
  const { succeeded, failed } = useAddToast();
  const { t } = useTranslation("provider");

  const [publishProductVariant, { loading }] = useProductVariantReviewPublishMutation({
    onCompleted: (mutationData: ProductVariantReviewPublishMutation) => {
      const mutationErrors = mutationData?.productVariantReviewPublish?.productErrors;
      if (mutationErrors && mutationErrors?.length > 0) {
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        setIsPublished(mutationData?.productVariantReviewPublish?.productVariantReview?.published as boolean);
        succeeded(isPublished ? t("Succeeded in unpublishing review") : t("Succeeded in publishing review"));
      }
    },
    onError: () => {
      failed(t("Failed in publishing review"));
    },
  });

  const handleChangeVisibility = event => {
    const isChecked: boolean = event.target.checked;
    publishProductVariant({
      variables: {
        productVariantReviewPublishId: id,
        input: {
          published: isChecked,
        },
      },
    });
  };

  return <CustomToggleButton checked={isPublished} onChange={handleChangeVisibility} disabled={loading} />;
};
