import {
  GuidedCareTaskUpdateFormSchema,
  IGuidedCareTaskUpdateFormSchemaValues,
  guidedCareTaskUpdateFormSchemaDefaultValues,
} from "./GuidedCareTaskUpdateFormSchema";
import { GuidedCareTeamMembersAutocomplete } from "@health/autocompletes";
import { guidedCareTaskStatusOptions } from "@health/enum-options";
import { AssigneeType, GuidedCareTask } from "@health/queries/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, Grid } from "@toolkit/ui";
import { convertGuidedCareTaskToFormValues } from "pages/GuidedCareTasks/others";
import { IGuidedCareTaskUpdateFormEvent } from "pages/GuidedCareTasks/types";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";

type GuidedCareTaskUpdateFormProps = {
  guidedCareTask: GuidedCareTask;
  onChange: (event: IGuidedCareTaskUpdateFormEvent) => void;
};

export type GuidedCareTaskUpdateFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTaskUpdateFormSchemaValues>;
  submit: () => void;
};

export const GuidedCareTaskUpdateFormForwardRef: ForwardRefRenderFunction<GuidedCareTaskUpdateFormRef, GuidedCareTaskUpdateFormProps> = (
  props,
  ref
) => {
  const { guidedCareTask, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<IGuidedCareTaskUpdateFormSchemaValues>({
    defaultValues: guidedCareTaskUpdateFormSchemaDefaultValues,
    schema: GuidedCareTaskUpdateFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IGuidedCareTaskUpdateFormSchemaValues) => {
    if (guidedCareTask) {
      onChange({
        type: "UPDATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (guidedCareTask) {
      const _guidedCareTask = convertGuidedCareTaskToFormValues(guidedCareTask);
      setValues(_guidedCareTask);
    }
  }, [guidedCareTask, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2} mb={2}>
          {guidedCareTask?.assignee?.assigneeType !== AssigneeType.Patient && (
            <Grid item xs={12}>
              <GuidedCareTeamMembersAutocomplete
                name={"assignee.teamMember"}
                label={t("Assignee")}
                filter={{
                  teamId: guidedCareTask?.guidedCareJourneyItem?.guidedCareJourney?.providerGuidedCareHealthProgramTeam?.id!,
                  memberPosition: guidedCareTask?.assignee?.teamMember?.guidedCareProgramTeamMember?.position!,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormAutocomplete name={"status"} label={t("Status")} placeholder={t("Status")} options={guidedCareTaskStatusOptions} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareTaskUpdateForm = forwardRef(GuidedCareTaskUpdateFormForwardRef);
