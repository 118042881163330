/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaleDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SaleDeleteMutation = { __typename?: 'Mutation', saleDelete?: { __typename?: 'SaleDelete', discountErrors: Array<{ __typename?: 'DiscountError', code: Types.DiscountErrorCode, field?: string | null, message?: string | null }> } | null };


export const SaleDeleteDocument = gql`
    mutation SaleDelete($id: ID!) {
  saleDelete(id: $id) {
    discountErrors {
      code
      field
      message
    }
  }
}
    `;
export type SaleDeleteMutationFn = Apollo.MutationFunction<SaleDeleteMutation, SaleDeleteMutationVariables>;

/**
 * __useSaleDeleteMutation__
 *
 * To run a mutation, you first call `useSaleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saleDeleteMutation, { data, loading, error }] = useSaleDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaleDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SaleDeleteMutation, SaleDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaleDeleteMutation, SaleDeleteMutationVariables>(SaleDeleteDocument, options);
      }
export type SaleDeleteMutationHookResult = ReturnType<typeof useSaleDeleteMutation>;
export type SaleDeleteMutationResult = Apollo.MutationResult<SaleDeleteMutation>;
export type SaleDeleteMutationOptions = Apollo.BaseMutationOptions<SaleDeleteMutation, SaleDeleteMutationVariables>;