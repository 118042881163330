import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { ReviewsScreen } from "pages/Reviews";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { ROUTE_PATHS } from "shared/constants";
import ProductsFormEditPage from "../edit/ProductsEditPage.screen";
import ProductsList from "../home/Products.screen";

export const productRoutes: (props: { navigate: NavigateFunction; t: TFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  t,
  enabled,
}) => {
  return {
    id: "Product Management",
    text: ROUTE_PATHS.productsManagementVendorRoutes?.name,
    isProhibited: !(enabled && hasPermission(PermissionEnum.ManageProductStocks)),
    subItems: [
      {
        id: "products",
        text: ROUTE_PATHS.productsManagementVendorRoutes.name,
        route: ROUTE_PATHS.productsManagementVendorRoutes.path,
        fullPath: ROUTE_PATHS.productsManagementVendorRoutes.fullPath,
        element: <ProductsList />,
        onClick: route => navigate(route),
      },

      {
        id: "update-Product",
        text: ROUTE_PATHS.productsManagementVendorRoutes.products?.edit?.name,
        route: ROUTE_PATHS.productsManagementVendorRoutes.products?.edit?.path,
        fullPath: ROUTE_PATHS.productsManagementVendorRoutes.products?.edit.fullPath,
        element: <ProductsFormEditPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "reviews",
        route: "reviews/:id",
        fullPath: "/reviews/:id",
        text: t("Reviews", { ns: "provider" }),
        element: <ReviewsScreen />,
        onClick: route => navigate(route),
        isProhibited: !hasPermission(PermissionEnum.ManageCodeSystemReviewers),
        hidden: true,
      },
    ],
  };
};
