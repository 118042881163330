import { useEffect, useState } from "react";
import { MyProfileDetailsItem } from "./DetailsCard.types";

export type IValue = { value: string | boolean | undefined; name: string | number | undefined };

export const useDetailsPaper = (item: MyProfileDetailsItem) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<IValue | undefined>(undefined);

  const handleClick = () => {
    if (open && value?.name != "-") {
      item?.handleUpdate?.(value);
    }
    setOpen(!open);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    item?.handleDelete?.();
  };
  const handleChangeValue = e => {
    setValue(e.target.value);
  };
  const handleChangeOptionValue = (_, value) => {
    setValue(value);
  };

  useEffect(() => {
    setValue({ value: item?.value, name: item?.subTitle });
  }, [item?.subTitle]);

  return {
    open,
    value,
    handleClick,
    handleCancel,
    handleDelete,
    handleChangeValue,
    handleChangeOptionValue,
  };
};
