import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const HighPriority: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='26.212' height='26.213' viewBox='0 0 26.212 26.213' {...props}>
      <path
        id='icons8-high-priority'
        d='M27.691,13.848,16.364,2.521a1.781,1.781,0,0,0-2.517,0L2.521,13.848a1.781,1.781,0,0,0,0,2.517L13.848,27.692a1.781,1.781,0,0,0,2.517,0L27.692,16.365A1.782,1.782,0,0,0,27.691,13.848Zm-11.363-5.8-.2,9.073H14.086l-.2-9.073Zm-1.219,14.3c-.835,0-1.334-.445-1.334-1.192a1.167,1.167,0,0,1,1.334-1.206,1.2,1.2,0,1,1,0,2.4Z'
        transform='translate(-2 -2)'
        fill='#ed7b15'
      />
    </SvgIcon>
  );
};
