import { GuidedCareTaskListActions } from "../../components/GuidedCareTaskListActions/GuidedCareTaskListActions";
import { guidedCareTaskStatusOptionsMap } from "@health/enum-options";
import { AssigneeType, GuidedCareTask, GuidedCareTaskSortingField, H_OrderDirection } from "@health/queries/types";
import { pickLocalizedText, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography, useFormatDate } from "@toolkit/ui";
import { useMemo } from "react";
import { PatientProfile } from "shared/modules/patient";

type GuidedCareTaskListColumnsProps = {
  isPatientColHidden?: boolean;
};

export const useGuidedCareTaskListColumns = (props: GuidedCareTaskListColumnsProps): CustomTableColumnProps<GuidedCareTask>[] => {
  const { isPatientColHidden } = props;

  const { t } = useTranslation("provider");

  const { formatLocalizedDate } = useFormatDate();

  return useMemo(() => {
    return [
      {
        key: "task",
        header: t("Task"),
        accessor: ({ displayEn, displayAr }) => (
          <Typography sx={{ maxWidth: 200, wordBreak: "break-word" }}>{pickLocalizedText(displayEn!, displayAr!)}</Typography>
        ),
      },
      {
        key: "assignee",
        header: t("Assignee"),
        accessor: ({ assignee, guidedCareJourneyItem }) => {
          return assignee?.assigneeType === AssigneeType.Patient ? (
            <PatientProfile patient={guidedCareJourneyItem?.guidedCareJourney?.patientMedicalProfile?.patient} />
          ) : (
            assignee?.teamMember?.user?.fullName
          );
        },
        isHidden: isPatientColHidden,
      },
      {
        key: "patient",
        header: t("Patient"),
        accessor: ({ guidedCareJourneyItem }) => {
          const patient = guidedCareJourneyItem?.guidedCareJourney?.patientMedicalProfile?.patient;
          return patient ? <PatientProfile patient={patient} /> : "-";
        },
        isHidden: isPatientColHidden,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => guidedCareTaskStatusOptionsMap[status!]?.label,
      },
      {
        key: "dueDate",
        header: t("Due Date"),
        accessor: ({ dueDate }) => formatLocalizedDate(dueDate),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareTaskSortingField.DueDate,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: data => <GuidedCareTaskListActions guidedCareTask={data} />,
      },
    ];
  }, [formatLocalizedDate, isPatientColHidden, t]);
};
