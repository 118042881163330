import { SvgIcon, useTheme } from "@mui/material";
import React, { FC } from "react";
import { IconProps } from "./types";
export const DocumentIcon: FC<IconProps> = ({ width, height, fill, viewBox, ...props }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "12.266"}
      height={height ? height : "15.945"}
      viewBox={viewBox ? viewBox : "0 0 12.266 15.945"}
      {...props}
    >
      <path
        id='icons8-document_1_'
        data-name='icons8-document'
        d='M17.086,6.166,13.1,2.18A.613.613,0,0,0,12.666,2H6.227A1.226,1.226,0,0,0,5,3.227V16.719a1.226,1.226,0,0,0,1.227,1.227h9.813a1.226,1.226,0,0,0,1.227-1.227V6.6A.613.613,0,0,0,17.086,6.166Zm-4.113,7.486H8.066a.613.613,0,1,1,0-1.227h4.906a.613.613,0,1,1,0,1.227ZM14.2,11.2H8.066a.613.613,0,1,1,0-1.227H14.2a.613.613,0,0,1,0,1.227ZM12.973,6.906a.614.614,0,0,1-.613-.613V3.168L16.1,6.906Z'
        transform='translate(-5 -2)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
