import { makeStyles } from "@toolkit/ui";

export const useCurrentWorkingBranchSelectDialogStyles = makeStyles()(theme => ({
  paper: {
    width: 536,
    borderRadius: 15,
    backgroundColor: theme.palette.common.white,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  line: {
    width: "100%",
    margin: "10px 0px",
    background: theme.palette.stale[200],
  },
  action: {
    height: 70,
    padding: 10,
    display: "flex",
    alignItems: "center",
    boxShadow: theme.mixins.shadows.md,
  },
  selectBtn: {
    width: 108,
  },
}));
