import { getAutocompleteFilter } from "@toolkit/ui";
import { useVendorProductVariantsAutocompleteQuery } from "./gql";
export const getProductVariantsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "productVariants",
        query: useVendorProductVariantsAutocompleteQuery,
        labelBy: option => { var _a, _b; return (_b = (_a = option === null || option === void 0 ? void 0 : option.vendorProductVariant) === null || _a === void 0 ? void 0 : _a.productVariant) === null || _b === void 0 ? void 0 : _b.name; },
        arabicLabelBy: option => { var _a, _b; return (_b = (_a = option === null || option === void 0 ? void 0 : option.vendorProductVariant) === null || _a === void 0 ? void 0 : _a.productVariant) === null || _b === void 0 ? void 0 : _b.nameAr; },
        backendAccessor: option => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.vendorProductVariant) === null || _a === void 0 ? void 0 : _a.id; },
    });
};
