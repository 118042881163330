import { HealthPackage, HealthPackageError, HealthPackageUpdateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { healthPackageEditBreadCrumb, healthPackageListBreadCrumb } from "pages/HealthPackages/constant";
import { useHealthPackageQuery, useHealthPackageUpdateMutation } from "pages/HealthPackages/gql";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ellipseName } from "utils";

export const usePackageEditPageHook = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();
  const [errors, setError] = useState<HealthPackageError[]>();

  const { succeeded, failed } = useAddToast();

  const [healthPackageUpdate, { loading: isFetchingUpdate }] = useHealthPackageUpdateMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const errors = data?.healthPackageUpdate?.healthPackageErrors;
      if (errors?.length === 0) {
        succeeded(t("Health package updated successfully"));
        navigate(-1);
      } else {
        const error = t(formatMessageErrors(errors));
        failed(error);
        setError(errors as HealthPackageError[]);
      }
    },
    onError: () => {
      failed(t("Failed in updating health package"));
    },
  });

  const { data, loading: isLoading } = useHealthPackageQuery({
    variables: {
      healthPackageId: String(params?.id),
    },
  });

  const handleUpdatePackage = (data: HealthPackageUpdateInput) => {
    healthPackageUpdate({
      variables: {
        healthPackageUpdateId: params.id as string,
        input: {
          description: data.description,
          descriptionAr: data.descriptionAr,
          shortDescription: data.shortDescription,
          shortDescriptionAr: data.shortDescriptionAr,
          mainImageUrl: encodeURI(data.mainImageUrl!),
          name: data.name,
          nameAr: data.nameAr,
          price: +(data.price ?? 0),
          targetGender: (data?.targetGender as any)?.value,
          targetFromAge: Number(data?.targetFromAge),
          targetToAge: Number(data?.targetToAge),
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: t("Health Packages Management"),
      values: [healthPackageListBreadCrumb(t), healthPackageEditBreadCrumb(ellipseName(data?.healthPackage?.name!, 20))],
    });
  }, [data?.healthPackage?.name]);

  return {
    healthPackage: data?.healthPackage as HealthPackage,
    errors,
    isLoading,
    isFetchingUpdate,
    handleUpdatePackage,
  };
};
