import { useTranslation } from "@toolkit/i18n";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ModalCloseIcon, Typography } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { PatientSendNotificationData, useSendNotificationMessage } from "./hooks";
import { useStyles } from "./PatientAppointmentForm.styles";

type PatientSendNotificationFormProps = {
  onClose: () => void;
};

export const PatientSendNotificationForm: FC<PatientSendNotificationFormProps> = ({ onClose }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();

  const methods = useForm<PatientSendNotificationData>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const { handleSendNotificationMessage } = useSendNotificationMessage();
  const onSubmit = async (data: PatientSendNotificationData) => {
    const isSent = await handleSendNotificationMessage(data);
    if (isSent) {
      onClose();
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classes.title}>
        <Typography fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("Send Notification")}
        </Typography>
        <IconButton onClick={onClose} sx={{ width: 40 }}>
          <ModalCloseIcon sx={{ width: 10, height: 10 }} />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <DialogContent
            sx={{
              paddingInline: 2,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
                  {t("URL")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField isRequired type='url' name='url' label={t("Add URL")} />
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
                  {t("Notification Message")}
                </Typography>
              </Grid>
              <Grid item xs={12} columnSpacing={2}>
                <CustomTextField isRequired multiline minRows={5} name='message' label={t("Notification Message")} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.action}>
            <Button type='submit'>{t("Send Notification")}</Button>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  );
};
