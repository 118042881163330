import { GuidedCareTaskUpdateForm, GuidedCareTaskUpdateFormRef } from "../../forms/GuidedCareTaskUpdateForm/GuidedCareTaskUpdateForm";
import { GuidedCareTask } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { IGuidedCareTaskUpdateFormEvent } from "pages/GuidedCareTasks/types";
import React, { FC, useRef } from "react";

type GuidedCareTaskUpdateFormModalProps = BaseDialogProps & {
  guidedCareTask: GuidedCareTask;
  onChange: (values: IGuidedCareTaskUpdateFormEvent) => void;
};

export const GuidedCareTaskUpdateFormModal: FC<GuidedCareTaskUpdateFormModalProps> = props => {
  const { guidedCareTask, onChange, ...rest } = props;

  const { t } = useTranslation("provider");
  const guidedCareTaskUpdateForm = useRef<GuidedCareTaskUpdateFormRef>(null);

  const onUpdateClick = () => {
    guidedCareTaskUpdateForm.current?.submit();
  };

  return (
    <BaseDialog
      {...rest}
      maxWidth={"sm"}
      title={t("Update Task")}
      confirmButton={{ label: t("Update"), onClick: onUpdateClick }}
      cancelButton={{ label: t("Cancel") }}
    >
      <GuidedCareTaskUpdateForm guidedCareTask={guidedCareTask} ref={guidedCareTaskUpdateForm} onChange={onChange} />
    </BaseDialog>
  );
};
