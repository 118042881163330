import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Tab } from "@mui/material";
import React, { CSSProperties, FC, ReactNode, useEffect } from "react";
import { useTabsStyles } from "./Tabs.styles";

export type TabsItemsType = Array<{
  title: ReactNode | string | any;
  index: string;
  content: ReactNode;
  disabled?: boolean;
  display?: boolean;
  hidden?: boolean;
}>;
interface TabsProps {
  items: TabsItemsType;
  marginLeft?: CSSProperties["marginLeft"];
  isRoot?: boolean;
  selectedValue?: string;
  onTabChange?: (value: string) => void;
}

export const TabLayout: FC<TabsProps> = props => {
  const { items, marginLeft, isRoot, selectedValue, onTabChange } = props;
  const [value, setValue] = React.useState(selectedValue || items[0].index);

  useEffect(() => {
    setValue(selectedValue || items[0].index);
  }, [selectedValue]);
  const { classes } = useTabsStyles({ isRoot: !!isRoot });
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    onTabChange?.(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box className={classes.tabs}>
          <Container
            fixed
            sx={{ padding: 0, marginLeft: marginLeft ? marginLeft : "auto" }}
            classes={{ root: isRoot ? classes.container : classes.defaultRoot }}
          >
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              {items
                ?.filter(item => !item?.hidden)
                .map(item => (
                  <Tab
                    label={item.title}
                    value={item.index}
                    key={item.index}
                    classes={{ root: classes.root, selected: classes.selected }}
                    disabled={item.disabled}
                  />
                ))}
            </TabList>
          </Container>
        </Box>
        {items?.map((item, index) => (
          <TabPanel
            key={item.index + item.title}
            value={item.index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            // eslint-disable-next-line no-nested-ternary
            classes={{ root: item?.display ? classes.display : isRoot ? classes.defaultPanelRoot : classes.panelRoot }}
          >
            {item.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};
TabLayout.defaultProps = {
  marginLeft: undefined,
  isRoot: false,
  selectedValue: undefined,
  onTabChange: undefined,
};
