/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllVendorsQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAllVendorsQuery = { __typename?: 'Query', vendors?: { __typename?: 'VendorCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'VendorCountableEdge', node: { __typename?: 'Vendor', name: string, id: string } }> } | null };


export const GetAllVendorsDocument = gql`
    query getAllVendors($last: Int, $first: Int, $after: String, $before: String) {
  vendors(last: $last, first: $first, after: $after, before: $before) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetAllVendorsQuery__
 *
 * To run a query within a React component, call `useGetAllVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVendorsQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetAllVendorsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllVendorsQuery, GetAllVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllVendorsQuery, GetAllVendorsQueryVariables>(GetAllVendorsDocument, options);
      }
export function useGetAllVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllVendorsQuery, GetAllVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllVendorsQuery, GetAllVendorsQueryVariables>(GetAllVendorsDocument, options);
        }
export type GetAllVendorsQueryHookResult = ReturnType<typeof useGetAllVendorsQuery>;
export type GetAllVendorsLazyQueryHookResult = ReturnType<typeof useGetAllVendorsLazyQuery>;
export type GetAllVendorsQueryResult = Apollo.QueryResult<GetAllVendorsQuery, GetAllVendorsQueryVariables>;