import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const MessageIcon: FC<SvgIconProps> = ({ width, height }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 30.682 29.502'>
      <path
        id='icons8-chat_2_'
        data-name='icons8-chat (2)'
        d='M17.341,3C8.868,3,2,8.812,2,15.981a12.258,12.258,0,0,0,5.442,9.908c.085,1.813-.2,4.315-2.7,5.469l0,0A.59.59,0,0,0,4.95,32.5l.041,0H5a10.372,10.372,0,0,0,6.993-3.211A2.284,2.284,0,0,1,14,28.65a17.865,17.865,0,0,0,3.337.311c8.473,0,15.341-5.812,15.341-12.981S25.814,3,17.341,3Z'
        transform='translate(-2 -3)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
