/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProfessionalsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProfessionalsQuery = { __typename?: 'Query', Professionals?: { __typename?: 'DoctorCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'DoctorCountableEdge', node: { __typename?: 'Doctor', id: string, bio?: string | null, yearsOfExperience?: number | null, seniority?: Types.DoctorSeniorityEnum | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, nationalId?: string | null, gender?: Types.UserGender | null, photo?: string | null } | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, code: string, display?: string | null } | null> | null, qualifications?: Array<{ __typename?: 'Qualification', code: string, id: string, issuer: string } | null> | null } }> } | null };


export const ProfessionalsDocument = gql`
    query Professionals($first: Int, $before: String, $after: String, $last: Int) {
  Professionals: doctors(
    first: $first
    before: $before
    after: $after
    last: $last
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      node {
        user {
          id
          firstName
          lastName
          nationalId
          gender
          photo
        }
        id
        bio
        yearsOfExperience
        specializations {
          id
          code
          display
        }
        qualifications {
          code
          id
          issuer
        }
        seniority
      }
    }
  }
}
    `;

/**
 * __useProfessionalsQuery__
 *
 * To run a query within a React component, call `useProfessionalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessionalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionalsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useProfessionalsQuery(baseOptions?: Apollo.QueryHookOptions<ProfessionalsQuery, ProfessionalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionalsQuery, ProfessionalsQueryVariables>(ProfessionalsDocument, options);
      }
export function useProfessionalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionalsQuery, ProfessionalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionalsQuery, ProfessionalsQueryVariables>(ProfessionalsDocument, options);
        }
export type ProfessionalsQueryHookResult = ReturnType<typeof useProfessionalsQuery>;
export type ProfessionalsLazyQueryHookResult = ReturnType<typeof useProfessionalsLazyQuery>;
export type ProfessionalsQueryResult = Apollo.QueryResult<ProfessionalsQuery, ProfessionalsQueryVariables>;