import { useTranslation } from "@toolkit/i18n";
import { Grid, useMediaQuery } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { useProfileContext } from "../../context/ProfileContext";
import BasicLineChart from "../Charts/BasicLineChart";
import { OverviewCard } from "./OverviewCard";

export const BasicHealthParameters: FC = () => {
  const { t } = useTranslation("provider");

  const { patient } = useProfileContext();
  const bodyComposition = patient?.bodyComposition;
  const description = t("iot Device");
  const format = "DD MMM YYYY";

  const weight = patient?.bodyCompositions?.edges
    ?.filter(item => item?.node?.weightInKG)
    .map(item => ({
      value: item?.node?.weightInKG!,
      date: moment(item?.node?.modified).format(format),
      currentData: item?.node?.weightInKG === bodyComposition?.weightInKG,
    }))
    .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))!;
  const bmi = patient?.bodyCompositions?.edges
    ?.filter(item => item?.node?.bmi)
    .map(item => ({
      value: item?.node?.bmi!,
      date: moment(item?.node?.modified).format(format),
      currentData: item?.node?.bmi === bodyComposition?.bmi,
    }));

  const isMobile = useMediaQuery("(max-width:1340px)");
  const lg = isMobile ? 4 : "auto";

  return (
    <>
      <Grid item xs={12} sm={4} md={4} lg={lg}>
        <OverviewCard
          title={t("Weight")}
          unit={"kg"}
          width={!isMobile ? 418 : 200}
          value={bodyComposition?.weightInKG! ? bodyComposition?.weightInKG! + " kg" : t("No data available")}
          description={description}
        >
          <BasicLineChart title={t("Weight")} data={weight} />
        </OverviewCard>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={lg}>
        <OverviewCard
          width={!isMobile ? 418 : 200}
          title={t("BMI")}
          value={bodyComposition?.bmi! || t("No data available")}
          description={description}
        >
          <BasicLineChart title={t("BMI")} data={bmi} />
        </OverviewCard>
      </Grid>
    </>
  );
};
