/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorProductVariantQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type VendorProductVariantQuery = { __typename?: 'Query', productVariant?: { __typename?: 'ProductVariant', id: string, name?: string | null, vendorProductVariant?: { __typename?: 'VendorProductVariant', id: string, stocks?: Array<{ __typename?: 'Stock', quantity: number, branch?: { __typename?: 'Branch', id: string, name: string } | null } | null> | null, price?: { __typename?: 'Money', currency: string, amount: number } | null } | null } | null };


export const VendorProductVariantDocument = gql`
    query vendorProductVariant($id: ID!) {
  productVariant(id: $id) {
    id
    name
    vendorProductVariant {
      id
      stocks {
        quantity
        branch {
          id
          name
        }
      }
      price {
        currency
        amount
      }
    }
  }
}
    `;

/**
 * __useVendorProductVariantQuery__
 *
 * To run a query within a React component, call `useVendorProductVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorProductVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorProductVariantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorProductVariantQuery(baseOptions: Apollo.QueryHookOptions<VendorProductVariantQuery, VendorProductVariantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorProductVariantQuery, VendorProductVariantQueryVariables>(VendorProductVariantDocument, options);
      }
export function useVendorProductVariantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorProductVariantQuery, VendorProductVariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorProductVariantQuery, VendorProductVariantQueryVariables>(VendorProductVariantDocument, options);
        }
export type VendorProductVariantQueryHookResult = ReturnType<typeof useVendorProductVariantQuery>;
export type VendorProductVariantLazyQueryHookResult = ReturnType<typeof useVendorProductVariantLazyQuery>;
export type VendorProductVariantQueryResult = Apollo.QueryResult<VendorProductVariantQuery, VendorProductVariantQueryVariables>;