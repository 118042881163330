import { useTranslation } from "@toolkit/i18n";

import { last } from "lodash";
import { FC } from "react";
import BarLineChart from "../Charts/BarLineChart";
import { OverviewCard } from "./OverviewCard";
import { IVitalSignChart } from "./types";
import { useTheme } from "@toolkit/ui";

type VitalSignTemperatureChartProps = IVitalSignChart;

export const VitalSignTemperatureChart: FC<VitalSignTemperatureChartProps> = props => {
  const { data, percentage, width } = props;

  const { t } = useTranslation("provider");
  const {
    palette: { secondary },
  } = useTheme();
  const lastItem = last(data);

  return (
    <OverviewCard
      width={width}
      title={t("Temperature")}
      value={`${lastItem?.value || 0}`}
      percentage={percentage}
      unit={"°C"}
      date={lastItem?.date!}
      description={t("By") + " " + (lastItem?.source! || "-")}
    >
      <BarLineChart title={t("Temperature")} color={secondary.main} data={data} />
    </OverviewCard>
  );
};
