import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, FormCard, Grid, PageWrapper, WarningConfirmationDialog, useModal } from "@toolkit/ui";
import { MedicalFormClassificationsForm, MedicalFormInformationForm, MedicalFormQuestionsForm } from "../../../MedicalForms/forms";
import { convertMedicalFormToFormValues } from "../../others";
import { MedicalFormUpsertFormSchema, medicalFormUpsertFormDefaultValues, } from "./MedicalFormUpsertFormSchema";
import { useMedicalFormUpsertFormStyle } from "./useMedicalFormUpsertFormStyle";
export const MedicalFormUpsertForm = props => {
    const { isAdmin, medicalForm, isLoading, isSubmitDisabled, handleCancel, onChange } = props;
    const { t } = useTranslation("domains");
    const { open, handleOpen, handleClose } = useModal();
    const { classes } = useMedicalFormUpsertFormStyle();
    const form = useCustomForm({
        defaultValues: medicalFormUpsertFormDefaultValues,
        schema: MedicalFormUpsertFormSchema,
    });
    const { setValues, handleSubmit, reset } = form;
    const onSubmit = (values) => {
        onChange({
            type: "SUBMIT",
            payload: { values },
        });
    };
    const onResetFieldsClick = () => {
        handleOpen();
    };
    const onWarningConfirmationDialogConfirm = () => {
        reset();
        handleClose();
    };
    useEffect(() => {
        if (medicalForm) {
            const _medicalForm = convertMedicalFormToFormValues(medicalForm);
            setValues(_medicalForm);
        }
    }, [medicalForm, setValues]);
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(PageWrapper, Object.assign({ actions: _jsxs(Box, Object.assign({ className: classes.formActionsWrapper }, { children: [_jsx(Button, Object.assign({ type: "submit", variant: 'contained', color: "primary", disabled: isSubmitDisabled, startIcon: isSubmitDisabled && _jsx(CircularProgress, { size: 15 }) }, { children: t("Save") })), _jsx(Button, Object.assign({ variant: 'outlined', className: classes.resetFieldsButton, onClick: onResetFieldsClick }, { children: t("Reset Fields") })), _jsx(Button, Object.assign({ variant: 'outlined', onClick: handleCancel }, { children: t("Cancel") }))] })) }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: formGirdSpacing }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormCard, Object.assign({ title: t("Medical Form Information"), loading: isLoading, doYouHaveData: true }, { children: _jsx(MedicalFormInformationForm, { isAdmin: isAdmin }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormCard, Object.assign({ loading: isLoading, doYouHaveData: true }, { children: _jsx(MedicalFormQuestionsForm, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormCard, Object.assign({ loading: isLoading, doYouHaveData: true }, { children: _jsx(MedicalFormClassificationsForm, {}) })) }))] })) })), _jsx(WarningConfirmationDialog, { isOpen: open, titleText: t("Reset Fields"), bodyText: t("Are you sure you want to reset all fields?"), confirmText: t("Confirm"), cancelText: t("Cancel"), onClose: handleClose, onConfirm: onWarningConfirmationDialogConfirm })] })) })));
};
