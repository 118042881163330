import { makeVar } from "@apollo/client";
import { i18n } from "@toolkit/i18n";
import { AppToastProvider } from "@toolkit/ui";
import { fetchFreshAccessToken } from "shared/utils/tokenUtils";

export const isExpiredLicenseEndDate = makeVar(false);

export const getMeetingPlatformLinkWithToken = (meetingLink: string, token: string): string => {
  return meetingLink?.replace("@oauth_token", token).replace("@exit_url", location.origin);
};

export const openMeetingPlatformLink = async (meetingLink?: string, token?: string, refreshToken?: string) => {
  if (meetingLink && meetingLink.length > 0) {
    const newToken = await fetchFreshAccessToken(token!, refreshToken!);
    const visitUrl = getMeetingPlatformLinkWithToken(meetingLink, newToken);
    visitUrl && window.open(visitUrl);
  } else {
    AppToastProvider.addFailedToast(i18n.t("Failed to rejoin the call. Please retry."));
  }
};
