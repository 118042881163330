import React, { FC, useEffect, useRef, useState } from "react";
import { useStyles } from "./useStyles";

type VerificationInputProps = {
  onChange: (value: string) => void;
};

export const VerificationInput: FC<VerificationInputProps> = ({ onChange }) => {
  const { classes } = useStyles();

  const [otp, setOTP] = useState(new Array(6).fill(""));
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (index, value) => {
    const newOTP = [...otp];

    const prevValues = otp[index] ? Array.from(otp[index]) : [];
    const currentValues = Array.from(value);
    const diffIndex = currentValues.findIndex((char, i) => char !== prevValues[i]);

    if (value.length > 1) {
      newOTP[index] = currentValues[diffIndex];
    } else {
      newOTP[index] = value;
    }
    setOTP(newOTP);

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };
  useEffect(() => {
    onChange(otp.join(""));
  }, [otp]);

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };
  const handlePaste = event => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text");
    const pastedOTP = pastedData.slice(0, 6).split("");
    const newOTP = [...otp];

    pastedOTP.forEach((value, index) => {
      if (inputRefs.current[index] && value.match(/[0-9]/)) {
        newOTP[index] = value;
      }
    });

    setOTP(newOTP);
    onChange(newOTP.join(""));
  };

  return (
    <div>
      {Array.from({ length: 6 }, (_, index) => (
        <input
          key={index}
          maxLength={1}
          type='number'
          value={otp[index]}
          onPaste={handlePaste}
          onChange={e => handleChange(index, e.target.value)}
          onKeyDown={e => handleKeyDown(index, e)}
          ref={input => (inputRefs.current[index] = input as HTMLInputElement)}
          className={classes.input}
        />
      ))}
    </div>
  );
};
