import { Promotion, PromotionSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { ConnectionDocumentNode, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import moment from "moment";
import { GetAllPromotionsQuery } from "pages/Promotions/gql";
import { useMemo } from "react";
import { PhotoComponent } from "./PhotoComponent";
import { PublishedToggleButton } from "./PublishedToggleButton";
import {
  getCategoriesAutocompleteFilter,
  getProductVariantsAutocompleteFilter,
  getHealthPackagesAutocompleteFilter,
} from "@health/autocompletes";

export type PromotionsColumnsTypes = CustomTableColumnProps<ConnectionDocumentNode<GetAllPromotionsQuery, "promotions">>[];

export const usePromotionColumns = (): PromotionsColumnsTypes => {
  const { t } = useTranslation("provider");
  const options = [
    { label: t("Published"), value: true },
    { label: t("Not Published"), value: false },
  ];
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: row => <TruncateTypography text={row?.name!} />,
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: PromotionSortField.Name,
        filter: {
          name: "name",
          type: "string",
        },
      },
      {
        key: "description",
        header: t("Description"),
        accessor: row => <TruncateTypography text={row?.description!} />,
      },
      {
        key: "photo",
        header: t("Photo"),
        accessor: row => {
          return <PhotoComponent promotion={row as Promotion} />;
        },
        isHidden: false,
      },

      {
        key: "publishDate",
        header: t("Published Date"),
        accessor: row => moment(row?.publishDate).format("DD MMM YYYY"),
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: PromotionSortField.PublishDate,
      },
      {
        key: "expiryDate",
        header: t("Expiry Date"),
        accessor: row => moment(row?.expiryDate).format("DD MMM YYYY"),
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: PromotionSortField.ExpiryDate,
      },
      {
        key: "categoryIds",
        header: t("Categories"),
        showOnlyForFilterField: true,
        filter: getCategoriesAutocompleteFilter({ name: "categoryIds", multiple: true }),
      },
      {
        key: "fromExpiryDate",
        header: t("Expiry Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "fromExpiryDate",
        },
      },
      {
        key: "toExpiryDate",
        header: t("Expiry Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "toExpiryDate",
        },
      },
      {
        key: "fromPublishDate",
        header: t("Publish Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "fromPublishDate",
        },
      },
      {
        key: "toPublishDate",
        header: t("Publish Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "toPublishDate",
        },
      },
      {
        key: "published",
        header: t("Published"),
        accessor: row => <PublishedToggleButton id={row?.id as string} isPublished={row?.published as boolean} />,
        isHidden: false,
        filter: {
          type: "autocomplete",
          name: "published",
          options: options,
          getOptionLabel: option => option?.label,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "productVariantIds",
        header: t("Product Variants"),
        showOnlyForFilterField: true,
        filter: getProductVariantsAutocompleteFilter({ name: "productVariantIds", multiple: true }),
      },
      {
        key: "healthPackageIds",
        header: t("Health Packages"),
        showOnlyForFilterField: true,
        filter: getHealthPackagesAutocompleteFilter({ name: "healthPackageIds", multiple: true }),
      },
    ];
  }, []);
};
