import { ShowButton } from "@toolkit/ui";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export const ProductVariantReviews: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const handleOpen = () => {
    navigate("/reviews/" + id);
  };
  return <ShowButton onClick={handleOpen} />;
};
