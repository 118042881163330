import { AppointmentType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, ExpandMoreIcon, TextField } from "@toolkit/ui";
import { FC, useMemo } from "react";

interface AppointmentTypeFieldProps {
  onAppointmentTypeChange: (value: AppointmentType) => void;
}

export const AppointmentTypeField: FC<AppointmentTypeFieldProps> = ({ onAppointmentTypeChange }) => {
  const { t } = useTranslation("provider");

  const fieldOptions = useMemo(
    () => [
      {
        label: t("Virtual"),
        id: AppointmentType.Online,
      },
      {
        label: t("In-Person"),
        id: AppointmentType.Onsite,
      },
      {
        label: t("At Home"),
        id: AppointmentType.AtHome,
      },
    ],
    [t]
  );

  return (
    <Autocomplete
      popupIcon={<ExpandMoreIcon />}
      options={fieldOptions}
      defaultValue={fieldOptions[0]}
      disableClearable
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={o => o?.label ?? ""}
      onChange={(_, val) => onAppointmentTypeChange(val?.id ?? AppointmentType.Online)}
      renderInput={params => <TextField {...params} fullWidth label={t("Visit Type")} />}
    />
  );
};

export default AppointmentTypeField;
