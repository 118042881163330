import { useTranslation } from "@toolkit/i18n";
import { Grid, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { ComingSoonAppointmentsGrid } from "../ComingSoonAppointmentsGrid";
import { PendingAppointmentsGrid } from "../PendingAppointmentsGrid";
import { WaitingRoomsList } from "../WaitingRooms";
import { NurseUserFeedsCard } from "./NurseUserFeeds";
import { GuidedCareTaskList } from "pages/GuidedCareTasks/containers/GuidedCareTaskList/GuidedCareTaskList";

export const NurseDashboardView: FC = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Grid container spacing={2} padding={4}>
      <Grid item xs={12}>
        <Typography variant='h1' sx={{ fontFamily: theme.mixins.fonts.fontFamily.medium, fontSize: 26 }}>
          {t("Nurse Dashboard")}
        </Typography>
      </Grid>

      <Grid item xs={12} md={4} order={{ xs: 1, md: 1 }}>
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium} mb={1}>
          {t("Virtual waiting rooms")}
        </Typography>

        <WaitingRoomsList />
      </Grid>

      <Grid item xs={12} md={8} order={{ xs: 2, md: 2 }}>
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium} mb={1}>
          {t("Upcoming Appointments")}
        </Typography>

        <ComingSoonAppointmentsGrid
          TableContainerProps={{
            sx: {
              height: 357.5,
            },
          }}
          pageSize={5}
          emptyIconHeight={100}
          iconBoxProps={{
            marginBottom: "90px",
            height: "5px",
          }}
        />
      </Grid>

      <Grid item xs={12} md={4} order={{ xs: 4, md: 3 }}>
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium} mb={1}>
          {t("My Feeds")}
          <NurseUserFeedsCard />
        </Typography>
      </Grid>

      <Grid item xs={12} md={8} order={{ xs: 3, md: 4 }}>
        <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium} mb={1}>
          {t("Pending Items")}
        </Typography>

        <PendingAppointmentsGrid
          TableContainerProps={{
            sx: {
              height: 357.5,
            },
          }}
          pageSize={5}
          emptyIconHeight={100}
          iconBoxProps={{
            marginBottom: "90px",
            height: "5px",
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <GuidedCareTaskList />
      </Grid>
    </Grid>
  );
};
