import { makeStyles } from "@toolkit/ui";

export const useOrderCancelModelStyles = makeStyles()(theme => ({
  cancel: {
    whiteSpace: "nowrap",
    display: " inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden !important",
    padding: "8px 20px",
  },
  reject: {
    margin: 5,
    width: 120,
    background: `${theme.palette.error.main} !important`,
    "&:hover": {
      color: theme.palette.common.white,
      opacity: 0.9,
      background: theme.palette.error.main,
    },
    "&:disabled": {
      color: theme.palette.common.white,
      opacity: `0.8 !important`,
      backgroundColor: `${theme.palette.error.main} !important`,
    },
  },
  paper: {
    width: 836,
    height: 363,
    borderRadius: 15,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  mobile: {
    marginInline: 35,
  },
  box: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  formControlLabel: {
    margin: 10,
    marginInline: 0,
  },
  call: {
    width: 25,
    height: 25,
    margin: 2,
    cursor: "pointer",
    "&:hover": {
      borderRadius: 100,
      background: theme.palette.gray.light,
    },
    "& svg": {
      width: 25,
      height: 25,
    },
  },
}));
