import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const TimeMachineIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='19.005' height='19.433' viewBox='0 0 19.005 19.433' {...props}>
      <path
        id='icons8-time-machine'
        d='M4.44.966a.483.483,0,0,0-.052.013.414.414,0,0,0-.323.413V5.524H8.2a.413.413,0,1,0,0-.826H5.447a8.665,8.665,0,1,1-2.621,6.2,9,9,0,0,1,.943-3.99l-.736-.374A9.847,9.847,0,0,0,2,10.895,9.519,9.519,0,1,0,4.892,4.078V1.392A.414.414,0,0,0,4.44.966Zm6.649,3.318v5.9a.819.819,0,0,0-.413.71.8.8,0,0,0,.026.207L7.9,13.9l.594.594,2.8-2.8a.8.8,0,0,0,.207.026.823.823,0,0,0,.413-1.536v-5.9Z'
        transform='translate(-2 -0.964)'
        fill={props?.fill ? props?.fill : theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
