import { MedicalFormAnswer, TaskWorkflowType } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useToggle } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, useAddToast } from "@toolkit/ui";
import { useGuidedCareTaskUpdateToDoneMutation } from "pages/GuidedCareTasks/gql";
import { FC } from "react";
import { HealthInsuranceSatisfaction } from "shared/modules/patient/components/Assessments/HealthInsuranceSatisfaction";

type GuidedCareTaskActionsMedicalFormProps = {
  guidedCareTaskId: string;
  taskWorkflowType: TaskWorkflowType;
  assigneeUserId: string;
  medicalFormAnswers: MedicalFormAnswer;
};

export const GuidedCareTaskActionsMedicalForm: FC<GuidedCareTaskActionsMedicalFormProps> = props => {
  const { guidedCareTaskId, taskWorkflowType, assigneeUserId, medicalFormAnswers } = props;

  const { t } = useTranslation("provider");
  const { open, handleToggle } = useToggle();
  const { succeeded, failed } = useAddToast();

  const [updateGuidedCareTask, { loading }] = useGuidedCareTaskUpdateToDoneMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.updateGuidedCareTaskToDone?.entityErrors;

      if (!mutationErrors?.length) {
        succeeded(t("Guided Care Task updated successfully"));
      } else {
        failed(formatMessageErrors(mutationErrors));
      }
      handleToggle();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      handleToggle();
    },
  });

  const handleSubmitClick = () => {
    updateGuidedCareTask({
      variables: {
        actionEntityId: guidedCareTaskId,
        taskWorkflowType,
        assigneeUserId,
      },
    });
  };

  return (
    <>
      <Button variant={"outlined"} onClick={handleToggle}>
        {t("Review")}
      </Button>

      {open && (
        <HealthInsuranceSatisfaction
          isOpen={open}
          label={medicalFormAnswers?.medicalForm?.name!}
          date={medicalFormAnswers?.medicalForm?.updateDate || medicalFormAnswers?.medicalForm?.createdDate}
          questionAnswers={medicalFormAnswers?.questionAnswers!}
          classificationName={medicalFormAnswers?.classificationName!}
          numberOfQuestions={medicalFormAnswers?.questionAnswers?.length!}
          onToggle={handleToggle}
          footerComponent={
            loading ? (
              <CircularProgress />
            ) : (
              <Button disabled={loading} onClick={handleSubmitClick}>
                {t("Submit Review")}
              </Button>
            )
          }
        />
      )}
    </>
  );
};
