import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useMarkAllNotificationsAsSeenMutation } from "./gql";

export const ReadAllNotificationsButton: FC<{ notificationsCount: number | undefined }> = ({ notificationsCount }) => {
  const { t } = useTranslation("provider");
  const { failed } = useAddToast();
  const theme = useTheme();
  const [markAllNotificationsAsSeenMutation, { loading }] = useMarkAllNotificationsAsSeenMutation({
    onCompleted: response => {
      if (response.markAllNotificationsAsSeen?.success) {
        console.info("MarkAllNotificationsAsSeen success");
      } else {
        failed(formatMessageErrors(response.markAllNotificationsAsSeen?.errors!));
      }
    },
  });
  const handleClick = () => {
    markAllNotificationsAsSeenMutation();
  };
  return (
    <Button
      sx={{
        fontSize: theme.mixins.fonts.fontSize.sm,
        fontFamily: theme.mixins.fonts.fontFamily.regular,
      }}
      disabled={loading || notificationsCount === 0}
      variant='text'
      onClick={handleClick}
    >
      {t("Clear all")}
    </Button>
  );
};
