import { OrderDirection, Promotion, PromotionSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { usePromotionColumns } from "./components/list/PromotionsColumns";
import { useGetAllPromotionsQuery } from "./gql";

export const PromotionsScreen: FC = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({ title: t("Promotions"), values: [{ id: "", name: "", route: "" }] });
  }, []);
  const handleAddPromotion = () => {
    navigate("new");
  };

  const handleEditPromotion = (promotion: Promotion) => {
    navigate("edit/" + promotion?.id);
  };
  return (
    <GridProvider
      hasTableSetting
      gridName='promotionList'
      columns={usePromotionColumns()}
      query={useGetAllPromotionsQuery}
      dataAccessor={"promotions"}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditPromotion,
      }}
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: PromotionSortField.Created,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddPromotion} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
