import { HealthPackageCreateInput } from "@health/queries/types";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const usePackageFormHook = ({ handleOnSubmit }) => {
  const navigate = useNavigate();

  const methods = useForm<HealthPackageCreateInput>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors: errorsForm },
  } = methods;

  const onSubmit = (data: HealthPackageCreateInput) => {
    handleOnSubmit({ ...data });
  };

  const handleCancel = () => {
    navigate(-1);
  };
  return {
    methods,
    location,
    errorsForm,
    handleSubmit,
    onSubmit,
    handleCancel,
  };
};
