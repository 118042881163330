import { TFunction, i18n } from "@toolkit/i18n";
import { CustomTableColumnProps, OrderDirection } from "@toolkit/ui";
import { getDepartmentsAutocompleteFilter } from "@health/autocompletes";
import { MedicalFormActivation } from "@health/domains";
import { getYesNoAutocompleteFilter, medicalFormCategoryOptionsMap, medicalFormTypeOptionsMap } from "@health/enum-options";
import { MedicalForm, MedicalFormSortingField } from "@health/queries/types";

export const useProviderMedicalFormsTableColumns = (t: TFunction): CustomTableColumnProps<MedicalForm>[] => {
  return [
    {
      key: "name",
      header: t("Name", { ns: "provider" }),
      accessor: ({ name, nameAr }) => (i18n.language === "en" ? name : nameAr || name),
      type: "string",
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MedicalFormSortingField.Name,
      filter: {
        type: "string",
        name: "nameContains",
      },
    },
    {
      key: "code",
      header: t("Code", { ns: "provider" }),
      accessor: "code",
      type: "string",
      filter: {
        type: "string",
        name: "codes",
        getValueForBackend: name => (name ? [name] : undefined),
      },
    },
    {
      key: "type",
      header: t("Type", { ns: "gcadmin" }),
      accessor: ({ type }) => medicalFormTypeOptionsMap[type!]?.label,
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MedicalFormSortingField.Type,
    },
    {
      key: "category",
      header: t("Category", { ns: "gcadmin" }),
      accessor: ({ category }) => medicalFormCategoryOptionsMap[category!]?.label,
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MedicalFormSortingField.Category,
    },
    {
      key: "department",
      header: t("Department", { ns: "provider" }),
      accessor: ({ department }) => department?.name,
    },
    {
      key: "active",
      isSortable: true,
      sortDirection: OrderDirection.Desc,
      sortColumnEnum: MedicalFormSortingField.Active,
      header: t("Active", { ns: "provider" }),
      accessor: row => <MedicalFormActivation id={row?.id!} isActive={!!row?.active} />,
      filter: getYesNoAutocompleteFilter({ name: "isActive" }),
    },
    {
      key: "Departments",
      header: t("Departments", { ns: "provider" }),
      showOnlyForFilterField: true,
      filter: getDepartmentsAutocompleteFilter({ name: "departments", multiple: true }),
    },
  ];
};
