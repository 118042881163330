import { ServiceDescription } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, Box, ExpandMoreIcon, Stack, TextField, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

const options = [
  {
    label: "Lab Tests",
    id: ServiceDescription.LabTests,
  },
  {
    label: "Rad Tests",
    id: ServiceDescription.RadTests,
  },
];

export const ServiceDescriptionInput: FC = () => {
  const { t } = useTranslation("provider");
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const theme = useTheme();
  const numberOfAppointments = watch("serviceDescription");
  if (numberOfAppointments <= 1) {
    return null;
  }
  return (
    <>
      <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
        {t("Service Description")}
      </Typography>
      <Stack direction='row' alignItems='center' spacing={2}>
        <Box flex={1}>
          <Controller
            name='serviceDescription'
            control={control}
            render={({ field }) => {
              const onChange = (event: React.SyntheticEvent, option: (typeof options)[number] | null) => {
                field.onChange(option?.id);
              };

              return (
                <Autocomplete
                  {...field}
                  popupIcon={<ExpandMoreIcon />}
                  options={options}
                  isOptionEqualToValue={(option, value: string) => option.id === value}
                  getOptionLabel={o => {
                    if (typeof o === "string") {
                      return options.find(opt => opt.id === o)?.label;
                    }

                    return o?.label;
                  }}
                  onChange={onChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      error={Boolean(errors.serviceDescription?.message)}
                      helperText={errors.serviceDescription?.message}
                    />
                  )}
                />
              );
            }}
          />
        </Box>
      </Stack>
    </>
  );
};
