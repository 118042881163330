import { hasPermission } from "@health/common";
import { FeatureEnum, IsFeatureEnabledType } from "@health/hooks";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { LabIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { HealthPackageOrdersScreen } from "./HealthPackageOrders";

const baseRoute = "/health-package-orders";

export const settingsPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${baseRoute}${this.route}`;
    },
  },
};

export const healthPackageOrdersRoutes: (props: {
  navigate: NavigateFunction;
  t: TFunction;
  isFeatureEnabled: IsFeatureEnabledType;
}) => RouteItem = ({ navigate, t, isFeatureEnabled }): RouteItem => {
  const isHealthPackageItemHidden = !(
    isFeatureEnabled([FeatureEnum.HealthPackage]) && hasPermission(PermissionEnum.ManageHealthPackageOrders)
  );

  return {
    id: "healthPackageOrders",
    text: t("Health Package Orders", { ns: "provider" }),
    icon: <LabIcon />,
    route: baseRoute,
    fullPath: settingsPaths.indexPage.fullPath,
    element: <HealthPackageOrdersScreen />,
    hidden: isHealthPackageItemHidden,
    onClick: (route: string) => navigate(route),
  };
};
