import { PatientChronicDisease } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../context/ProfileContext";
import { useAddPatientChronicDiseaseMutation, useDeletePatientChronicDiseaseMutation } from "../../gql";
import { PatientProfileSectionContainer } from "./PatientProfileSectionContainer";
import { getChronicDiseasesSectionContent } from "./chronic-diseases.utils";
import { AddNewDialog } from "./sections/AddNewDialog";

export const ChronicDiseasesSection = () => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const theme = useTheme();
  const { patientCurrentMedicalStatus, patientId, userId, token } = useProfileContext();
  const [fetchAddPatientChronicDisease, { loading }] = useAddPatientChronicDiseaseMutation({
    onCompleted: response => {
      const patientChronicDiseases = response?.addPatientChronicDisease?.patientCurrentMedicalStatus?.patientChronicDiseases;
      if (patientChronicDiseases) {
        succeeded(t("Add Patient Chronic Disease Successfully"));
      } else {
        failed(t("Add Patient Chronic Disease Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFormToken(token),
  });

  const [fetchDeletePatientChronicDisease] = useDeletePatientChronicDiseaseMutation({
    onCompleted: response => {
      const patientChronicDiseases = response?.deletePatientChronicDisease?.patientCurrentMedicalStatus?.patientChronicDiseases;
      if (patientChronicDiseases) {
        succeeded(t("Delete Patient Chronic Disease Successfully"));
      } else {
        failed(t("Delete Patient Chronic Disease Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFormToken(token),
  });

  const patientChronicDiseases = patientCurrentMedicalStatus?.patientChronicDiseases as PatientChronicDisease[];

  const handleAddPatientChronicDisease = data => {
    fetchAddPatientChronicDisease({
      variables: {
        input: {
          userId,
          patientId: patientId!,
          code: data?.code?.value?.code!,
        },
      },
    });
  };

  const handleDeletePatientChronicDisease = id => {
    fetchDeletePatientChronicDisease({
      variables: {
        userId,
        patientId: patientId!,
        deletePatientChronicDiseaseId: id,
      },
    });
  };
  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("Latest Chronic Diagnosis")}
        </Typography>

        <AddNewDialog title={t("Add Chronic Disease")} loading={loading} onAdd={handleAddPatientChronicDisease} />
      </Box>
      <PatientProfileSectionContainer
        items={getChronicDiseasesSectionContent<PatientChronicDisease>(
          patientChronicDiseases,
          t("There is no chronic diagnoses reported"),
          handleDeletePatientChronicDisease
        )}
      />
    </>
  );
};
