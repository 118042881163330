// eslint-disable-next-line no-restricted-imports
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { OndemandVideoIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { UserInfoType } from "shared/configs/oidc";
import { isVendorAdmin } from "utils";
import { StreamingPage } from "./";

const streamingRoute = "/streaming";

export const streamingPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${streamingRoute}${this.route}`;
    },
  },
};

export const streamingRoutes: (props: { navigate: NavigateFunction; t: TFunction; userInfo: UserInfoType }) => RouteItem = ({
  navigate,
  t,
  userInfo,
}): RouteItem => ({
  id: "streaming",
  text: t("Streaming", { ns: "provider" }),
  icon: <OndemandVideoIcon color='inherit' />,
  route: `${streamingRoute}/*`,
  fullPath: streamingPaths.indexPage.fullPath,
  element: <StreamingPage />,
  isProhibited: !(isVendorAdmin(userInfo) && hasPermission(PermissionEnum.ManageSocialAndStreaming)),
  onClick: (route: string) => navigate(route),
});
