import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    width: 100,
    height: 350,
    overflowY: "initial",
  },
  noPhoto: {
    width: 500,
    padding: 10,
  },
  imageListItem: {
    "&.MuiImageListItem-img": {
      width: "359px",
      height: "370px",
      margin: "10px",
      objectFit: "contain",
    },
  },
  imageContainer: {
    width: "40px",
    height: "40px",
    border: "1px solid" + theme.palette.gray.light,
    borderRadius: 10,
    padding: "5px",
    cursor: "pointer",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.gray.light,
    width: "40px",
    height: "40px",
    border: "1px solid" + theme.palette.gray.light,
    borderRadius: 2,
    padding: "5px",
    cursor: "pointer",
  },
}));
