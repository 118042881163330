import { GuidedCareTaskUpdateFormModal } from "../../modals/GuidedCareTaskUpdateFormModal/GuidedCareTaskUpdateFormModal";
import { GuidedCareTask } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast, useModal } from "@toolkit/ui";
import { useGuidedCareTaskUpdateMutation } from "pages/GuidedCareTasks/gql";
import { convertGuidedCareTaskFormValuesToBackEndValues } from "pages/GuidedCareTasks/others";
import { IGuidedCareTaskUpdateFormEvent } from "pages/GuidedCareTasks/types";
import { FC } from "react";

type GuidedCareTaskUpdateButtonProps = {
  guidedCareTask: GuidedCareTask;
};

export const GuidedCareTaskUpdateButton: FC<GuidedCareTaskUpdateButtonProps> = props => {
  const { guidedCareTask } = props;

  const { t } = useTranslation("provider");
  const { open, handleOpen, handleClose } = useModal();
  const { succeeded, failed } = useAddToast();

  const [updateGuidedCareTask, { loading }] = useGuidedCareTaskUpdateMutation({
    onCompleted: data => {
      const errors = data?.guidedCareTaskUpdate?.entityErrors;

      if (!errors?.length) {
        succeeded(t("Task updated successfully"));
      } else {
        failed(t("Task update failed"));
      }

      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      handleClose();
    },
  });

  const onGuidedCareTaskUpdateFormModalChange = (event: IGuidedCareTaskUpdateFormEvent) => {
    if (event.type === "UPDATE") {
      const values = convertGuidedCareTaskFormValuesToBackEndValues(event.payload.values);

      updateGuidedCareTask({
        variables: {
          id: guidedCareTask?.id!,
          input: values,
        },
      });
    }
  };

  return (
    <>
      <Button variant={"outlined"} disabled={loading} onClick={handleOpen}>
        {t("Update")}
      </Button>

      {open && (
        <GuidedCareTaskUpdateFormModal
          open={open}
          guidedCareTask={guidedCareTask}
          onClose={handleClose}
          onChange={onGuidedCareTaskUpdateFormModalChange}
        />
      )}
    </>
  );
};
