import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const AppointmentSchedulingIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='23.777' height='21.849' viewBox='0 0 23.777 21.849' {...props}>
      <path
        id='icons8-appointment-scheduling'
        d='M9.416,4a4.6,4.6,0,0,0-3.8,1.586c-.926,1.221-1.1,3.078-.518,5.52a1.6,1.6,0,0,0-.309,1.206c.128,1.054.532,1.482.874,1.658a4.852,4.852,0,0,0,1.05,2.2l0,.227c0,.484,0,.912-.038,1.458-.294.674-1.259,1.059-2.375,1.5C2.453,20.1.144,21.013-.032,23.92l-.029.5h14.4a5.717,5.717,0,0,1-.556-.95H.98c.356-1.909,2.042-2.579,3.675-3.23,1.3-.518,2.532-1,2.941-2.113l.028-.119c.052-.627.048-1.107.048-1.62l0-.442.009-.28-.252-.138a3.843,3.843,0,0,1-.859-1.976l-.038-.394-.394-.028c-.062,0-.3-.181-.394-.936a.572.572,0,0,1,.142-.532l.3-.181-.09-.342c-.579-2.251-.48-3.975.289-4.982A3.711,3.711,0,0,1,9.425,4.95c.888,0,1.548.223,1.762.6l.114.2.233.033a1.775,1.775,0,0,1,1.368.827c.741,1.187.318,3.387-.047,4.5l-.1.352.3.2a.572.572,0,0,1,.142.532c-.09.755-.333.931-.394.936l-.394.028-.038.394a3.968,3.968,0,0,1-.821,1.971l-.257.134V16.4a14.906,14.906,0,0,0,.047,1.615l.024.119A3.4,3.4,0,0,0,13.4,19.874a5.474,5.474,0,0,1,.28-.912,2.545,2.545,0,0,1-1.4-1.1,13.976,13.976,0,0,1-.038-1.458v-.233a4.862,4.862,0,0,0,1.026-2.2c.342-.176.75-.6.874-1.658a1.574,1.574,0,0,0-.3-1.187,7.549,7.549,0,0,0,.071-4.612.419.419,0,0,0,.1-.133c.152-.3.707-.465,1.6-.48.717,0,1.263.176,1.425.456l.114.2.233.032a1.561,1.561,0,0,1,1.207.737,4.839,4.839,0,0,1,.076,3.506l-.076.351.28.205s.119.09.085.346c-.066.532-.233.684-.266.694l-.39.033-.043.39a3.18,3.18,0,0,1-.65,1.548l-.257.133,0,.656c0,.147,0,.294,0,.446a5.655,5.655,0,0,1,.945-.195V15.2l0-.152a4.06,4.06,0,0,0,.851-1.79,1.687,1.687,0,0,0,.746-1.4,1.363,1.363,0,0,0-.242-1.007A5.539,5.539,0,0,0,19.4,6.821a2.465,2.465,0,0,0-1.676-1.14,2.692,2.692,0,0,0-2.114-.731,3.062,3.062,0,0,0-2.251.717,2.738,2.738,0,0,0-1.482-.784A3.05,3.05,0,0,0,9.416,4Zm9.551,12.349a4.75,4.75,0,1,0,4.75,4.75A4.764,4.764,0,0,0,18.967,16.349Zm0,.95a3.8,3.8,0,1,1-3.8,3.8A3.811,3.811,0,0,1,18.967,17.3Zm-.475.95v2.612l-1.71,1.282.57.761,2.09-1.568V18.249Z'
        transform='translate(0.061 -4)'
        fill={props?.fill ? props?.fill : theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
