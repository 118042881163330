import { Promotion, PromotionError, PromotionInput } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GeneralInformation } from "./GeneralInformation";
import { ImgUploader } from "./ImgUploader";

export const PromotionsForm: FC<{
  onDone: (inputData: PromotionInput) => void;
  loading: boolean;
  isEdit?: boolean;
  errors: PromotionError[];
  defaultValues?: Promotion;
}> = ({ errors, loading, onDone, defaultValues, isEdit }) => {
  const { t } = useTranslation("provider");
  const methods = useForm();
  const navigate = useNavigate();
  const handleCancel = () => navigate(-1);
  const onSubmit = (input: PromotionInput) => {
    onDone(input);
  };
  const doYouHaveData = isEdit ? defaultValues != undefined : true;
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box display='inline-block'>
              <FormActions
                isLoading={loading}
                hasCancel
                hasFormButton
                formButtonTitle={isEdit ? t("Update") : t("Create")}
                onNavigation={handleCancel}
              />
            </Box>
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard doYouHaveData={doYouHaveData} loading={loading} title={t("General Information")}>
                <GeneralInformation errors={errors} defaultValues={defaultValues} isEdit={isEdit} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard doYouHaveData={doYouHaveData} loading={loading} title={t("Upload Photo")}>
                <ImgUploader errors={errors} defaultValues={defaultValues} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
