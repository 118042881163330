/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePromotionMutationVariables = Types.Exact<{
  input: Types.PromotionInput;
}>;


export type CreatePromotionMutation = { __typename?: 'Mutation', promotionCreate?: { __typename?: 'PromotionCreate', promotion?: { __typename?: 'Promotion', id: string, name: string, photo?: string | null, published: boolean, description?: string | null, expiryDate: any, created: any, publishDate: any, healthPackages?: { __typename?: 'HealthPackageCountableConnection', edges: Array<{ __typename?: 'HealthPackageCountableEdge', node: { __typename?: 'HealthPackage', name: string, id: string } }> } | null, productCategories?: { __typename?: 'CategoryCountableConnection', edges: Array<{ __typename?: 'CategoryCountableEdge', node: { __typename?: 'Category', id: string, name: string } }> } | null, vendorProducts?: { __typename?: 'VendorProductVariantCountableConnection', edges: Array<{ __typename?: 'VendorProductVariantCountableEdge', node: { __typename?: 'VendorProductVariant', id: string, productVariant: { __typename?: 'ProductVariant', name?: string | null, id: string } } }> } | null } | null, promotionErrors: Array<{ __typename?: 'PromotionError', message?: string | null, field?: string | null, code: Types.PromotionErrorCode }> } | null };


export const CreatePromotionDocument = gql`
    mutation createPromotion($input: PromotionInput!) {
  promotionCreate(input: $input) {
    promotion {
      id
      name
      photo
      published
      description
      expiryDate
      created
      publishDate
      healthPackages(first: 10) {
        edges {
          node {
            name
            id
          }
        }
      }
      productCategories(first: 10) {
        edges {
          node {
            id
            name
          }
        }
      }
      vendorProducts(first: 10) {
        edges {
          node {
            id
            productVariant {
              name
              id
            }
          }
        }
      }
    }
    promotionErrors {
      message
      field
      code
    }
  }
}
    `;
export type CreatePromotionMutationFn = Apollo.MutationFunction<CreatePromotionMutation, CreatePromotionMutationVariables>;

/**
 * __useCreatePromotionMutation__
 *
 * To run a mutation, you first call `useCreatePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromotionMutation, { data, loading, error }] = useCreatePromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromotionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromotionMutation, CreatePromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromotionMutation, CreatePromotionMutationVariables>(CreatePromotionDocument, options);
      }
export type CreatePromotionMutationHookResult = ReturnType<typeof useCreatePromotionMutation>;
export type CreatePromotionMutationResult = Apollo.MutationResult<CreatePromotionMutation>;
export type CreatePromotionMutationOptions = Apollo.BaseMutationOptions<CreatePromotionMutation, CreatePromotionMutationVariables>;