import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const XIcon: FC<SvgIconProps> = ({ width, height, fill }) => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 18.864 18.864'>
      <path
        id='icons8-close_1_'
        data-name='icons8-close (1)'
        d='M6.572,4a.853.853,0,0,0-.606.251L4.251,5.966a.857.857,0,0,0,0,1.212L10.5,13.431,4.251,19.685a.857.857,0,0,0,0,1.212l1.715,1.715a.857.857,0,0,0,1.212,0l6.253-6.253,6.253,6.253a.857.857,0,0,0,1.212,0L22.612,20.9a.857.857,0,0,0,0-1.212l-6.253-6.253,6.253-6.253a.857.857,0,0,0,0-1.212L20.9,4.251a.857.857,0,0,0-1.212,0L13.431,10.5,7.178,4.251A.854.854,0,0,0,6.572,4Z'
        transform='translate(-4 -4)'
        fill={fill ?? palette.error.main}
      />
    </SvgIcon>
  );
};
