import { PromotionError, PromotionInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { promotionListBreadCrumb, promotionNewBreadCrumb } from "../constant";
import { CreatePromotionMutation, useCreatePromotionMutation } from "../gql";

export const usePromotionNewPageHook = () => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<PromotionError[]>([]);
  const { setBreadCrumb } = useBreadCrumbs();

  const [createPromotionMutation, { loading }] = useCreatePromotionMutation({
    onCompleted: (mutationData: CreatePromotionMutation) => {
      if (mutationData?.promotionCreate?.promotionErrors?.length! > 0) {
        setErrors(mutationData?.promotionCreate?.promotionErrors as PromotionError[]);
        failed(t(formatMessageErrors(mutationData?.promotionCreate?.promotionErrors)));
      } else {
        succeeded(t("Succeeded in creating promotion"));
        navigate(-1);
      }
    },
  });
  const onSubmit = (inputData: PromotionInput | any) => {
    createPromotionMutation({
      variables: {
        input: {
          ...inputData,
          productCategories: inputData?.productCategories ? inputData?.productCategories?.map(cat => cat?.value?.id) : [],
          healthPackages: inputData?.healthPackages ? inputData?.healthPackages?.map(pack => pack?.value?.id) : [],
          vendorProducts: inputData?.vendorProducts ? inputData?.vendorProducts?.map(pack => pack?.value?.vendorProductVariant?.id) : [],
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: "Promotions",
      values: [promotionListBreadCrumb(t), promotionNewBreadCrumb(t)],
    });
  }, []);

  return {
    loading,
    errors,
    onSubmit,
  };
};
