import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { EvaluationResultType } from "../types";
import { createAutocompleteOption, FormAutocomplete, Grid, Typography, useTheme } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

interface EvaluationResultProps {
  evaluationResult: EvaluationResultType;
}

export const EvaluationResult: FC<EvaluationResultProps> = ({ evaluationResult }) => {
  const { t } = useTranslation("provider");

  const form = useFormContext();
  const { setValue } = form;

  const hasEnrolledProgram = evaluationResult?.enrolledGuidedPrograms?.length;
  const hasSuggestedPrograms = evaluationResult?.suggestedGuidedPrograms?.length;

  const SuggestedPrograms = hasSuggestedPrograms
    ? evaluationResult?.suggestedGuidedPrograms?.map(program =>
        createAutocompleteOption(
          {
            id: program?.id!,
            name: program?.name!,
          },
          "id",
          "name"
        )
      )
    : undefined;

  useEffect(() => {
    setValue("suggestedGuidedPrograms", SuggestedPrograms?.[0]);
  }, [SuggestedPrograms, setValue]);
  const programLabel = t("Suggested Programs");
  const theme = useTheme();
  return (
    <>
      {evaluationResult && (
        <Grid item xs={14} spacing={3}>
          <Typography fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
            {t("Risk Level")}:{" "}
            <Typography display='inline' fontSize={theme.mixins.fonts.fontSize.sm}>
              {t(evaluationResult?.level!) || "-"}
            </Typography>
          </Typography>

          <Typography marginTop={1} fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
            {t("Enrolled programs")}:{" "}
            <Typography display={hasEnrolledProgram ? "block" : "inline"} fontSize={theme.mixins.fonts.fontSize.sm}>
              {hasEnrolledProgram
                ? evaluationResult?.enrolledGuidedPrograms?.map(program => (
                    <Typography key={program?.id} fontSize={theme.mixins.fonts.fontSize.sm}>
                      {program?.name}
                    </Typography>
                  ))
                : t("No enrolled programs")}
            </Typography>
          </Typography>

          {evaluationResult?.notification && (
            <Typography marginTop={1} fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
              {t("Notification")}:{" "}
              <Typography display='inline' fontSize={theme.mixins.fonts.fontSize.sm}>
                {evaluationResult?.notification || "-"}
              </Typography>
            </Typography>
          )}

          {hasSuggestedPrograms ? (
            <Grid item xs={12} marginTop={2}>
              <FormAutocomplete
                name={"suggestedGuidedPrograms"}
                label={programLabel}
                placeholder={programLabel}
                options={SuggestedPrograms}
              />
            </Grid>
          ) : (
            <Typography fontWeight='bold' marginTop={1} fontSize={theme.mixins.fonts.fontSize.sm}>
              {programLabel}:
              <Typography display='inline' fontSize={theme.mixins.fonts.fontSize.sm}>
                {t("No suggested programs")}
              </Typography>
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
};
