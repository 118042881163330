/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaleUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.SaleInput;
}>;


export type SaleUpdateMutation = { __typename?: 'Mutation', saleUpdate?: { __typename?: 'SaleUpdate', sale?: { __typename?: 'Sale', id: string, value: number, type: Types.SaleType, endDate: any, startDate: any } | null, discountErrors: Array<{ __typename?: 'DiscountError', code: Types.DiscountErrorCode, field?: string | null, message?: string | null }> } | null };


export const SaleUpdateDocument = gql`
    mutation SaleUpdate($id: ID!, $input: SaleInput!) {
  saleUpdate(id: $id, input: $input) {
    sale {
      id
      value
      type
      endDate
      startDate
    }
    discountErrors {
      code
      field
      message
    }
  }
}
    `;
export type SaleUpdateMutationFn = Apollo.MutationFunction<SaleUpdateMutation, SaleUpdateMutationVariables>;

/**
 * __useSaleUpdateMutation__
 *
 * To run a mutation, you first call `useSaleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saleUpdateMutation, { data, loading, error }] = useSaleUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaleUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SaleUpdateMutation, SaleUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaleUpdateMutation, SaleUpdateMutationVariables>(SaleUpdateDocument, options);
      }
export type SaleUpdateMutationHookResult = ReturnType<typeof useSaleUpdateMutation>;
export type SaleUpdateMutationResult = Apollo.MutationResult<SaleUpdateMutation>;
export type SaleUpdateMutationOptions = Apollo.BaseMutationOptions<SaleUpdateMutation, SaleUpdateMutationVariables>;