import { getMediaLink } from "@health/common";
import { Promotion, PromotionError } from "@health/queries/types";
import { combineErrors, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, ImageUploadController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

export const ImgUploader: FC<{ errors: PromotionError[]; defaultValues?: Promotion }> = ({ errors, defaultValues }) => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation("provider");
  const formErrors = combineErrors(formState.errors, errors);
  const { accessToken } = useOidcUserProfile();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageUploadController
          control={control}
          name='photo'
          defaultValue={defaultValues?.photo && getMediaLink(defaultValues?.photo)}
          validationRation={true}
          error={Boolean(formErrors?.photo?.message)}
          helperText={formErrors?.photo?.message}
          rules={{
            required: getRequiredValidation(t, false),
          }}
          token={accessToken!}
        />
      </Grid>
    </Grid>
  );
};
