import { Collapse, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { FC } from "react";
import { useModal } from "../hooks";
import { ArrowDown, ArrowUp } from "../icons";

export const Expandable: FC<{
  headerTitle: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
}> = ({ children, headerTitle, defaultOpen }) => {
  const { open, handleToggle } = useModal(defaultOpen);
  return (
    <>
      <ListItem onClick={handleToggle}>
        <ListItemText>
          <Typography variant='h6' sx={{ mb: 2 }}>
            {headerTitle}
          </Typography>
        </ListItemText>
        <ListItemAvatar>{open ? <ArrowUp /> : <ArrowDown />}</ListItemAvatar>
      </ListItem>
      <Collapse in={open} title='test'>
        {children}
      </Collapse>
    </>
  );
};

Expandable.defaultProps = {
  defaultOpen: false,
};
