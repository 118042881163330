import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const EditByDocIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const theme = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "16px"}
      height={height ? height : "21.333px"}
      viewBox={viewBox ? viewBox : "0 0 16 21.333"}
      {...props}
    >
      <path
        id='icons8-doctor-male_1_'
        data-name='icons8-doctor-male (1)'
        d='M7.778,4a.889.889,0,0,0-.863.674A41.447,41.447,0,0,0,6,13.778a.889.889,0,0,0,.372.72A6.375,6.375,0,0,0,6,16.406v1.639a3.172,3.172,0,0,0,1.014,2.4,1.568,1.568,0,0,0,.661.363A6.819,6.819,0,0,0,13.9,25.328a.992.992,0,0,0,.2,0,6.82,6.82,0,0,0,6.22-4.519,1.568,1.568,0,0,0,.661-.363A3.172,3.172,0,0,0,22,18.045V16.406a3.969,3.969,0,0,0-.505-1.828.889.889,0,0,0,.505-.8,41.447,41.447,0,0,0-.915-9.1A.889.889,0,0,0,20.222,4Zm.74,1.778H19.483a40.2,40.2,0,0,1,.679,7.111H18.444a4.444,4.444,0,1,0-8.889,0H7.839A40.2,40.2,0,0,1,8.517,5.778ZM14,10.222a2.653,2.653,0,0,1,2.667,2.667,2.712,2.712,0,0,1-.05.509.889.889,0,0,0-.08.281,2.652,2.652,0,0,1-5.071.005.889.889,0,0,0-.082-.288,2.711,2.711,0,0,1-.05-.507A2.653,2.653,0,0,1,14,10.222ZM14,12a.889.889,0,1,0,.889.889A.889.889,0,0,0,14,12Zm4.019,2.753a3.1,3.1,0,0,1,2.2,2.58v.712a1.217,1.217,0,0,1-.408,1.064.9.9,0,0,1-.34.207L19,19.434l-.155.462s-1.171,3.66-4.845,3.66S9.155,19.9,9.155,19.9L9,19.434l-.474-.118a.9.9,0,0,1-.34-.207,1.217,1.217,0,0,1-.408-1.064V16.953c0-1.062,1.54-.294,2.67-1.425a4.41,4.41,0,0,0,7.571-.774Z'
        transform='translate(-6 -4)'
        fill={fill ? fill : theme.palette.primary.dark}
      />
    </SvgIcon>
  );
};
