import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Grid } from "@toolkit/ui";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { VitalSignsCharts } from "shared/modules/patient/components/VitalSigns/components/VitalSignsCharts/VitalSignsCharts";
import { ECGView } from "./ECGView";
import { GlucoseView } from "./GlucoseView";
import { KPIView } from "./KPIView";

type LiveSignsProps = {
  patientId: string;
  userId: string;
};

export const LiveSigns: FC<LiveSignsProps> = props => {
  const { patientId, userId } = props;

  const [showHistory, setShowHistory] = useState(false);

  const { t } = useTranslation("provider");
  const [params] = useSearchParams();

  const token = params.get("token")!;
  const visitId = params.get("visitId")!;

  const handleShowHistory = () => {
    setShowHistory(!showHistory);
  };

  return (
    <Box p={4}>
      <Grid item container spacing={2} alignContent='baseline'>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
            <Button onClick={handleShowHistory}>{!showHistory ? t("Show History") : t("Last Read")}</Button>
          </Box>
        </Grid>

        {!showHistory ? (
          <KPIView token={token} patientId={patientId} userId={userId} visitId={visitId} />
        ) : (
          <VitalSignsCharts token={token} patientId={patientId} userId={userId} />
        )}

        <Grid item xs={12}>
          <ECGView token={token} patientId={patientId!} visitId={!showHistory ? visitId : undefined} />
        </Grid>

        <Grid item xs={12}>
          <GlucoseView token={token} patientId={patientId!} visitId={!showHistory ? visitId : undefined} />
        </Grid>
      </Grid>
    </Box>
  );
};
