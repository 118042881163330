/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchHealthPackagesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.HealthPackageFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthPackageSortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchHealthPackagesQuery = { __typename?: 'Query', healthPackages?: { __typename?: 'HealthPackageCountableConnection', edges: Array<{ __typename?: 'HealthPackageCountableEdge', node: { __typename?: 'HealthPackage', id: string, name: string, description?: string | null, nameAr?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, isPublished: boolean, approvalStatus: Types.HealthPackageApprovalStatus, targetFromAge?: number | null, targetToAge?: number | null, targetGender: Types.HealthPackageTargetGender, categoryCode: string, categoryDisplay?: string | null, mainImageUrl?: string | null, price?: { __typename?: 'Money', amount: number, currency: string } | null, sale?: { __typename?: 'Sale', id: string, name: string, nameAr?: string | null, value: number, type: Types.SaleType, startDate: any, endDate: any } | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const FetchHealthPackagesDocument = gql`
    query fetchHealthPackages($first: Int, $filter: HealthPackageFilterInput, $sortBy: HealthPackageSortingInput, $before: String, $after: String, $last: Int) {
  healthPackages(
    first: $first
    filter: $filter
    sortBy: $sortBy
    before: $before
    after: $after
    last: $last
  ) {
    edges {
      node {
        id
        name
        description
        nameAr
        descriptionAr
        shortDescription
        shortDescriptionAr
        price {
          amount
          currency
        }
        isPublished
        approvalStatus
        targetFromAge
        targetToAge
        targetGender
        categoryCode
        categoryDisplay
        mainImageUrl
        sale {
          id
          name
          nameAr
          value
          type
          startDate
          endDate
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useFetchHealthPackagesQuery__
 *
 * To run a query within a React component, call `useFetchHealthPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHealthPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHealthPackagesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useFetchHealthPackagesQuery(baseOptions?: Apollo.QueryHookOptions<FetchHealthPackagesQuery, FetchHealthPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchHealthPackagesQuery, FetchHealthPackagesQueryVariables>(FetchHealthPackagesDocument, options);
      }
export function useFetchHealthPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchHealthPackagesQuery, FetchHealthPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchHealthPackagesQuery, FetchHealthPackagesQueryVariables>(FetchHealthPackagesDocument, options);
        }
export type FetchHealthPackagesQueryHookResult = ReturnType<typeof useFetchHealthPackagesQuery>;
export type FetchHealthPackagesLazyQueryHookResult = ReturnType<typeof useFetchHealthPackagesLazyQuery>;
export type FetchHealthPackagesQueryResult = Apollo.QueryResult<FetchHealthPackagesQuery, FetchHealthPackagesQueryVariables>;