import { useOnHealthProgramStatusUpdate } from "@health/common";
import { VisitStatus as VisitStatusEnum } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, DisconnectedCallIcon, PageWrapper, PulseConnection, Typography } from "@toolkit/ui";
import { isTabsCountVisible } from "pages/programs-calls/constants";
import { FC, useEffect } from "react";
import { PageTabs } from "shared/components/PageTabs";
import { useVisitsQuery } from "../../gql";
import { useActiveCallStyles } from "../ActiveCall/ActiveCall.styles";
import { Instructions } from "../ActiveCall/Instructions";
import { IVisit } from "../types";
import { AcceptVisit } from "./AcceptVisit";
import { RejectModel } from "./RejectModel";

export const NewVisit: FC<IVisit & { onVisitAccepted: () => void }> = ({ onVisitAccepted, tabs, onChangeTab: handleChangeTab, state }) => {
  const { t } = useTranslation("provider");

  const { classes, theme } = useActiveCallStyles();
  const { data, loading, refetch } = useVisitsQuery({
    variables: {
      first: 1,
      filter: {
        status: [VisitStatusEnum.Assigned],
      },
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    refetch();
  }, [state]);

  const visit = data?.visits?.edges?.map(item => item?.node)[0];

  useOnHealthProgramStatusUpdate(({ data }) => {
    if (data.graphqlType === "Visit" && data?.id) {
      refetch({
        first: 1,
        filter: {
          status: [VisitStatusEnum.Assigned],
        },
      });
    }
  });

  return (
    <PageWrapper
      headerComponent={<PageTabs onChangeTab={handleChangeTab} tabs={tabs} selected={state} showTabCount={isTabsCountVisible} />}
      filters={
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            height: 60,
          }}
        />
      }
    >
      <Box className={classes.container}>
        <Box className={classes.card}>
          {loading ? (
            <CircularProgress size={80} className={classes.loading} />
          ) : (
            <>
              {!visit ? (
                <Typography fontFamily={theme.mixins.fonts.fontFamily.medium}>{t("There are no visits")}</Typography>
              ) : (
                <>
                  <PulseConnection />

                  <div>
                    {(visit?.patient?.firstName ?? "patient") + " " + (visit?.patient?.lastName ?? "")} {t("is ready to start the call")}
                  </div>

                  <Box
                    sx={{
                      display: "flex",
                      margin: 2,
                      width: 250,
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div>
                        <AcceptVisit
                          id={visit?.id}
                          onVisitAccepted={onVisitAccepted}
                          buttonProps={{ className: `${classes.iconButton} ${classes.iconButtonHover} ${classes.accept}` }}
                        />
                      </div>
                      <Typography align='center'>{t("Accept")}</Typography>
                    </div>

                    <div>
                      <div>
                        <RejectModel
                          id={visit?.id}
                          icon={<DisconnectedCallIcon fill={theme.palette.error.main} />}
                          buttonProps={{ className: `${classes.iconButton} ${classes.iconButtonHover} ${classes.reject}` }}
                        />
                      </div>
                      <Typography align='center'>{t("Reject")}</Typography>
                    </div>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
        <Instructions />
      </Box>
    </PageWrapper>
  );
};
