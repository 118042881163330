import { getOrderStatusColor } from "@health/domains";
import { orderStatusActiveOptions } from "@health/enum-options";
import { OrderStatus } from "@health/queries/types";
import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, getTranslatedNameField, OrderStatusTypography, TruncateTypography, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { GetHealthPackageOrderNode } from "../types";
import { AcceptHealthPackageOrderButton } from "./AcceptHealthPackageOrderButton";
import { RejectHealthPackageOrderButton } from "./RejectHealthPackageOrderButton";
import { SetHealthPackageOrderAsConsumedButton } from "./SetHealthPackageOrderAsConsumedButton";

export type PatientsColumnsType = CustomTableColumnProps<GetHealthPackageOrderNode>[];

export const useGetHealthPackageOrdersListColumns = (): PatientsColumnsType => {
  const { t, i18n } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "number",
        header: t("Number"),
        accessor: ({ number }) => {
          return <Typography> {number}</Typography>;
        },
        isHidden: false,
        filter: {
          type: "string",
          name: "numbers",
        },
      },
      {
        key: "customerName",
        header: t("Customer Name"),
        accessor: ({ user }) => <TruncateTypography text={formatUserNameFull(user!)} />,
      },
      {
        key: "contactNumber",
        header: t("Customer Mobile"),
        accessor: ({ user }) => <span className='mobile-phone-rtl-fix'>{String(user?.mobile ?? "")}</span>,
      },
      {
        key: "package",
        header: t("Health Package Name"),
        accessor: ({ lines }) => <TruncateTypography text={getTranslatedNameField(lines?.[0]?.healthPackage!, i18n.language)!} />,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <OrderStatusTypography status={status} color={getOrderStatusColor(status)} t={t} />,
        filter: {
          name: "status",
          type: "autocomplete",
          multiple: true,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.map(item => item?.value),
          options: orderStatusActiveOptions,
        },
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: ({ status, id }) => {
          if (status === OrderStatus.NewRequest) {
            return (
              <Box sx={{ width: "200px" }}>
                <AcceptHealthPackageOrderButton orderId={id} />
                <RejectHealthPackageOrderButton orderId={id} />
              </Box>
            );
          }
          if (status === OrderStatus.InProgress) {
            return <SetHealthPackageOrderAsConsumedButton orderId={id} />;
          }
          return <></>;
        },
      },
    ] as PatientsColumnsType;
  }, [i18n.language, t]);
};
