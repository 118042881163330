import { Money, ProductVariant } from "@health/queries/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import PriceInformation from "./PriceInformation/PriceInformation";
import { useProductFormState } from "./ProductForm.hook";
import Warehouse from "./Warehouse/Warehouse.component";

interface ProductFormType {}

export const ProductForm: FC<ProductFormType> = () => {
  const { t } = useTranslation("provider");

  const { ...methods } = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const { onSubmit, productVariant, isLoading, handleCancel } = useProductFormState();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper actions={<FormActions hasCancel formButtonTitle={t("Save")} hasFormButton={true} onNavigation={handleCancel} />}>
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12} spacing={formGirdSpacing}>
              <FormCard title={t("Price")} doYouHaveData={true} loading={isLoading}>
                <PriceInformation price={productVariant?.vendorProductVariant?.price as Money} />
              </FormCard>
            </Grid>
            <Grid item xs={12} spacing={formGirdSpacing}>
              <FormCard title={t("Warehouse")} loading={isLoading} doYouHaveData={true}>
                <Warehouse defaultData={productVariant as ProductVariant} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
