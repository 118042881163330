import { TFunction } from "@toolkit/i18n";
import { promotionsPaths } from "./route";

export const promotionListBreadCrumb = (t: TFunction) => {
  return {
    id: promotionsPaths.indexPage.fullPath,
    name: t("Promotions", { ns: "provider" }),
    route: promotionsPaths.indexPage.fullPath,
  };
};

export const promotionNewBreadCrumb = (t: TFunction) => {
  return {
    id: promotionsPaths.addNew.fullPath,
    name: t("New", { ns: "provider" }),
    // route: promotionsPaths.addNew.fullPath
  };
};

export const promotionEditBreadCrumb = (promotionName: string | undefined) => {
  return {
    id: promotionsPaths.edit.fullPath,
    name: promotionName || "",
    // route: promotionsPaths.edit.fullPath.replace(":id", promotionId)
  };
};
