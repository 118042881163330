import { SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const DisconnectedCallIcon: FC<SvgIconProps> = ({ fill }) => {
  const { palette } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23.01' height='23.011' viewBox='0 0 23.01 23.011'>
      <path
        id='icons8-call-disconnected'
        d='M17.99,2.99a1,1,0,0,0-.7,1.717L20.586,8l-3.293,3.293a1,1,0,1,0,1.414,1.414L22,9.414l3.293,3.293a1,1,0,1,0,1.414-1.414L23.414,8l3.293-3.293a1,1,0,1,0-1.414-1.414L22,6.586,18.707,3.293A1,1,0,0,0,17.99,2.99ZM7.738,5a1.186,1.186,0,0,0-.381.031,3.883,3.883,0,0,0-1.814,1.09C3.43,8.238,2.309,11.8,10.254,19.746s11.507,6.825,13.621,4.711a3.885,3.885,0,0,0,1.092-1.818,1.175,1.175,0,0,0-.549-1.3l-4.691-2.748a1.174,1.174,0,0,0-1.2.006l-1.867,1.113a1.171,1.171,0,0,1-1.324-.078,30.629,30.629,0,0,1-2.658-2.318,30.288,30.288,0,0,1-2.318-2.658,1.169,1.169,0,0,1-.078-1.324L11.4,11.467a1.179,1.179,0,0,0,0-1.2L8.66,5.586A1.184,1.184,0,0,0,7.738,5Z'
        transform='translate(-4 -2.99)'
        fill={fill ?? palette.common.white}
      />
    </svg>
  );
};
