import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const MagicIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;

  const { palette } = useTheme();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "18.588"}
      height={height ? height : "18.588"}
      viewBox={viewBox ? viewBox : "0 0 18.588 19.303"}
      {...props}
    >
      <path
        id='icons8-magic'
        d='M15.154,2a.619.619,0,0,0-.545.318L13.63,4.044l-1.91-.531a.628.628,0,0,0-.772.772l.531,1.91-1.726.977a.627.627,0,0,0,0,1.091l1.726.977,0,0,2.512-2.513a1.517,1.517,0,1,1,2.145,2.145L13.627,11.39l0,0,.976,1.726a.628.628,0,0,0,1.092,0l.976-1.726,1.912.529a.626.626,0,0,0,.771-.771l-.529-1.912,1.726-.976a.627.627,0,0,0,0-1.092L18.829,6.2l.531-1.91a.628.628,0,0,0-.772-.772l-1.91.531L15.7,2.318A.622.622,0,0,0,15.154,2ZM11.481,9.247,3.444,17.284a1.517,1.517,0,1,0,2.145,2.145l8.037-8.037Zm2.946,5.612a.715.715,0,0,0-.7.725V16.3h-.715a.715.715,0,1,0,0,1.43h.715v.715a.715.715,0,1,0,1.43,0v-.715h.715a.715.715,0,1,0,0-1.43h-.715v-.715a.715.715,0,0,0-.726-.725Zm5,2.145a.715.715,0,0,0-.7.725v.715h-.715a.715.715,0,1,0,0,1.43h.715v.715a.715.715,0,1,0,1.43,0v-.715h.715a.715.715,0,1,0,0-1.43h-.715v-.715A.715.715,0,0,0,19.432,17Z'
        transform='translate(-3 -2)'
        fill={palette.primary.main}
      />
    </SvgIcon>
  );
};
