import { makeStyles } from "@toolkit/ui";

export const useActiveCallStyles = makeStyles()(theme => ({
  card: {
    padding: "50px 50px 30px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    width: 688,
    margin: "auto",
    background: theme.palette.common.white,
    borderRadius: 10,
  },
  iconButton: {
    margin: 10,
    width: 62,
    height: 62,
    borderRadius: "50%",
    "& svg": {
      fill: theme.palette.common.white,
    },
  },
  iconButtonHover: {
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  accept: {
    background: theme.palette.success[900],
    "&:hover": {
      background: theme.palette.success.main,
    },
    "&:disabled": {
      backgroundColor: theme.palette.success[900],
      opacity: 0.5, // Example: reduce opacity when disabled
    },
  },
  cancel: {
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error[1100],
    },
    "&:disabled": {
      backgroundColor: theme.palette.error[1100],
      opacity: 0.5, // Example: reduce opacity when disabled
    },
  },
  reject: {
    border: "2px solid",
    borderColor: theme.palette.gray[1100],
    "&:disabled": {
      backgroundColor: theme.palette.error[1100],
      opacity: 0.5, // Example: reduce opacity when disabled
    },
  },
  recall: {
    background: theme.palette.warning[1100],
  },
  join: {
    background: theme.palette.yellow.main,
    width: 170,
    "&:hover": {
      background: theme.palette.yellow.main,
    },
  },
  profile: {
    margin: 10,
    width: 166,
    "&:hover": {
      "& path": {
        fill: theme.palette.common.white,
      },
      background: theme.palette.primary.main,
    },
  },
  view: {
    margin: 10,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  line: {
    width: "100%",
    borderColor: theme.palette.stale[100],
  },
  loading: {
    color: theme.palette.success.main,
  },
}));
