import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const LabIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "41.782px"}
      height={height ? height : "31.754px"}
      viewBox={viewBox ? viewBox : "0 0 41.782 31.754"}
      {...props}
    >
      <path
        id='icons8-lab-items_1_'
        data-name='icons8-lab-items (1)'
        d='M15.877,6a.835.835,0,0,0-.521,1.488,4.027,4.027,0,0,1,1.356,1.98v13.82L8.559,35.149a1.662,1.662,0,0,0,1.371,2.6H31.852a1.663,1.663,0,0,0,1.371-2.6l-8.154-11.86V9.393a2.17,2.17,0,0,1,1.222-1.817A.835.835,0,0,0,25.9,6Zm1.841,1.671h6.159A4.308,4.308,0,0,0,23.4,9.248a.711.711,0,0,0-.007.095V23.548a.838.838,0,0,0,.147.473l1.83,2.66H16.407l1.83-2.66a.845.845,0,0,0,.147-.473V9.343a.84.84,0,0,0-.034-.237A6,6,0,0,0,17.718,7.671Zm-12.7,5.014a.835.835,0,0,0-.836.836v4.456A7.083,7.083,0,0,0,0,24.377,7.44,7.44,0,0,0,7.475,31.9H8.6l1.169-1.671H7.48A5.771,5.771,0,0,1,1.671,24.39a5.482,5.482,0,0,1,.273-1.678H13.238a3.574,3.574,0,0,1,.132.854c.005.249,0,1.184,0,1.519l1.588-2.272a6.681,6.681,0,0,0-4.1-4.873v-4.42a.835.835,0,0,0-.836-.836Zm23.4,0a.835.835,0,0,0-.836.836v6.545a.836.836,0,0,0,0,.273v3.654L29.2,26.308a.821.821,0,0,0,.051-.253V21.041h9.192v9.192h-6.5L33.108,31.9h6.166a.835.835,0,0,0,.836-.836V20.346a.836.836,0,0,0,0-.273V16.052a2.187,2.187,0,0,1,1.2-1.781.835.835,0,0,0-.366-1.586ZM5.849,14.356H9.192v4.178a.837.837,0,0,0,.607.8,5.348,5.348,0,0,1,2.543,1.7H2.877A5.644,5.644,0,0,1,5.3,19.318a.837.837,0,0,0,.545-.783Zm23.4,0h9.667a3.645,3.645,0,0,0-.475,1.671V19.37H29.247Zm-13.989,14H26.523l5.314,7.73-21.9.013Z'
        transform='translate(0 -6)'
        fill={fill ?? "inherit"}
      />
    </SvgIcon>
  );
};
