import { DiscountValueTypeEnum } from "@health/queries/types";
import { BACKEND_DATE_TIME_FORMAT, MAX_LENGTH_MESSAGE } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment";
import { z } from "zod";

export const useSaleValidationHook = maxPrice => {
  const { t } = useTranslation("provider");

  const saleValidationSchema = z
    .object({
      name: z
        .string()
        .min(1, { message: t("Required") })
        .max(255, t(MAX_LENGTH_MESSAGE, { value: 255 })),
      nameAr: z
        .string()
        .min(1, { message: t("Required") })
        .max(255, t(MAX_LENGTH_MESSAGE, { value: 255 })),
      type: z
        .object(
          {
            label: z.string(),
            value: z.string(),
          },
          { invalid_type_error: t("Required") }
        )
        .refine(
          type => {
            return type && type.label && type.value;
          },
          {
            message: t("Required"),
            path: ["type"],
          }
        ),
      value: z.coerce.number({ invalid_type_error: t("Required") }).positive(),
      startDate: z.string({ invalid_type_error: t("Required") }).min(1, { message: t("Required") }),
      endDate: z.string({ invalid_type_error: t("Required") }).min(1, { message: t("Required") }),
    })
    .refine(
      data => {
        if (data?.type?.value === DiscountValueTypeEnum.Percentage) {
          return data?.value < 100;
        }
        if (data?.type?.value === DiscountValueTypeEnum.Fixed) {
          return data?.value < maxPrice;
        }
        return !!data?.type?.value;
      },
      {
        message: t("Value should not exceed maximum based on type"),
        path: ["value"],
      }
    )
    .refine(
      data => {
        const todayDate = moment().startOf("day");
        const start = moment(data?.startDate, BACKEND_DATE_TIME_FORMAT);
        const end = moment(data?.endDate, BACKEND_DATE_TIME_FORMAT);
        return start.isValid() && end.isValid() && start.isBefore(end) && start.isAfter(todayDate);
      },
      {
        message: t("Start date must be after today's date and after before end date"),
        path: ["startDate"],
      }
    )
    .refine(
      data => {
        const todayDate = moment().startOf("day");
        const start = moment(data?.startDate, BACKEND_DATE_TIME_FORMAT);
        const end = moment(data?.endDate, BACKEND_DATE_TIME_FORMAT);
        return start.isValid() && end.isValid() && end.isAfter(start) && end.isAfter(todayDate);
      },
      {
        message: t("End date must be after today's date and after start date"),
        path: ["endDate"],
      }
    );

  return { saleValidationSchema };
};
