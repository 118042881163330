import { useGuidedCareTaskListQuery } from "../../gql/queries";
import { useGuidedCareTaskListColumns } from "./useGuidedCareTaskListColumns";
import { GridProvider } from "@health/domains";
import { GuidedCareTaskFilter, guidedCareTaskFilterOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TableGrid, Typography } from "@toolkit/ui";
import { getGuidedCareTaskListFilterStatus } from "pages/GuidedCareTasks/others";
import { FC, useState } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { MenuTimeLine } from "shared/modules/patient/components/Journey/components/MenuTimeLine";

type GuidedCareTaskListProps = {
  patientId?: string;
  isPatientColHidden?: boolean;
};

export const GuidedCareTaskList: FC<GuidedCareTaskListProps> = props => {
  const { patientId, isPatientColHidden } = props;

  const [selectedFilter, setSelectedFilter] = useState<GuidedCareTaskFilter>(GuidedCareTaskFilter.Uncompleted);

  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();

  const handleSelectedFilter = (value: GuidedCareTaskFilter) => {
    setSelectedFilter(value);
  };

  return (
    <GridProvider
      gridName={"guidedCareTaskList"}
      columns={useGuidedCareTaskListColumns({ isPatientColHidden })}
      query={useGuidedCareTaskListQuery}
      filterInput={{
        assigneeUserIds: userInfo?.user_id ? [String(userInfo?.user_id)] : [],
        statuses: getGuidedCareTaskListFilterStatus(selectedFilter),
        patientUserId: patientId,
      }}
      skipCall={!userInfo?.user_id}
    >
      <FormCard doYouHaveData loading={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems={"center"}>
              <Grid xs={7}>
                <Typography variant='medium-lg'>{t("My Tasks")}</Typography>
              </Grid>

              <Grid xs={5} textAlign={"end"}>
                <MenuTimeLine
                  title={t("Status")}
                  items={guidedCareTaskFilterOptions}
                  selected={selectedFilter!}
                  onChange={selectedValue => handleSelectedFilter(selectedValue?.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableGrid />
          </Grid>
        </Grid>
      </FormCard>
    </GridProvider>
  );
};
