import { useTranslation } from "@toolkit/i18n";
import { last } from "lodash";
import { FC } from "react";
import BasicLineChart from "../Charts/BasicLineChart";
import { OverviewCard } from "./OverviewCard";
import { IVitalSignChart } from "./types";

type VitalSignRespirationRateChartProps = IVitalSignChart;

export const VitalSignRespirationRateChart: FC<VitalSignRespirationRateChartProps> = props => {
  const { data, width, percentage } = props;

  const { t } = useTranslation("provider");

  const lastItem = last(data);

  return (
    <OverviewCard
      width={width}
      title={t("Respiration Rate")}
      value={`${Math.floor(lastItem?.value! || 0)}`}
      percentage={percentage}
      unit={"bpm"}
      date={lastItem?.date!}
      description={t("By") + " " + (lastItem?.source! || "-")}
    >
      <BasicLineChart title={t("Respiration Rate")} data={data} />
    </OverviewCard>
  );
};
