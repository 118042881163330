import { Promotion, PromotionError, PromotionInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { promotionEditBreadCrumb, promotionListBreadCrumb } from "../constant";
import { UpdatePromotionMutation, useGetPromotionQuery, useUpdatePromotionMutation } from "../gql";

export const usePromotionEditPageHook = () => {
  const [errors, setErrors] = useState<PromotionError[]>([]);
  const params = useParams();
  const navigate = useNavigate();
  const { failed, succeeded } = useAddToast();
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const [updatePromotionMutation, { loading }] = useUpdatePromotionMutation({
    onCompleted: (mutationData: UpdatePromotionMutation) => {
      if (mutationData?.promotionUpdate?.promotionErrors?.length! > 0) {
        setErrors(mutationData?.promotionUpdate?.promotionErrors as PromotionError[]);
        failed(t(formatMessageErrors(mutationData?.promotionUpdate?.promotionErrors)));
      } else {
        succeeded(t("Promotion updated successfully"));
        navigate(-1);
      }
    },
  });
  const { data, loading: isPromotionLoading } = useGetPromotionQuery({
    variables: {
      id: params?.id as string,
    },
  });
  const onSubmit = (inputData: PromotionInput | any) => {
    let updatedInputData: PromotionInput = {};

    if (promotion) {
      Object.keys(inputData).forEach(key => {
        if (promotion[key] !== inputData[key]) {
          updatedInputData[key] = inputData[key];
        }
      });
    } else {
      updatedInputData = inputData;
    }
    const vendorProducts = inputData?.vendorProducts?.map(pack =>
      pack?.value?.vendorProductVariant ? pack?.value?.vendorProductVariant?.id : pack?.value?.id
    );

    updatePromotionMutation({
      variables: {
        promotionUpdateId: params?.id as string,
        input: {
          ...updatedInputData,
          productCategories: inputData?.productCategories ? inputData?.productCategories?.map(cat => cat?.value?.id) : [],
          healthPackages: inputData?.healthPackages ? inputData?.healthPackages?.map(pack => pack?.value?.id) : [],
          vendorProducts: vendorProducts,
        },
      },
    });
  };
  const promotion = data?.promotion as Promotion;

  useEffect(() => {
    setBreadCrumb({
      title: "Promotions",
      values: [promotionListBreadCrumb(t), promotionEditBreadCrumb(data?.promotion?.name)],
    });
  }, [data?.promotion?.name]);

  return {
    loading,
    isPromotionLoading,
    promotion,
    errors,
    onSubmit,
  };
};
