import { useOnPatientProfileAccessResponse, usePatientProfileAccessClose } from "@health/common";
import { Patient, PatientHealthHistory } from "@health/queries/types";
import { formatUserNameFull } from "@toolkit/core";
import { Stack } from "@toolkit/ui";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { PatientTimeLine } from "../components/Journey/PatientTimeLine";
import { Activities } from "../components/Journey/UpcomingActivities/Activities";
import { useEvaluatePatientHealthConditionsMutation, useGetPatientDataLazyQuery, usePatientMedicalProfileLazyQuery } from "../gql";
import { ProfileContext } from "./ProfileContext";

export const ProfileContextProvider: FC<
  PropsWithChildren<{
    patientData: Patient;
    isOpen: boolean;
    onClose?: () => void;
    token?: string;
    tokenId?: string;
    defaultSelectedTab?: string;
  }>
> = ({ children, onClose, isOpen, patientData, token, tokenId, defaultSelectedTab = "0" }) => {
  const [checkAccess, setCheckAccess] = useState(false);
  const [consentStatus, setConsentStatus] = useState<string | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
  const [selectedHealthProgram, setSelected] = useState<string | undefined>(undefined);

  const sections = teamMembers => [
    {
      id: 1,
      content: (
        <Stack spacing={8}>
          <PatientTimeLine teamMembers={teamMembers} />
          <Activities />
        </Stack>
      ),
    },
  ];

  const [getPatientInfo, { data: patientInfo, loading: patientInfoLoading }] = useGetPatientDataLazyQuery({
    context: getApolloContextFormToken(token!),
  });
  const [getPatientMedicalProfile, { data, loading }] = usePatientMedicalProfileLazyQuery({
    onCompleted: () => {
      setCheckAccess(true);
    },
    onError: () => {
      setCheckAccess(false);
    },
    context: getApolloContextFormToken(token!),
  });
  const [getConditions, { data: conditionsData, loading: loadingConditions }] = useEvaluatePatientHealthConditionsMutation({
    context: getApolloContextFormToken(token!),
  });
  const patientId = patientData?.id!;
  const userId = patientData?.user?.id!;
  const patientName = formatUserNameFull(patientData!);
  useOnPatientProfileAccessResponse(({ data }) => {
    if (data?.patientProfileConsentFormId) {
      getPatientMedicalProfile({
        variables: {
          patientId: data?.patientId!,
        },
      });
      setConsentStatus(data?.consentStatus);
    }
  });
  usePatientProfileAccessClose(({ data }) => {
    if (data?.patientProfileConsentFormId) {
      getPatientMedicalProfile({
        variables: {
          patientId: data?.patientId!,
        },
        fetchPolicy: "no-cache",
      });
      setConsentStatus(data?.consentStatus);
    }
  });
  const handleResetStatus = () => {
    setConsentStatus(undefined);
    setCheckAccess(true);
  };
  const handleProgramSelected = item => {
    setSelected(item?.id);
  };

  const handleTabChange = newValue => {
    setSelectedTab(String(newValue));
  };

  useEffect(() => {
    setSelectedTab(defaultSelectedTab);
  }, [defaultSelectedTab]);
  useEffect(() => {
    isOpen &&
      patientData &&
      getPatientMedicalProfile({
        variables: {
          patientId,
        },
      });
    isOpen &&
      patientData &&
      getPatientInfo({
        variables: {
          patientId,
        },
      });
    !isOpen && setSelectedTab(defaultSelectedTab);
  }, [JSON.stringify(patientData), isOpen, consentStatus, userId, patientId]);

  const patient = (patientInfo?.patient || data?.patientMedicalProfile?.patient) as Patient;
  const patientCurrentMedicalStatus = data?.patientMedicalProfile?.patientCurrentMedicalStatus!;
  const patientHealthHistory = data?.patientMedicalProfile?.patientHealthHistory as PatientHealthHistory[];
  const conditions = conditionsData?.evaluatePatientHealthConditions?.map(edge => edge?.evaluatedHealthCondition!) || [];
  useEffect(() => {
    patient?.id &&
      getConditions({
        variables: {
          patientId: patient?.id!,
        },
      });
  }, [patient?.id]);
  return (
    <ProfileContext.Provider
      value={{
        selectedTab,
        sections,
        consentStatus,
        loading: loading || patientInfoLoading || loadingConditions,
        patient,
        checkAccess,
        userId,
        isOpen,
        onClose,
        patientName,
        selectedHealthProgram,
        handleTabChange,
        handleResetStatus,
        handleProgramSelected,
        patientCurrentMedicalStatus,
        patientHealthHistory,
        patientId,
        token,
        tokenId,
        conditions,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
