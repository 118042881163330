/* eslint-disable max-lines */
import { DiscountError } from "@health/queries/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatMessageErrors } from "@toolkit/apollo";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  SaleDeleteMutation,
  SaleUpdateMutation,
  useSaleDeleteMutation,
  useSaleUpdateMutation,
  useSalesQuery,
} from "pages/HealthPackages/gql";
import { SaleCreateMutation, useSaleCreateMutation } from "pages/HealthPackages/gql/mutations";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useSaleValidationHook } from "./saleValidationHook";

export const useDiscountDialogHooks = ({ healthPackageId, maxPrice }) => {
  const [isSaleModalOpen, setIsSaleModalOpen] = useState(false);

  const { saleValidationSchema } = useSaleValidationHook(maxPrice);
  type SaleInputSchema = z.infer<typeof saleValidationSchema>;
  const methods = useForm<SaleInputSchema>({
    resolver: zodResolver(saleValidationSchema),
    mode: "onChange",
  });

  const {
    data: saleData,
    loading: isLoadingSale,
    refetch,
  } = useSalesQuery({
    variables: {
      first: 10,
      filter: { healthPackageId: healthPackageId },
    },
    skip: !isSaleModalOpen,
  });

  const defaultValue = saleData?.sales?.edges?.[0]?.node;

  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors: reactFormErrors },
  } = methods;
  const [errors, setError] = useState<DiscountError[]>([]);

  useEffect(() => {
    setValue("startDate", defaultValue?.startDate || null);
    setValue("endDate", defaultValue?.endDate || null);
  }, [defaultValue]);

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const [healthPackageSaleCreateMutation, { loading: createLoading }] = useSaleCreateMutation({
    onCompleted: (data: SaleCreateMutation) => {
      if (data?.saleCreate?.discountErrors?.length! > 0) {
        failed(formatMessageErrors(data?.saleCreate?.discountErrors));
        setError(data?.saleCreate?.discountErrors!);
      } else {
        succeeded(t("Succeeded in Creating Health Package Sale!"));
        setIsSaleModalOpen(false);
        refetch();
      }
    },
  });

  const [healthPackageSaleUpdateMutation, { loading: updateLoading }] = useSaleUpdateMutation({
    onCompleted: (data: SaleUpdateMutation) => {
      if (data?.saleUpdate?.discountErrors?.length! > 0) {
        failed(formatMessageErrors(data?.saleUpdate?.discountErrors));
        setError(data?.saleUpdate?.discountErrors!);
      } else {
        succeeded(t("Succeeded in Updating Health Package Sale!"));
        setIsSaleModalOpen(false);
        refetch();
      }
    },
  });

  const [healthPackageSaleDeleteMutation, { loading: deleteLoading }] = useSaleDeleteMutation({
    onCompleted: (data: SaleDeleteMutation) => {
      if (data?.saleDelete?.discountErrors?.length! > 0) {
        failed(formatMessageErrors(data?.saleDelete?.discountErrors));
        setError(data?.saleDelete?.discountErrors!);
      } else {
        succeeded(t("Succeeded in Deleting Health Package Sale!"));
        reset();
        setIsSaleModalOpen(false);
      }
    },
  });

  const handleOnSubmit = (data: SaleInputSchema) => {
    const input = {
      name: data.name,
      nameAr: data.nameAr,
      health_packages: [healthPackageId],
      type: (data.type as any)?.value,
      startDate: data.startDate,
      endDate: data.endDate,
      value: Number(data.value),
    };
    if (defaultValue?.id) {
      healthPackageSaleUpdateMutation({
        variables: {
          id: defaultValue?.id,
          input,
        },
      });
    } else {
      healthPackageSaleCreateMutation({
        variables: {
          input,
        },
      });
    }
  };

  const onSubmit = (data: SaleInputSchema) => {
    handleOnSubmit({ ...data });
  };

  const handleDeleteDiscount = () => {
    defaultValue?.id &&
      healthPackageSaleDeleteMutation({
        variables: {
          id: defaultValue?.id,
        },
        update: cache => {
          const normalizedId = cache.identify({ id: defaultValue?.id, __typename: "Sale" });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
  };

  const handleClose = () => {
    reset();
    setIsSaleModalOpen(false);
  };

  const handleOpen = () => {
    setIsSaleModalOpen(true);
  };
  const formErrors = combineErrors(reactFormErrors, errors);
  return {
    control,
    isLoadingSale,
    methods,
    defaultValue,
    t,
    formErrors,
    isSaleModalOpen,
    createLoading,
    updateLoading,
    deleteLoading,
    isSubmitting: deleteLoading || createLoading || updateLoading,
    handleOpen,
    handleClose,
    register,
    handleSubmit,
    onSubmit,
    handleDeleteDiscount,
  };
};
