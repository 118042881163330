/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-identical-functions */
import { Maybe, Order, OrderLine, OrderStatus, SiteSettings } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { Box, useTheme } from "@toolkit/ui";
import { HeaderCard } from "../cards/HeaderCard.component";
import { OrderTimeout } from "../cards/OrderTimeout";
import { OrderColorEnum } from "../cards/OrdersCard";
import { getColorFromStatus, getTitle } from "./utils";

export const getSharedOrderData = (order: Partial<Order>) => {
  return {
    id: order?.id,
    status: order?.status,
    number: Number(order.number),
    type: order?.type,
    products: order?.lines,
    deliveryDate: order?.deliveryDate,
    deliveryTimeSlot: order?.deliveryTimeSlot,
    iseRxOrder: Boolean(order?.prescription),
    patient: order?.prescription?.patient,
    shippingAddress: order?.shippingAddress,
    diagnosis: order?.prescription?.diagnosis,
    color: getColorFromStatus(order.status),
    medicalAlerts: order?.prescription?.medicalAlerts,
    medications: order?.prescription?.medications?.map(line => line),
  };
};

export const mapOrderToNewColumn = (order: Partial<Order>, time: Partial<SiteSettings> | undefined, t: TFunction) => {
  const theme = useTheme();
  return {
    ...getSharedOrderData(order),
    status: order?.status,
    timeOutComponent: (
      <Box mx={1}>
        <OrderTimeout
          title={t("Time to accept", { ns: "provider" })}
          color={theme.palette.primary.main}
          time={time?.orderAcceptanceTimeout!}
        />
      </Box>
    ),
  };
};

const getMedication = (line: Maybe<OrderLine>) => line?.medication;

export const mapOrderToPendingColumn = (order: Partial<Order>, time: Partial<SiteSettings> | undefined, t: TFunction) => ({
  ...getSharedOrderData(order),
  referenceNumber:
    order?.status === OrderStatus.Allocated || order?.status === OrderStatus.ErxHubAuthSubmitTimedOut
      ? order?.prescription?.referenceNumber
      : undefined,
  medications: order?.lines?.map(line => ({
    ...getMedication(line),
    display: line?.tradeDrug?.display,
    quantity: line?.quantity,
    img: line?.tradeDrug?.smallIconURL,
  })),
  headerComponent: (
    <HeaderCard
      title={getTitle(t)[String(order?.status)]}
      color={getColorFromStatus(String(order?.status)) || OrderColorEnum.warning}
      isOrderLoading={order?.status === OrderStatus.WaitingErxHubApproval || order?.status === OrderStatus.WaitingErxHubClaimApproval}
    />
  ),
  timeOutComponent: order?.status === OrderStatus.ErxHubAuthSubmitTimedOut && (
    <OrderTimeout title={t("Time to dispense eRx", { ns: "provider" })} color={OrderColorEnum.error} time={time?.orderDispensingTimeout!} />
  ),
});

export const mapOrderToDispensingColumn = (order: Partial<Order>, time: Partial<SiteSettings> | undefined, t: TFunction) => ({
  ...getSharedOrderData(order),
  medications: order?.lines
    ?.map(
      line =>
        line?.medication && {
          ...getMedication(line),
          display: line?.tradeDrug?.display,
          quantity: line.quantity,
          img: line?.tradeDrug?.smallIconURL,
          authStatus: line?.authStatus,
          rejectionReason: line?.rejectionReason,
          variantName: line?.variantName,
        }
    )
    .filter(Boolean),
  referenceNumber: order?.prescription?.referenceNumber,
  headerComponent: (
    <HeaderCard
      title={getTitle(t)[String(order?.status)]}
      subTitle={order?.status === OrderStatus.ErxHubRejected ? order?.rejectionReason?.reason : ""}
      color={getColorFromStatus(order.status)}
      paymentMethod={order?.paymentMethod}
      status={order?.status}
      total={order?.total?.gross?.amount! - order?.total?.net?.amount! + " " + order?.total?.gross?.currency}
      isOrderLoading={order?.status === OrderStatus.WaitingErxHubApproval || order?.status === OrderStatus.WaitingErxHubClaimApproval}
    />
  ),

  timeOutComponent: (
    <OrderTimeout
      time={order?.status === OrderStatus.ErxHubRejected ? time?.orderErxHubApprovalResubmitTimeout! : time?.orderDispensingTimeout!}
      title={
        order?.status === OrderStatus.ErxHubRejected
          ? t("Time to resubmit eRx", { ns: "provider" })
          : t("Time to dispense eRx", { ns: "provider" })
      }
      color={getColorFromStatus(order.status)}
    />
  ),
});

export const mapOrderToDeliveredColumn = (order: Partial<Order>, time: Partial<SiteSettings> | undefined, t: TFunction) => ({
  ...getSharedOrderData(order),
  medications: order?.lines?.map(line => ({
    ...getMedication(line),
    display: line?.tradeDrug?.display,
    quantity: line?.quantity,
    img: line?.tradeDrug?.smallIconURL,
  })),
  referenceNumber: order?.prescription?.referenceNumber,
  headerComponent: (
    <HeaderCard
      title={getTitle(t)[String(order?.status)]}
      color={OrderColorEnum.info}
      deliveryStatus={order?.deliveryStatus || null}
      isOrderLoading={order?.status === OrderStatus.WaitingErxHubApproval || order?.status === OrderStatus.WaitingErxHubClaimApproval}
    />
  ),
});
