/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackageDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type HealthPackageDeleteMutation = { __typename?: 'Mutation', healthPackageDelete?: { __typename?: 'HealthPackageDelete', healthPackage?: { __typename?: 'HealthPackage', id: string } | null, healthPackageErrors: Array<{ __typename?: 'HealthPackageError', code: Types.HealthPackageErrorCode, field?: string | null, message?: string | null }> } | null };


export const HealthPackageDeleteDocument = gql`
    mutation HealthPackageDelete($id: ID!) {
  healthPackageDelete(id: $id) {
    healthPackage {
      id
    }
    healthPackageErrors {
      code
      field
      message
    }
  }
}
    `;
export type HealthPackageDeleteMutationFn = Apollo.MutationFunction<HealthPackageDeleteMutation, HealthPackageDeleteMutationVariables>;

/**
 * __useHealthPackageDeleteMutation__
 *
 * To run a mutation, you first call `useHealthPackageDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthPackageDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthPackageDeleteMutation, { data, loading, error }] = useHealthPackageDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthPackageDeleteMutation(baseOptions?: Apollo.MutationHookOptions<HealthPackageDeleteMutation, HealthPackageDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthPackageDeleteMutation, HealthPackageDeleteMutationVariables>(HealthPackageDeleteDocument, options);
      }
export type HealthPackageDeleteMutationHookResult = ReturnType<typeof useHealthPackageDeleteMutation>;
export type HealthPackageDeleteMutationResult = Apollo.MutationResult<HealthPackageDeleteMutation>;
export type HealthPackageDeleteMutationOptions = Apollo.BaseMutationOptions<HealthPackageDeleteMutation, HealthPackageDeleteMutationVariables>;