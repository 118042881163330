import { getEnvVariable } from "@health/env";

export const fetchFreshAccessToken = async (accessToken: string, refreshToken: string) => {
  try {
    return await fetch(getEnvVariable("KEYCLOAK_API_URL", process.env.REACT_APP_KEYCLOAK_API_URL) + "/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
      body: `grant_type=refresh_token&client_id=${getEnvVariable(
        "KEYCLOAK_CLIENT_ID_PROVIDER",
        process.env.REACT_APP_KEYCLOAK_CLIENT_ID_PROVIDER || "vendor"
      )}&refresh_token=${refreshToken}`,
    })
      .then(res => res.json())
      .then(res => res.access_token);
  } catch (error) {
    console.error("Failed to get refresh token.", error);
    return null;
  }
};
