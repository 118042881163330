import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const EditByUserIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "20px"}
      height={height ? height : "20.91px"}
      viewBox={viewBox ? viewBox : "0 0 20 20.91"}
      {...props}
    >
      <path
        id='icons8-user_2_'
        data-name='icons8-user (2)'
        d='M14,3A5.454,5.454,0,0,0,8.546,8.455v.909a5.455,5.455,0,0,0,10.91,0V8.455A5.454,5.454,0,0,0,14,3Zm0,14.546c-3.642,0-8.315,1.97-9.66,3.718A1.651,1.651,0,0,0,5.662,23.91H22.337a1.651,1.651,0,0,0,1.323-2.646C22.315,19.517,17.641,17.546,14,17.546Z'
        transform='translate(-4 -3)'
        fill={fill ? fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
