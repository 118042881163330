import { VisitStatus as VisitStatusEnum } from "@health/queries/types";
import { Box, CustomToggleButton, FilterGrid, FormControlLabel, PageWrapper, TableGrid, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { PageTabs, PageTabsStatusType, PageTabsType } from "shared/components/PageTabs";
import { useVisitColumns } from "./components/useVisitColumns";
import { useVisitsQuery } from "./gql";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { useTranslation } from "@toolkit/i18n";
import { getSelfOnlyValue, VisitTabsStatus } from "./utils";
import { isTabsCountVisible } from "./constants";

type IProgramsCalls = {
  status: VisitStatusEnum[];
  state: PageTabsStatusType;
  onChangeTab: (view: PageTabsStatusType) => void;
  tabs: PageTabsType;
  isSelfOnly: boolean;
  handleToggleOnlyMyVisit: (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
};

export const ProgramsCalls: FC<IProgramsCalls> = ({
  status,
  state,
  onChangeTab: handleChangeTab,
  tabs,
  isSelfOnly,
  handleToggleOnlyMyVisit,
}) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { userInfo } = useOidcUserProfile();
  const isSelfOnlyValue = getSelfOnlyValue({ isSelfOnly, status: state });
  const isVendorAdmin = userInfo?.app_role?.toLowerCase() === "admin";
  return (
    <GridProvider
      hideFilterInput
      variables={{
        filter: {
          status,
          selfOnly: isSelfOnlyValue,
        },
      }}
      filterInput={{
        status,
        selfOnly: isSelfOnlyValue,
      }}
      gridName='visits'
      columns={useVisitColumns({ status: state, isSelfOnly: isSelfOnlyValue })}
      query={useVisitsQuery}
    >
      <PageWrapper
        headerComponent={<PageTabs onChangeTab={handleChangeTab} tabs={tabs} selected={state} showTabCount={isTabsCountVisible} />}
        filters={
          <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
            <Box display='flex' alignItems='center' height={60}>
              {isVendorAdmin && state !== VisitTabsStatus.activeCall && (
                <FormControlLabel
                  sx={{ minWidth: 120, marginInline: "5px", fontSize: theme.mixins.fonts.fontSize.xs }}
                  control={<CustomToggleButton value={isSelfOnly} defaultChecked={isSelfOnly} onChange={handleToggleOnlyMyVisit} />}
                  label={t("Me Only")}
                />
              )}
            </Box>
            <FilterGrid />
          </Box>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
