/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaleCreateMutationVariables = Types.Exact<{
  input: Types.SaleInput;
}>;


export type SaleCreateMutation = { __typename?: 'Mutation', saleCreate?: { __typename?: 'SaleCreate', sale?: { __typename?: 'Sale', id: string, value: number, type: Types.SaleType, endDate: any, startDate: any } | null, discountErrors: Array<{ __typename?: 'DiscountError', code: Types.DiscountErrorCode, field?: string | null, message?: string | null }> } | null };


export const SaleCreateDocument = gql`
    mutation SaleCreate($input: SaleInput!) {
  saleCreate(input: $input) {
    sale {
      id
      value
      type
      endDate
      startDate
    }
    discountErrors {
      code
      field
      message
    }
  }
}
    `;
export type SaleCreateMutationFn = Apollo.MutationFunction<SaleCreateMutation, SaleCreateMutationVariables>;

/**
 * __useSaleCreateMutation__
 *
 * To run a mutation, you first call `useSaleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saleCreateMutation, { data, loading, error }] = useSaleCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaleCreateMutation(baseOptions?: Apollo.MutationHookOptions<SaleCreateMutation, SaleCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaleCreateMutation, SaleCreateMutationVariables>(SaleCreateDocument, options);
      }
export type SaleCreateMutationHookResult = ReturnType<typeof useSaleCreateMutation>;
export type SaleCreateMutationResult = Apollo.MutationResult<SaleCreateMutation>;
export type SaleCreateMutationOptions = Apollo.BaseMutationOptions<SaleCreateMutation, SaleCreateMutationVariables>;