import { extractIdFromBase64, formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";
import { NotificationContent } from "shared/components/Notifications/NotificationContent";
import { useAppointmentQuery } from "shared/components/Notifications/gql";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { useGuidedCareAppointmentReminderNotificationStyle } from "./useGuidedCareAppointmentReminderNotificationStyle";

type GuidedCareAppointmentReminderNotificationProps = {
  appointmentId: string;
  careTeamAdminMessage: string;
};

export const GuidedCareAppointmentReminderNotification: FC<GuidedCareAppointmentReminderNotificationProps> = props => {
  const { appointmentId, careTeamAdminMessage } = props;

  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();

  const { classes, theme } = useGuidedCareAppointmentReminderNotificationStyle();

  const { data, loading } = useAppointmentQuery({
    variables: {
      id: appointmentId,
    },
    skip: !appointmentId,
  });

  const appointment = data?.appointment;
  const appointmentDoctorId = extractIdFromBase64(appointment?.doctor?.id!);
  const programName = appointment?.providerGuidedCareHealthProgramTeam?.guidedCareHealthProgram?.name;
  const patientName = `${appointment?.consumer?.firstName || ""} ${appointment?.consumer?.lastName || ""}`;

  return (
    <NotificationContent title={t("Guided Care Appointment Reminder")} color={theme.palette.success.main}>
      {loading ? (
        <Box className={classes.loadingWrapper}>
          <CircularProgress color={"inherit"} size={20} />
        </Box>
      ) : (
        <Box>
          {appointmentDoctorId !== userInfo?.doctor_id ? (
            <Typography fontSize={14}>
              {t("You have an upcoming appointment with")} <span className={classes.boldText}> {patientName} </span>
              {t("for program")} <span className={classes.boldText}> {programName}, </span>
              {t("but the patient still hasn't scheduled an appointment.")}
            </Typography>
          ) : (
            <>
              <Typography fontSize={14}>
                <span className={classes.boldText}> {t("Program")}: </span>
                {programName}
              </Typography>

              <Typography fontSize={14}>
                <span className={classes.boldText}> {t("Patient")}: </span>
                {patientName}
              </Typography>

              <Typography fontSize={14}>
                <span className={classes.boldText}> {t("Doctor")}: </span>
                {appointment?.doctor?.user ? formatUserNameFull(appointment?.doctor?.user!) : ""}
              </Typography>

              {careTeamAdminMessage && <Typography>{careTeamAdminMessage}</Typography>}
            </>
          )}
        </Box>
      )}
    </NotificationContent>
  );
};
