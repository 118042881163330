import { TFunction } from "@toolkit/i18n";
import { productsManagementVendorRoutes } from "shared/constants/routes/product";

export const ProductListBreadCrumb = (t: TFunction) => {
  return {
    id: productsManagementVendorRoutes.fullPath,
    name: t("Products Management", { ns: "provider" }),
    route: productsManagementVendorRoutes.fullPath,
  };
};
export const ProductEditBreadCrumb = (productName: string | undefined) => {
  return {
    id: productsManagementVendorRoutes.products.edit.fullPath,
    name: productName || "",
  };
};
