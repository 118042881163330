import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  card: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: 10,
    borderRadius: 15,
    background: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.xs,
    height: 151,
    width: 384.1,
  },
  iconButton: {
    margin: "0px 4px",
    width: "42px",
    height: "42px",
    borderRadius: 4,
    background: "linear-gradient(212deg, rgba(37,40,127,1) 0%, rgba(109,38,99,1) 100%, rgba(136,37,88,1) 100%, rgba(136,37,88,1) 110%)",
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#25287f",endColorstr="#882558",GradientType=1)',
    "& svg": {
      color: theme.palette.common.white,
    },
  },
  input: {
    width: "42px",
    minHeight: "42px",
    margin: "0px 4px",
    boxShadow: theme.mixins.shadows.sm,
    borderRadius: "4px",
    border: "none",
    fontSize: 22,
    textAlign: "center",
    fontFamily: theme.mixins.fonts.fontFamily.medium,
    color: theme.palette.primary.main,
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield",
  },
}));
