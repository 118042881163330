import { guidedCareJourneyItemSourceOptions } from "@health/enum-options";
import { Appointment, TeamMemberPosition } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Stack, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { JourneyDurationPeriod } from "shared/modules/patient/types";
import {
  journeyActivityFilterOptions,
  journeyActivityScopeOptions,
  journeyActivityScopeOptionsMap,
  journeyDurationPeriodOptions,
} from "../../constants/enum-options";
import { AppointmentAction } from "./AppointmentAction";
import { PatientAddActivity } from "./PatientAddActivity";
import { AnnualTimeLineView } from "./TimeLine/AnnualTimeLine";
import { MonthlyTimeLineView } from "./TimeLine/MonthlyTimeLine";
import { MenuTimeLine } from "./components/MenuTimeLine";
import { usePatientTimeLineHooks } from "./usePatientTimeLineHooks";
import { IEnumOption } from "@toolkit/core";

type PatientTimeLineProps = {
  teamMembers: IEnumOption<TeamMemberPosition>[];
};

export const PatientTimeLine: FC<PatientTimeLineProps> = ({ teamMembers }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const {
    source,
    duration,
    hasSimilar,
    selectedMe,
    selectItem,
    selectedActivities,
    selectedTeamMemberPosition,
    handleHasSimilar,
    handleSelectItem,
    handleChangeSource,
    handleDuration,
    handleSelectedMe,
    handleSelectedShowActivities,
    handleSelectedTeamMemberPosition,
  } = usePatientTimeLineHooks();

  return (
    <Box>
      <Stack direction='row' alignItems='flex-start' justifyContent='space-between' spacing={2}>
        <Box display='flex'>
          <Typography fontSize={"26px"} fontFamily={theme.mixins.fonts.fontFamily.medium} color={theme.palette.primary.main}>
            {t("Patient’s Events Timeline")}
          </Typography>

          <PatientAddActivity />

          <MenuTimeLine
            title={journeyActivityScopeOptionsMap[selectedMe]?.label}
            items={journeyActivityScopeOptions}
            selected={selectedMe!}
            onChange={handleSelectedMe}
          />

          <MenuTimeLine title={t("Duration")} items={journeyDurationPeriodOptions} selected={duration!} onChange={handleDuration} />

          <MenuTimeLine
            title={t("Care Team Member")}
            items={teamMembers}
            selected={selectedTeamMemberPosition!}
            onChange={handleSelectedTeamMemberPosition}
          />

          <MenuTimeLine
            title={t("Activity By")}
            items={guidedCareJourneyItemSourceOptions}
            selected={source!}
            onChange={handleChangeSource}
          />

          <MenuTimeLine
            title={t("Activities")}
            items={journeyActivityFilterOptions}
            selected={selectedActivities!}
            onChange={handleSelectedShowActivities}
          />
        </Box>

        {selectItem?.id && (
          <AppointmentAction selectAppointment={selectItem?.appointment as Appointment} onFindSimilar={handleHasSimilar} />
        )}
      </Stack>

      {JourneyDurationPeriod.Annual === duration ? (
        <AnnualTimeLineView
          source={source!}
          duration={duration}
          hasSimilar={hasSimilar}
          selectedMe={selectedMe}
          selectedActivities={selectedActivities}
          selectedTeamMemberPosition={selectedTeamMemberPosition!}
          selectItem={selectItem!}
          onSelectItem={handleSelectItem}
        />
      ) : (
        <MonthlyTimeLineView
          source={source}
          duration={duration!}
          selectedMe={selectedMe}
          hasSimilar={hasSimilar}
          selectedActivities={selectedActivities}
          selectedTeamMemberPosition={selectedTeamMemberPosition!}
          selectItem={selectItem!}
          onSelectItem={handleSelectItem}
        />
      )}
    </Box>
  );
};
