import { Box, IconButton, Typography } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useState } from "react";
import { Button } from "../../mui";
import { CustomIcon } from "../CustomIcon";
import { useTimelineStyles } from "./useTimelineStyles";

interface AnnualTimeLineProps<T> {
  year?: number;
  timeline: { label: string; activities: T[] }[];
  children: React.ReactNode;
  isDisableNext?: boolean;
  isDisablePrev?: boolean;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

export const AnnualTimeLine = <T,>({
  year,
  timeline,
  children,
  isDisableNext,
  isDisablePrev,
  onIncrement,
  onDecrement,
}: AnnualTimeLineProps<T>) => {
  const { classes, theme } = useTimelineStyles();
  const [step, setStep] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  const numItemsToShow = Math.floor(windowWidth / 113) - 1;

  const handleNext = () => {
    setStep(Math.min(step + numItemsToShow, timeline.length - numItemsToShow));
    step === timeline.length - numItemsToShow && (setStep(0), onIncrement?.());
  };

  const handlePrev = () => {
    year ? (setStep(0), onDecrement?.()) : setStep(Math.max(step - numItemsToShow, 0));
  };

  const showArrows = timeline.length > numItemsToShow;

  return (
    <Box display='flex' alignItems='end' justifyContent='center' position='relative'>
      {year ? (
        <Button
          className={`${classes.year} ${classes.prevYear}`}
          onClick={handlePrev}
          startIcon={<CustomIcon icon='arrow' color={theme.palette.common.white} transform='rotate(180deg)' viewBox='-4 -3 24 24' />}
        >
          {year - 1}
        </Button>
      ) : (
        showArrows && (
          <IconButton disabled={step === 0 || isDisablePrev} className={classes.btn} onClick={handlePrev}>
            <CustomIcon icon='arrow' color={theme.palette.common.white} transform='rotate(180deg)' viewBox='-3 -3 24 24' />
          </IconButton>
        )
      )}

      <Stepper connector={<Box />}>
        {timeline?.map((item, i) => {
          const childrenWithProps = React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement<any>(child, { activities: item?.activities });
            }
            return child;
          });
          return (
            <Step
              key={item?.label}
              className={classes.step}
              sx={{ display: i >= step && i < step + numItemsToShow ? "flex" : "none !important" }}
            >
              <StepLabel
                StepIconComponent={props => <Box {...props} className={classes.stepIcon} />}
                classes={{
                  root: classes.stepLabel,
                  iconContainer: classes.iconContainer,
                }}
              >
                <Box className={classes.centerFlex}>
                  <Box className={classes.activities}>{childrenWithProps}</Box>
                  <Box display='flex'>
                    {Array.from({ length: 4 }, (_, index) => (
                      <Box display='flex' alignItems='baseline' key={index}>
                        <Box className={classes.separate} />
                        {i == timeline?.length - 1 && index === 3 && (
                          <Box
                            className={classes.stepIcon}
                            sx={{
                              marginBottom: "0px !important",
                              marginLeft: "8px",
                              marginTop: "-16px",
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                  <Typography
                    fontSize={theme.mixins.fonts.fontSize.xs}
                    marginTop={2}
                    color={theme.palette.primary.main}
                    fontFamily={theme.mixins.fonts.fontFamily.medium}
                  >
                    {item?.label}
                  </Typography>
                </Box>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {year ? (
        <Button className={classes.year} onClick={handleNext} endIcon={<CustomIcon icon='arrow' color='white' viewBox='-4 -3 24 24' />}>
          {year + 1}
        </Button>
      ) : (
        showArrows && (
          <IconButton disabled={step === timeline.length - numItemsToShow || isDisableNext} className={classes.btn} onClick={handleNext}>
            <CustomIcon icon='arrow' color='white' viewBox='-3 -3 24 24' />
          </IconButton>
        )
      )}
    </Box>
  );
};

AnnualTimeLine.defaultProps = {
  year: undefined,
  timeline: [],
  children: null,
  isDisableNext: false,
  isDisablePrev: false,
  onIncrement: () => {},
  onDecrement: () => {},
};
