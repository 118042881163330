import { Box, Calender, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNotificationContentStyle } from "./NotificationContent.styles";
import { NotificationContentProps } from "./types";

export const NotificationContent: FC<NotificationContentProps> = ({ title, color, children }) => {
  const { classes } = useNotificationContentStyle({ color });

  return (
    <Box display='inline-flex'>
      <Typography className={classes.icon}>
        <Calender sx={{ justifyContent: "center", alignItems: "center", display: "flex" }} fontSize='small' width={15.54} height={15.54} />
      </Typography>
      <Box display={"list-item"} flexGrow={1}>
        <Typography variant='body2' className={classes.appointment}>
          {title}
        </Typography>

        <Box m={1}>{children}</Box>
      </Box>
    </Box>
  );
};
