import { Box, Card, CustomIcon, paths, Typography, useTheme } from "@toolkit/ui";
import { FC, ReactNode } from "react";

export const OverviewInfo: FC<{
  title: string;
  subTitle?: string | number;
  icon: ReactNode;
  color?: string;
  description?: string;
  height?: number;
  endIcon?: keyof typeof paths;
}> = ({ title, subTitle, endIcon, color, icon, description, height }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        padding: 1,
        borderRadius: "10px",
        height: height ? height : "100%",
        boxShadow: theme.mixins.shadows.xs,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          height: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 33,
              height: 33,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              background: color || "",
              border: color ? "none" : `1px solid ${theme.palette.stale.main}`,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Box display='flex' alignItems='center'>
              <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium} whiteSpace='nowrap'>
                {title}
              </Typography>
              {subTitle && (
                <Typography fontSize={theme.mixins.fonts.fontSize.sm} textTransform={"capitalize"} whiteSpace='nowrap'>
                  : {subTitle}
                </Typography>
              )}
            </Box>
            {description && (
              <Typography fontSize={theme.mixins.fonts.fontSize.xxs} color={theme.palette.stale.main}>
                {description}
              </Typography>
            )}
          </Box>
        </Box>
        {endIcon && <CustomIcon icon={endIcon} />}
      </Box>
    </Card>
  );
};
