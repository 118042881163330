/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackageCloneMutationVariables = Types.Exact<{
  healthPackageCloneId: Types.Scalars['ID'];
}>;


export type HealthPackageCloneMutation = { __typename?: 'Mutation', healthPackageClone?: { __typename?: 'HealthPackageClone', healthPackage?: { __typename?: 'HealthPackage', id: string, name: string, nameAr?: string | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, targetFromAge?: number | null, targetToAge?: number | null, targetGender: Types.HealthPackageTargetGender, categoryCode: string, categoryDisplay?: string | null, isPublished: boolean, mainImageUrl?: string | null, approvalStatus: Types.HealthPackageApprovalStatus, price?: { __typename?: 'Money', amount: number, currency: string } | null } | null, healthPackageErrors: Array<{ __typename?: 'HealthPackageError', code: Types.HealthPackageErrorCode, field?: string | null, message?: string | null }> } | null };


export const HealthPackageCloneDocument = gql`
    mutation HealthPackageClone($healthPackageCloneId: ID!) {
  healthPackageClone(id: $healthPackageCloneId) {
    healthPackage {
      id
      name
      nameAr
      description
      descriptionAr
      shortDescription
      shortDescriptionAr
      price {
        amount
        currency
      }
      targetFromAge
      targetToAge
      targetGender
      categoryCode
      categoryDisplay
      isPublished
      mainImageUrl
      approvalStatus
    }
    healthPackageErrors {
      code
      field
      message
    }
  }
}
    `;
export type HealthPackageCloneMutationFn = Apollo.MutationFunction<HealthPackageCloneMutation, HealthPackageCloneMutationVariables>;

/**
 * __useHealthPackageCloneMutation__
 *
 * To run a mutation, you first call `useHealthPackageCloneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthPackageCloneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthPackageCloneMutation, { data, loading, error }] = useHealthPackageCloneMutation({
 *   variables: {
 *      healthPackageCloneId: // value for 'healthPackageCloneId'
 *   },
 * });
 */
export function useHealthPackageCloneMutation(baseOptions?: Apollo.MutationHookOptions<HealthPackageCloneMutation, HealthPackageCloneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthPackageCloneMutation, HealthPackageCloneMutationVariables>(HealthPackageCloneDocument, options);
      }
export type HealthPackageCloneMutationHookResult = ReturnType<typeof useHealthPackageCloneMutation>;
export type HealthPackageCloneMutationResult = Apollo.MutationResult<HealthPackageCloneMutation>;
export type HealthPackageCloneMutationOptions = Apollo.BaseMutationOptions<HealthPackageCloneMutation, HealthPackageCloneMutationVariables>;