import { Promotion, PromotionError } from "@health/queries/types";
import {
  combineErrors,
  formGirdBreakPoints,
  formGirdSpacing,
  getRequiredValidation,
  required,
  validateValueIsNotEmpty,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, CustomToggleButton, FormControlLabel, Grid, TextField, mapToAutocompleteOptions } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CategoriesAutocomplete, HealthPackagesAutocomplete, VendorProductVariantsAutocomplete } from "@health/autocompletes";

export const GeneralInformation: FC<{ isEdit?: boolean; errors: PromotionError[]; defaultValues?: Promotion }> = ({
  errors,
  defaultValues,
}) => {
  const { t } = useTranslation("provider");
  const { register, setValue, formState, control } = useFormContext();
  const formErrors = combineErrors(formState.errors, errors);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const categoriesDefaultValues = defaultValues?.productCategories?.edges?.map(category => category?.node);
    const healthPackagesDefaultValues = defaultValues?.healthPackages?.edges?.map(packages => packages?.node);
    const vendorProductsDefaultValues = defaultValues?.vendorProducts?.edges?.map(item => item?.node);

    setValue("productCategories", categoriesDefaultValues ? mapToAutocompleteOptions<any>(categoriesDefaultValues, "id", "name") : []);
    setValue("healthPackages", healthPackagesDefaultValues ? mapToAutocompleteOptions<any>(healthPackagesDefaultValues, "id", "name") : []);
    setValue(
      "vendorProducts",
      vendorProductsDefaultValues
        ? mapToAutocompleteOptions<any>(vendorProductsDefaultValues, "id", item => item?.productVariant?.name)
        : []
    );
  }, [defaultValues, setValue]);

  return (
    <>
      <Grid container spacing={formGirdSpacing}>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            fullWidth
            size='small'
            label={t("Name")}
            error={Boolean(formErrors.name?.message)}
            helperText={t(formErrors.name?.message)}
            {...register("name", { required: required(t), validate: value => validateValueIsNotEmpty(value, t) })}
            defaultValue={defaultValues?.name}
          />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <TextField
            fullWidth
            size='small'
            label={t("Description")}
            error={Boolean(formErrors.description?.message)}
            helperText={t(formErrors.description?.message)}
            {...register("description", { required: required(t) })}
            defaultValue={defaultValues?.description}
          />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <CategoriesAutocomplete name={"productCategories"} multiple label={t("Product Categories")} />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <HealthPackagesAutocomplete name={"healthPackages"} multiple />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <VendorProductVariantsAutocomplete name={"vendorProducts"} multiple />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <CustomDatePicker
            control={control}
            label={t("Expiry Date")}
            defaultValue={defaultValues?.expiryDate}
            placeholder={t("Expiry Date")}
            {...register("expiryDate", {
              required: getRequiredValidation(t, true),
            })}
            error={Boolean(formErrors?.expiryDate)}
            helperText={t(formErrors?.expiryDate?.message)}
          />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <CustomDatePicker
            control={control}
            label={t("Published Date")}
            placeholder={t("Published Date")}
            defaultValue={defaultValues?.publishDate}
            {...register("publishDate", {
              required: getRequiredValidation(t, true),
            })}
            error={Boolean(formErrors?.publishDate)}
            helperText={t(formErrors?.publishDate?.message)}
          />
        </Grid>
      </Grid>

      <Controller
        name='published'
        defaultValue={defaultValues?.published}
        control={control}
        render={({ field: { onChange: handleC, value } }) => (
          <FormControlLabel
            control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
            label={t("Published")}
          />
        )}
      />
    </>
  );
};
