import { Appointment, AppointmentStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography, useTheme } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { AppointmentCancel, AppointmentReschedule } from "../../../../pages/appointments";
import { NotificationContent } from "../NotificationContent";
import { useAppointmentNotificationHook } from "./useAppointmentNotification";

type AppointmentReminderProps = {
  id: string;
  notificationType: string;
  englishText: string;
  arabicText: string;
  changes: { field: string; value: string }[];
  isDisplayedInDropDown: boolean;
};
export const AppointmentReminder: FC<AppointmentReminderProps> = ({
  id,
  notificationType,
  englishText,
  arabicText,
  changes,
  isDisplayedInDropDown,
}) => {
  const { t, i18n } = useTranslation("provider");
  const { appointment } = useAppointmentNotificationHook(id, isDisplayedInDropDown);

  const confirmedStartTime = changes?.find(item => item?.["field"] === "confirmedStartTime")?.value;
  const confirmedEndTime = changes?.find(item => item?.["field"] === "confirmedEndTime")?.value;

  const appointmentDate = moment(confirmedStartTime).format("DD/MM/YYYY");
  const startTime = moment(confirmedStartTime).format("hh:mmA");
  const endTime = moment(confirmedEndTime).format("hh:mmA");
  const duration = moment.duration(moment(confirmedStartTime).diff(moment()));

  const stillTime = [
    { value: duration.days(), display: t("day", { count: duration.days() }) },
    { value: duration.hours(), display: t("hour", { count: duration.hours() }) },
    { value: duration.minutes(), display: t("min", { count: duration.minutes() }) },
  ]
    .filter(item => item?.value > 0)
    .map(item => item?.display)
    .join(", ");
  const hasAction =
    appointment?.status === AppointmentStatus.Rejected ||
    appointment?.status === AppointmentStatus.CanceledByConsumer ||
    appointment?.status === AppointmentStatus.CanceledByDoctor;
  const theme = useTheme();
  return (
    <NotificationContent
      color={theme.palette.success.main}
      title={notificationType === "CONFIRMATION" ? t("Appointment Confirmation") : t("Appointment Reminder")}
    >
      {englishText && i18n.language === "en" && (
        <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {englishText}
        </Typography>
      )}
      {arabicText && i18n.language === "ar" && (
        <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {arabicText}
        </Typography>
      )}
      <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
        {t("Appointment Date")}:{" "}
        <Typography component='span' fontSize={theme.mixins.fonts.fontSize.sm}>
          {appointmentDate}
        </Typography>
      </Typography>
      <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
        {t("Appointment Time")}:{" "}
        <Typography component='span' fontSize={theme.mixins.fonts.fontSize.sm}>
          {startTime} - {endTime}
        </Typography>
      </Typography>
      <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
        {t("Your Appointment in")}:{" "}
        <Typography component='span' fontSize={theme.mixins.fonts.fontSize.sm}>
          {stillTime || "-"}
        </Typography>
      </Typography>

      {notificationType === "CONFIRMATION" && !hasAction && (
        <Box mt={2} display='row' gap={2}>
          <AppointmentReschedule
            id={appointment?.id!}
            doctorId={appointment?.doctor?.id}
            status={appointment?.status!}
            patient={appointment?.consumer as Appointment["consumer"]}
            paymentInfo={(appointment as Appointment)?.paymentInfo}
            paymentStatus={(appointment as Appointment)?.paymentStatus}
          />
          <AppointmentCancel
            id={appointment?.id!}
            status={appointment?.status!}
            buttonEl={
              <Button variant={"outlined"} color='error'>
                {t("Cancel")}
              </Button>
            }
          />
        </Box>
      )}
    </NotificationContent>
  );
};
