/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { Box, EmptyDataIcon, Typography, useTheme } from "@toolkit/ui";
import * as echarts from "echarts";
import moment from "moment";
import { FC, useEffect, useRef } from "react";

type BarLineChartProps = {
  title: string;
  color?: string;
  height?: number;
  widthValue?: number | string;
  isHorizontal?: boolean;
  maxRangeValue?: number;
  minRangeValue?: number;
  data?: {
    value: number;
    currentData: boolean;
    date?: string;
    unit?: string;
    source?: string;
  }[];
};
interface MarkLineData {
  yAxis: number | string;
  xAxis: number | string;
  lineStyle: {
    color: string;
    symbol?: string;
    symbolSize?: number;
  };
}
const BarLineChart: FC<BarLineChartProps> = ({
  title,
  color,
  height,
  data,
  widthValue,
  isHorizontal = false,
  maxRangeValue,
  minRangeValue,
}) => {
  const { t, i18n } = useTranslation("provider");
  const theme = useTheme();

  const dataView = data?.map(item => ({
    value: item.value,
    date: moment(item?.date).locale(i18n.language).format("DD-MM-YYYY hh:mm"),
    unit: item?.unit,
    source: item?.source,
    itemStyle: {
      color: item.currentData ? color || theme.palette.primary.main : theme.palette.primary.main,
      borderRadius: item.value < 0 || isHorizontal ? [0, 5, 5, 0] : [5, 5, 0, 0],
    },
    label: {
      show: false,
    },
  }));
  const isDataLargerThanMax = data?.some(item => item?.value > maxRangeValue!);
  const dataX = data?.map(item => `${moment(item?.date).format("DD-MM")}`) || [];

  const chartRefBar = useRef(null);

  const markLine: MarkLineData[] = [];
  if (maxRangeValue && minRangeValue) {
    markLine.push(
      {
        yAxis: !isHorizontal ? maxRangeValue : "none",
        xAxis: isHorizontal ? maxRangeValue : "none",
        lineStyle: {
          color: theme.palette.stale.main,
        },
      },
      {
        yAxis: !isHorizontal ? minRangeValue : "none",
        xAxis: isHorizontal ? minRangeValue : "none",
        lineStyle: {
          color: theme.palette.stale.main,
        },
      }
    );
  }
  const getTooltipContent = params => {
    const value1 = params?.data?.value || "-";
    const unit = params?.data?.unit || "";
    const date = params?.data?.date || "-";
    const source = params?.data?.source ? `${t("Source")}: ${params?.data?.source}` : "";

    let tooltipContent = `${title}: `;
    tooltipContent += `${value1} ${unit}`;
    tooltipContent += ` <br/> ${t("Date")}: ${date} <br/> ${source}`;
    return tooltipContent;
  };

  useEffect(() => {
    if (data?.length) {
      const chart = echarts.init(chartRefBar.current);

      const options = {
        grid: {
          left: "3%",
          right: "5%",
          bottom: "4%",
          containLabel: true,
        },
        tooltip: {
          show: !!data?.length,
          trigger: "item",
          formatter: params => getTooltipContent(params),
          extraCssText: "box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); border: 1px solid #ccc; background-color: #fff; padding: 10px;",
        },
        xAxis: [
          {
            type: isHorizontal ? "value" : "category",
            show: !!dataView?.length,
            data: dataX,
            max: !isDataLargerThanMax && isHorizontal ? maxRangeValue : undefined,
            axisLine: {
              lineStyle: {
                color: theme.palette.stale.main,
              },
            },
            axisLabel: {
              textStyle: {
                textAlign: "center",
              },
              show: isHorizontal,
            },
          },
        ],
        yAxis: [
          {
            show: !!dataView?.length,
            type: isHorizontal ? "category" : "value",
            max: !isDataLargerThanMax && !isHorizontal ? maxRangeValue : undefined,
            axisLine: {
              lineStyle: {
                color: theme.palette.stale.main,
              },
            },
            axisLabel: {
              show: !isHorizontal,
              fontSize: Number(widthValue!) <= 200 ? 8 : 12,
            },
          },
        ],
        series: [
          {
            name: "Bar Series",
            type: "bar",
            barWidth: "25%",
            barGap: 0,
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: dataView,
            markLine: {
              silent: true,
              symbol: "none",
              data: markLine,
            },
          },
        ],
        markLine: {
          silent: true,
          symbol: "none",
          data: markLine,
        },
      };

      chart.setOption(options);

      return () => {
        chart.dispose();
      };
    }
  }, [JSON.stringify(data), data?.length, widthValue]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: widthValue || "100%",
        marginTop: -6,
        height: height || "210px",
        marginInline: "5px",
      }}
    >
      <>
        {!data?.length ? (
          <Box>
            <EmptyDataIcon sx={{ width: widthValue || "100%" }} height={90} />
            <Typography fontFamily={theme.mixins.fonts.fontFamily.medium}>{t("No data available")}</Typography>
          </Box>
        ) : (
          <Box ref={chartRefBar} sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }} />
        )}
      </>
    </Box>
  );
};

export default BarLineChart;
