import { get } from "lodash";
import i18next from "i18next";
import { H_RejectionReason, H_CancelReason } from "@health/queries/types";

export const getReasonText = (ReasonObject: Partial<H_RejectionReason> | Partial<H_CancelReason>) => {
  const isUserLanguageArabic = i18next.language.toLowerCase() === "ar";
  if (isUserLanguageArabic) {
    return get(ReasonObject, "text_ar", get(ReasonObject, "text"));
  }
  return get(ReasonObject, "text", "-");
};
