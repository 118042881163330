import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, ExpandMoreIcon, TextField, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { MyProfileDetailsItem } from "./DetailsCard.types";
import { IValue } from "./useDetailsPaper";

type IUpdateFieldProps = {
  item: MyProfileDetailsItem;
  value: IValue;
  onChangeOptionValue: (e, value) => void;
  onChangeValue: (e) => void;
};

export const UpdateField: FC<IUpdateFieldProps> = ({
  item,
  value,
  onChangeOptionValue: handleChangeOptionValue,
  onChangeValue: handleChangeValue,
}) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <>
      {!item?.options?.length ? (
        <TextField
          fullWidth
          type='number'
          size='small'
          value={value?.value}
          label={t(item?.title)}
          onChange={handleChangeValue}
          sx={{
            "& .MuiFilledInput-root": {
              minHeight: 40,
              fontFamily: theme.mixins.fonts.fontFamily.regular,
            },
          }}
        />
      ) : (
        <Autocomplete
          fullWidth
          value={value}
          popupIcon={<ExpandMoreIcon />}
          options={item?.options || []}
          onChange={handleChangeOptionValue}
          sx={{
            "& .MuiFilledInput-root": {
              minHeight: 20,
              fontFamily: theme.mixins.fonts.fontFamily.regular,
            },
          }}
          getOptionLabel={option => String(option?.name)}
          renderInput={params => (
            <TextField
              hiddenLabel
              placeholder={t(item?.title) || ""}
              {...params}
              sx={{
                "& .MuiFilledInput-root": {
                  minHeight: 20,
                  fontFamily: theme.mixins.fonts.fontFamily.regular,
                },
              }}
            />
          )}
        />
      )}
    </>
  );
};
