import { Patient } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, IconButton, Typography, useAddToast } from "@toolkit/ui";
import { usePatientIdByConsentCodeLazyQuery } from "pages/dashboard/gql/queries";
import { useState } from "react";
import { PatientProfileDrawer } from "shared/modules/patient/components/PatientProfileDrawer";
import { ProfileContextProvider } from "shared/modules/patient/context/ProfileContextProvider";
import { VerificationInput } from "./VerificationInput";
import { useStyles } from "./useStyles";

export const ViewPatientJourney = () => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();
  const { failed } = useAddToast();
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState<string>("");

  const [getPatientMedicalProfileByConsent, { data }] = usePatientIdByConsentCodeLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      setOpen(true);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value: string) => {
    setCode(value);
  };

  const handleClick = () => {
    getPatientMedicalProfileByConsent({
      variables: {
        code,
      },
    });
  };

  return (
    <Box className={classes.card}>
      <Typography
        fontSize={theme.mixins.fonts.fontSize.xl}
        fontFamily={theme.mixins.fonts.fontFamily.bold}
        color={theme.palette.primary.main}
      >
        {t("View Patient Journey")}
      </Typography>
      <Box display='flex' marginTop={"13px"}>
        <VerificationInput onChange={handleChange} />
        <IconButton onClick={handleClick} disabled={code?.length !== 6} className={classes.iconButton}>
          <CustomIcon icon={"nextArrow"} color='white' viewBox={"11 10 15 15"} />
        </IconButton>
      </Box>
      <Box sx={{ width: "230px" }} marginTop={"11.5px"}>
        <Typography
          sx={{
            whiteSpace: "normal",
          }}
          fontSize={theme.mixins.fonts.fontSize.xs}
          width={"fit-content"}
          fontFamily={theme.mixins.fonts.fontFamily.regular}
          textAlign='center'
          color={theme.palette.primary.main}
        >
          {t("Ask Patient for io Health Token ID in order to view their journey")}
        </Typography>
      </Box>
      <ProfileContextProvider isOpen={open} patientData={data?.patientIdByConsentCode! as Patient} tokenId={code} onClose={handleClose}>
        <PatientProfileDrawer />
      </ProfileContextProvider>
    </Box>
  );
};
