import { makeStyles } from "@toolkit/ui";

export const useSuggestionsStyles = makeStyles()(theme => ({
  tabsWrapper: {
    marginBottom: 16,
    marginRight: "-20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      marginLeft: "-10px",
      marginRight: "-10px",
    },
  },
  selectedTab: {
    zIndex: 1,
    height: `45px !important`,
    fontSize: `${theme.mixins.fonts.fontSize.xs} !important`,
    color: `${theme.palette.common.white} !important`,
    borderRadius: `35px !important`,
    background: `${theme.palette.primary.main} !important`,
  },
  tab: {
    padding: 2,
    height: 37,
    fontSize: theme.mixins.fonts.fontSize.xs,
    flex: "1 1 auto",
    fontFamily: theme.mixins.fonts.fontFamily.medium,
    minHeight: 43,
    background: theme.palette.common.white,
    "&:last-child": {
      border: 1,
      borderTopRightRadius: 35,
      borderBottomRightRadius: 35,
    },
    "&:first-child": {
      border: 1,
      borderTopLeftRadius: 35,
      borderBottomLeftRadius: 35,
    },
    color: `${theme.palette.primary.main}`,
  },
  tabs: {
    margin: 2,
    padding: 2,
    display: "flex",
    marginBottom: 4,
    borderRadius: 35,
    background: theme.palette.common.white,
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
  },
}));
