/* eslint-disable max-statements */
import { AppointmentStatus } from "@health/queries/types";
import moment from "moment-timezone";
import { i18nProvider } from "shared/i18n";
import { i18n } from "@toolkit/i18n";

export type StatusEnums =
  | AppointmentStatus.PendingApproval
  | AppointmentStatus.CallInProgress
  | AppointmentStatus.ChatInProgress
  | AppointmentStatus.ComingSoon
  | AppointmentStatus.Approved
  | AppointmentStatus.Request
  | AppointmentStatus.Rejected
  | AppointmentStatus.CanceledByDoctor
  | AppointmentStatus.CanceledByConsumer
  | AppointmentStatus.Cancelled
  | AppointmentStatus.RescheduledByConsumer
  | AppointmentStatus.RescheduledByDoctor
  | AppointmentStatus.Confirmed
  | AppointmentStatus.Tentative
  | AppointmentStatus.PaymentSucceeded;

export const notificationStatusMapper: Record<StatusEnums, { display: string; color: string }> = {
  [AppointmentStatus.Tentative]: { display: i18n.t("Appointment Tentative", { ns: "provider" }), color: "#4fa043" },
  [AppointmentStatus.Request]: { display: i18n.t("New Appointment Request", { ns: "provider" }), color: "#4fa043" },
  [AppointmentStatus.ComingSoon]: { display: i18n.t("Appointment Reminder", { ns: "provider" }), color: "#E67E22" },
  [AppointmentStatus.Rejected]: { display: i18n.t("Appointment Rejected", { ns: "provider" }), color: "#913263" },
  [AppointmentStatus.PendingApproval]: { display: i18n.t("Appointment Request Confirmation", { ns: "provider" }), color: "#25287F" },
  [AppointmentStatus.CallInProgress]: { display: i18n.t("Visit starting now", { ns: "provider" }), color: "#4fa043" },
  [AppointmentStatus.ChatInProgress]: { display: i18n.t("Visit starting now", { ns: "provider" }), color: "#4fa043" },
  [AppointmentStatus.CanceledByDoctor]: { display: i18n.t("Appointment Canceled By Doctor", { ns: "provider" }), color: "#913263" },
  [AppointmentStatus.Cancelled]: { display: i18n.t("Appointment Cancelled", { ns: "provider" }), color: "#913263" },
  [AppointmentStatus.CanceledByConsumer]: { display: i18n.t("Appointment Canceled By Consumer", { ns: "provider" }), color: "#913263" },
  [AppointmentStatus.Approved]: { display: i18n.t("Appointment Confirmed", { ns: "provider" }), color: "#4fa043" },
  [AppointmentStatus.RescheduledByConsumer]: {
    display: i18n.t("Appointment Rescheduled By Consumer", { ns: "provider" }),
    color: "#25287F",
  },
  [AppointmentStatus.RescheduledByDoctor]: { display: i18n.t("Appointment Rescheduled By Doctor", { ns: "provider" }), color: "#25287F" },
  [AppointmentStatus.Confirmed]: { display: i18n.t("Appointment Confirmed", { ns: "provider" }), color: "#4fa043" },
  [AppointmentStatus.PaymentSucceeded]: { display: i18n.t("Payment Succeeded", { ns: "provider" }), color: "#4fa043" },
};

export const dateFormat = "dddd, DD MMMM YYYY";
export const getAppointmentDate = (data, t?, dateFormat = "dddd, DD MMMM YYYY") => {
  const translate = text => {
    return i18nProvider.t(text);
  };

  const formatTranslatedDate = date => {
    const [weekDay, restOfDate] = date.split(",");
    if (!restOfDate) return "";

    const [day, month, year] = restOfDate.trim().split(" ");
    const translatedWeekDay = translate(weekDay);
    const translatedMonth = translate(month);
    return `${translatedWeekDay}, ${day} ${translatedMonth} ${year}`;
  };
  const getDateFromTimeSlot = timeSlot => {
    return moment(JSON.parse(timeSlot).startTime).format(dateFormat);
  };

  if (data?.confirmedStartTime && data?.confirmedEndTime) {
    const formattedDate = moment(data?.confirmedStartTime).format(dateFormat);
    return formatTranslatedDate(formattedDate);
  }

  if (data?.suggestedTimeSlots?.[0]) {
    const formattedDate = getDateFromTimeSlot(data?.suggestedTimeSlots?.[0]);
    return formatTranslatedDate(formattedDate);
  }

  if (data?.requestedTimeSlots?.[0]) {
    const formattedDate = getDateFromTimeSlot(data?.requestedTimeSlots?.[0]);
    return formatTranslatedDate(formattedDate);
  }

  const formattedDate = moment(data?.created).format(dateFormat);
  return formatTranslatedDate(formattedDate);
};

export const getTimeFrame = item => {
  if (item?.confirmedStartTime && item?.confirmedEndTime) {
    return `${moment(item?.confirmedStartTime).format("hh:mmA")} - ${moment(item?.confirmedEndTime).format("hh:mmA")}`;
  }
  if (item?.suggestedTimeSlots?.[0]) {
    return item?.suggestedTimeSlots
      ?.map(slot => `${moment(JSON.parse(slot!)?.startTime).format("hh:mmA")} - ${moment(JSON.parse(slot!)?.endTime).format("hh:mmA")}`)
      .join(", ");
  } else {
    if (item?.requestedTimeSlots?.[0]) {
      return item?.requestedTimeSlots
        ?.map(slot => `${moment(JSON.parse(slot!)?.startTime).format("hh:mmA")} - ${moment(JSON.parse(slot!)?.endTime).format("hh:mmA")}`)
        .join(", ");
    } else {
      return "-";
    }
  }
};
