/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPromotionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetPromotionQuery = { __typename?: 'Query', promotion?: { __typename?: 'Promotion', id: string, name: string, photo?: string | null, expiryDate: any, description?: string | null, created: any, published: boolean, publishDate: any, healthPackages?: { __typename?: 'HealthPackageCountableConnection', edges: Array<{ __typename?: 'HealthPackageCountableEdge', node: { __typename?: 'HealthPackage', name: string, id: string } }> } | null, productCategories?: { __typename?: 'CategoryCountableConnection', edges: Array<{ __typename?: 'CategoryCountableEdge', node: { __typename?: 'Category', id: string, name: string } }> } | null, vendorProducts?: { __typename?: 'VendorProductVariantCountableConnection', edges: Array<{ __typename?: 'VendorProductVariantCountableEdge', node: { __typename?: 'VendorProductVariant', id: string, productVariant: { __typename?: 'ProductVariant', name?: string | null, id: string } } }> } | null, vendor?: { __typename?: 'Vendor', id: string, name: string } | null } | null };


export const GetPromotionDocument = gql`
    query getPromotion($id: ID!) {
  promotion(id: $id) {
    id
    name
    photo
    expiryDate
    description
    created
    published
    publishDate
    healthPackages(first: 10) {
      edges {
        node {
          name
          id
        }
      }
    }
    productCategories(first: 10) {
      edges {
        node {
          id
          name
        }
      }
    }
    vendorProducts(first: 10) {
      edges {
        node {
          id
          productVariant {
            name
            id
          }
        }
      }
    }
    vendor {
      id
      name
    }
  }
}
    `;

/**
 * __useGetPromotionQuery__
 *
 * To run a query within a React component, call `useGetPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPromotionQuery(baseOptions: Apollo.QueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, options);
      }
export function useGetPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, options);
        }
export type GetPromotionQueryHookResult = ReturnType<typeof useGetPromotionQuery>;
export type GetPromotionLazyQueryHookResult = ReturnType<typeof useGetPromotionLazyQuery>;
export type GetPromotionQueryResult = Apollo.QueryResult<GetPromotionQuery, GetPromotionQueryVariables>;