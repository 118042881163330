import { SvgIcon, useTheme } from "@mui/material";
import React from "react";

export const CollapseIcon = () => {
  const { palette } = useTheme();

  return (
    <SvgIcon
      className='svg-icon'
      sx={{
        overflow: "hidden",
        fill: palette.primary.main,
        verticalAlign: "middle",
        width: "14px",
        height: "14px",
      }}
      viewBox='0 0 1024 1024'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M341.333333 384 170.666667 384C145.066667 384 128 366.933333 128 341.333333s17.066667-42.666667 42.666667-42.666667l170.666667 0c25.6 0 42.666667 17.066667 42.666667 42.666667S366.933333 384 341.333333 384z' />
      <path d='M341.333333 384C315.733333 384 298.666667 366.933333 298.666667 341.333333L298.666667 170.666667c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 170.666667C384 366.933333 366.933333 384 341.333333 384z' />
      <path d='M341.333333 384C328.533333 384 320 379.733333 311.466667 371.2l-298.666667-298.666667c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l298.666667 298.666667c17.066667 17.066667 17.066667 42.666667 0 59.733333C362.666667 379.733333 354.133333 384 341.333333 384z' />
      <path d='M853.333333 384l-170.666667 0c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667l170.666667 0c25.6 0 42.666667 17.066667 42.666667 42.666667S878.933333 384 853.333333 384z' />
      <path d='M682.666667 384c-25.6 0-42.666667-17.066667-42.666667-42.666667L640 170.666667c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 170.666667C725.333333 366.933333 708.266667 384 682.666667 384z' />
      <path d='M682.666667 384c-12.8 0-21.333333-4.266667-29.866667-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733333l298.666667-298.666667c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333l-298.666667 298.666667C704 379.733333 695.466667 384 682.666667 384z' />
      <path d='M341.333333 725.333333 170.666667 725.333333c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667l170.666667 0c25.6 0 42.666667 17.066667 42.666667 42.666667S366.933333 725.333333 341.333333 725.333333z' />
      <path d='M341.333333 896c-25.6 0-42.666667-17.066667-42.666667-42.666667l0-170.666667c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 170.666667C384 878.933333 366.933333 896 341.333333 896z' />
      <path d='M42.666667 1024c-12.8 0-21.333333-4.266667-29.866667-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733333l298.666667-298.666667c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333l-298.666667 298.666667C64 1019.733333 55.466667 1024 42.666667 1024z' />
      <path d='M853.333333 725.333333l-170.666667 0c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667l170.666667 0c25.6 0 42.666667 17.066667 42.666667 42.666667S878.933333 725.333333 853.333333 725.333333z' />
      <path d='M682.666667 896c-25.6 0-42.666667-17.066667-42.666667-42.666667l0-170.666667c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 170.666667C725.333333 878.933333 708.266667 896 682.666667 896z' />
      <path d='M981.333333 1024c-12.8 0-21.333333-4.266667-29.866667-12.8l-298.666667-298.666667c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l298.666667 298.666667c17.066667 17.066667 17.066667 42.666667 0 59.733333C1002.666667 1019.733333 994.133333 1024 981.333333 1024z' />
    </SvgIcon>
  );
};
