import { ServiceDetailsInput } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ModalCloseIcon, Typography } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { useStyles } from "./PatientAppointmentForm.styles";

type ServiceDetailEditFormProps = {
  service: ServiceDetailsInput;
  onSubmit: (service: ServiceDetailsInput) => void;
  onClose: () => void;
};

export const ServiceDetailEditForm: FC<ServiceDetailEditFormProps> = ({ service, onSubmit: handleOnSubmit, onClose }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useStyles();

  const methods = useForm<ServiceDetailsInput>({
    mode: "onChange",
    defaultValues: service,
  });
  const { handleSubmit } = methods;
  const onSubmit = async (data: ServiceDetailsInput) => {
    handleOnSubmit(data);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classes.title}>
        <Typography fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("Edit Service Type")}
        </Typography>
        <IconButton onClick={onClose} sx={{ width: 40 }}>
          <ModalCloseIcon sx={{ width: 10, height: 10 }} />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField disabled name='serviceCode' label={t("Code")} />
              </Grid>

              <Grid item xs={12} columnSpacing={2}>
                <CustomTextField isRequired multiline minRows={4} name='standard' label={t("Description")} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.action}>
            <Button type='submit'>{t("Edit")}</Button>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  );
};
