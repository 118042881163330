import { getAutocompleteFilter } from "@toolkit/ui";
import { useVendorsAutocompleteQuery } from "./gql";
export const getVendorsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "vendors",
        query: useVendorsAutocompleteQuery,
        labelBy: "name",
        arabicLabelBy: "nameAr",
        backendAccessor: "id",
    });
};
