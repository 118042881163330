import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const NewJob: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='27.76' height='26.999' viewBox='0 0 27.76 26.999' {...props}>
      <path
        id='icons8-new-job'
        d='M14,3a2,2,0,0,0-2,2H4A2,2,0,0,0,2,7v9a2,2,0,0,0,2,2H18.8a3.04,3.04,0,0,1,2.293-1.057,3.1,3.1,0,0,1,.637.068,3.056,3.056,0,0,1,4.543,0,3.084,3.084,0,0,1,.635-.068,3,3,0,0,1,.785.115A1.985,1.985,0,0,0,28,16V7a2,2,0,0,0-2-2H18a2,2,0,0,0-2-2Zm1,11a1,1,0,1,1-1,1A1,1,0,0,1,15,14Zm9,4a1.051,1.051,0,0,0-.814.385l-.725.879-1.1-.285a1.057,1.057,0,0,0-1.32.957l-.068,1.137-1.061.418a1.055,1.055,0,0,0-.5,1.549l.611.961-.611.961a1.056,1.056,0,0,0,.5,1.549l1.061.418.068,1.137a1.057,1.057,0,0,0,1.32.957l1.1-.285.725.879a1.056,1.056,0,0,0,1.629,0l.725-.879,1.1.285a1.057,1.057,0,0,0,1.32-.957l.068-1.137,1.061-.418a1.055,1.055,0,0,0,.5-1.549L28.982,24l.611-.961a1.058,1.058,0,0,0-.5-1.551l-1.061-.418-.068-1.135a1.058,1.058,0,0,0-1.32-.959l-1.1.285-.725-.877A1.051,1.051,0,0,0,24,18ZM2,19.443V23a2,2,0,0,0,2,2H16.3a3.024,3.024,0,0,1,.348-1,3.059,3.059,0,0,1,.852-4H4A3.959,3.959,0,0,1,2,19.443Z'
        transform='translate(-2 -3)'
        fill={palette.success.main}
      />
    </SvgIcon>
  );
};
export default NewJob;
