import { Grid } from "@toolkit/ui";
import { FC } from "react";
import { DetailsCardInfoProps } from "./DetailsCard.types";
import { MyProfileDetailsPaper } from "./DetailsPaper.component";

export const PatientProfileSectionContainer: FC<DetailsCardInfoProps> = ({ items }) => {
  return (
    <Grid container spacing={1}>
      {items?.map((item, index) => (
        <Grid item key={index} xs={12} md={4} lg={item?.xs}>
          <MyProfileDetailsPaper item={item} />
        </Grid>
      ))}
    </Grid>
  );
};
