import { Box, Button, IconButton, Typography, useTheme } from "@toolkit/ui";
import { ISideButton } from "pages/e-visit/VitalSigns/types";
import { FC } from "react";

type SideButtonsProps = {
  items: ISideButton[];
  selected: ISideButton;
  onClick: (item: ISideButton) => void;
  isHidden: boolean;
};

export const SideButtons: FC<SideButtonsProps> = props => {
  const { items, selected, onClick, isHidden } = props;
  const theme = useTheme();

  return (
    <Box sx={{ width: "90px", display: "flex", flexDirection: "column" }}>
      {items?.map(el => (
        <Box
          key={el.name}
          sx={{
            height: "90px",
            width: "90px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          component={componentProps => <Button variant='text' {...componentProps} disabled={isHidden && el?.name == "Referrals"} />}
          onClick={onClick.bind(null, el)}
        >
          <IconButton
            sx={{
              border: "1px solid " + theme.palette.primary.main,
              backgroundColor: selected && el?.name === selected?.name ? theme.palette.primary.main : theme.palette.common.white,
              "& svg": {
                color: selected && el?.name === selected?.name ? theme.palette.common.white : theme.palette.primary.main,
              },
            }}
            disableRipple
          >
            {el.icon}
          </IconButton>

          <Typography
            noWrap
            gutterBottom
            sx={{ fontSize: theme.mixins.fonts.fontSize.md }}
            fontFamily={theme.mixins.fonts.fontFamily.regular}
          >
            {el.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
