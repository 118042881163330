/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllPromotionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PromotionFilterInput>;
  sortBy?: Types.InputMaybe<Types.PromotionSortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAllPromotionsQuery = { __typename?: 'Query', promotions?: { __typename?: 'PromotionCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'PromotionCountableEdge', node: { __typename?: 'Promotion', published: boolean, publishDate: any, photo?: string | null, name: string, modified: any, id: string, expiryDate: any, description?: string | null, created: any, productCategories?: { __typename?: 'CategoryCountableConnection', edges: Array<{ __typename?: 'CategoryCountableEdge', node: { __typename?: 'Category', id: string, name: string } }> } | null, healthPackages?: { __typename?: 'HealthPackageCountableConnection', edges: Array<{ __typename?: 'HealthPackageCountableEdge', node: { __typename?: 'HealthPackage', id: string, name: string } }> } | null } }> } | null };


export const GetAllPromotionsDocument = gql`
    query getAllPromotions($filter: PromotionFilterInput, $sortBy: PromotionSortingInput, $before: String, $after: String, $first: Int, $last: Int) {
  promotions(
    filter: $filter
    sortBy: $sortBy
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    edges {
      node {
        published
        publishDate
        photo
        name
        modified
        id
        expiryDate
        description
        created
        productCategories(first: 10) {
          edges {
            node {
              id
              name
            }
          }
        }
        healthPackages(first: 10) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllPromotionsQuery__
 *
 * To run a query within a React component, call `useGetAllPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPromotionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAllPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPromotionsQuery, GetAllPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPromotionsQuery, GetAllPromotionsQueryVariables>(GetAllPromotionsDocument, options);
      }
export function useGetAllPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPromotionsQuery, GetAllPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPromotionsQuery, GetAllPromotionsQueryVariables>(GetAllPromotionsDocument, options);
        }
export type GetAllPromotionsQueryHookResult = ReturnType<typeof useGetAllPromotionsQuery>;
export type GetAllPromotionsLazyQueryHookResult = ReturnType<typeof useGetAllPromotionsLazyQuery>;
export type GetAllPromotionsQueryResult = Apollo.QueryResult<GetAllPromotionsQuery, GetAllPromotionsQueryVariables>;