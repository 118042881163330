/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import { hasPermission } from "@health/common";
import { PermissionEnum, ProductVariant, SaleType } from "@health/queries/types";
import { TFunction, i18n } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { useBranchesAutocompleteOptionsQuery, useCategoriesClientQuery, useProductBrandsQuery, useProductTypesQuery } from "../../gql";
import { ProductVariantReviews } from "../ProductVariantReviews";
import { ShowProductImages } from "./ShowProductImages.component";
import ShowProductStocks from "./ShowProductStocks.component";
import { formatAttributes, formatPrice } from "./utils";
import { DiscountDialog } from "./DiscountDialog";
import moment from "moment";

export const ProductColumns = (t: TFunction): CustomTableColumnProps<ProductVariant>[] => {
  return useMemo(() => {
    return [
      {
        key: "product-image",
        header: t("Product Image", { ns: "provider" }),
        accessor: ({ product }) => {
          return <ShowProductImages product={product} />;
        },
      },
      {
        key: "product-name",
        header: t("Product Name", { ns: "provider" }),
        accessor: ({ product }) => (i18n.language === "ar" ? product?.nameAr : product?.name || "--"),
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "variant-name",
        header: t("Variant Name", { ns: "provider" }),
        accessor: ({ name, product, attributes }) => {
          const hasVariants = product?.productType?.hasVariants;
          const attributesFormatted = attributes?.map(item => formatAttributes(item)).join(" - ");
          return hasVariants ? attributesFormatted || "--" : name || "--";
        },
      },

      {
        key: "reviews",
        header: t("Reviews", { ns: "provider" }),
        accessor: ({ id }) => <ProductVariantReviews id={id} />,
        isHidden: !hasPermission(PermissionEnum.ManageCodeSystemReviewers),
        hideFromPreferencesAndTable: !hasPermission(PermissionEnum.ManageCodeSystemReviewers),
      },
      {
        key: "stocks",
        header: t("Stocks", { ns: "provider" }),
        accessor: ({ vendorProductVariant }) => <ShowProductStocks stocks={vendorProductVariant?.stocks} />,
      },
      {
        key: "price",
        header: t("Price", { ns: "provider" }),
        accessor: ({ vendorProductVariant }) => (vendorProductVariant?.price ? formatPrice(vendorProductVariant?.price) : "--"),
      },
      {
        key: "Branches",
        header: t("Branches", { ns: "provider" }),
        showOnlyForFilterField: true,
        filter: {
          name: "branches",
          type: "autocomplete",
          getOptionLabel: option => option?.name,
          multiple: true,
          query: useBranchesAutocompleteOptionsQuery,
          getValueForBackend: options => options?.map(item => item?.id),
          accessor: "branches",
        },
      },
      {
        key: "categories",
        header: t("Categories", { ns: "provider" }),
        showOnlyForFilterField: true,
        filter: {
          name: "categories",
          type: "autocomplete",
          getOptionLabel: option => option?.name,
          multiple: true,
          filtersForQuery: {
            name_Icontains: undefined, // to clear the hardcoded field in the smart autocomplete /frontend-toolkit/ui/src/components/AutocompleteController/SmartAutocompleteController.tsx:39
          },
          query: useCategoriesClientQuery,
          getValueForBackend: options => options?.map(item => item?.id),
          accessor: "categories",
        },
      },
      {
        key: "productBrands",
        header: t("Product Brands", { ns: "provider" }),
        showOnlyForFilterField: true,
        filter: {
          name: "productBrands",
          type: "autocomplete",
          getOptionLabel: option => option?.name,
          multiple: true,
          query: useProductBrandsQuery,
          getValueForBackend: options => options?.map(item => item?.id),
          accessor: "productBrands",
        },
      },
      {
        key: "productTypes",
        header: t("Product Types", { ns: "provider" }),
        showOnlyForFilterField: true,
        filter: {
          name: "productTypes",
          type: "autocomplete",
          getOptionLabel: option => option?.name,
          multiple: true,
          filtersForQuery: {
            name_Icontains: undefined, // to clear the hardcoded field in the smart autocomplete /frontend-toolkit/ui/src/components/AutocompleteController/SmartAutocompleteController.tsx:39
          },
          query: useProductTypesQuery,
          getValueForBackend: options => options?.map(item => item?.id),
          accessor: "productTypes",
        },
      },
      {
        key: "fromPrice",
        header: t("From Price", { ns: "provider" }),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "priceFrom",
        },
      },
      {
        key: "toPrice",
        header: t("To Price"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "priceTo",
        },
      },
      {
        key: "discount",
        header: t("Discount", { ns: "provider" }),
        accessor: ({ vendorProductVariant }) => {
          if (!vendorProductVariant?.sale?.value) return "--";
          const isPercentage = vendorProductVariant?.sale?.type === SaleType.Percentage;
          const discount = `${Math.round(vendorProductVariant?.sale?.value)}% ${t(" Off", { ns: "provider" })}`;

          return (
            <Typography
              sx={{
                width: "100px",
              }}
            >
              {isPercentage ? discount : `${vendorProductVariant?.sale?.value} ${t(vendorProductVariant?.price?.currency!)} ${t(" Off")}`}
            </Typography>
          );
        },
        filter: {
          options: [
            { name: t("Yes"), value: true },
            { name: t("No"), value: false },
          ],
          type: "autocomplete",
          name: "onSale",
          getOptionLabel: option => option?.name,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "discountExpiryDate",
        header: t("Discount Expiry Date", { ns: "provider" }),
        accessor: ({ vendorProductVariant }) =>
          vendorProductVariant?.sale?.endDate ? moment(vendorProductVariant?.sale?.endDate).format("DD MMM yyyy") : "--",
      },
      {
        key: "actions",
        header: t("Actions", { ns: "provider" }),
        accessor: row => (
          <Box sx={{ width: "200px" }}>
            {row?.vendorProductVariant?.id && (
              <DiscountDialog productId={row?.product?.id!} maxPrice={row?.vendorProductVariant?.price?.amount!} />
            )}
          </Box>
        ),
      },
    ];
  }, []);
};
