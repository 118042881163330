import { useReactiveVar } from "@apollo/client";
import { Patient } from "@health/queries/types";
import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, isNotificationMenuOpenedVar, Typography, useTheme } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { PatientProfileDrawer } from "../components/PatientProfileDrawer";
import { ProfileContextProvider } from "../context/ProfileContextProvider";
import { useGetPatientDataQuery } from "../gql";

type AccessResponseNotificationsProps = {
  data: { consentStatus: string; patientId: string };
};
export const AccessResponseNotifications: FC<AccessResponseNotificationsProps> = ({ data }) => {
  const { t } = useTranslation("provider");
  const isOpen = useReactiveVar(isNotificationMenuOpenedVar);
  const [open, setOpen] = useState(false);

  const { data: patientData } = useGetPatientDataQuery({
    variables: {
      patientId: data?.patientId!,
    },
    skip: !data?.patientId || !isOpen,
  });
  const patient = patientData?.patient! as Patient;
  const [patientInfo, setPatient] = useState<Patient | undefined>(undefined);
  useEffect(() => {
    patient && setPatient(patient);
  }, [JSON.stringify(patient)]);
  const handleToggleDrawer = isOpen => event => {
    event.stopPropagation();
    // isNotificationMenuOpenedVar(false);
    setOpen(isOpen);
    setPatient(patient!);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  return (
    <Box display='flex' gap={1} m={2} width='100%'>
      <Box
        sx={{
          backgroundColor: data?.consentStatus === "APPROVED" ? theme.palette.success.main : theme.palette.secondary.main,
          width: "35px",
          height: "35px",
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <CustomIcon icon='group' color='white' width={22} height={25} viewBox='0 0 28 28' />
      </Box>
      <div>
        <Typography whiteSpace='nowrap'>
          {t("Profile access Response For")}
          <Typography fontFamily={theme.mixins.fonts.fontFamily.medium}>{formatUserNameFull(patient!)}</Typography>
        </Typography>
        <Typography fontFamily={theme.mixins.fonts.fontFamily.medium} color={data?.consentStatus !== "APPROVED" ? "error" : "green"}>
          {t("Status")}: {data?.consentStatus === "APPROVED" ? t("Approved") : t("Rejected")}
        </Typography>
        <Button variant='outlined' color='primary' size='small' onClick={handleToggleDrawer(true)}>
          {data?.consentStatus === "APPROVED" ? t("View Profile") : t("Re-Send")}
        </Button>
        <ProfileContextProvider patientData={patientInfo as Patient} isOpen={open} onClose={handleClose}>
          <PatientProfileDrawer />
        </ProfileContextProvider>
      </div>
    </Box>
  );
};
