import { formatMessageErrors, updateCache } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { FetchHealthPackagesDocument, HealthPackageCloneMutation, useHealthPackageCloneMutation } from "pages/HealthPackages/gql";
import { FC, memo, useState } from "react";
import { CloneDialogProps } from "./CloneDialog.types";

const CloneDialog: FC<CloneDialogProps> = ({ row }) => {
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const [healthPackageCloneMutation, { loading: isCloneLoading }] = useHealthPackageCloneMutation({
    onCompleted: (data: HealthPackageCloneMutation) => {
      if (data?.healthPackageClone?.healthPackageErrors?.length! > 0) {
        failed(formatMessageErrors(data?.healthPackageClone?.healthPackageErrors));
      } else {
        succeeded(t("Succeeded in Cloning Health Package!"));
        setIsCloneModalOpen(false);
      }
    },
  });

  const handleClone = () => {
    if (isCloneLoading) return;
    healthPackageCloneMutation({
      variables: {
        healthPackageCloneId: row?.id,
      },
      update(cache, { data: healthPackageData }) {
        updateCache(cache, healthPackageData, FetchHealthPackagesDocument);
      },
    });
    handleClose();
  };

  const handleClose = () => {
    setIsCloneModalOpen(false);
  };
  const handleOpen = () => {
    setIsCloneModalOpen(true);
  };
  const theme = useTheme();
  return (
    <>
      <Button
        sx={{
          width: "150px",
        }}
        disabled={isCloneLoading}
        onClick={handleOpen}
      >
        {t("Clone")}
      </Button>
      <CustomDialog
        title={t("Clone Health Package")}
        open={isCloneModalOpen}
        onClose={handleClose}
        maxWidth='md'
        onCloseModal={handleClose}
        keepMounted={false}
        footerComponent={
          <>
            <Button onClick={handleClone} disabled={isCloneLoading} startIcon={isCloneLoading && <CircularProgress size={15} />}>
              {t("Clone")}
            </Button>
            <Button variant='outlined' onClick={handleClose} autoFocus>
              {t("Close")}
            </Button>
          </>
        }
        DialogContentProps={{ sx: { minHeight: "10vh" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography fontSize={theme.mixins.fonts.fontSize.xl} marginBottom='16px' color={theme.palette.primary.main}>
            {t("Are you sure you want to clone this Health Package?")}
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
};
export default memo(CloneDialog);
