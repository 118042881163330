import { useTranslation } from "@toolkit/i18n";
import { makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";

type OrderTimeoutProps = {
  title: string;
  time?: number;
  color: string;
};

export const useOrderTimeoutStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.stale.main,
  },
  time: {
    color,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
}));

export const OrderTimeout: FC<OrderTimeoutProps> = props => {
  const { title, time, color } = props;
  const { classes } = useOrderTimeoutStyles({ color });

  const { t } = useTranslation("provider");

  const timer = Number(time) / 60;
  const timeValue = Number(time) % 60 !== 0 ? timer.toFixed(2) : timer;

  return (
    <>
      {time && (
        <Typography className={classes.title}>
          {title} : <span className={classes.time}>{t("min", { count: Number(timeValue) })}</span>
        </Typography>
      )}
    </>
  );
};

OrderTimeout.defaultProps = {
  time: undefined,
};
