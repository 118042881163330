import { makeStyles } from "@toolkit/ui";

export const useGapsCircleStyle = makeStyles()(theme => ({
  root: {
    width: 25,
    height: 25,
    borderRadius: "50%",
    fontSize: 13,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.warning.main,
  },
}));
