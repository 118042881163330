import { Promotion } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, ShowButton, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useModal } from "shared/hooks/useModel";

export const PhotoComponent: FC<{ promotion: Promotion }> = ({ promotion }) => {
  const { t } = useTranslation("provider");
  const { handleToggle, open } = useModal();
  return (
    <>
      <ShowButton onClick={handleToggle} />
      {open && (
        <CustomDialog maxWidth='xs' scrollType='body' title={t("Promotion Picture")} open={open} onCloseModal={handleToggle}>
          {promotion?.photo ? (
            <Box
              component='img'
              sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                alignSelf: "center",
              }}
              alt={promotion?.name!}
              src={promotion?.photo as string}
            />
          ) : (
            <Typography>{t("There is no images to view")}</Typography>
          )}
        </CustomDialog>
      )}
    </>
  );
};
