import { FC } from "react";
import { useProfileContext } from "../../context/ProfileContext";
import { PatientProfileTabContainer } from "../PatientProfileTabContainer";

type PatientJourneyProps = {
  teamMembers: { key: string; label: string; value: string }[];
};
export const PatientJourney: FC<PatientJourneyProps> = ({ teamMembers }) => {
  const { sections } = useProfileContext();

  return <PatientProfileTabContainer sections={sections(teamMembers)} isContainer isRoot />;
};
