import { NumericPattern, getNumericValidation, getPositiveNumber, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, CustomIcon, Grid, IconButton, TextField, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { WarehouseRawProps } from "./Warehouse.type";

export const WarehouseRaw: FC<WarehouseRawProps> = ({
  index,
  handleAddRow,
  handleRemoveRow,
  branches,
  stocks,
  onFetchMore,
  isLoading,
  hasMore,
}) => {
  const { t } = useTranslation("provider");
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const watchedCurrentBranch = watch(`stocks`);
  const watchedCurrentBranchWithNoEmpty = watchedCurrentBranch?.filter(item => item.branch || item.quantity);
  const filterBranches = branches?.filter(val => {
    return !watchedCurrentBranchWithNoEmpty.find(val2 => {
      return val?.id === val2?.branch?.id;
    });
  });
  const optionsNotEmpty = filterBranches?.length == 0;
  const values = watchedCurrentBranch?.find(item => !item?.branch && !item?.quantity);
  const theme = useTheme();
  return (
    <>
      <Grid item xs={12} sm={5} md={4}>
        <AutocompleteController
          ControllerProps={{
            control: control,
            name: `stocks.[${index}].branch`,
            defaultValue: stocks?.[index]?.branch,
          }}
          TextFieldProps={{
            placeholder: t("Branches"),
            error: Boolean(errors?.stocks ? errors?.stocks?.branch?.message : null),
            helperText: errors?.stocks ? errors?.stocks?.branch?.message : null,
          }}
          loading={isLoading}
          hasMore={hasMore}
          fetchMoreData={onFetchMore}
          options={filterBranches || []}
          getOptionLabel={option => option?.name}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        <TextField
          label={t("Quantity")}
          placeholder={t("Quantity")}
          fullWidth
          type='text'
          error={Boolean(errors?.stocks ? errors?.stocks?.quantity?.message || errors?.stocks?.[index]?.quantity?.message : null)}
          helperText={errors?.stocks ? errors?.stocks?.quantity?.message || errors?.stocks?.[index]?.quantity?.message : null}
          defaultValue={stocks?.[index]?.quantity}
          InputProps={{
            inputProps: {
              inputMode: "numeric",
              pattern: "[0-9]*",
            },
          }}
          {...register(`stocks.[${index}].quantity`, {
            required: getRequiredValidation(t, true),
            min: getPositiveNumber(t, 0),
            pattern: getNumericValidation(t, NumericPattern),
          })}
        />
      </Grid>
      <Grid container justifyContent='flex-start' alignContent='center' item xs={12} sm md>
        <IconButton size={"small"} onClick={() => handleAddRow(index)} disabled={values || optionsNotEmpty}>
          <CustomIcon icon={"add"} width={30} height={26} viewBox='1 1 24 24' color={theme.palette.success.main} />
        </IconButton>
        {watchedCurrentBranch.length !== 1 && (
          <IconButton size={"small"} onClick={() => handleRemoveRow(index)}>
            <CustomIcon icon={"close"} width={30} height={26} viewBox='0 0 24 24' />
          </IconButton>
        )}
      </Grid>
    </>
  );
};
