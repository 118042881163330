import { GuidedCareTaskFilter, guidedCareTaskAssigneeTypeOptionsMap, guidedCareTaskStatusOptionsMap } from "@health/enum-options";
import { GuidedCareTask, GuidedCareTaskUpdateInput, TaskDefinitionStatus } from "@health/queries/types";
import { createAutocompleteOption } from "@toolkit/ui";
import { IGuidedCareTaskUpdateFormSchemaValues } from "pages/GuidedCareTasks/forms/GuidedCareTaskUpdateForm/GuidedCareTaskUpdateFormSchema";

export const getGuidedCareTaskListFilterStatus = (value: GuidedCareTaskFilter): TaskDefinitionStatus[] => {
  if (value === GuidedCareTaskFilter.Completed) {
    return [TaskDefinitionStatus.Done];
  } else if (value === GuidedCareTaskFilter.Uncompleted) {
    return [TaskDefinitionStatus.Pending];
  }

  return [TaskDefinitionStatus.Done, TaskDefinitionStatus.Pending, TaskDefinitionStatus.Missed, TaskDefinitionStatus.Cancelled];
};

export const isGuidedCareTaskActionable = (status: TaskDefinitionStatus) => {
  return status === TaskDefinitionStatus.Pending || status === TaskDefinitionStatus.InProgress || status === TaskDefinitionStatus.Missed;
};

export const convertGuidedCareTaskToFormValues = (task: GuidedCareTask): IGuidedCareTaskUpdateFormSchemaValues => {
  return {
    status: guidedCareTaskStatusOptionsMap[task?.status!],
    assignee: {
      id: task?.assignee?.id!,
      assigneeUserId: task?.assignee?.assigneeUserId!,
      assigneeType: guidedCareTaskAssigneeTypeOptionsMap[task?.assignee?.assigneeType!],
      teamMember: task?.assignee?.teamMember
        ? createAutocompleteOption(
            {
              id: task?.assignee?.teamMember?.id!,
              name: task?.assignee?.teamMember?.user?.fullName!,
            },
            "id",
            "name"
          )
        : undefined,
    },
  };
};

export const convertGuidedCareTaskFormValuesToBackEndValues = (
  values: IGuidedCareTaskUpdateFormSchemaValues
): GuidedCareTaskUpdateInput => {
  return {
    status: values?.status?.value,
    assignee: [
      {
        id: values?.assignee?.id,
        assigneeUserId: values?.assignee?.assigneeUserId!,
        assigneeType: values?.assignee?.assigneeType?.value!,
        teamMember: values?.assignee?.teamMember ? { id: values?.assignee?.teamMember?.value?.id! } : undefined,
      },
    ],
  };
};
