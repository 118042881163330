import { useTranslation } from "@toolkit/i18n";
import { last } from "lodash";
import { FC } from "react";
import BasicLineChart from "../Charts/BasicLineChart";
import { OverviewCard } from "./OverviewCard";
import { IVitalSignChart, IVitalSignChartData } from "./types";

type VitalSignBloodPressureChartProps = IVitalSignChart & {
  data2: IVitalSignChartData[];
};

export const VitalSignBloodPressureChart: FC<VitalSignBloodPressureChartProps> = props => {
  const { data, data2, width, percentage } = props;

  const { t } = useTranslation("provider");

  const lastItem = last(data);

  const value = `${Math.floor(last(data2)?.value! || 0)}/${Math.floor(lastItem?.value! || 0)}`;

  return (
    <OverviewCard
      width={width}
      title={t("Blood Pressure")}
      value={`${value || 0}`}
      unit={"mmHg"}
      description={t("By") + " " + (lastItem?.source || "-")}
      percentage={percentage}
      date={lastItem?.date!}
    >
      <BasicLineChart title={t("Blood Pressure")} data={data} data2={data2} />
    </OverviewCard>
  );
};
