import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const Notifications: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.832' height='18.848' viewBox='0 0 15.832 18.848' {...props}>
      <path
        id='icons8-notification_2_'
        data-name='icons8-notification (2)'
        d='M11.916,0a1.506,1.506,0,0,0-1.4,2.061,4.685,4.685,0,0,0-3.5,4.724c0,2.952-.724,4.3-1.414,5.054a5.994,5.994,0,0,1-.978.825,2.289,2.289,0,0,0-.4.353A.872.872,0,0,0,4,13.57a1.521,1.521,0,0,0,.766,1.249,5.151,5.151,0,0,0,1.743.624,21.317,21.317,0,0,0,3.3.342,2.259,2.259,0,1,0,4.37.8,2.227,2.227,0,0,0-.153-.8,21.317,21.317,0,0,0,3.3-.342,5.151,5.151,0,0,0,1.743-.624,1.521,1.521,0,0,0,.766-1.249.872.872,0,0,0-.224-.554,2.288,2.288,0,0,0-.4-.353,5.994,5.994,0,0,1-.978-.825c-.689-.757-1.414-2.1-1.414-5.054a4.691,4.691,0,0,0-3.5-4.724A1.506,1.506,0,0,0,11.916,0Zm0,.754a.754.754,0,1,1-.754.754A.748.748,0,0,1,11.916.754ZM12.8,2.709a3.872,3.872,0,0,1,3.263,4.076c0,3.079.783,4.66,1.6,5.56a6.708,6.708,0,0,0,1.1.931,2.149,2.149,0,0,1,.283.236c.046.052.035.047.035.059,0,.236-.109.408-.424.613a4.541,4.541,0,0,1-1.484.53,28.263,28.263,0,0,1-5.254.365,28.263,28.263,0,0,1-5.254-.365,4.541,4.541,0,0,1-1.484-.53c-.315-.2-.424-.377-.424-.613,0-.012-.01-.007.035-.059a2.149,2.149,0,0,1,.283-.236,6.708,6.708,0,0,0,1.1-.931c.819-.9,1.6-2.481,1.6-5.56a3.863,3.863,0,0,1,3.263-4.064,1.481,1.481,0,0,0,.883.294A1.465,1.465,0,0,0,12.8,2.709Zm-2.179,13.1c.423.009.853.024,1.3.024s.873-.015,1.3-.024a1.53,1.53,0,0,1,.212.777,1.508,1.508,0,1,1-2.8-.777Z'
        transform='translate(-4)'
        fill={props?.fill || palette.primary.main}
      />
    </SvgIcon>
  );
};
