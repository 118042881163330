import { getMediaLink } from "@health/common";
import { useTranslation } from "@toolkit/i18n";
import { Box, CardMedia, CustomDialog, EmptyCard, ImageIcon, ImageList, ImageListItem, useModal } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./styles";

export const ShowProductImages: FC<{ product }> = ({ product }) => {
  const { open, handleToggle } = useModal();
  const { t } = useTranslation("provider");
  const { classes } = useStyles();

  return (
    <CustomDialog
      open={open}
      title={t("Product Photo's")}
      onCloseModal={handleToggle}
      button={
        <Box onClick={handleToggle}>
          {product?.mainImageUrl ? (
            <CardMedia className={classes.imageContainer} image={getMediaLink(decodeURI(product?.mainImageUrl!))} />
          ) : (
            <Box className={classes.logoContainer}>
              <ImageIcon height={40} width={25} viewBox='0 -7 45 45' />
            </Box>
          )}
        </Box>
      }
    >
      {product?.images?.length != 0 ? (
        <ImageList sx={{ width: 500, height: 450 }} cols={3} gap={0} className={classes.gridList}>
          {product?.images?.map((image: any) => (
            <ImageListItem key={image?.id}>
              <img
                className={classes.imageListItem}
                height={product?.images?.length == 1 || product?.images?.length == 2 ? 300 : 150}
                width={"500px"}
                loading='lazy'
                src={getMediaLink(image?.url)}
                alt={image?.alt + "product-image"}
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <EmptyCard title={t("There are no images for this product.")} />
      )}
    </CustomDialog>
  );
};
