import { makeStyles } from "@toolkit/ui";

export const useOrderSectionStyles = makeStyles<{ color?: string; hasBG?: boolean; hasAction?: boolean; open?: boolean }>()(
  (theme, { color, hasBG, hasAction, open }) => ({
    title: {
      fontSize: theme.mixins.fonts.fontSize.sm,
      color: color ?? theme.palette.primary.main,
      fontFamily: theme.mixins.fonts.fontFamily.medium,
    },
    subTitle: {
      fontSize: theme.mixins.fonts.fontSize.sm,
      color: theme.palette.stale.main,
    },
    alerts: {
      fontSize: theme.mixins.fonts.fontSize.sm,
    },
    line: {
      marginTop: 5,
      marginBottom: 5,
    },
    section: {
      padding: 10,
      paddingInline: open ? 30 : 10,
      paddingBottom: !hasBG && hasAction ? 0 : 10,
      borderTop: hasBG ? `1px solid ${theme.palette.gray.light}` : "none",
      borderBottom: hasBG ? `1px solid ${theme.palette.gray.light}` : "none",
      background: hasBG ? theme.palette.gray.light : theme.palette.common.white,
    },
  })
);
