/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorProductVariantStocksDeleteMutationVariables = Types.Exact<{
  variantId: Types.Scalars['ID'];
  branchIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type VendorProductVariantStocksDeleteMutation = { __typename?: 'Mutation', vendorProductVariantStocksDelete?: { __typename?: 'ProductVariantStocksDelete', productVariant?: { __typename?: 'ProductVariant', id: string, name?: string | null } | null, stockErrors: Array<{ __typename?: 'StockError', field?: string | null, message?: string | null, code: Types.StockErrorCode }> } | null };


export const VendorProductVariantStocksDeleteDocument = gql`
    mutation vendorProductVariantStocksDelete($variantId: ID!, $branchIds: [ID!]) {
  vendorProductVariantStocksDelete(variantId: $variantId, branchIds: $branchIds) {
    productVariant {
      id
      name
    }
    stockErrors {
      field
      message
      code
    }
  }
}
    `;
export type VendorProductVariantStocksDeleteMutationFn = Apollo.MutationFunction<VendorProductVariantStocksDeleteMutation, VendorProductVariantStocksDeleteMutationVariables>;

/**
 * __useVendorProductVariantStocksDeleteMutation__
 *
 * To run a mutation, you first call `useVendorProductVariantStocksDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorProductVariantStocksDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorProductVariantStocksDeleteMutation, { data, loading, error }] = useVendorProductVariantStocksDeleteMutation({
 *   variables: {
 *      variantId: // value for 'variantId'
 *      branchIds: // value for 'branchIds'
 *   },
 * });
 */
export function useVendorProductVariantStocksDeleteMutation(baseOptions?: Apollo.MutationHookOptions<VendorProductVariantStocksDeleteMutation, VendorProductVariantStocksDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorProductVariantStocksDeleteMutation, VendorProductVariantStocksDeleteMutationVariables>(VendorProductVariantStocksDeleteDocument, options);
      }
export type VendorProductVariantStocksDeleteMutationHookResult = ReturnType<typeof useVendorProductVariantStocksDeleteMutation>;
export type VendorProductVariantStocksDeleteMutationResult = Apollo.MutationResult<VendorProductVariantStocksDeleteMutation>;
export type VendorProductVariantStocksDeleteMutationOptions = Apollo.BaseMutationOptions<VendorProductVariantStocksDeleteMutation, VendorProductVariantStocksDeleteMutationVariables>;