import { AppointmentType, Visit } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, IconButton, Typography, paths, useMediaQuery, useTheme } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const getStatus = (status: AppointmentType) => {
  switch (status) {
    case AppointmentType.AtHome:
      return "home";
    case AppointmentType.Onsite:
      return "virtual";
    case AppointmentType.Online:
      return "user3";
    default:
      return "user3";
  }
};
export const VisitActions: FC<{ title: string; hasAction: boolean }> = ({ title, hasAction }) => {
  const theme = useTheme();
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <IconButton
        disabled={!hasAction}
        sx={{
          height: 30,
          "&:disabled": {
            "& svg": {
              fill: theme.palette.gray[600],
            },
          },
        }}
      >
        <CustomIcon icon='ray' />
      </IconButton>
      <Typography
        fontSize={theme.mixins.fonts.fontSize.xs}
        sx={{
          color: !hasAction ? theme.palette.gray[600] : theme.palette.primary.main,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
export const PreviousVisits: FC<{ visits: Partial<Visit>[] }> = ({ visits }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:1045px)");
  const handleSeeAll = () => {
    navigate("/visit-history");
  };

  return (
    <>
      <Box display='flex' justifyContent={"space-between"} mb={1}>
        <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("Previous Visits")}{" "}
          <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontFamily={theme.mixins.fonts.fontFamily.regular} component='span'>
            ({t("last 90 days")})
          </Typography>
        </Typography>
        <Button
          variant='text'
          sx={{ color: theme.palette.secondary.main, fontSize: theme.mixins.fonts.fontSize.sm, height: 30 }}
          onClick={handleSeeAll}
        >
          {t("See All")}
        </Button>
      </Box>
      <Box
        sx={{
          padding: "12px",
          borderRadius: "10px",
          background: theme.palette.common.white,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          boxShadow: theme.mixins.shadows.main,
        }}
      >
        {visits?.map(visit => (
          <Box
            key={visit.id}
            display='flex'
            justifyContent='space-between'
            gap={1}
            p={1}
            sx={{
              alignItems: "center",
              borderRadius: "5px",
              height: isMobile ? "100%" : 65,
              backgroundColor: theme.palette.gray.light,
            }}
          >
            <Box display='flex'>
              <CustomIcon icon={getStatus(visit?.appointment?.type!) as keyof typeof paths} />
              <Box>
                <Typography
                  fontSize={theme.mixins.fonts.fontSize.sm}
                  color={theme.palette.primary.main}
                  textTransform={"capitalize"}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: 300,
                  }}
                >
                  {visit?.appointment?.status?.replaceAll("_", " ").toLowerCase() || visit?.status?.replaceAll("_", " ").toLowerCase()} -{" "}
                  {visit?.doctor?.user?.fullName}
                </Typography>
                <Typography color={theme.palette.stale.main} fontSize={theme.mixins.fonts.fontSize.sm}>
                  {moment(visit?.created).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </Box>
            <Box display='flex'>
              <VisitActions title={t("Lab")} hasAction={visit?.hasLab!} />
              <VisitActions title={t("Rad")} hasAction={visit?.hasRad!} />
              <VisitActions title={t("Note")} hasAction={visit?.hasDoctorNote!} />
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};
