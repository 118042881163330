import { makeStyles } from "@toolkit/ui";

export const useHealthPackageStyles = makeStyles()({
  imageListItem: {
    "&.MuiImageListItem-img": {
      width: "359px",
      height: "370px",
      margin: "10px",
      objectFit: "contain",
    },
    margin: "0px 5px 10px 0px",
  },
});
