import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import moment from "moment/moment";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { useDoctorLicenseQuery } from "./gql";
import { isExpiredLicenseEndDate } from "./utils";

export const DoctorLicenseNotification = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { accessToken } = useOidcUserProfile();

  const { data } = useDoctorLicenseQuery({
    skip: !accessToken,
    onCompleted: request => {
      const isExpired = moment().isAfter(request?.me?.healthLicenseEndDate);
      isExpiredLicenseEndDate(isExpired);
    },
  });

  const isExpired = moment().isAfter(data?.me?.healthLicenseEndDate);

  return (
    <Box
      sx={{
        display: isExpired ? "block" : "none",
        bgcolor: theme.palette.secondary.main,
        // zIndex: 1500,
        boxShadow: theme.mixins.shadows.md,
        borderBottom: "1px solid " + theme.palette.gray.light,
        m: 0,
      }}
    >
      <Box
        sx={{
          margin: "auto",
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          fontSize={theme.mixins.fonts.fontSize.lg}
          fontFamily={theme.mixins.fonts.fontFamily.medium}
          color={theme.palette.common.white}
        >
          {t("Your health license is not valid or expired")}
        </Typography>
      </Box>
    </Box>
  );
};
