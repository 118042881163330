import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const DiabetesIcon: FC<SvgIconProps> = props => {
  const { width, height, fill, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "26px"}
      height={height ? height : "18px"}
      viewBox={viewBox ? viewBox : "0 0 26 18"}
      {...props}
    >
      <path
        id='icons8-diabetes'
        d='M6.934,6A4.863,4.863,0,0,0,3.471,7.482L2,9V22a6.827,6.827,0,0,0,4.828,2H15l0-.006A2,2,0,0,0,17,22a1.974,1.974,0,0,0-.119-.682,1.952,1.952,0,0,1,.227-1.8A2.234,2.234,0,0,0,17.5,18.25a2.188,2.188,0,0,0-.111-.7,1.79,1.79,0,0,1,.494-1.762A2.225,2.225,0,0,0,18.5,14.25a2.28,2.28,0,0,0-.406-1.3A.6.6,0,0,1,18.568,12H26a2,2,0,0,0,1.973-2.336A2.075,2.075,0,0,0,25.893,8H11l-.578-.576A4.864,4.864,0,0,0,6.934,6ZM26,18s-2,2.9-2,4a2,2,0,0,0,4,0C28,20.9,26,18,26,18Z'
        transform='translate(-2 -5.998)'
        fill={fill ? fill : palette.common.white}
      />
    </SvgIcon>
  );
};
