import { ApolloProvider } from "@apollo/client";
import { NotificationsServiceProvider } from "@health/common";
import { getEnvVariable } from "@health/env";
import { MeetoraProvider } from "@health/meetora";
import { SadaProvider } from "@health/sada";
import { I18nextProvider } from "@toolkit/i18n";
import { FallbackComponent, ToolkitUiProvider } from "@toolkit/ui";
import React, { FC, PropsWithChildren, Suspense, useMemo } from "react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import { removeUserWorkingDetails } from "shared/components/UserWorkspaceSelection";
import { client } from "shared/configs/apollo";
import { oidcUserManager } from "shared/configs/oidc";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { i18nProvider } from "shared/i18n";
import { ErrorBoundary } from "./ErrorBoundary";
import { RootRouter } from "./shared/components/Root/RootRoutes";

window["getAuthLocalStorageKey"] = () =>
  `oidc.user:${getEnvVariable("KEYCLOAK_REALM_LINK", process.env.REACT_APP_KEYCLOAK_REALM_LINK)}:${getEnvVariable(
    "KEYCLOAK_CLIENT_ID_PROVIDER",
    process.env.REACT_APP_KEYCLOAK_CLIENT_ID_PROVIDER || "vendor"
  )}`;

const AppRootProviders: FC<PropsWithChildren> = ({ children }) => {
  const { accessToken: token, sseToken } = useOidcUserProfile();
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18nProvider}>
          <BrowserRouter>
            <ToolkitUiProvider>
              <Suspense fallback={<FallbackComponent />}>
                <NotificationsServiceProvider
                  eventSourceUrl={getEnvVariable("SUBSCRIPTION_URL", process.env.REACT_APP_SUBSCRIPTION_URL!)}
                  token={sseToken || ""}
                >
                  <SadaProvider token={token || ""}>
                    <MeetoraProvider token={token || ""}>{children}</MeetoraProvider>
                  </SadaProvider>
                </NotificationsServiceProvider>
              </Suspense>
            </ToolkitUiProvider>
          </BrowserRouter>
        </I18nextProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

function App(): React.ReactElement {
  const oidcConfig: AuthProviderProps = useMemo(
    () =>
      ({
        ...oidcUserManager.settings,
        onSigninCallback: user => {
          if (!user || !user?.access_token) return;
          oidcUserManager.storeUser(user);
          removeUserWorkingDetails((user?.profile?.vendor_user_type as string) || "");
        },
      } as AuthProviderProps),
    []
  );
  return (
    <AuthProvider {...oidcConfig}>
      <AppRootProviders>
        <RootRouter />
      </AppRootProviders>
    </AuthProvider>
  );
}

export default App;
