import { PatientNonChronicDisease } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../context/ProfileContext";
import { useAddPatientNonChronicDiseaseMutation, useDeletePatientNonChronicDiseaseMutation } from "../../gql";
import { PatientProfileSectionContainer } from "./PatientProfileSectionContainer";
import { getChronicDiseasesSectionContent } from "./chronic-diseases.utils";
import { AddNewDialog } from "./sections/AddNewDialog";

export const NonChronicDiseasesSection = () => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const theme = useTheme();
  const { patientCurrentMedicalStatus, patientId, userId, token } = useProfileContext();

  const [fetchAddPatientNonChronicDisease, { loading }] = useAddPatientNonChronicDiseaseMutation({
    onCompleted: response => {
      const patientNonChronicDiseases = response?.addPatientNonChronicDisease?.patientCurrentMedicalStatus?.patientNonChronicDiseases;
      if (patientNonChronicDiseases) {
        succeeded(t("Add Patient Non-Chronic Disease Successfully"));
      } else {
        failed(t("Add Patient Non-Chronic Disease Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFormToken(token),
  });

  const [fetchDeletePatientNonChronicDisease] = useDeletePatientNonChronicDiseaseMutation({
    onCompleted: response => {
      const patientNonChronicDiseases = response?.deletePatientNonChronicDisease?.patientCurrentMedicalStatus?.patientNonChronicDiseases;
      if (patientNonChronicDiseases) {
        succeeded(t("Delete Patient Non-Chronic Disease Successfully"));
      } else {
        failed(t("Delete Patient Non-Chronic Disease Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    context: getApolloContextFormToken(token),
  });

  const patientNonChronicDiseases = patientCurrentMedicalStatus?.patientNonChronicDiseases as PatientNonChronicDisease[];

  const handleAddPatientNonChronicDisease = data => {
    fetchAddPatientNonChronicDisease({
      variables: {
        input: {
          userId,
          patientId: patientId!,
          code: data?.code?.value?.code!,
        },
      },
    });
  };

  const handleDeletePatientChronicDisease = id => {
    fetchDeletePatientNonChronicDisease({
      variables: {
        userId,
        patientId: patientId!,
        deletePatientNonChronicDiseaseId: id,
      },
    });
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("Latest non-chronic diagnosis")}
        </Typography>
        <AddNewDialog title={t("Non-Chronic Diagnosis")} loading={loading} onAdd={handleAddPatientNonChronicDisease} />
      </Box>
      <PatientProfileSectionContainer
        items={getChronicDiseasesSectionContent<PatientNonChronicDisease>(
          patientNonChronicDiseases,
          t("There is no non-chronic diagnoses reported"),
          handleDeletePatientChronicDisease
        )}
      />
    </>
  );
};
