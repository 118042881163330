import { HealthPackage, HealthPackageApprovalStatus } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import {
  FetchHealthPackagesDocument,
  HealthPackageRequestApprovalMutation,
  useHealthPackageRequestApprovalMutation,
} from "pages/HealthPackages/gql";
import { FC } from "react";

export const HealthPackageRequestReviewButton: FC<{ row: HealthPackage }> = ({ row }) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const [healthPackageRequestApprovalMutation, { loading }] = useHealthPackageRequestApprovalMutation({
    onCompleted: (data: HealthPackageRequestApprovalMutation) => {
      if (data?.healthPackageRequestApproval?.healthPackageErrors?.length! > 0) {
        failed(formatMessageErrors(data?.healthPackageRequestApproval?.healthPackageErrors));
      } else {
        succeeded(t("Succeeded in Requesting Approval!"));
      }
    },
    refetchQueries: [{ query: FetchHealthPackagesDocument, variables: { first: 10, imagesFirst2: 10 } }],
  });

  const handleRequestReview = () => {
    healthPackageRequestApprovalMutation({
      variables: {
        healthPackageRequestApprovalId: row?.id,
      },
    });
  };

  const isButtonDisabled =
    row?.approvalStatus !== HealthPackageApprovalStatus.Draft && row?.approvalStatus !== HealthPackageApprovalStatus.Rejected;

  return (
    <Button
      variant='outlined'
      sx={{
        width: "150px",
      }}
      disabled={loading || isButtonDisabled}
      onClick={handleRequestReview}
    >
      {t("Request review")}
    </Button>
  );
};
