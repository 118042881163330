import { Grid } from "@toolkit/ui";
import { memo } from "react";
import { HealthConditions } from "../Reading/HealthConditions";
import { PatientVitalSignsCharts } from "shared/modules/patient/components/VitalSigns/PatientVitalSignsCharts";
import { BodyComposition } from "./BodyComposition";
import { ChronicDiseasesSection } from "./ChronicDiseases";
import { NonChronicDiseasesSection } from "./NonChronicDiseases";
import { Visits } from "./Visits";

export const PatientInfo = memo(() => {
  return (
    <Grid container spacing={2}>
      <BodyComposition />

      <PatientVitalSignsCharts />

      <Grid item container spacing={2} xs={12} alignContent='baseline'>
        <HealthConditions />
      </Grid>

      <Visits />

      <Grid item xs={12}>
        <ChronicDiseasesSection />
      </Grid>

      <Grid item xs={12}>
        <NonChronicDiseasesSection />
      </Grid>
    </Grid>
  );
});
