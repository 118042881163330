import { useOpenStateController } from "@health/common";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  CircularProgress,
  CustomMuiDialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  TruncateTypography,
  Typography,
  useAddToast,
} from "@toolkit/ui";
import React, { FC } from "react";
import { useGetRejectionReasonsQuery, useVendorOrderRejectMutation } from "../gql";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";
import { GetHealthPackageOrdersDocument } from "pages/HealthPackageOrders/gql";
import { OrderStatusEnum } from "@health/queries/types";
import { ellipseName } from "utils";

type RejectReasonModelProps = {
  id: string;
};

export const RejectReasonButton: FC<RejectReasonModelProps> = ({ id }) => {
  const { failed, succeeded } = useAddToast();

  const { t } = useTranslation("provider");
  const { classes, theme } = useOrderActionStyles();
  const [rejectionReason, setRejectionReason] = React.useState("");
  const { open: isOpen, handleOpen, handleClose } = useOpenStateController();
  const { data, loading } = useGetRejectionReasonsQuery({
    skip: !isOpen,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionReason((event.target as HTMLInputElement).value);
  };

  const orderRejectionReasons = data?.getRejectionReasons?.map(reason => reason);

  const [orderVendorReject, { loading: loadingReject }] = useVendorOrderRejectMutation({
    onCompleted: data => {
      if (data?.vendorOrderReject?.orderErrors?.length == 0) {
        succeeded(t("Order Rejected Successfully"));
        handleClose();
      } else {
        const msg = formatMessageErrors(data?.vendorOrderReject?.orderErrors);
        failed(msg);
      }
    },
    onError: error => {
      failed(t(formatGraphQLError(error?.graphQLErrors)));
      handleClose();
    },
    refetchQueries: [
      {
        query: GetHealthPackageOrdersDocument,
        variables: {
          first: 10,
          filter: {
            checkoutType: "HEALTH_PACKAGE",
            status: [OrderStatusEnum.NewRequest, OrderStatusEnum.WaitingProviderSelection],
          },
        },
      },
    ],
  });
  const handleOrderRejected = (): void => {
    const selectedReason = orderRejectionReasons?.find(reason => reason?.id === rejectionReason);
    orderVendorReject({
      variables: {
        id,
        rejectionReason: selectedReason?.display!,
      },
    });
  };

  return (
    <CustomMuiDialog
      open={isOpen}
      onBackdropClick={handleClose}
      DialogTitleProps={{
        title: t("Reject Request Reason"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        children: (
          <CircularIntegration loading={loadingReject}>
            <Button onClick={handleOrderRejected} disabled={!rejectionReason} className={classes.reject}>
              {t("Reject")}
            </Button>
          </CircularIntegration>
        ),
      }}
      button={
        <Button color='error' variant='outlined' onClick={handleOpen}>
          {t("Reject")}
        </Button>
      }
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <RadioGroup value={rejectionReason} onChange={handleChange}>
          {orderRejectionReasons?.length ? (
            orderRejectionReasons?.map(reason => (
              <FormControlLabel
                key={reason?.id}
                value={reason?.id}
                control={<Radio />}
                label={
                  <TruncateTypography
                    text={String(ellipseName(reason?.display, 100))}
                    sx={{
                      fontSize: theme.mixins.fonts.fontSize.sm,
                      color: rejectionReason !== reason?.display ? theme.palette.stale.main : theme.palette.primary.main,
                    }}
                  />
                }
              />
            ))
          ) : (
            <Typography>{t("There is no Reject Request Reason")}</Typography>
          )}
        </RadioGroup>
      )}
    </CustomMuiDialog>
  );
};
