import React, { FC } from "react";
import PackageForm from "../PackageForm.component";
import { usePackageEditPageHook } from "./usePackageEditPageHook";

const PackageEditPage: FC = () => {
  const { healthPackage, errors, isLoading, isFetchingUpdate, handleUpdatePackage } = usePackageEditPageHook();

  return (
    <PackageForm
      isSaving={isFetchingUpdate}
      isEditMode
      isLoading={isLoading}
      healthPackage={healthPackage!}
      onSubmit={handleUpdatePackage}
      errors={errors}
    />
  );
};

export default PackageEditPage;
