import { Box, Typography, useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { TemplateFieldType } from "@health/queries/types";

type TableChartTypeProps = {
  type: string;
  tableOptions: {
    id: string;
    type: string;
    valueBoolean: boolean;
    valueString: string;
    source: string;
    date: string;
  }[];
};

export const HealthParameterFieldDataType: FC<TableChartTypeProps> = ({ type, tableOptions }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='space-between' mx={1} px={1} py={0.5}>
        <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("Value")}
        </Typography>
        <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("Date")}
        </Typography>
        <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("Source")}
        </Typography>
      </Box>
      {tableOptions?.slice(0, 3)?.map((item, index) => {
        const valueBoolean = item?.valueBoolean ? t("Yes") : t("No");
        return (
          <Box
            mx={1}
            p={1}
            key={item?.id}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              backgroundColor: index % 2 === 0 ? theme.palette.background.default : "transparent",
            }}
          >
            <Typography fontSize={theme.mixins.fonts.fontSize.xs} fontFamily={theme.mixins.fonts.fontFamily.medium}>
              {type === TemplateFieldType.Boolean ? valueBoolean : item?.valueString}
            </Typography>
            <Typography fontSize={theme.mixins.fonts.fontSize.xs}>{item?.date}</Typography>
            <Typography fontSize={theme.mixins.fonts.fontSize.xs}>{item?.source}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
