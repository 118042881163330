import { useTranslation } from "@toolkit/i18n";
import { CollapseIcon, Dialog, IconButton } from "@toolkit/ui";
import { FC } from "react";
import { useOrdersCardStyles } from "./OrdersCard.styles";
import { OrdersDialogProps } from "./OrdersCard.types";

export const OrdersDialog: FC<OrdersDialogProps> = props => {
  const { children, open, onToggle: handleToggle, color } = props;
  const { classes } = useOrdersCardStyles({ color });
  const { i18n } = useTranslation();
  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      open={!!open}
      onClose={handleToggle}
      classes={{
        paper: `${classes.paper} ${classes.border}`,
      }}
    >
      <IconButton onClick={handleToggle} className={i18n.language == "ar" ? `${classes.icon} ${classes.iconRtl}` : classes.icon}>
        <CollapseIcon />
      </IconButton>
      <div>{children}</div>
    </Dialog>
  );
};
