import { useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, CustomIcon, Rating, RatingIcon, ShowButton, Typography, TypographyMedium, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useModal } from "shared/hooks/useModel";

export const ReviewContent: FC<{ rating: number; content: string }> = ({ rating, content }) => {
  const { handleToggle, open } = useModal();
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <>
      <ShowButton onClick={handleToggle} />
      {open && (
        <CustomDialog open={open} onCloseModal={handleToggle} maxWidth='sm' title={t("Review details") + ": "}>
          <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
            <Box display='flex' flexDirection='row'>
              <TypographyMedium marginRight='10px'>{t("Rating")}:</TypographyMedium>
              <Rating
                value={rating!}
                icon={<RatingIcon />}
                disabled={true}
                emptyIcon={<CustomIcon icon='rating' color={theme.palette.gray.light} width={22} height={20} viewBox='0 0 22 22' />}
              />
            </Box>
            <TypographyMedium display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
              {"Review"}: <Typography marginLeft='10px'>{content!}</Typography>
            </TypographyMedium>
          </Box>
        </CustomDialog>
      )}
    </>
  );
};
