/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.ProductVariantFilterInput>;
  sortBy?: Types.InputMaybe<Types.VariantSortingInput>;
}>;


export type ProductVariantsQuery = { __typename?: 'Query', productVariants?: { __typename?: 'ProductVariantCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductVariantCountableEdge', node: { __typename?: 'ProductVariant', id: string, name?: string | null, rating?: number | null, nameAr?: string | null, product?: { __typename?: 'Product', id: string, name: string, mainImageUrl?: string | null, images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null } | null, vendorProductVariant?: { __typename?: 'VendorProductVariant', id: string, price?: { __typename?: 'Money', currency: string, amount: number } | null, stocks?: Array<{ __typename?: 'Stock', quantity: number, branch?: { __typename?: 'Branch', id: string, name: string } | null } | null> | null, sale?: { __typename?: 'Sale', id: string, name: string, nameAr?: string | null, value: number, type: Types.SaleType, startDate: any, endDate: any } | null } | null, images?: Array<{ __typename?: 'ProductImage', url: string, alt: string } | null> | null } }> } | null };


export const ProductVariantsDocument = gql`
    query productVariants($first: Int, $after: String, $before: String, $last: Int, $filter: ProductVariantFilterInput, $sortBy: VariantSortingInput) {
  productVariants(
    first: $first
    after: $after
    before: $before
    last: $last
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
        rating
        product {
          id
          name
          mainImageUrl
          images {
            id
            url
          }
        }
        vendorProductVariant {
          id
          price {
            currency
            amount
          }
          stocks {
            quantity
            branch {
              id
              name
            }
          }
          sale {
            id
            name
            nameAr
            value
            type
            startDate
            endDate
          }
        }
        nameAr
        images {
          url
          alt
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useProductVariantsQuery__
 *
 * To run a query within a React component, call `useProductVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariantsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useProductVariantsQuery(baseOptions?: Apollo.QueryHookOptions<ProductVariantsQuery, ProductVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductVariantsQuery, ProductVariantsQueryVariables>(ProductVariantsDocument, options);
      }
export function useProductVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductVariantsQuery, ProductVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductVariantsQuery, ProductVariantsQueryVariables>(ProductVariantsDocument, options);
        }
export type ProductVariantsQueryHookResult = ReturnType<typeof useProductVariantsQuery>;
export type ProductVariantsLazyQueryHookResult = ReturnType<typeof useProductVariantsLazyQuery>;
export type ProductVariantsQueryResult = Apollo.QueryResult<ProductVariantsQuery, ProductVariantsQueryVariables>;