import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, Dialog, Divider, getTranslatedNameField, IconButton, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BranchUserForm from "./BranchUserForm";
import { branchUserPreferenceVar } from "./branchUserReactiveVar";
import { useCurrentWorkingBranchSelectDialogStyles } from "./CurrentWorkingBranchSelectDialog.styles";
import { removeSelectedBranchFromLocalStorage, setSelectedBranchToLocalStorage } from "./utils";
import { useAssignUserADefaultBranchMutation } from "gql/mutations";
import { isDoctorUser } from "utils";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { useReactiveVar } from "@apollo/client";

export const CurrentWorkingBranchSelectDialog: FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation("provider");
  const { classes } = useCurrentWorkingBranchSelectDialogStyles();
  const { userInfo } = useOidcUserProfile();
  const [fetchAssignUserADefaultBranch, { loading }] = useAssignUserADefaultBranchMutation();
  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;
  const currentUserWorkDetails = useReactiveVar(branchUserPreferenceVar);

  const onSubmit = async data => {
    if (data?.branch) {
      const dataBranch = {
        branch: data?.branch && {
          id: data?.branch?.id,
          name: data?.branch?.name,
          nameAr: data?.branch?.nameAr,
        },
        city: data?.city && {
          id: data?.city?.value?.id,
          name: getTranslatedNameField(data?.city?.value, i18n.language) || data?.city?.value?.name,
        },
      };
      await fetchAssignUserADefaultBranch({
        variables: {
          branchId: data?.branch?.id!,
        },
      });
      branchUserPreferenceVar(dataBranch);
      setSelectedBranchToLocalStorage(JSON.stringify(dataBranch));
    } else {
      removeSelectedBranchFromLocalStorage();
      branchUserPreferenceVar(undefined);
    }
    onClose();
  };

  return (
    <Dialog
      open={Boolean(isOpen)}
      classes={{
        paper: classes.paper,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.box}>
            <Box className={classes.header}>
              <Typography className={classes.title}>{t("Select Branch User")}</Typography>
              {currentUserWorkDetails && isDoctorUser(userInfo) && currentUserWorkDetails.branch && (
                <IconButton
                  onClick={onClose}
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                >
                  <CustomIcon icon='miniClose' viewBox='0 0 22 22' />
                </IconButton>
              )}
            </Box>
            <Divider light className={classes.line} />

            <Box
              sx={{
                margin: "15px 0px",
              }}
            >
              <BranchUserForm />
            </Box>
          </Box>
          <Box className={classes.action}>
            <Button className={classes.selectBtn} disabled={loading} type='submit'>
              {t("Select")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  );
};
