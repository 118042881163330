import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { PromotionEditPageForm } from "./Edit/PromotionEditPageForm";
import { PromotionNewPageForm } from "./New/PromotionNewPageForm";
import { PromotionsScreen } from "./PromotionsList.screen";

const promotionsRoute = `${marketplaceRoute}/promotions`;

export const promotionsPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${promotionsRoute}${this.route}`;
    },
  },
  addNew: {
    route: "new",
    get fullPath() {
      return `${promotionsRoute}/${this.route}`;
    },
  },
  edit: {
    route: "edit/:id",
    get fullPath() {
      return `${promotionsRoute}/${this.route}`;
    },
  },
};

export const promotionsRoutes: (props: { navigate: NavigateFunction; t: TFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  t,
  enabled,
}): RouteItem => {
  return {
    id: "promotions",
    text: t("Promotions", { ns: "provider" }),
    route: promotionsRoute,
    isProhibited: !(enabled && hasPermission(PermissionEnum.ManagePromotions)),
    hidden: !(enabled && hasPermission(PermissionEnum.ManagePromotions)),
    subItems: [
      {
        id: promotionsPaths.indexPage.fullPath,
        text: t("Promotions", { ns: "provider" }),
        route: promotionsPaths.indexPage.route,
        fullPath: promotionsPaths.indexPage.fullPath,
        element: <PromotionsScreen />,
        onClick: (route: string) => navigate(route),
        hidden: !(enabled && hasPermission(PermissionEnum.ManagePromotions)),
      },
      {
        id: promotionsPaths.addNew.fullPath,
        text: t("New Promotion", { ns: "provider" }),
        route: promotionsPaths.addNew.route,
        fullPath: promotionsPaths.addNew.fullPath,
        element: <PromotionNewPageForm />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
      {
        id: promotionsPaths.edit.fullPath,
        text: t("Edit Promotion", { ns: "provider" }),
        route: promotionsPaths.edit.route,
        fullPath: promotionsPaths.edit.fullPath,
        element: <PromotionEditPageForm />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
    ],
  };
};
