import { makeStyles } from "@toolkit/ui";

export const useTimeSlotWrapperStyle = makeStyles()(theme => ({
  root: {
    "&::before": {
      display: "none",
    },
  },
  accordionSummary: {
    paddingInline: 0,
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  divider: {
    width: "100%",
  },
  accordionDetails: {
    paddingInline: 0,
  },
  timeSlotsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  },
  timeSlotItem: {
    margin: 0,
    width: 152,
  },
}));
