/* eslint-disable no-nested-ternary */
import { NetworkStatus } from "@apollo/client";
import { Visit } from "@health/queries/types";
import { Card, EmptyCard, EmptyDataIcon, Grid, InfiniteScrollContainer } from "@toolkit/ui";
import React, { ReactNode } from "react";
import WaitingRoomsCard from "../WaitingRoomsCard";
import WaitingRoomsCardsSkeleton from "../WaitingRoomsCardsSkeleton";
import { useWaitingRoomsList } from "./useWaitingRoomsList";

type WaitingRoomsCardProps = {
  cardTitle?: ReactNode;
  cardBodyClassName?: string;
  cardProps?: React.ComponentProps<typeof Card>;
};
export const WaitingRoomsList: React.FC<WaitingRoomsCardProps> = ({ cardTitle, cardProps, cardBodyClassName }) => {
  const { visits, pageInfo, classes, fetchMoreData: handleFetchNextData, t, networkStatus, loading, cx } = useWaitingRoomsList();
  return (
    <Card sx={{ height: "420px" }} {...cardProps}>
      {cardTitle}
      {loading && networkStatus !== NetworkStatus.fetchMore && !visits?.length ? (
        <WaitingRoomsCardsSkeleton count={4} />
      ) : visits?.length ? (
        <div className={cx(classes.listContainer, cardBodyClassName)} id='scrollableDiv'>
          <InfiniteScrollContainer
            dataLength={Number(visits?.length) || 0}
            // eslint-disable-next-line react/jsx-handler-names
            next={handleFetchNextData}
            hasMore={Boolean(pageInfo?.hasNextPage)}
            loader={<WaitingRoomsCardsSkeleton count={5} />}
            scrollableTarget='scrollableDiv'
          >
            {visits?.map(visit => (
              <WaitingRoomsCard key={visit.id} visit={visit as Visit} />
            ))}
          </InfiniteScrollContainer>
        </div>
      ) : (
        <Grid container alignItems='center' justifyContent='center'>
          <EmptyCard title={t("No active virtual room")} message='' className={classes?.emptyCard} icon={<EmptyDataIcon />} />
        </Grid>
      )}
    </Card>
  );
};
