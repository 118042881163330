import { citiesManagementRoutes } from "./cities";
import { ordersRoutes } from "./orders";
import { ordersAdminRoutes } from "./ordersMangment";
import { productsManagementVendorRoutes } from "./product";
import { productsManagementRoutes } from "./products";
import { providersManagementRoutes } from "./providers";
import { subscriptionManagementRoutes } from "./subscriptions";
import { usersManagementRoutes } from "./users";
const dashboard = "dashboard";
export const ROUTE_PATHS = {
  dashboard: {
    name: "Dashboard",
    path: dashboard,
  },
  usersManagement: usersManagementRoutes,
  productsManagement: productsManagementRoutes,
  providersManagement: providersManagementRoutes,
  subscriptionsManagement: subscriptionManagementRoutes,
  productsManagementVendorRoutes: productsManagementVendorRoutes,
  ordersManagement: ordersRoutes,
  citiesManagement: citiesManagementRoutes,
  ordersAdminRoutes: ordersAdminRoutes,
  systemRules: {
    path: "system-rules",
  },
};
