import { i18n, useTranslation } from "@toolkit/i18n";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CustomMuiDialog,
  FormControlLabel,
  Tooltip,
  TruncateTypography,
  Typography,
} from "@toolkit/ui";
import React, { FC, Fragment, useState } from "react";
import { useOrderActionStyles } from "../actions/OrderAction.styles";
import { MedicationsMappingDialogProps } from "./types";
import { OrderSection } from "./OrderSection";

export const MedicationsMappingDialog: FC<MedicationsMappingDialogProps> = ({
  isLoading,
  products,
  buttonLabel,
  medications,
  onSubmit: handleAccept,
}) => {
  const { classes } = useOrderActionStyles();
  const { t } = useTranslation("provider");
  const [open, setOpen] = useState<boolean>(false);

  const [acceptedMedications, setAcceptedMedications] = useState<any[]>([]);

  const medicationsData = medications?.map(item => ({
    id: item?.id,
    display: item?.display,
    tradeCode: item?.code,
    quantity: item?.quantity,
    isChecked: false,
  }));

  const handleSelectedValue = (e, id) => {
    const values = medicationsData?.map(item => (item.id === id ? { ...item, isChecked: e.target.checked } : item)) || [];
    setAcceptedMedications([...values]);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleConfirm = () => {
    handleToggle();
    if (!!medications?.length && !acceptedMedications?.length) return;

    const inputs = acceptedMedications
      ?.filter(item => item.isChecked)
      ?.map(item => ({
        id: item?.id,
        tradeCode: item?.tradeCode,
        quantity: item?.quantity,
      }));
    handleAccept(inputs);
  };

  const buttonLabels = buttonLabel === t("View & Accept");
  const hasCheckedAllMedications = acceptedMedications?.some(item => !item.isChecked); // check if any item is checked
  const isDisabled = (!!medications?.length && (Boolean(!acceptedMedications?.length) || hasCheckedAllMedications)) || isLoading;

  return (
    <CustomMuiDialog
      open={open}
      button={
        <Tooltip title={buttonLabel}>
          <Button
            className={!buttonLabels ? classes.outlinedAccept : classes.accept}
            variant={buttonLabels ? "contained" : "outlined"}
            onClick={handleToggle}
          >
            {buttonLabel}
          </Button>
        </Tooltip>
      }
      DialogTitleProps={{
        title: medicationsData?.length ? t("Medications") : t("Products"),
        onClose: handleToggle,
      }}
      onClose={handleToggle}
      DialogActionsProps={{
        children: (
          <Tooltip title={acceptedMedications?.length === 0 || hasCheckedAllMedications ? t("Please select all medications") : ""}>
            <span>
              <Button startIcon={isLoading && <CircularProgress size={12} />} disabled={isDisabled} onClick={handleConfirm}>
                {t("Confirm")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      {medicationsData?.length ? (
        <>
          {medicationsData?.map(medication => {
            const currentItem = acceptedMedications.find(item => item?.id === medication?.id)!;

            return (
              <Fragment key={medication?.id}>
                <FormControlLabel
                  control={<Checkbox checked={currentItem?.isChecked} onChange={e => handleSelectedValue(e, medication?.id)} />}
                  label={
                    <Typography>
                      <strong>{t("Trade Name")}</strong> : {medication?.display! || medication?.tradeCode!} <strong>{t("Quantity")}</strong>{" "}
                      : {medication?.quantity}
                    </Typography>
                  }
                />
              </Fragment>
            );
          })}
        </>
      ) : (
        <>
          <OrderSection hasBG title={t("Products")}>
            {products?.map(line => (
              <Box key={line?.id} className={classes.box}>
                <Avatar variant='square' alt={line?.thumbnail?.alt!} src={line?.thumbnail?.url!} className={classes.avatar} />
                <div>
                  <TruncateTypography
                    text={i18n.language === "ar" ? line?.translatedProductName! || line?.productName! : line?.productName!}
                    className={classes.text}
                  />
                  <Typography className={classes.text}>
                    {t("sku")}: {line?.productSku}
                  </Typography>
                  <Typography className={classes.text}>
                    {t("Qty")}: {line?.quantity}
                  </Typography>
                </div>
              </Box>
            ))}
          </OrderSection>
        </>
      )}
    </CustomMuiDialog>
  );
};
