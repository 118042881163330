/* eslint-disable sonarjs/cognitive-complexity */
import { useTranslation } from "@toolkit/i18n";
import { Badge, Box, CustomIcon, EditByDocIcon, EditByUserIcon, EditIcon, IconButton, Paper, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useDetailsCardStyles } from "./DetailsCard.styles";
import { MyProfileDetailsItem } from "./DetailsCard.types";
import { UpdateField } from "./UpdateField";
import { useDetailsPaper } from "./useDetailsPaper";

export const MyProfileDetailsPaper: FC<{
  item: MyProfileDetailsItem;
}> = ({ item }) => {
  const { classes, theme } = useDetailsCardStyles({ status: String(item?.status), border: item?.border });
  const { t } = useTranslation("provider");
  const { open, value, handleClick, handleCancel, handleDelete, handleChangeValue, handleChangeOptionValue } = useDetailsPaper(item);

  return (
    <Paper sx={{ height: item?.height }} className={item?.action ? classes.actionPaper : classes.paper}>
      {item?.icon && <div className={item?.iconShape ? classes.iconShape : classes.iconHeader}>{item?.icon}</div>}
      {item?.action && <div className={classes.itemAction}>{item?.action}</div>}
      <div className={classes.header}>
        <div className={classes.titleIcon}>
          <div>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm} className={item?.defaultFont ? classes.defaultFont : classes.font}>
              {t(item?.title)}
              {item?.notifications && (
                <Badge className={classes.badge}>
                  <Typography className={classes.notifications} fontSize='small'>
                    {item?.notifications}
                  </Typography>
                </Badge>
              )}
            </Typography>
            {item?.date && (
              <Typography fontSize={theme.mixins.fonts.fontSize.xs} color={theme.palette.stale.main}>
                {t(item?.date)}
              </Typography>
            )}
          </div>
          {item?.isEdit &&
            (open ? (
              <div>
                <IconButton
                  onClick={handleCancel}
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                >
                  <CustomIcon icon='close2' width={20} height={50} viewBox='3 -7 10 30' />
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                >
                  <CustomIcon icon='checkok' width={20} height={50} viewBox='3 -6 10 30' />
                </IconButton>
              </div>
            ) : (
              <IconButton
                onClick={handleClick}
                sx={{
                  height: 20,
                }}
              >
                <EditIcon color='primary' className={classes.icon} />
              </IconButton>
            ))}
          <Box display='flex' alignItems='center' gap={1}>
            {item?.hasDelete && (
              <IconButton onClick={handleDelete}>
                <CustomIcon icon='delete' />
              </IconButton>
            )}
            {item?.isEditedByDoctor && <EditByDocIcon color='primary' className={classes.icon} />}
            {item?.isEditedByUser && <EditByUserIcon color='primary' className={classes.icon} />}
          </Box>
        </div>
        <div className={classes.titleIcon}>
          {open ? (
            <UpdateField item={item} value={value!} onChangeValue={handleChangeValue} onChangeOptionValue={handleChangeOptionValue} />
          ) : (
            item?.subTitle && (
              <Typography
                fontSize={theme.mixins.fonts.fontSize.xs}
                color={theme.palette.stale.main}
                sx={{ textDecoration: item?.subTitleStyle ? item?.subTitleStyle : "none" }}
              >
                {item?.subTitle}
              </Typography>
            )
          )}
          {item?.duration && (
            <Typography m='5px' fontSize={theme.mixins.fonts.fontSize.sm}>
              {item?.duration}
            </Typography>
          )}
          {item?.lastReading && (
            <Typography m='5px' fontSize={theme.mixins.fonts.fontSize.sm} color={item?.lastReadingStatus}>
              {t(item?.lastReading)}
            </Typography>
          )}
        </div>
      </div>
    </Paper>
  );
};
